import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MessageEntity } from 'models/api/support/message';
import { addLoadingMatchers } from 'store/utils/addLoadingMatchers';
import { CREATE_MESSAGE, GET_MESSAGES, getMessages } from '../actions/messagesActions';
import { getCursorValue } from 'utils/getCursorValue';
import { isNull } from 'lodash-es';
import { RootState } from 'store';
import { sortByDate } from 'utils/sortByDate';
import { ReadMessagesEventBody } from 'models/api/support/supportEvents';

type MessagesState = {
  ticketId: number | null;
  pagination: { left: number; cursor: string; limit: number } | null;
  isLoading: boolean;
  isMessageCreating: boolean;
};

const INITIAL_STATE: MessagesState = {
  ticketId: null,
  pagination: null,
  isLoading: false,
  isMessageCreating: false,
};

const messagesListAdapter = createEntityAdapter<MessageEntity>({
  sortComparer: (a, b) => sortByDate('asc')(a.createdAt, b.createdAt),
});

export const messagesListSelectors = messagesListAdapter.getSelectors((state: RootState) => state.messages);

const messagesSlice = createSlice({
  name: 'messages',
  initialState: messagesListAdapter.getInitialState<MessagesState>(INITIAL_STATE),
  reducers: {
    resetMessages: (state) => {
      messagesListAdapter.removeAll(state);
      state.pagination = INITIAL_STATE.pagination;
      state.isLoading = INITIAL_STATE.isLoading;
      state.isMessageCreating = INITIAL_STATE.isMessageCreating;
      state.ticketId = INITIAL_STATE.ticketId;
    },
    addMessage: (state, { payload }: PayloadAction<MessageEntity>) => {
      if (isNull(state.ticketId) || payload.ticketId !== state.ticketId) {
        return;
      }

      messagesListAdapter.addOne(state, payload);
    },
    updateMessagesReadAt: (state, { payload }: PayloadAction<ReadMessagesEventBody>) => {
      if (isNull(state.ticketId) || payload.ticketId !== state.ticketId) {
        return;
      }

      messagesListAdapter.updateMany(
        state,
        payload.readMessagesIds.map((id) => ({ id, changes: { readAt: payload.readAt } }))
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMessages.fulfilled, (state, { payload, meta }) => {
      if (!isNull(state.ticketId) && meta.arg.ticketId !== state.ticketId) {
        return;
      }

      state.ticketId = meta.arg.ticketId;

      if (meta.arg.cursor) {
        messagesListAdapter.addMany(state, payload.list);
      } else {
        messagesListAdapter.setAll(state, payload.list);
      }

      state.pagination = {
        limit: payload.pagination.perPage,
        cursor: getCursorValue(payload.pagination.nextPageUrl),
        left: payload.leftMessages,
      };
    });
    addLoadingMatchers(builder, GET_MESSAGES, 'isLoading');
    addLoadingMatchers(builder, CREATE_MESSAGE, 'isMessageCreating');
  },
});

export const { name, actions, reducer } = messagesSlice;
