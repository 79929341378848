import { apiGet, apiPost } from '../../../services/helper';
import { getDataFromResponse } from '../../../utils/getDataFromResponse';
import { asyncPipe } from '../../../utils/asyncPipe';
import { QuestionnaireApi } from './service.types';

const QUESTIONNAIRE_API_URL = '/questionary';

class QuestionnaireServiceFactory {
  getBasicQuestionnaireInfo = async () => {
    return asyncPipe(
      apiGet,
      getDataFromResponse
    )<QuestionnaireApi.getBasicQuestionnaireInfo.Response.Success>(QUESTIONNAIRE_API_URL);
  };

  uploadDocument = async (body: FormData) => {
    return asyncPipe(apiPost, getDataFromResponse)<QuestionnaireApi.uploadDocument.Response.Success>(
      `${QUESTIONNAIRE_API_URL}/file`,
      body,
      {
        'Content-Type': 'multipart/form-data',
      }
    );
  };

  removeDocument = async (body: FormData) => {
    return asyncPipe(apiPost, getDataFromResponse)<QuestionnaireApi.removeDocument.Response.Success>(
      `${QUESTIONNAIRE_API_URL}/file`,
      body,
      {
        'Content-Type': 'multipart/form-data',
      }
    );
  };

  changeQuestionnaireStatus = async (body: QuestionnaireApi.changeQuestionnaireStatus.Request.Body) => {
    return asyncPipe(apiPost, getDataFromResponse)<QuestionnaireApi.changeQuestionnaireStatus.Response.Success>(
      `${QUESTIONNAIRE_API_URL}/generate/status`,
      body,
      { 'Content-Type': 'application/json' }
    );
  };

  sendForm = async ({ filling, type }: QuestionnaireApi.sendForm.Request.Body) => {
    return asyncPipe(apiPost, getDataFromResponse)<QuestionnaireApi.sendForm.Response.Success>(
      `${QUESTIONNAIRE_API_URL}/fill`,
      {
        filling: JSON.stringify(filling),
        type,
      },
      { 'Content-Type': 'application/json' }
    );
  };

  resetQuestionnaire = async () => {
    return asyncPipe(
      apiPost,
      getDataFromResponse
    )<QuestionnaireApi.resetQuestionnaire.Response.Success>(`${QUESTIONNAIRE_API_URL}/reset`);
  };
}

export const QuestionnaireService = new QuestionnaireServiceFactory();
