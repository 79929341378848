import React from 'react';
import { Field } from 'features/Login/components/Field';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/ui/Button';
import arrowIcon from 'icons/arrowLeft.svg';
import * as S from 'features/Login/RestorePasswordForm/RestorePasswordForm.styled';
import { useRestorePasswordForm } from 'features/Login/RestorePasswordForm/hooks/useRestorePasswordForm';
import { RestorePasswordNamesEnum } from 'features/Login/consts';
import { useEmail } from 'features/Login/hooks/useEmail';
import { useRestorePasswordVisible } from 'features/Login/hooks/useRestorePasswordVisible';
import { Error } from 'features/Login/components/Error';
import { isHttpError } from 'errors/httpError';

type RestorePasswordFormProps = ReturnType<typeof useEmail> &
  Pick<ReturnType<typeof useRestorePasswordVisible>, 'closePasswordRestoring'>;

export function RestorePasswordForm({ closePasswordRestoring, email, onEmailChange }: RestorePasswordFormProps) {
  const { t } = useTranslation();
  const { form, isSuccess, onSubmit, isSubmitting, error } = useRestorePasswordForm();

  if (isSuccess) {
    return <div>{t('auth.На вашу электронную почту отправлено письмо со ссылкой для восстановления пароля')}</div>;
  }

  return (
    <S.RestorePasswordForm
      form={form}
      onFinish={onSubmit}
      initialValues={{
        [RestorePasswordNamesEnum.enum.email]: email,
      }}
    >
      {isHttpError(error) && error.hasOriginalMessage && (
        <Error
          message={error.message}
          mb={0}
        />
      )}
      <Field
        name={RestorePasswordNamesEnum.enum.email}
        variant="username"
        placeholder={t('auth.Электронная почта')}
        type="text"
        onChange={onEmailChange}
      />
      <Button
        width="100%"
        type="submit"
        disabled={isSubmitting}
        loading={isSubmitting}
      >
        {t('auth.Восстановить пароль')}
      </Button>
      <Button
        type="button"
        variant="outlined"
        icon={arrowIcon}
        width="100%"
        onClick={closePasswordRestoring}
      >
        {t('auth.Назад')}
      </Button>
    </S.RestorePasswordForm>
  );
}
