import { ZodType } from 'zod';

export function parseDataBySchema<T>(schema: ZodType<T>, data: unknown) {
  const result = schema.safeParse(data);

  if (result.success) {
    return [result.data, null] as const;
  }

  return [null, result.error] as const;
}
