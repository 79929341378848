import React from 'react';
import { Props as SVGProps } from 'react-inlinesvg';
import { iconsList } from '../iconsList';
import { DefaultTheme } from 'styled-components';
import { ResponsiveValue, SizeVariants } from './types';
import * as S from './SvgIcon.styled';
import { isUndefined } from 'lodash-es';

type NameXORPath =
  | {
      /** название иконки */
      name: keyof typeof iconsList;
      path?: never;
    }
  | {
      name?: never;
      /** импорт кастомной иконки */
      path: string;
    };

export type SvgIconProps = {
  /** размер иконки */
  size?: SizeVariants | false;
  /** размер иконки до указанного брейкпоинта */
  responsive?: { [key in keyof DefaultTheme['breakpoints']]?: ResponsiveValue };
} & NameXORPath &
  Omit<SVGProps, 'src' | 'width' | 'height'>;

export function SvgIcon({ name, path, size = 'large', responsive, ...props }: SvgIconProps) {
  if ((isUndefined(name) && isUndefined(path)) || (name && path)) {
    throw new Error(`В компонент ${SvgIcon.name} нужно передать либо свойство name либо path`);
  }

  return (
    <S.SVG
      {...props}
      src={name ? iconsList[name] : path}
      size={size}
      responsive={responsive}
    />
  );
}
