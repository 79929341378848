import { useState } from 'react';
import { useProfile } from '../../hooks/useProfile';

export const UserActive = ({ id, children }) => {
  const { store, updateUsers } = useProfile();
  const active = store?.user?.active;
  const user = {
    ...store?.user,
    roles: store?.user?.roles.map((role) => role.id),
  };
  const [loading, setLoading] = useState(false);

  const activeHandler = async () => {
    setLoading(true);
    await updateUsers(id, { ...user, active: !active });
    setLoading(false);
  };

  return children({ active, activeHandler, loading });
};
