import React from 'react';
import { useSelector } from 'react-redux';
import { WelcomeDocSearch } from './WelcomeDocSearch';
import { WelcomeRemains } from './WelcomeRemains';
import { useAppEnv } from 'hooks/useAppEnv';

function WelcomeModals() {
  const { globalLoader } = useSelector(({ loader }) => loader);
  const { permissions } = useSelector((state) => state?.auth?.init) || {};

  const { isTestAppEnv } = useAppEnv();

  return (
    <React.Fragment>
      {!globalLoader && !isTestAppEnv && (
        <React.Fragment>
          {permissions.includes('search.documents') && <WelcomeDocSearch />}
          {permissions.includes('balance.remains') && <WelcomeRemains />}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default WelcomeModals;
