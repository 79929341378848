import styled from 'styled-components';

const animation = `
  animation: fadeIn ease-in 0.15s forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

export const AlertWrapper = styled.div<{ isWide?: boolean }>`
  display: inline-flex;
  margin-top: ${({ theme }) => theme.space[16]};
  ${animation}

  ${({ isWide }) =>
    isWide &&
    `
      display: flex;
      margin-top: 0;
      
      
      &&& .ant-alert {
        border: none;
        border-radius: 0;
        width: 100%;
      }
  `}
`;

export const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.yellow};
  ${animation}

  svg {
    width: ${({ theme }) => theme.sizes[20]};
    height: ${({ theme }) => theme.sizes[20]};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
      width: ${({ theme }) => theme.sizes[16]};
      height: ${({ theme }) => theme.sizes[16]};
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
      width: ${({ theme }) => theme.sizes[14]};
      height: ${({ theme }) => theme.sizes[14]};
    }
  }
`;
