import { useMount } from 'react-use';
import { useAppEnv } from 'hooks/useAppEnv';

declare global {
  interface Window {
    setTestEnv: () => void;
    setProdEnv: () => void;
  }
}

export function useGlobalAppEnv() {
  const { setAppEnv } = useAppEnv();

  useMount(() => {
    window.setTestEnv = () => setAppEnv('test');
    window.setProdEnv = () => setAppEnv('prod');
  });
}
