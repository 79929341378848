import { LoginParamsSchema } from 'models/params/auth/login';
import { RestorePasswordParamsSchema } from 'models/params/auth/restorePassword';
import { z } from 'zod';

export const LoginFormSchema = LoginParamsSchema.pick({ username: true, password: true }).partial();

export const RestorePasswordSchema = RestorePasswordParamsSchema.pick({ email: true }).partial();

export const LoginErrorsSchema = z
  .object({
    username: z.array(z.string()),
    password: z.array(z.string()),
  })
  .partial();

export const RestorePasswordErrorsSchema = z.object({
  email: z.array(z.string()),
});
