import React from 'react';
import { Pagination as AntdPagination } from 'antd';
import './style.scss';
import { actionTriggering } from '../../analytics';

function Pagination(props) {
  const changePageHandler = (page) => {
    actionTriggering('paginate', {
      ActionTargetId: page,
      ActionTargetValue: props.total.toString(),
    });
    props.onChange(page);
  };
  return (
    <AntdPagination
      {...props}
      onChange={changePageHandler}
    />
  );
}

export default Pagination;
