import React, { useEffect, useRef, useState } from 'react';
import * as S from './Timer.styled';
import { useTranslation } from 'react-i18next';

type TimerProps = {
  duration: number;
  prefix: string;
};

export function Timer({ duration, prefix }: TimerProps) {
  const { t } = useTranslation();

  const [time, setTime] = useState(0);
  const timerId = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (time >= duration) {
      return;
    }

    timerId.current = setTimeout(setTime, 1000, time + 1);

    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
    };
  }, [duration, time]);

  return (
    <React.Fragment>
      {`${prefix} `}
      <S.Timer>
        {duration - time} {t('notifications.с')}
      </S.Timer>
    </React.Fragment>
  );
}
