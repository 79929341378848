import React from 'react';
import ReactSelect from 'react-select';
import './style.scss';

function NoOptionsMessage() {
  return <span className="custom-select__no-options">Нет вариантов!</span>;
}

function Select(props) {
  return (
    <div className="custom-select-container">
      <ReactSelect
        components={{ NoOptionsMessage }}
        {...props}
        className="custom-select"
        classNamePrefix="custom-select"
      />
    </div>
  );
}

export default React.memo(Select);
