import { apiDelete, apiGet, apiPost } from '../../../services/helper';

class FinancesServiceFactory {
  getDKZ = async () => {
    const { data } = await apiGet(`/v1/dkz`);

    return data;
  };
  getActs = async (search = '') => {
    const { data } = await apiGet(`/v1/acts${search}`);

    return data;
  };
  createActs = async (payload) => {
    try {
      const { data } = await apiPost('/v1/acts', payload);

      return data;
    } catch (error) {
      return error.response.data;
    }
  };
  removeAct = async (id) => {
    const { data } = await apiDelete(`/v1/acts/${id}`);

    return data;
  };
}

export const FinancesService = new FinancesServiceFactory();
