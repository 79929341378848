import { createAsyncThunk } from '@reduxjs/toolkit';
import { BannersService } from './service';
import { DisableBannerPayload, SendRequestFromBannerPayload } from './actions.types';

export const BANNERS_ACTION_PREFIX = 'banners';
export const SEND_REQUEST_FROM_BANNER_ACTION_TYPE = `${BANNERS_ACTION_PREFIX}/sendRequestFromBanner`;
export const DISABLE_BANNER_ACTION_TYPE = `${BANNERS_ACTION_PREFIX}/disableBanner`;

export const getBanners = createAsyncThunk(`${BANNERS_ACTION_PREFIX}/getBanners`, async () => {
  const { data } = await BannersService.getBanners();
  return data;
});

export const sendRequestFromBanner = createAsyncThunk(
  SEND_REQUEST_FROM_BANNER_ACTION_TYPE,
  async ({ bannerId, ...body }: SendRequestFromBannerPayload) => {
    return BannersService.sendRequestFromBanner(bannerId, body);
  }
);

export const disableBanner = createAsyncThunk(DISABLE_BANNER_ACTION_TYPE, async (body: DisableBannerPayload) => {
  return BannersService.disableBanner(body);
});
