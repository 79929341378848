import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors['grey-8']};
`;

export const Icon = styled.div`
  margin-right: 4px;
  display: flex;
  min-width: 16px;
  width: 16px;
  height: 16px;
  color: #2d81e0;
`;
