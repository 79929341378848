import styled, { DefaultTheme } from 'styled-components';
import { space, width } from 'styled-system';
import { ButtonVariants, SizesVariants } from './index';

const getButtonStylesByVariant = (theme: DefaultTheme, variant: ButtonVariants) => {
  const map = {
    primary: {
      default: {
        background: theme.colors.orange,
        color: theme.colors.white,
        borderColor: 'transparent',
        boxShadow: 'none',
      },
      hovered: {
        color: theme.colors.white,
        borderColor: 'transparent',
        boxShadow: 'none',
        background: theme.colors['orange-2'],
      },
      focused: {
        color: theme.colors.white,
        borderColor: 'transparent',
        background: theme.colors['orange-2'],
        boxShadow: '0px 0px 4px rgb(234 131 67 / 50%)',
      },
      active: {
        color: theme.colors.white,
        borderColor: 'transparent',
        background: theme.colors['orange-3'],
        boxShadow: 'none',
      },
      disabled: {
        background: theme.colors['orange-5'],
        color: theme.colors.white,
        borderColor: 'transparent',
        boxShadow: 'none',
      },
    },
    outlined: {
      default: {
        background: 'transparent',
        color: theme.colors['grey-5'],
        borderColor: theme.colors['grey-5'],
        boxShadow: 'none',
      },
      hovered: {
        background: 'transparent',
        boxShadow: 'none',
        color: theme.colors['grey-1'],
        borderColor: theme.colors['grey-1'],
      },
      focused: {
        background: 'transparent',
        color: theme.colors['grey-1'],
        borderColor: theme.colors['grey-1'],
        boxShadow: '0px 0px 4px rgb(147 147 147 / 50%)',
      },
      active: {
        background: 'transparent',
        color: theme.colors['grey-1'],
        borderColor: theme.colors['grey-1'],
        boxShadow: '0px 0px 4px rgb(147 147 147 / 50%)',
      },
      disabled: {
        background: 'transparent',
        color: theme.colors['grey-6'],
        borderColor: theme.colors['grey-6'],
        boxShadow: 'none',
      },
    },
  };

  return map[variant];
};

const getButtonStylesBySize = (theme: DefaultTheme, size: SizesVariants) => {
  const map = {
    sm: {
      height: theme.space['22'],
      padding: `0 ${theme.space['6']}`,
      lineHeight: theme.lineHeights['14'],
      fontSize: theme.fontSizes['12'],
    },
    md: {
      height: theme.space['28'],
      padding: `0 ${theme.space['8']}`,
      lineHeight: theme.lineHeights['22'],
      fontSize: theme.fontSizes['14'],
    },
    xmd: {
      height: theme.space['32'],
      padding: `0 ${theme.space['12']}`,
      lineHeight: theme.lineHeights['16'],
      fontSize: theme.fontSizes['14'],
    },
    lg: {
      height: theme.space['40'],
      padding: `0 ${theme.space['16']}`,
      lineHeight: '1.1875',
      fontSize: theme.fontSizes['16'],
    },
  };

  return map[size];
};

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: ${({ theme }) => theme.space['4']};
`;

export const Button = styled.button<{ variant: ButtonVariants; size: SizesVariants; loading: boolean }>`
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.space['4']};
  height: ${({ size, theme }) => getButtonStylesBySize(theme, size).height};
  padding: ${({ size, theme }) => getButtonStylesBySize(theme, size).padding};
  background: ${({ variant, theme }) => getButtonStylesByVariant(theme, variant).default.background};
  color: ${({ variant, theme }) => getButtonStylesByVariant(theme, variant).default.color};
  border: 1px solid;
  border-color: ${({ variant, theme }) => getButtonStylesByVariant(theme, variant).default.borderColor};
  font-size: ${({ size, theme }) => getButtonStylesBySize(theme, size).fontSize};
  line-height: ${({ size, theme }) => getButtonStylesBySize(theme, size).lineHeight};
  border-radius: 3px;
  outline: none;
  column-gap: ${({ theme }) => theme.space['4']};
  white-space: nowrap;

  ${space};
  ${width};

  &:hover {
    background: ${({ variant, theme }) => getButtonStylesByVariant(theme, variant).hovered.background};
    color: ${({ variant, theme }) => getButtonStylesByVariant(theme, variant).hovered.color};
    box-shadow: ${({ variant, theme }) => getButtonStylesByVariant(theme, variant).hovered.boxShadow};
    border-color: ${({ variant, theme }) => getButtonStylesByVariant(theme, variant).hovered.borderColor};
  }

  &:focus {
    background: ${({ variant, theme }) => getButtonStylesByVariant(theme, variant).focused.background};
    box-shadow: ${({ variant, theme }) => getButtonStylesByVariant(theme, variant).focused.boxShadow};
    color: ${({ variant, theme }) => getButtonStylesByVariant(theme, variant).focused.color};
    border-color: ${({ variant, theme }) => getButtonStylesByVariant(theme, variant).focused.borderColor};
  }

  &:active {
    background: ${({ variant, theme }) => getButtonStylesByVariant(theme, variant).active.background};
    box-shadow: ${({ variant, theme }) => getButtonStylesByVariant(theme, variant).active.boxShadow};
    color: ${({ variant, theme }) => getButtonStylesByVariant(theme, variant).active.color};
    border-color: ${({ variant, theme }) => getButtonStylesByVariant(theme, variant).active.borderColor};
  }

  &:disabled {
    background: ${({ variant, theme }) => getButtonStylesByVariant(theme, variant).disabled.background};
    box-shadow: ${({ variant, theme }) => getButtonStylesByVariant(theme, variant).disabled.boxShadow};
    color: ${({ variant, theme }) => getButtonStylesByVariant(theme, variant).disabled.color};
    border-color: ${({ variant, theme }) => getButtonStylesByVariant(theme, variant).disabled.borderColor};
    cursor: not-allowed;
  }

  & > ${ButtonContent} {
    opacity: ${({ loading }) => (loading ? 0 : 1)};
  }
`;

const getIconStylesBySize = (theme: DefaultTheme, size: SizesVariants) => {
  const map = {
    sm: {
      width: theme.space['14'],
      height: theme.space['14'],
    },
    md: {
      width: theme.space['16'],
      height: theme.space['16'],
    },
    xmd: {
      width: theme.space['16'],
      height: theme.space['16'],
    },
    lg: {
      width: theme.space['18'],
      height: theme.space['18'],
    },
  };

  return map[size];
};

export const ButtonIcon = styled.i<{ size: SizesVariants }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ size, theme }) => getIconStylesBySize(theme, size).width};
  height: ${({ size, theme }) => getIconStylesBySize(theme, size).height};
  flex: 0 0 auto;

  svg {
    flex: 0 0 auto;
  }
`;

export const ButtonLoader = styled.div`
  position: absolute;
  inset: 0;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
