import { useLoginMutation } from 'services/auth/authApi';

export function useLogin() {
  const [login, { isError, isLoading, isSuccess, data, originalArgs, error, reset }] = useLoginMutation();

  return {
    login,
    data,
    isError,
    isLoading,
    isSuccess,
    originalArgs,
    error,
    reset,
  };
}
