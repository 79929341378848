import styled, { DefaultTheme } from 'styled-components';
import { AppLinksLocation } from 'features/AppLinks/AppLinks';

type WrapperProps = { theme: DefaultTheme; location: AppLinksLocation };

const getTitleStylesByLocation = ({ theme, location }: WrapperProps) => {
  const map: Record<AppLinksLocation, string> = {
    sidebar: `
      color: ${theme.colors['grey-6']};
      font-size: ${theme.fontSizes[16]};
      line-height: ${theme.fontSizes[24]};
      
      @media screen and (max-width: ${theme.breakpoints.lg - 1}px) and (min-width: 851px) {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        margin-bottom: ${theme.space[4]};
      }
      
      @media screen and (max-width: ${theme.breakpoints.lg - 1}px) {
        font-size: ${theme.fontSizes[14]};
        line-height: ${theme.fontSizes[22]};
      }
    `,
    auth: `
      color: ${theme.colors['grey-1']};
      font-size: ${theme.fontSizes[12]};
      line-height: ${theme.fontSizes[14]};
      justify-self: center;
    `,
  };

  return map[location];
};

const getLinkStylesByLocation = ({ theme, location }: WrapperProps) => {
  const map: Record<AppLinksLocation, string> = {
    sidebar: `
      font-size: ${theme.sizes[32]};
      height: ${theme.sizes[32]};
      color: ${theme.colors.white};
      border-color: ${theme.colors['grey-3']};
      
      @media screen and (max-width: ${theme.breakpoints.lg - 1}px) and (min-width: 851px) {
        max-width: 112px;
      }
    `,
    auth: `
      font-size: ${theme.sizes[40]};
      height: ${theme.sizes[40]};
      color: ${theme.colors['grey-8']};
      border-color: ${theme.colors['grey-8']};
    `,
  };
  return map[location];
};

export const Title = styled.div`
  grid-column: 1 / 3;
  grid-row: 1 / 2;
`;

export const Link = styled.a`
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: repeat(2, auto);
  row-gap: ${({ theme }) => theme.space[2]};
  line-height: 1;
  border: 1px solid;
  border-radius: 6px;
  align-content: center;
  align-items: center;
  transition: opacity 0.15s;

  &:hover {
    opacity: 0.8;
  }
`;

export const Wrapper = styled.div<{ location: AppLinksLocation }>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  column-gap: ${({ theme }) => theme.space[8]};
  row-gap: ${({ theme }) => theme.space[12]};

  ${({ location, theme }) =>
    location === 'sidebar' &&
    ` 
    @media screen and (max-width: ${theme.breakpoints.lg - 1}px) and (min-width: 851px) {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      row-gap: ${theme.space[8]};
    }
    
    @media screen and (max-width: 850px) {
      grid-template-columns: repeat(2, 112px);
    }
  `}

  ${Title} {
    ${getTitleStylesByLocation};
  }

  ${Link} {
    ${getLinkStylesByLocation};
  }
`;

export const LinkTitle = styled.span`
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  font-size: 0.2em;
`;

export const LinkIcon = styled.i`
  display: flex;
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  padding-right: 0.25em;
  padding-left: 0.25em;

  svg {
    height: 0.6em;
  }
`;

export const LinkAppName = styled.span<{ isGP?: boolean }>`
  display: flex;
  grid-column: 2 / 3;
  grid-row: 2 / 3;

  svg {
    height: ${({ isGP }) => (isGP ? '0.4em' : '0.35em')};
  }
`;
