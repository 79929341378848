import { z } from 'zod';
import { MetalEntity } from 'models/api/wts/common/metal';

export const FixationItemEntity = z.object({
  id: z.string(),
  fix: z.boolean(),
  amount: z.number(),
  metal: MetalEntity,
});

export type FixationItemEntity = z.infer<typeof FixationItemEntity>;
