import types from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case types.GET_DKZ: {
      return {
        ...state,
        ...{
          total: action.payload.data.total,
          date: action.payload.data.date,
          link: action.payload.data.link,
        },
      };
    }
    default:
      return state;
  }
}
