import React from 'react';

// import Access, { accesses } from '../../../../components/Access';

import { Access, accesses } from '../../../../../components/Access';
import { LoginAsUser } from '../../../../../features/User/LoginAsUser';
import { UserActive } from '../../../../../features/User/UserActive';

// import { LoginAsUser } from '../../../../features/User/LoginAsUser';
// import { UserActive } from '../../../../features/User/UserActive';
import * as S from './styled';

export function SubsectionsAffix({ id, isSyncedAd }) {
  return (
    <S.Wrapper>
      <S.SwitchContainer>
        <UserActive id={id}>
          {({ active, activeHandler, loading }) => (
            <S.Switch
              checked={active}
              onChange={activeHandler}
              disabled={loading || isSyncedAd}
              data-test-id="switch_access-user"
            />
          )}
        </UserActive>
        <S.SwitchTitle>Доступ к личному кабинету</S.SwitchTitle>
      </S.SwitchContainer>
      <Access code={accesses.USER_AUTH}>
        <LoginAsUser>
          {(loginHandler) => (
            <S.Button
              type="primary"
              data-test-id="btn_auth-user"
              onClick={() => loginHandler(id)}
            >
              Войти под пользователем
            </S.Button>
          )}
        </LoginAsUser>
      </Access>
    </S.Wrapper>
  );
}
