const types = {
  AUTH_USER: 'AUTH_USER',
  UNAUTH_USER: 'UNAUTH_USER',
  RESTORE_USER: 'RESTORE_USER',
  AUTH_ERROR: 'AUTH_ERROR',
  GET_LAYOUT_DATA: 'GET_LAYOUT_DATA',
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  CHANGE_COMPANY: 'CHANGE_COMPANY',
  GET_COMPANY_LIST: 'GET_COMPANY_LIST',
  GET_NEXT_PAGE: 'GET_NEXT_PAGE',
  COMPANY_LOADER: 'COMPANY_LOADER',
  COMPANY_NEXT_PAGE_LOADER_START: 'COMPANY_NEXT_PAGE_LOADER_START',
  COMPANY_NEXT_PAGE_LOADER_END: 'COMPANY_NEXT_PAGE_LOADER_END',
  GET_PAGE: 'GET_PAGE',
  TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
  HIDE_SIDEBAR: 'HIDE_SIDEBAR',
  GET_NOTIFICATIONS_COUNT: 'GET_NOTIFICATIONS_COUNT',
  GET_MANAGERS: 'GET_MANAGERS',
  GET_DOCUMENTS: 'GET_DOCUMENTS',
  GET_OTHER_DOCUMENTS: 'GET_OTHER_DOCUMENTS',
  REMOVE_DOCUMENT: 'REMOVE_DOCUMENT',
  ADD_DOCUMENT: 'ADD_DOCUMENT',
  GET_CONTRACTS: 'GET_CONTRACTS',
  GET_SUB_CONTRACTS: 'GET_SUB_CONTRACTS',
  GET_USER_EMAILS: 'GET_USER_EMAILS',
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  GET_PROFILE: 'GET_PROFILE',
  HIDE_SUBSCRIBE_MSG: 'HIDE_SUBSCRIBE_MSG',
  SHOW_SUBSCRIBE_MSG: 'SHOW_SUBSCRIBE_MSG',
  GET_BALANCE_RESULT: 'GET_BALANCE_RESULT',
  CHANGE_SECOND_LVL: 'CHANGE_SECOND_LVL',
  GET_DKZ: 'GET_DKZ',
  GET_ACTS: 'GET_ACTS',
  ADD_ACT: 'ADD_ACT',
  CHANGE_FILTER_VALUE: 'CHANGE_FILTER_VALUE',
  GET_REPORTS: 'GET_REPORTS',
  GET_NEXT_REPORTS: 'GET_NEXT_REPORTS',
  CHANGE_CONTRACTS: 'CHANGE_CONTRACTS',
  RELEAS_FIRST_LVL: 'RELEAS_FIRST_LVL',
  GET_NEXT_PAGE_RELEASE: 'GET_NEXT_PAGE_RELEASE',
  CLEAR_MODAL_BALANCE_RESULT: 'CLEAR_MODAL_BALANCE_RESULT',
  SHIPMENT_FIRST_LVL: 'SHIPMENT_FIRST_LVL',
  GET_NEXT_PAGE_SHIPMENT: 'GET_NEXT_PAGE_SHIPMENT',
  SAVE_SECOND_LVL: 'SAVE_SECOND_LVL',
  SUPPLY_FIRST_LVL: 'SUPPLY_FIRST_LVL',
  GET_SECOND_SUPPLY: 'GET_SECOND_SUPPLY',
  GET_NEXT_PAGE_SUPPLY: 'GET_NEXT_PAGE_SUPPLY',
  LOADING_LVL: 'LOADING_LVL',
  NEXT_PAGE_LOADING: 'NEXT_PAGE_LOADING',
  NEXT_PAGE_LOADING_END: 'NEXT_PAGE_LOADING_END',
  GET_TICKETS: 'GET_TICKETS',
  GET_MODAL_INFROMATION: 'GET_MODAL_INFROMATION',
  GET_TICKET_TEMPLATES: 'GET_TICKET_TEMPLATES',
  GET_TICKET_DETAILS: 'GET_TICKET_DETAILS',
  GET_TICKET_MESSAGES: 'GET_TICKET_MESSAGES',
  CLOSE_TIKET: 'CLOSE_TIKET',
  CLOSE_TICKET_IMAGE_PREVIEW_MODAL: 'CLOSE_TICKET_IMAGE_PREVIEW_MODAL',

  GET_MMR: 'GET_MMR',
  GET_METALS: 'GET_METALS',
  SEND_PROPOSAL_SUPPLY: 'SEND_PROPOSAL_SUPPLY',
  GET_PROPOSAL_SUPPLY_LIST: 'GET_PROPOSAL_SUPPLY_LIST',
  GET_PROPOSAL_UPDATE_SUPPLY: 'GET_PROPOSAL_UPDATE_SUPPLY',

  REGISTR_TOURE_TARGET: 'REGISTR_TOURE_TARGET',
  UNREGISTR_TOURE_TARGET: 'UNREGISTR_TOURE_TARGET',

  SAVE_IMAGE_PREVIEW: 'SAVE_IMAGE_PREVIEW',

  SAVE_ACCESS: 'SAVE_ACCESS',

  GET_QUESTIONNAIR: 'GET_QUESTIONNAIR',
  SAVE_QUESTIONAIR_REQUEST: 'SAVE_QUESTIONAIR_REQUEST',
  NEXT_STEP_QUESTIONNAIR: 'NEXT_STEP_QUESTIONNAIR',
  PREV_STEP_QUESTIONNAIR: 'PREV_STEP_QUESTIONNAIR',
  SET_QUESTIONNAIR_STEP: 'SET_QUESTIONNAIR_STEP',
  CHANGE_LOAD_QUESTIONNAIR: 'CHANGE_LOAD_QUESTIONNAIR',
  CHANGE_QUESTIONAIR_STATUS: 'CHANGE_QUESTIONAIR_STATUS',
  REFRESH_QUESTIONAIR_PROGRESS: 'REFRESH_QUESTIONAIR_PROGRESS',

  GET_PERMISSIONS: 'GET_PERMISSIONS',
  SHOW_PERMISSIONS_LOADER: 'SHOW_PERMISSIONS_LOADER',
  HIDE_PERMISSIONS_LOADER: 'HIDE_PERMISSIONS_LOADER',

  GET_DASHBOARDS: 'GET_DASHBOARDS',
  GET_DASHBOARDS_FILTERS: 'GET_DASHBOARDS_FILTERS',
  CHANGE_DASHBOARDS_FILTERS: 'CHANGE_DASHBOARDS_FILTERS',
  DESTROY_DASHBOARDS: 'DESTROY_DASHBOARDS',

  GET_SERVICES_LOADING: 'GET_SERVICES_LOADING',
  GET_SERVICES_SETTING: 'GET_SERVICES_SETTING',
  GET_SERVICES_ERROR: 'GET_SERVICES_ERROR',
  CHECK_SERVICE: 'CHECK_SERVICE',
  TOGGLE_CURRENT_PAGE: 'TOGGLE_CURRENT_PAGE',
  CHANGE_PAGE: 'CHANGE_PAGE',
  CLEAR_ALL_CHECKED: 'CLEAR_ALL_CHECKED',
  SERVICES_SORT_BY: 'SERVICES_SORT_BY',
  FILTER_SERVICES: 'FILTER_SERVICES',
  CHANGE_SECTION: 'CHANGE_SECTION',
  MASS_DOWNLOAD__LOADING: 'MASS_DOWNLOAD__LOADING',
  MASS_DOWNLOAD__SUCCESS: 'MASS_DOWNLOAD__SUCCESS',
  MASS_DOWNLOAD__ERROR: 'MASS_DOWNLOAD__ERROR',

  LIST_LOADING: 'LIST_LOADING',
  GET_LIST: 'GET_LIST',
  GET_LIST_FAILED: 'GET_LIST_FAILED',
  CLEAR_LIST_FILTERS: 'CLEAR_LIST_FILTERS',
  GET_ADMIN_ROLES: 'GET_ADMIN_ROLES',
  GET_RELATION_TOP: 'GET_RELATION_TOP',

  LOADING_TAB_CONTENT: 'LOADING_TAB_CONTENT',
  HIDE_TAB_CONTENT_LOADER: 'HIDE_TAB_CONTENT_LOADER',
  GET_TAB_CONTENT: 'GET_TAB_CONTENT',

  GET_SUPPORT_FILTER: 'GET_SUPPORT_FILTER',
  CLEAR_SUPPORT_FILTERS: 'CLEAR_SUPPORT_FILTERS',
  ADMIN_GET_TICKETS: 'ADMIN_GET_TICKETS',
  ADMIN_GET_COMMENTS: 'ADMIN_GET_COMMENTS',
  ADMIN_GET_TICKET_DETAILS: 'ADMIN_GET_TICKET_DETAILS',
  ADMIN_CLOSE_TIKET: 'ADMIN_CLOSE_TIKET',
  ADMIN_CLOSE_TICKET_IMAGE_PREVIEW_MODAL: 'ADMIN_CLOSE_TICKET_IMAGE_PREVIEW_MODAL',
  SHOW_TICKET_DETAILS_LOADER: 'SHOW_TICKET_DETAILS_LOADER',
  HIDE_TICKET_DETAILS_LOADER: 'HIDE_TICKET_DETAILS_LOADER',
};

export default types;
