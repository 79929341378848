import React from 'react';
import logoUrlEn from './images/eng-grey.svg';
import logoUrlEnWhite from './images/eng-white.svg';
import logoSmall from './images/small.svg';
import { LogoImg } from './styled';
import { useMedia } from '../../hooks/useMedia';

const images = {
  normal: logoUrlEn,
  inverse: logoUrlEnWhite,
};

export function Logo({ type = 'normal', scalability = false }) {
  const isSmallScreen = useMedia(['(max-width: 1200px)'], [true], false);
  const img = scalability && !isSmallScreen ? logoSmall : images[type];

  return (
    <LogoImg
      src={img}
      alt="Красцветмет - ОАО «Красцветмет»"
    />
  );
}

export default Logo;
