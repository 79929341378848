import { BannersSection } from 'constants/banners';
import { useBanners } from './ducks/hook';
import * as S from './styled';
import { Banner } from './Banner';
import React, { useCallback, useEffect, useState } from 'react';
import { useUnmount } from 'react-use';
import { useScreen } from 'hooks/useScreen';
import { Carousel, message } from 'antd';
import { RequestForm } from './RequestForm';
import { BannerEntity } from './ducks/service.types';
import { SendRequestFromBannerPayload } from './ducks/actions.types';
import { bannersAnalytics } from './bannersAnalytics';
import { SpaceProps } from 'styled-system';
import { Icons } from 'components/Icons';

const PRIVACY_POLICY = 'https://www.krastsvetmet.ru/privacy-policy/';

export type BannersProps = {
  section?: BannersSection;
} & SpaceProps;

export function Banners({ section, ...props }: BannersProps) {
  const { isSmallScreen } = useScreen();

  const { enable, banners, getBanners, reset, requestLoading, sendRequestFromBanner, disableBanners } =
    useBanners(section);

  useEffect(() => {
    const promise = getBanners();
    return promise?.abort;
  }, [getBanners]);

  useUnmount(reset);

  const [activeBanner, setActiveBanner] = useState<null | BannerEntity>(null);

  const onRequest = useCallback((banner: BannerEntity) => {
    bannersAnalytics.requestClick(banner.titleForm);
    setActiveBanner(banner);
  }, []);

  const resetActiveBanner = useCallback(() => {
    setActiveBanner(null);
  }, []);

  const onSendRequestFromBanner = useCallback(
    (payload: SendRequestFromBannerPayload) => {
      sendRequestFromBanner(payload)
        ?.unwrap()
        .then(() => {
          resetActiveBanner();
          message.success('Заявка успешно отправлена');
          bannersAnalytics.requestSubmit(payload.service);
        })
        .catch(() => {
          message.error('Не удалось отправить заявку');
        });
    },
    [resetActiveBanner, sendRequestFromBanner]
  );

  const closeBanners = useCallback(() => {
    const bannersIds = banners?.map(({ id }) => id) || [];
    disableBanners({ ids: bannersIds });
    bannersAnalytics.close();
  }, [banners, disableBanners]);

  if (!enable || isSmallScreen || !banners?.length) {
    return null;
  }

  return (
    <S.BannerWrap
      {...props}
      section={section}
    >
      <Carousel
        autoplay
        effect="fade"
        autoplaySpeed={5000}
      >
        {banners?.map((banner) => (
          <Banner
            key={banner.id}
            title={banner.title}
            titleSub={banner.titleSub}
            titleForm={banner.titleForm}
            url={banner.url}
            id={banner.id}
            alignment={banner.alignment}
            backgroundColor={banner.backgroundColor}
            content={banner.content}
            price={banner.price}
            paths={banner.paths}
            priceContent={banner.priceContent}
            button={banner.button}
            onRequest={() => onRequest(banner)}
          />
        ))}
      </Carousel>
      <S.Close onClick={closeBanners}>{Icons.cancel}</S.Close>
      <S.RequestModal
        visible={Boolean(activeBanner)}
        onCancel={resetActiveBanner}
        footer={null}
        destroyOnClose
      >
        <S.RequestTitle>Заказать консультацию</S.RequestTitle>
        <S.RequestDescription>Оставьте контактные данные, мы с вами свяжемся</S.RequestDescription>
        <RequestForm
          activeBanner={activeBanner}
          onSubmit={onSendRequestFromBanner}
          isLoading={requestLoading}
        />
        <S.RequestPolicy>
          {'Нажимая кнопку, вы даете согласие на обработку персональных данных и соглашаетесь с '}
          <a
            href={PRIVACY_POLICY}
            target="_blank"
            rel="noreferrer"
          >
            политикой конфиденциальности
          </a>
        </S.RequestPolicy>
      </S.RequestModal>
    </S.BannerWrap>
  );
}
