import React from 'react';
import { Redirect } from 'react-router-dom';
import * as S from 'features/Login/Login.styled';
import { LoginForm } from 'features/Login/LoginForm';
import Logo from 'components/Logo';
import { Locales } from 'features/Login/Locales';
import { AppLinks, isAppLinksVisible } from 'features/AppLinks';
import { RestorePasswordForm } from 'features/Login/RestorePasswordForm';
import { useAuth } from 'features/Login/hooks/useAuth';
import { useRestorePasswordVisible } from 'features/Login/hooks/useRestorePasswordVisible';
import { LoginProps } from 'features/Login/types';
import { useEmail } from 'features/Login/hooks/useEmail';
import { SSO } from 'features/Login/SSO';
import { useTranslation } from 'react-i18next';

export function Login({ location }: LoginProps) {
  const { t } = useTranslation();

  const { authState, successPath, initApp } = useAuth(location);

  const { openPasswordRestoring, closePasswordRestoring, isPasswordRestoring } = useRestorePasswordVisible();

  const { email, onEmailChange } = useEmail();

  if (authState.authenticated) {
    return <Redirect to={successPath} />;
  }

  return (
    <S.LoginWrapper>
      <S.LoginCard>
        <S.LogoWrapper>
          <Logo />
        </S.LogoWrapper>
        {isPasswordRestoring ? (
          <RestorePasswordForm
            closePasswordRestoring={closePasswordRestoring}
            email={email}
            onEmailChange={onEmailChange}
          />
        ) : (
          <React.Fragment>
            <LoginForm
              authError={authState.authErrorMessage}
              openPasswordRestoring={openPasswordRestoring}
              email={email}
              onEmailChange={onEmailChange}
              initApp={initApp}
            />
            <React.Fragment>
              <S.Separator>{t('auth.или')}</S.Separator>
              <SSO initApp={initApp} />
            </React.Fragment>
            <Locales />
            {isAppLinksVisible && (
              <S.AppLinksWrapper>
                <AppLinks location="auth" />
              </S.AppLinksWrapper>
            )}
          </React.Fragment>
        )}
      </S.LoginCard>
    </S.LoginWrapper>
  );
}
