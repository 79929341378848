import { useTranslation } from 'react-i18next';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { LOCALES } from 'constants/locales';

export function useLocales(onChange?: () => void) {
  const { i18n } = useTranslation();
  const { language } = i18n;

  const onLanguageChange = async (e: RadioChangeEvent) => {
    await i18n.changeLanguage(e.target.value);
    onChange?.();
  };

  return {
    value: LOCALES.find((locale) => language.includes(locale)),
    items: LOCALES,
    onLanguageChange,
  };
}
