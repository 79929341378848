import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { Permission } from 'constants/permissions';
import { RootState } from '../store';

export function usePermissions() {
  const permissions: string[] = useSelector(({ access }: RootState) => access);

  const isPermissionExist = useCallback(
    (permission: Permission | null | undefined) => !permission || permissions.includes(permission),
    [permissions]
  );

  return {
    permissions,
    isPermissionExist,
  };
}
