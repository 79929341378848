import React from 'react';
import { icons } from 'features/sidebar/Icons';
import { NavItem } from './NavItem';
import * as S from './styled';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { actionTriggering } from '../../analytics';
import { useIsAdminSection } from '../../hooks/useIsAdminSection';
import { Trans } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Access, accesses } from 'components/Access';
import { AppLinks, isAppLinksVisible } from 'features/AppLinks';
import { isEmptyNavBlock } from 'features/sidebar/utils';
import { SidebarCodesEnum } from 'constants/sidebarCodes';
import { WsNotification } from 'features/support/WsNotification';
import { useQaAttr } from 'hooks/useQA';

export function Footer({ pages = [], localization, changeLocalization, count }) {
  const isAdminSection = useIsAdminSection();
  const { permissions } = useSelector((state) => state?.auth?.init) || {};
  const { loading } = useSelector((state) => state?.localization);
  const isMobileDevice = useMediaQuery({ query: '(max-width: 850px)' });
  const { qaAttr } = useQaAttr('sidebar');

  const changeLocalicationHandler = () => {
    changeLocalization(localization === 'ru' ? 'en' : 'ru');
    actionTriggering('sidebar__change-localization');
  };

  const onChangeHandler = () => {
    changeLocalicationHandler();
  };

  const clickHandler = () => {
    changeLocalicationHandler();
  };

  const renderLanguageButton = () => {
    return isMobileDevice ? (
      <S.LocalRadioGroup
        value={localization}
        onChange={onChangeHandler}
        disabled={loading}
      >
        <S.LocalRadio value={'ru'}>Ru</S.LocalRadio>
        <S.LocalRadio value={'en'}>Eng</S.LocalRadio>
      </S.LocalRadioGroup>
    ) : (
      <S.LocalButton
        onClick={clickHandler}
        disabled={loading}
      >
        {localization === 'ru' ? 'Ru' : 'En'}
      </S.LocalButton>
    );
  };

  const hasLanguageSection = permissions.includes('localization');

  return (
    <S.Footer>
      {pages.map(({ name, link, code, permission }, index) => (
        <S.NavLink
          key={code}
          to={link}
          onClick={() =>
            actionTriggering('layout__1st-lvl-nav', {
              ActionTargetName: code,
            })
          }
          {...qaAttr(code)}
        >
          <NavItem
            key={link}
            iconName={code}
            count={code === SidebarCodesEnum.enum.support && count}
            theme="secondary"
            permission={permission}
            tabIndex={index}
          >
            <S.ItemNavTitle>
              {/*ключ name берется из ./mock.js */}
              <Trans i18nKey={`sidebar.${name}`} />
              {code === SidebarCodesEnum.enum.support && (
                <WsNotification
                  isEnabled
                  size="sm"
                />
              )}
            </S.ItemNavTitle>
          </NavItem>
        </S.NavLink>
      ))}
      {isAppLinksVisible && (
        <S.AppLinksWrapper
          hasTopSeparator={!isEmptyNavBlock(pages, permissions)}
          hasBottomSeparator={hasLanguageSection || permissions.includes(accesses.HAS_ADMIN)}
        >
          <AppLinks location="sidebar" />
        </S.AppLinksWrapper>
      )}
      {isAdminSection ? (
        <S.PanelButtonWrapper>
          <Link
            to="/"
            style={{ width: '100%' }}
          >
            <S.PanelButton type="primary">
              <S.PanelIcon>{icons.arrowReturn}</S.PanelIcon>
              Вернуться
            </S.PanelButton>
          </Link>
        </S.PanelButtonWrapper>
      ) : (
        <S.PanelButtonWrapper>
          <Access code={accesses.HAS_ADMIN}>
            <Link
              to="/admin"
              style={{ width: '100%' }}
            >
              <S.PanelButton type="primary">
                <S.PanelIcon>{icons.gear}</S.PanelIcon>
                Управление
              </S.PanelButton>
            </Link>
          </Access>
          {hasLanguageSection && renderLanguageButton()}
        </S.PanelButtonWrapper>
      )}
    </S.Footer>
  );
}
