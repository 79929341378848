import { createAsyncThunk } from '@reduxjs/toolkit';
import { FAQService } from './service';
import { FAQApi, RubricEntity } from './service.types';
import { ALL_QUESTIONS_RUBRIC_ID } from '../consts';
import i18n from 'i18next';

export const FAQ_ACTION_PREFIX = 'faq';

const normalizeRubrics = (data: FAQApi.getRubrics.Response.Success['data']): RubricEntity[] => {
  return [
    { id: ALL_QUESTIONS_RUBRIC_ID, name: i18n.t('feedback.Все вопросы'), countQuestions: data.allCountQuestions },
    ...data.rubrics,
  ];
};

const normalizeQuestionsParams = ({
  rubricId,
  search,
}: FAQApi.getQuestions.Request.Params): FAQApi.getQuestions.Request.Params => ({
  ...(search ? { search } : {}),
  ...(rubricId === ALL_QUESTIONS_RUBRIC_ID ? {} : { rubricId }),
});

export const getQuestions = createAsyncThunk(
  `${FAQ_ACTION_PREFIX}/getQuestions`,
  async (payload: FAQApi.getQuestions.Request.Params) => {
    const { data } = await FAQService.getQuestions(normalizeQuestionsParams(payload));
    return data;
  }
);

export const getRubrics = createAsyncThunk(`${FAQ_ACTION_PREFIX}/getRubrics`, async () => {
  const { data } = await FAQService.getRubrics();
  return normalizeRubrics(data);
});
