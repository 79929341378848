import { z } from 'zod';

export const ProductStatusEnum = z.enum(['active', 'canceled', 'completed', 'expired']);
export type ProductStatusEnum = z.infer<typeof ProductStatusEnum>;

export const SaleStatusEnum = z.enum(['in_progress', 'completed', 'draft']);
export type SaleStatusEnum = z.infer<typeof SaleStatusEnum>;

export const SaleTableStatusEnum = z.enum(['in_progress', 'completed', 'active']);
export type SaleTableStatusEnum = z.infer<typeof SaleTableStatusEnum>;
