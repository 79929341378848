import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import * as actions from 'actions/adminList';
import User from './User';
import Pagination from '../../../../components/Pagination';
import { Empty, Button, Row, Col, Spin } from 'antd';
import './index.scss';
import { Access, accesses } from '../../../../components/Access';
import { updateQueryParams } from 'actions/adminList';
import { emptyCb } from 'utils/emptyCb';
import { useAppDispatch } from 'store';
import { useUnmount } from 'react-use';

function Users() {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const { list: users, pagination, filters, loading } = useSelector(({ adminList: { users } }) => users);
  const usersPromise = useRef();

  const getUsers = (params) => {
    usersPromise.current = dispatch(actions.getUsers(params));

    usersPromise.current
      .unwrap()
      .then(() => updateQueryParams(params))
      .catch(emptyCb);
  };

  useUnmount(() => {
    usersPromise.current?.abort?.();
  });

  const nextUsers = (page) => {
    getUsers({ ...filters, page });
  };

  useEffect(() => {
    const validFilters = filters || queryString.parse(search);
    getUsers(validFilters);
    return () => {
      dispatch(actions.resetFilters('users'));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="admin-panel-users-page">
      <Row
        type="flex"
        justify="end"
        style={{ padding: '0 10px 15px' }}
      >
        <Col>
          <Access code={accesses.USER_CREATE}>
            <Link to="/admin/users/create">
              <Button
                icon={<PlusOutlined />}
                type="primary"
              >
                Создать
              </Button>
            </Link>
          </Access>
        </Col>
      </Row>
      <Spin spinning={loading}>
        <div className="admin-panel-users__list">
          {users.map((user) => (
            <User
              {...user}
              key={user.id}
            />
          ))}
          {!loading && users.length === 0 && (
            <Empty
              style={{
                width: '100%',
                padding: 30,
                backgroundColor: '#fff',
              }}
            />
          )}
        </div>
      </Spin>
      {pagination && (
        <Pagination
          pageSize={pagination.perPage}
          current={pagination.currentPage}
          hideOnSinglePage
          total={pagination.total}
          onChange={nextUsers}
          showSizeChanger={false}
        />
      )}
    </div>
  );
}

export default Users;
