import React from 'react';
import { Link } from 'react-router-dom';
import { useIsAdminSection } from '../../../hooks/useIsAdminSection';
import { icons } from 'features/sidebar/Icons';
import { logo } from 'features/sidebar/logo';
import { CompanyMobile } from './CompanyMobile';
import * as S from './styled';

export function MobileSidebar({ company, count, onShow, onShowDetail }) {
  const isAdminSection = useIsAdminSection();
  return (
    <S.Wrapper>
      <S.Container>
        <S.Group>
          <S.Icon onClick={onShow}>{icons.menu}</S.Icon>
          <S.LogoIcon>{logo}</S.LogoIcon>
        </S.Group>
        <Link
          to="/notifications"
          style={{ display: 'flex' }}
        >
          <S.Badge count={count}>
            <S.Icon>{icons.bell}</S.Icon>
          </S.Badge>
        </Link>
      </S.Container>
      {!isAdminSection && company && (
        <CompanyMobile
          company={company.name}
          onShowDetail={onShowDetail}
        />
      )}
    </S.Wrapper>
  );
}
