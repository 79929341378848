import { asyncPipe } from '../utils/asyncPipe';
import { apiGet } from './helper';
import { getDataFromResponse } from '../utils/getDataFromResponse';
import { AdminContractsApi } from './adminContractsService.types';

class AdminContractsServiceFactory {
  getContracts = (params: AdminContractsApi.getContracts.Request.Params) => {
    return asyncPipe(apiGet, getDataFromResponse)<AdminContractsApi.getContracts.Response.Success>(
      '/v1/admin/contracts',
      params
    );
  };

  getContractById = (contractId: string) => {
    return asyncPipe(
      apiGet,
      getDataFromResponse
    )<AdminContractsApi.getContractById.Response.Success>(`/v1/admin/contracts/${contractId}`);
  };
}

export const AdminContractsService = new AdminContractsServiceFactory();
