import React from 'react';
import { usePermissions } from '../../hooks/usePermissions';
import { Permission } from '../../constants/permissions';

export const accesses = {
  USER_COMPANY_ADD: 'user.company.update',
  USER_CREATE: 'user.create',
  USER_AUTH: 'user.auth',
  USER_PERMISSIONS: 'user.company.permissions',
  USER_COMPANY_UPDATE: 'user.company.update',
  USER_COMPANY_VISIBLE: 'user.company.visible',

  USER_ROLE: 'user.role',

  //Public
  HAS_ADMIN: 'admin',
  BALANCE_RELEASE: 'release',
  BALANCE_SHIPMENT: 'shipment',
  BALANCE_SUPPLY: 'supply',
  BALANCE_SUPPLY_METAL: 'balance.report',
  BALANCE_SUPPLY_NZP: 'balance.nzp',
  FINANCES_ACT: 'finances.act',
  FINANCES_DKZ: 'finances.dkz',
  SUPERVISOR_REPORTS: 'supervisor.reports',
};

type AccessProps = {
  code?: Permission | null;
  fallback?: JSX.Element;
  children: JSX.Element | JSX.Element[];
};

export function Access({ code, fallback, children }: AccessProps) {
  const { isPermissionExist } = usePermissions();

  if (isPermissionExist(code)) {
    return Array.isArray(children) ? <React.Fragment>{children}</React.Fragment> : children;
  }

  return fallback || null;
}
