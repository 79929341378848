import types from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case types.GET_NEXT_REPORTS: {
      return {
        ...state,
        reports: action.payload.reports,
        paginate: action.payload.paginate,
      };
    }
    case types.GET_REPORTS: {
      return {
        ...state,
        reports: action.payload.reports,
        paginate: action.payload.paginate,
        filters: action.payload.values,
      };
    }
    case types.CHANGE_CONTRACTS: {
      return {
        ...state,
        subcontractsList: action.id !== '' ? action.payload : [],
      };
    }
    default:
      return state;
  }
}
