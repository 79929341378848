import React, { useCallback, useRef, useState } from 'react';
import Select from '../Select';
import { useSelector } from 'react-redux';
import { getRoles, getReport } from 'actions/adminUtils';
import * as actions from 'actions/adminList';
import { Button, Input } from 'antd';
import Icon, { DownloadOutlined } from '@ant-design/icons';
import { Access, accesses } from 'components/Access';
import './style.scss';
import { updateQueryParams } from 'actions/adminList';
import { emptyCb } from 'utils/emptyCb';
import { useAppDispatch } from 'store';
import { useMount, useUnmount } from 'react-use';
import { debounce } from 'lodash-es';
import { useLocation } from 'react-router-dom';
import { parseQueryString } from 'utils/parseQueryString';

function UsersFilter() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [search, setSearch] = useState('');
  const { roles, filters } = useSelector(({ adminUtils, adminList: { users } }) => ({
    ...adminUtils,
    ...users,
  }));
  const usersPromise = useRef();

  const getUserActivityTypes = () => {
    return [
      {
        value: '1',
        label: 'Активные',
      },
      {
        value: '0',
        label: 'Неактивные',
      },
    ];
  };

  useMount(() => {
    dispatch(getRoles());
    setSearch(parseQueryString(location.search).search);
  });

  const changeFiltersHandler = (item, type) => {
    const finalFilters = {
      ...filters,
      [type]: item ? item.value : null,
      page: null,
    };
    updateFilters(finalFilters);
  };

  const updateFilters = (filters) => {
    usersPromise.current = dispatch(actions.getUsers(filters));

    usersPromise.current
      .unwrap()
      .then(() => updateQueryParams(filters))
      .catch(emptyCb);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouceUpdateFilters = useCallback(debounce(updateFilters, 700), []);

  useUnmount(() => {
    usersPromise.current?.abort?.();
  });

  const handleInput = ({ target: { value } }) => {
    const finalFilters = {
      ...filters,
      search: value,
      page: null,
    };
    setSearch(value);

    debouceUpdateFilters(finalFilters);
  };

  const downloadUsersReport = () => {
    dispatch(getReport('users/report'));
  };

  const getCurrentActivity = (id, list) => {
    return list?.find(({ value }) => id && +value === +id);
  };

  const getCurrentRole = (filter, list) => {
    const role = list?.find(({ code }) => code === filter);
    if (role) {
      return {
        label: role.name,
        value: role.code,
      };
    }
    return undefined;
  };

  return (
    <React.Fragment>
      <form
        className="finances-filters"
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          flexWrap: 'wrap',
        }}
      >
        <div className="finances-filters__select-container">
          <label>
            <div>Поиск</div>
            <Input
              allowClear
              className="input-override"
              suffix={<Icon type="search" />}
              onChange={handleInput}
              type="text"
              size="large"
              value={search}
              placeholder="Введите имя или email"
              style={{
                marginBottom: 10,
              }}
            />
          </label>
        </div>
        <div className="finances-filters__select-container">
          <label>
            Роль
            <Select
              onChange={(value) => changeFiltersHandler(value, 'roles')}
              isClearable
              options={roles?.map(({ code, name }) => {
                return { label: name, value: code };
              })}
              value={filters ? getCurrentRole(filters.roles, roles) : null}
              placeholder="Выберите роль"
            />
          </label>
        </div>
        <div className="finances-filters__select-container">
          <label>
            Активность
            <Select
              onChange={(value) => changeFiltersHandler(value, 'active')}
              isClearable
              options={getUserActivityTypes()}
              value={filters ? getCurrentActivity(filters.active, getUserActivityTypes()) : null}
              placeholder="Все пользователи"
            />
          </label>
        </div>
        <Access code={accesses.SUPERVISOR_REPORTS}>
          <Button
            onClick={downloadUsersReport}
            style={{
              marginLeft: 'auto',
              marginBottom: 13,
              marginRight: 16,
            }}
            type="primary"
            htmlType="button"
            icon={<DownloadOutlined />}
          >
            Скачать отчет
          </Button>
        </Access>
      </form>
    </React.Fragment>
  );
}

export default UsersFilter;
