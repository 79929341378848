import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import moment from 'moment';
import * as R from 'ramda';

import { apiDelete, apiGet, apiPost } from '../../../services/helper';

const initialValuesStep1 = {
  common: {
    docId: undefined,
    docDate: undefined,
    docNumber: undefined,
    docDatePlan: undefined,
    mass: undefined,
  },
  batchInfo: {
    placeQuantity: undefined,
    carrierName: undefined,
    stamp: undefined,
  },
  responsible: [
    {
      fio: undefined,
      position: undefined,
    },
  ],
  company: {
    companyId: undefined,
    inn: undefined,
    address: undefined,
    contractId: undefined,
    isConsignor: false,
    consignor: {
      name: undefined,
      address: undefined,
      inn: undefined,
    },
  },
  isCompanies: false,
  companies: [],
  typeDistribution: undefined,
  contracts: [],
  documents: [],
};

const inventory = createSlice({
  name: 'inventory',
  initialState: {
    companies: [],
    contracts: {},
    init: {},
    inventoryId: null,
    step: 0,
    isLoading: false,
    isCompanies: false,
    initialValuesStep1,
  },
  reducers: {
    handleSetStep(state, { payload }) {
      const { step } = payload;
      state.step = step;
    },
    clearSteps(state) {
      state.inventoryId = null;
      state.step = 0;
      state.initialValuesStep1 = initialValuesStep1;
      state.init = {};
    },
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
    getInitInventorySuccess(state, { payload }) {
      const { data } = payload;
      state.init = { ...data };
    },
    initCreateSuccess(state, { payload }) {
      const { inventoryId } = payload;
      state.inventoryId = inventoryId;
    },
    setDefaultCompanyInfo(state, { payload }) {
      const { companyId, inn, address } = payload;

      if (!state.inventoryId) {
        state.initialValuesStep1 = {
          ...state.initialValuesStep1,
          company: {
            ...state.initialValuesStep1.company,
            companyId,
            inn,
            address,
          },
        };
      }
    },
    setInitialValues(state, { payload }) {
      const { values } = payload;
      state.initialValuesStep1 = { ...values };
    },
    setCompanies(state, { payload }) {
      const { list } = payload;
      const contracts = {};

      list.map((item) => {
        contracts[item?.id] = item.contracts.filter(({ dateEnd }) => {
          if (dateEnd) {
            return new Date() < new Date(dateEnd);
          } else {
            return true;
          }
        });
      });
      state.contracts = contracts;
      state.companies = list;
    },
    setIsCompanies(state, { payload }) {
      const { isCompanies } = payload;
      state.isCompanies = isCompanies;
    },
  },
});

export const {
  handleSetStep,
  getInitInventorySuccess,
  initCreateSuccess,
  setDefaultCompanyInfo,
  startLoading,
  endLoading,
  setInitialValues,
  setCompanies,
  clearSteps,
  setIsCompanies,
} = inventory.actions;

export const fetchInitInventory = () => async (dispatch, getState) => {
  dispatch(startLoading());

  const key = 'loading';
  message.loading({ content: 'Загрузка...', key });

  try {
    const [init, companies] = await Promise.all([apiGet('/v1/inventory/init'), apiGet('/v1/companies')]);

    const {
      data: { success, data },
    } = init;
    const {
      data: { success: successCompanies, data: dataCompanies },
    } = companies;

    if (success && successCompanies) {
      const { list } = dataCompanies;
      const { auth } = getState();

      const currentCompany = {
        address: auth?.init?.company?.address,
        contracts: auth?.init?.company?.contracts,
        id: auth?.init?.company?.id,
        inn: auth?.init?.company?.inn,
        name: auth?.init?.company?.name,
      };

      const listHasCurrent = list.find(({ id }) => id === auth?.init?.company?.id);

      dispatch(
        setCompanies({
          list: listHasCurrent ? list : [currentCompany, ...list],
        })
      );

      const companyDefaultInfo = {
        companyId: auth?.init?.company?.id,
        inn: auth?.init?.company?.inn,
        address: auth?.init?.company?.address,
      };

      dispatch(setDefaultCompanyInfo({ ...companyDefaultInfo }));
      dispatch(getInitInventorySuccess({ data }));
      dispatch(endLoading());

      message.success({ content: 'Готово', key, duration: 2 });
    }
  } catch (e) {
    message.error({
      content: 'Произошла ошибка сервера',
      key,
      duration: 2,
    });
  }
};

export const fetchFullStep1 =
  ({ values }) =>
  async (dispatch, getState) => {
    // создание первого шага
    const { inventory } = getState();
    const inventoryId = inventory?.inventoryId;
    const initValues = inventory?.initialValuesStep1;

    const key = 'loading';
    let result = null;

    message.loading({ content: 'Первый шаг...', key });

    try {
      if (!inventoryId) {
        result = await dispatch(createInventory({ values }));
      } else {
        if (!R.equals(values, initValues)) {
          result = await updateInventory({ values, inventoryId });
        } else {
          result = inventoryId;
        }
      }

      if (result) {
        dispatch(setIsCompanies({ isCompanies: values?.isCompanies }));
        message.success({ content: 'Готово', key, duration: 2 });
        return result;
      } else {
        throw new Error('server error');
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('error =>', e);
      message.error({
        content: 'Произошла ошибка сервера',
        key,
        duration: 2,
      });
    }
  };

const createInventory =
  ({ values }) =>
  async (dispatch) => {
    const [body, companies] = getBodyCommon(values);

    const {
      data: { success, data },
    } = await apiPost('/v1/inventory', body);

    if (success) {
      const { id: inventoryId } = data; // айдишник созданной описи

      const success = await fetchUpdateCompanies({
        companies,
        inventoryId,
      });

      if (success) {
        dispatch(initCreateSuccess({ inventoryId }));

        return inventoryId;
      } else {
        throw new Error('Server error');
      }
    } else {
      throw new Error('Server error');
    }
  };

const updateInventory = async ({ values, inventoryId }) => {
  const [body, companies] = getBodyCommon(values);

  const [
    {
      data: { success: inventorySuccess },
    },
    companiesSuccess,
  ] = await Promise.all([
    apiPost(`/v1/inventory/${inventoryId}`, body),
    fetchUpdateCompanies({
      companies,
      inventoryId,
    }),
  ]);

  if (inventorySuccess && companiesSuccess) {
    return inventoryId;
  } else {
    throw new Error('Server error');
  }
};

export const fetchUpdateCompanies = async ({ companies, inventoryId }) => {
  if (!companies) {
    return true;
  }

  const key = 'loading';
  message.loading({ content: 'Первый шаг...', key });

  const companyCollection = collectCompanies(companies);
  const responseAll = await fetchCollectCompanies(companyCollection, inventoryId);
  const successAll = getSuccessAll(responseAll); // success

  if (successAll) {
    message.success({ content: 'Готово', key, duration: 2 });
    return true;
  }

  message.error({
    content: 'Произошла ошибка сервера',
    key,
    duration: 2,
  });

  throw new Error('Server error');
};

const fetchCollectCompanies = async ([create, update], inventoryId) => {
  return Promise.all([
    ...create.map((company) => apiPost(`/v1/inventory/${inventoryId}/companies`, company)),
    ...update.map((company) => apiPost(`/v1/inventory/${inventoryId}/companies/${company?.id}`, company)),
  ]);
};

const collectCompanies = (companies) => {
  const create = [];
  const update = [];

  companies.map((company) => {
    if (company?.id) {
      update.push(company);
    } else {
      create.push(company);
    }
  });

  return [create, update];
};

const getSuccessAll = (response) => !response.filter((res) => !res?.data.success).length;

export const fetchGetInventory =
  ({ inventoryId }) =>
  async (dispatch) => {
    dispatch(startLoading());

    try {
      const key = 'loading';
      message.loading({ content: 'Загрузка первого шага...', key });

      const {
        data: { success, data },
      } = await apiGet(`/v1/inventory/${inventoryId}`);

      if (success) {
        message.success({ content: 'Готово', key, duration: 2 });

        const values = getInitialValues(data);

        dispatch(initCreateSuccess({ inventoryId }));
        dispatch(setInitialValues({ values, data }));
        dispatch(endLoading());
      }
    } catch (e) {
      message.error('Произошла ошибка сервера');
    }
  };

export const fetchDeleteCustomer =
  ({ id }) =>
  async (dispatch, getState) => {
    const key = 'loading';
    message.loading({ content: 'Удаление заказчика...', key });

    try {
      const { inventory } = getState();
      const inventoryId = inventory?.inventoryId;

      const {
        data: { success },
      } = await apiDelete(`/v1/inventory/${inventoryId}/companies/${id}`);

      if (success) {
        message.success({ content: 'Удалено', key, duration: 2 });
      }
    } catch (e) {
      message.error({
        content: 'Произошла ошибка сервера',
        key,
        duration: 2,
      });
    }
  };

// export const fetchGetCompanies = () => async (dispatch) => {
//     const key = 'loading';
//     message.loading({ content: 'Загрузка компаний...', key });
//
//     try {
//         const {
//             data: { success, data },
//         } = await apiGet('/v1/companies'); // data -. list
//         if (success) {
//             message.success({ content: 'Готово', key, duration: 2 });
//             const { list, pagination } = data;
//             dispatch(setCompanies({ list }));
//         }
//     } catch (e) {
//         message.error({
//             content: 'Произошла ошибка сервера!',
//             key,
//             duration: 2,
//         });
//     }
// };

const getBodyCommon = (values) => {
  return [
    {
      // common
      docId: values.common?.docId,
      docNumber: values.common?.docNumber,
      docDate: moment(values.common?.docDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      docDatePlan: moment(values.common?.docDatePlan, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      // docDatePlan: values.common?.docDatePlan,
      mass: values.common?.mass,

      // batchInfo
      placeQuantity: values.batchInfo?.placeQuantity,
      carrierId: values.batchInfo?.carrierName,
      stamp: values.batchInfo?.stamp,

      // company
      companyId: values.company?.companyId,
      companyAddress: values.company?.address,
      companyInn: values.company?.inn,
      contractId: values.company?.contractId,
      consignor: values.company?.consignor,

      // responsible
      responsible: values?.responsible.filter((responsible) => responsible.fio && responsible.position),
      typeDistribution: values.typeDistribution,
      // comments: Длинный комментарий
      companies: values?.companies,
    },
    values?.companies,
  ];
};

const getInitialValues = (data) => {
  return {
    common: {
      docId: data?.docId,
      docDate: data?.docDate,
      docNumber: data?.docNumber,
      docDatePlan: data?.docDatePlan,
      mass: data?.mass,
      //DD.MM.YYYY

      // docDate: moment(data?.docDate, 'DD.MM.YYYY').format(
      //     'DD.MM.YYYY'
      // ),
      // docDatePlan: moment(data?.docDatePlan, 'DD.MM.YYYY').format(
      //     'DD.MM.YYYY'
      // ),
    },
    batchInfo: {
      placeQuantity: data?.placeQuantity,
      carrierName: data?.carrierId,
      stamp: data?.stamp,
    },
    responsible: data?.responsible,
    company: {
      companyId: data?.company.id,
      inn: data?.company.inn,
      address: data?.company.address,
      contractId: data?.contractId ? data?.contractId : undefined,
      // contractId: data?.contractId,
      isConsignor: !!data?.consignor,
      consignor: data?.consignor,
    },
    isCompanies: !!data?.companies,
    companies: data?.companies,
    // typeDistribution: data?.typeDistribution,
    typeDistribution: !data?.companies ? undefined : data?.typeDistribution,
    // contracts: [],
    // documents: [],
  };
};

export default inventory.reducer;
