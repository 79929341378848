import styled from 'styled-components';
import { Button as AntdButton } from 'antd';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: #1f150b;
`;

export const Container = styled.div`
  width: 360px;
  background-color: #fff;
  border-radius: 3px;
  padding: 19px 30px;
`;

export const LogoContainer = styled.div`
  max-width: 200px;
  margin: auto;
  margin-bottom: 42px;
`;

export const Text = styled.div`
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
`;

export const Button = styled(AntdButton)`
  &&& {
    background: linear-gradient(180deg, #df955b 0%, #cd743d 100%);
    box-shadow: inset 0px -1px 0px rgba(139, 42, 42, 0.326455);
    border-radius: 3px;
    border: none;
    display: block;
    color: #fff;
    width: 100%;
    padding: 10px 0;
    margin-bottom: 20px;
    cursor: pointer;
    :hover {
      background-color: inherit;
    }
    :focus {
      background: linear-gradient(180deg, #cd743d 0%, #df955b 100%);
    }
    :active {
      background-color: #a6a6a6;
    }
  }
`;
