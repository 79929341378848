import { createAsyncThunk } from '@reduxjs/toolkit';
import * as profileService from './services';
import * as antd from 'antd';
import { getLayotData } from 'actions/auth';

export const fetchUsers = createAsyncThunk('profile/fetchUsers', async (id) => {
  const { data } = await profileService.getUsers(id);
  return data;
});

export const createUser = createAsyncThunk(
  'profile/createUser',
  async ({ values, avatar }, { dispatch, rejectWithValue }) => {
    try {
      const response = await profileService.createUser(values);
      const { data } = response;

      if (avatar) {
        await dispatch(updateAvatar({ id: data?.id, file: avatar }));
      }

      return response;
    } catch (err) {
      const error = err;
      if (!error.response) {
        throw err;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUsers = createAsyncThunk(
  'profile/updateUsers',
  async ({ id, userData }, { dispatch, rejectWithValue }) => {
    try {
      const response = await profileService.updateUsers(id, userData);

      dispatch(fetchUsers(id));
      return response;
    } catch (err) {
      antd.message.error(err.response.data.message);

      const error = err;
      if (!error.response) {
        throw err;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePassword = createAsyncThunk('profile/updatePassword', async ({ id, password }) => {
  const { data, success, message } = await profileService.updatePassword(id, password);
  if (!success) {
    throw new Error(message);
  }
  antd.message.success(message);

  return data;
});

export const updateAvatar = createAsyncThunk('profile/updateAvatar', async ({ id, file }, { dispatch }) => {
  const { data, success, message } = await profileService.updateAvatar(id, file);
  if (!success) {
    throw new Error(message);
  }
  antd.message.success(message);
  dispatch(fetchUsers(id));

  return data;
});

export const deleteUser = createAsyncThunk('profile/deleteUser', async (id) => {
  const { data, success, message } = await profileService.deleteUser(id);

  if (!success) {
    throw new Error(message);
  }
  antd.message.success(message);

  return data;
});

export const attachRole = createAsyncThunk('profile/attachRole', async ({ userId, roleId }, { dispatch }) => {
  try {
    const response = await profileService.attachRole(userId, roleId);
    const { data, message } = response;
    const rolesNeedsToRefresh = [5];

    antd.message.success(message);
    rolesNeedsToRefresh.includes(roleId) && window.location.pathname.includes('profile') && dispatch(getLayotData());

    return data;
  } catch (err) {
    const error = err;
    if (!error.response) {
      throw err;
    }
    antd.message.error(error.response.data.message);
    await dispatch(fetchUsers(userId));
  }
});

export const detachRole = createAsyncThunk('profile/detachRole', async ({ userId, roleId }, { dispatch }) => {
  try {
    const { data, message } = await profileService.detachRole(userId, roleId);
    const rolesNeedsToRefresh = [5];

    antd.message.success(message);
    rolesNeedsToRefresh.includes(roleId) && window.location.pathname.includes('profile') && dispatch(getLayotData());

    return data;
  } catch (err) {
    const error = err;
    if (!error.response) {
      throw err;
    }
    antd.message.error(error.response.data.message);
    await dispatch(fetchUsers(userId));
  }
});

export const updateLocale = createAsyncThunk('profile/updateLocale', async ({ userId, locale }) => {
  const { data, success, message } = await profileService.updateLocale(userId, locale);
  if (!success) {
    throw new Error(message);
  }
  antd.message.success(message);

  return data;
});
