export const ALL_CONTRACTS_VALUE = 0;
export const ONLY_MAIN_CONTRACT_VALUE = '__mainContract';

export const DATE_API_FORMAT = 'YYYY-MM-DD';
export const DATE_CLIENT_FORMAT = 'DD.MM.YY';
export const WITHOUT_PASSPORT_ADD_WORK = 'Дата ориентировочная и не учитывает длительность доработки сырья';

export const SuppliesParamsEnum = {
  DateFrom: 'dateFrom',
  DateTo: 'dateTo',
  Num: 'num',
  ContractId: 'contractId',
  SubContractId: 'subContractId',
  OnlyMain: 'onlyMain',
  Cursor: 'cursor',
} as const;

export const MetalAccordingEnum = {
  Factory: 'factory',
  Supplier: 'supplier',
} as const;

export const SupplyIndicatorsEnum = {
  // 1 - партия без признака доработки, с паспортом, без индикатора
  1: 1,
  // 2 - партия без признака доработки, без паспорта, желтый индикатор
  2: 2,
  // 3 - партия с признаком доработки, партия в доработке, желтый индикатор
  3: 3,
  // 4 - партия с признаком доработки, доработка завершенга, зелёный индикатор
  4: 4,
};
