import { useCallback, useEffect, useRef, useState } from 'react';
import { generateHash, getRedirectUri, openOAuthPopup } from 'features/Login/SSO/utils';
import { OAuthMessageSchema, OAuthStateSchema } from 'models/params/auth/oauth';
import { useTranslation } from 'react-i18next';
import { isNull } from 'lodash-es';
import { useStorage } from 'hooks/useStorage';
import { OAUTH_STATE_STORAGE_KEY, OAUTH_STATE_STORAGE_TYPE } from 'constants/oauth';
import { showMessage } from 'utils/showMessage';
import { useUnmount } from 'react-use';
import { useOAuthToken } from 'ducks/auth/hooks/useOAuthToken';
import { useAuth } from 'ducks/auth/hooks/useAuth';
import { accessToken } from 'utils/accessToken';
import { refreshToken } from 'utils/refreshToken';

export function useOAuth({ initApp }: Pick<ReturnType<typeof useAuth>, 'initApp'>) {
  const { t } = useTranslation();
  const [isCodeLoading, setCodeLoading] = useState(false);
  const {
    getOAuthToken,
    isLoading: isTokenLoading,
    isSuccess: isTokenSuccess,
    isError: isTokenError,
    data,
  } = useOAuthToken();

  const { storageValue, setStorageValue, removeStorageValue } = useStorage({
    type: OAUTH_STATE_STORAGE_TYPE,
    key: OAUTH_STATE_STORAGE_KEY,
    schema: OAuthStateSchema,
  });

  const popupRef = useRef<Window | null>(null);
  const intervalRef = useRef<ReturnType<typeof setInterval>>();

  const showAuthError = useCallback(() => {
    showMessage(t('auth.Ошибка авторизации по SSO'));
  }, [t]);

  const resetPopupState = useCallback(() => {
    popupRef.current?.close();
    clearInterval(intervalRef.current);
    setCodeLoading(false);
    removeStorageValue();
  }, [removeStorageValue]);

  const checkPopupClosing = useCallback(() => {
    if (!popupRef.current) {
      resetPopupState();
      return;
    }

    intervalRef.current = setInterval(() => {
      if (!popupRef.current?.closed) {
        return;
      }
      showAuthError();
      resetPopupState();
    }, 1000);
  }, [resetPopupState, showAuthError]);

  const onClick = useCallback(() => {
    setCodeLoading(true);

    const state = generateHash();
    setStorageValue(state);

    popupRef.current = openOAuthPopup(state);
    checkPopupClosing();
  }, [checkPopupClosing, setStorageValue]);

  useEffect(() => {
    const onPopupMessage = async (message: MessageEvent) => {
      const parsedResult = OAuthMessageSchema.safeParse(message.data);

      if (!parsedResult.success) {
        return;
      }

      const { payload } = parsedResult.data;

      if (isNull(payload) || payload.state !== storageValue) {
        showAuthError();
      } else {
        getOAuthToken({
          code: payload.code,
          redirectUri: getRedirectUri(),
        });
      }

      resetPopupState();
    };

    window.addEventListener('message', onPopupMessage);
    return () => {
      window.removeEventListener('message', onPopupMessage);
    };
  }, [getOAuthToken, resetPopupState, showAuthError, storageValue]);

  useEffect(() => {
    if (isTokenSuccess && data) {
      if (data.refreshToken) {
        refreshToken.setToken(data.refreshToken);
      }
      accessToken.setToken(data.accessToken);
      initApp();
    }
  }, [data, initApp, isTokenSuccess]);

  useEffect(() => {
    if (isTokenError) {
      showAuthError();
    }
  }, [isTokenError, showAuthError]);

  useUnmount(resetPopupState);

  return {
    isLoading: isCodeLoading || isTokenLoading,
    onClick,
  };
}
