import { z } from 'zod';

export const SidebarCodesEnum = z.enum([
  'balance',
  'finances',
  'prices',
  'other',
  'shipment',
  'dashboard',
  'orders',
  'remains',
  'inventory',
  'support',
  'managers',
  'documents',
  'questionnaire',
  'user',
  'exit',
  'users',
  'company',
  'other',
  'metal-sale',
  'metal-purchase',
  'early-shipment',
]);
