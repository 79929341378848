import React from 'react';
import { NavItem } from './NavItem';
import * as S from './styled';
import { actionTriggering } from 'analytics';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEmptyNavBlock } from 'features/sidebar/utils';
import { SidebarCodesEnum } from 'constants/sidebarCodes';
import { WsNotification } from 'features/support/WsNotification';
import { useQaAttr } from 'hooks/useQA';

export function MainNav({ mainNav, isAdmin, count }) {
  const { qaAttr } = useQaAttr(`sidebar${isAdmin ? '-admin' : ''}`);
  const { permissions } = useSelector((state) => state?.auth?.init) || {};

  const renderMenuItems = (pages) => {
    return pages.map(({ name, link, code, permission, extraIcon }, index) => (
      <S.NavLink
        key={code}
        to={link}
        onClick={() =>
          actionTriggering('layout__1st-lvl-nav', {
            ActionTargetName: code,
          })
        }
        {...qaAttr(code)}
      >
        <NavItem
          permission={permission}
          key={index}
          iconName={code === 'metal-sale' ? 'fixation' : code}
          tabIndex={index}
          count={code === SidebarCodesEnum.enum.support && count}
          extraIcon={extraIcon}
        >
          <S.ItemNavTitle>
            {/*ключ name берется из ./mock.js */}
            {isAdmin ? name : <Trans i18nKey={`sidebar.${name}`} />}
            {code === SidebarCodesEnum.enum.support && (
              <WsNotification
                isEnabled
                size="sm"
              />
            )}
          </S.ItemNavTitle>
        </NavItem>
      </S.NavLink>
    ));
  };

  return (
    <S.Main>
      {Object.entries(mainNav).map(([code, pages]) =>
        isEmptyNavBlock(pages, permissions) ? null : <S.Block key={code}>{renderMenuItems(pages)}</S.Block>
      )}
    </S.Main>
  );
}
