import { createAsyncThunk } from '@reduxjs/toolkit';
import { SuppliesService } from './service';
import { SuppliesApi } from './service.types';

export const SUPPLIES_PREFIX = 'supplies';
export const GET_SUPPLIES_ACTION_PREFIX = `${SUPPLIES_PREFIX}/getSupplies`;
export const GET_SUPPLY_ACTION_PREFIX = `${SUPPLIES_PREFIX}/getSupply`;

export const getSupplies = createAsyncThunk(
  GET_SUPPLIES_ACTION_PREFIX,
  async (payload?: SuppliesApi.getSupplies.Request.Params) => {
    const { data } = await SuppliesService.getSupplies(payload);
    return data;
  }
);

export const getSupply = createAsyncThunk(GET_SUPPLY_ACTION_PREFIX, async (payload: number) => {
  const { data } = await SuppliesService.getSupply(payload);
  return data;
});
