import { space, SpaceProps } from 'styled-system';
import styled from 'styled-components';

export const HeaderDescription = styled.div<SpaceProps>`
  ${space};
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.space['14']};
  color: ${({ theme }) => theme.colors['grey-1']};
  line-height: 1.2em;
`;
