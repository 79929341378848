import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminTicketsListApi, TicketsQueryParams } from 'services/admin/support/adminTicketsListApi';
import { skipEmptyValues } from 'utils/skipEmptyValues';
import { axiosErrorHandle } from 'utils/axiosErrorHandle';

export const ADMIN_TICKETS_LIST_PREFIX = 'adminTicketsList';
export const GET_ADMIN_TICKETS_LIST = `${ADMIN_TICKETS_LIST_PREFIX}/getAdminTicketsList`;
export const CREATE_ADMIN_TICKET = `${ADMIN_TICKETS_LIST_PREFIX}/createTicket`;
export const GET_ADMIN_TICKETS_REPORT = `${ADMIN_TICKETS_LIST_PREFIX}/getAdminTicketsReport`;

export const getAdminTicketsList = createAsyncThunk(GET_ADMIN_TICKETS_LIST, async (payload: TicketsQueryParams) => {
  const { data } = await adminTicketsListApi.getAdminTicketsList(skipEmptyValues(payload));
  return data;
});

export const createAdminTicket = createAsyncThunk(CREATE_ADMIN_TICKET, async (payload: FormData) => {
  try {
    const { data } = await adminTicketsListApi.createAdminTicket(payload);
    return data;
  } catch (err) {
    axiosErrorHandle(err, 'Не удалось создать обращение');
    throw err;
  }
});
