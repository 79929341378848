import types from './types';
import history from '../store/history';
import { ROOT_URL, JWT_ACCESS } from './connect';
import { getLayotData, initUpdateNotificationCount } from 'actions/auth';
import { checkError } from 'actions/errorText';
import axios from 'axios';
import { actionTriggering } from '../analytics';
import Cookies from 'universal-cookie';

export const getCompanyList = (search = '', exclude = '', page) => {
  return (dispatch) => {
    dispatch({
      type: types.COMPANY_LOADER,
      payload: true,
    });
    axios
      .get(`${ROOT_URL}/v1/companies`, {
        params: {
          search,
          page,
          exclude,
        },
        headers: {
          Authorization: `Bearer  ${localStorage.getItem(JWT_ACCESS)}`,
          credentials: 'include',
        },
      })
      .then((response) => {
        dispatch({
          type: types.GET_COMPANY_LIST,
          payload: response.data.data,
        });
        dispatch({
          type: types.COMPANY_LOADER,
          payload: false,
        });
      })
      .catch((error) => {
        checkError(error, dispatch, 'company', 'change');
        dispatch({
          type: types.COMPANY_LOADER,
          payload: false,
        });
      });
  };
};

export const changeCompany = (id) => {
  actionTriggering('layout__change-company', {
    ActionTargetName: id.toString(),
  });
  return async (dispatch) => {
    const cookies = new Cookies();

    cookies.set('companies', id, { path: '/' });
    await dispatch(getLayotData(true));
    initUpdateNotificationCount(dispatch, true);
    history.push('/signin', { currentPage: window.location.pathname });
  };
};

export const getNextPage = (search = '', exclude = '', page) => {
  return (dispatch) => {
    dispatch({
      type: types.COMPANY_NEXT_PAGE_LOADER_START,
    });
    axios
      .get(`${ROOT_URL}/v1/companies`, {
        params: {
          search,
          exclude,
          page,
        },
        headers: {
          Authorization: `Bearer  ${localStorage.getItem(JWT_ACCESS)}`,
        },
      })
      .then((response) => {
        dispatch({
          type: types.GET_NEXT_PAGE,
          payload: response.data.data,
        });
        dispatch({
          type: types.COMPANY_NEXT_PAGE_LOADER_END,
        });
      })
      .catch((error) => {
        checkError(error, dispatch, 'company', 'change');
        dispatch({
          type: types.COMPANY_NEXT_PAGE_LOADER_END,
        });
      });
  };
};
