import { apiGet, apiPost } from '../../../services/helper';

class DocumentServiceFactory {
  async getAllTessaDocuments(search = '') {
    const { data } = await apiGet(`/v1/tessa/documents${search}`);
    return data;
  }

  async getFilters() {
    const { data } = await apiGet(`/v1/tessa/documents/filters`);
    return data;
  }

  async downloadDocument(document) {
    const { data } = await apiPost(`/v1/files/tessa`, document);
    return data;
  }
}

export const DocumentSearvice = new DocumentServiceFactory();
