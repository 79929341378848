import { createAsyncThunk } from '@reduxjs/toolkit';
import { QuestionnaireService } from './service';
import { actionTriggering } from 'analytics';
import { actions } from './slice';
import { message as antdMessage } from 'antd';
import * as R from 'ramda';
import { RemoveDocumentPayload, SendFormPayload, UploadDocumentPayload } from './actions.types';
import { QuestionnaireApi } from './service.types';
import { AppDispatch } from '../../../store';
import _set from 'lodash-es/set';
import { normalizeFields, QUESTIONNAIRE_TYPES } from './utils';

export const TYPE_PREFIX = 'questionnaire';

export const fetchBasicQuestionnaireInfo = createAsyncThunk(`${TYPE_PREFIX}/fetchBasicQuestionnaireInfo`, async () => {
  const { data, success, message } = await QuestionnaireService.getBasicQuestionnaireInfo();
  if (!success) {
    throw new Error(message);
  }
  return data;
});
export const uploadDocument = createAsyncThunk(
  `${TYPE_PREFIX}/uploadDocument`,
  async ({ type, file, questId }: UploadDocumentPayload, { dispatch }) => {
    const formData = new FormData();
    formData.append(`file[${type}]`, file);
    formData.append(`filling_id`, questId);

    const { data, success, message } = await QuestionnaireService.uploadDocument(formData);
    if (!success) {
      actionTriggering('questionnaire__upload-file-error');
      throw new Error(message);
    }

    actionTriggering('questionnaire__upload-file-success');
    dispatch(fetchBasicQuestionnaireInfo());
    return data;
  }
);
export const removeDocument = createAsyncThunk(
  `${TYPE_PREFIX}/removeDocument`,
  async ({ type, questId }: RemoveDocumentPayload, { dispatch }) => {
    const formData = new FormData();
    formData.append(`filling_id`, questId);
    formData.append(`delete_type`, type);

    const { data, success, message } = await QuestionnaireService.removeDocument(formData);
    if (!success) {
      actionTriggering('questionnaire__remove-file-success');
      throw new Error(message);
    }

    actionTriggering('questionnaire__remove-file-error');
    dispatch(fetchBasicQuestionnaireInfo());
    return data;
  }
);

export const changeQuestionnaireStatus = createAsyncThunk(
  `${TYPE_PREFIX}/changeQuestionnaireStatus`,
  async (payload: QuestionnaireApi.changeQuestionnaireStatus.Request.Body) => {
    const { data, success, messages } = await QuestionnaireService.changeQuestionnaireStatus(payload);
    if (success) {
      antdMessage.success(messages);
    } else {
      antdMessage.error(messages);
      throw new Error(messages);
    }
    return data;
  }
);

export const resetQuestionnaire = createAsyncThunk(`${TYPE_PREFIX}/resetQuestionnaire`, async (_, { dispatch }) => {
  const { data, success, messages } = await QuestionnaireService.resetQuestionnaire();
  if (success) {
    antdMessage.success(messages);
  } else {
    antdMessage.error(messages);
    throw new Error(messages);
  }
  dispatch(fetchBasicQuestionnaireInfo());
  return data;
});

export const sendForm = createAsyncThunk(
  `${TYPE_PREFIX}/sendForm`,
  async ({ body, check }: SendFormPayload, { dispatch }) => {
    const normalizedBody = R.clone(body);
    _set(
      normalizedBody,
      'authorizedPerson.authorizedPersons',
      normalizeFields(normalizedBody.authorizedPerson?.authorizedPersons)
    );
    _set(
      normalizedBody,
      'authPerson',
      (Array.isArray(normalizedBody.authPerson) ? normalizedBody.authPerson : []).filter(Boolean)
    );
    _set(normalizedBody, 'beneficiaries.entity', normalizeFields(normalizedBody.beneficiaries?.entity));
    _set(normalizedBody, 'beneficiaries.individual', normalizeFields(normalizedBody.beneficiaries?.individual));
    _set(normalizedBody, 'managment.entity', normalizeFields(normalizedBody.managment?.entity));
    _set(normalizedBody, 'managment.individual', normalizeFields(normalizedBody.managment?.individual));

    const questionnaireType = normalizedBody.basic?.questionnairType
      ? QUESTIONNAIRE_TYPES[normalizedBody.basic.questionnairType as keyof typeof QUESTIONNAIRE_TYPES]
      : '';

    const { data, success, message } = await QuestionnaireService.sendForm({
      filling: normalizedBody,
      type: questionnaireType,
    });

    if (!success) {
      throw new Error(message);
    }

    check && dispatch(changeQuestionnaireStatus(check));

    return data;
  }
);

export const nextStep = (payload: SendFormPayload) => {
  return (dispatch: AppDispatch) => {
    dispatch(sendForm(payload));
    dispatch(actions.nextStepQuestionnaire());
  };
};

export const prevStep = (payload: SendFormPayload) => {
  return (dispatch: AppDispatch) => {
    dispatch(sendForm(payload));
    dispatch(actions.prevStepQuestionnaire());
  };
};
