import { z } from 'zod';
import { DiscountTypesEnum } from 'constants/metalSale';

export const DiscountEntity = z.object({
  value: z.number(),
  isDisabled: z.boolean(),
  type: DiscountTypesEnum,
});

export type DiscountEntity = z.infer<typeof DiscountEntity>;
