import styled from 'styled-components';
import {
  Badge as AntdBadge,
  Menu as AntdMenu,
  Dropdown as AntdDropdown,
  Tooltip as AntdTooltip,
  Button as AntdButton,
  Radio as AntdRadio,
} from 'antd';
import { NavLink as NavLinkRoute } from 'react-router-dom';
import * as AppLinks from 'features/AppLinks/AppLinks.styled';

const transitionDuration = '0.4';

export const Main = styled.div``;

export const Block = styled.div`
  position: relative;
  padding: 12px 0;
  ::before {
    position: absolute;
    top: 0;
    display: block;
    width: calc(100% - 40px);
    content: '';
    margin: 0 20px;
    border-bottom: 1px solid #797979;
  }
  :first-child::before {
    display: none;
  }
  @media (max-width: 1366px) {
    padding: 8px 0;
  }
  @media (max-width: 850px) {
    ::before {
      top: 0;
      left: 0;
    }
  }
`;

export const HeaderBlock = styled(Block)`
  ::before {
    display: block;
    top: unset;
    bottom: 0;
  }
`;

export const Logo = styled.div`
  position: relative;
  display: flex;
  padding: 0 16px;
`;

export const LogoIcon = styled.div`
  svg {
    width: 35px;
    height: 48px;
    transition: ${transitionDuration}s;
  }
  @media (max-width: 1366px) {
    svg {
      width: 30px;
      height: 41px;
    }
  }
`;

export const LogoText = styled.div`
  margin-left: 6px;
  margin-top: 24px;
  transition: ${transitionDuration}s;
  @media (max-width: 1366px) {
    margin-top: 19px;
    margin-left: 100px;
    opacity: 0;
    svg {
      width: 135px;
      height: 14px;
      transition: ${transitionDuration}s;
    }
  }
`;

export const UserBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 8px 20px;
  @media (max-width: 1366px) {
    padding: 6px 20px;
  }
  @media (max-width: 850px) {
    padding: 12px 20px;
    margin-bottom: 0;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 850px) {
    width: 100%;
  }
`;

export const UserTitle = styled.div`
  width: min-content;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: ${transitionDuration}s;
  @media (max-width: 1366px) {
    margin-left: 100px;
    opacity: 0;
  }
  @media (max-width: 850px) {
    margin-left: 0;
    opacity: 1;
  }
`;

export const Dropdown = styled(AntdDropdown)`
  &&& {
    .ant-dropdown-arrow {
      top: 3px;
      border-width: 8px;
      border-radius: 3px;
      box-shadow: -2px -2px 4px -2px rgb(0 0 0 / 18%);
    }
  }
`;

export const MenuIcon = styled.div`
  margin-right: 8px;
  width: 20px;
  height: 20px;
`;

export const Menu = styled(AntdMenu)`
  &&& {
    padding: 8px 0;
    border-radius: 3px;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 18%);
    .ant-dropdown-menu-item {
      display: flex;
      align-items: center;
      padding: 12px 20px;
      color: #1d1d1d;
      font-size: 14px;
      line-height: 15px;
    }
    .ant-dropdown-menu-item:hover {
      background-color: #eeeeee;
    }
  }
`;

export const NavItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 12px 20px;
  color: ${(props) => props.variant};
  column-gap: ${({ theme }) => theme.space[8]};

  :hover {
    background-color: #383838;
    box-shadow: 0px 0px 24px rgba(56, 56, 56, 0.16);
  }
  :active {
    background-color: #1d1d1d;
  }
  @media (max-width: 850px) {
    padding: 13px 20px;
  }
`;

export const NavLink = styled(NavLinkRoute)`
  &.active {
    ${NavItem} {
      background-color: #383838;
    }
    ${NavItem}::before {
      content: '';
      position: absolute;
      left: 0;
      display: block;
      height: calc(100% + 8px);
      border-left: 6px solid #797979;
      border-radius: 0 3px 3px 0;
      z-index: 1;
    }
  }
`;

export const ItemNavGroup = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  flex: 0 1 auto;
`;

export const ItemNavTitle = styled.div`
  font-size: 16px;
  line-height: 19px;
  transition: ${transitionDuration}s;
  display: flex;
  align-items: center;
  width: 100%;
  flex: 0 1 auto;
  justify-content: space-between;
  column-gap: ${({ theme }) => theme.space[8]};

  @media (max-width: 1366px) {
    transform: translateX(100px);
    font-size: 14px;
    line-height: 15px;
    opacity: 0;
  }
  @media (max-width: 850px) {
    transform: translateX(0px);
    opacity: 1;
  }
`;

export const NavIcon = styled.div`
  display: flex;
  margin-right: 12px;
  svg {
    transition: ${transitionDuration}s;
    width: 20px;
    height: 20px;
  }
  @media (max-width: 1366px) {
    svg {
      width: 18px;
      height: 18px;
    }
  }
  @media (max-width: 850px) {
    margin-right: 8px;
  }
`;

export const UserIcon = styled(NavIcon)`
  padding-bottom: 1px;
  transition: ${transitionDuration}s;
  color: #fff;
  @media (max-width: 1366px) {
    opacity: 0;
  }
  @media (max-width: 850px) {
    opacity: 1;
  }
`;

export const Company = styled.div`
  display: inline-flex;
  width: 85%;
`;

export const CompanyWrapper = styled(NavItem)`
  color: #fff;
  white-space: nowrap;
`;

export const CompanyBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
`;

export const CompanyBlockMobile = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
  overflow: hidden;
`;

export const CompanyName = styled(ItemNavTitle)`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  word-break: break-word;
  //min-width нужен, чтобы не прыгал текст
  min-width: 172px;
  @media (max-width: 1366px) {
    min-width: 174px;
  }
  @media (max-width: 850px) {
    white-space: unset;
    word-break: unset;
    min-width: unset;
  }
`;

export const Tooltip = styled(AntdTooltip)`
  &&& {
    .ant-tooltip {
      max-width: max-content;
    }
    .ant-tooltip-inner {
      padding: 8px 12px;
      background-color: #fff;
      color: #797979;
      font-size: 14px;
      line-height: 18px;
      border-radius: 3px;
      box-shadow: none;
      filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.18));
    }
    .ant-tooltip-arrow {
      width: 12px;
      height: 12px;
      overflow: visible;
    }
    .ant-tooltip-arrow-content {
      z-index: 1070;
      width: 12px;
      height: 12px;
      background-color: #fff;
      transform: translateY(7px) rotate(45deg);
      box-shadow: none;
    }
  }
`;

export const Bell = styled.div`
  display: flex;
  position: relative;
  /* margin-left: 8px; */
  padding: 6px;
  background-color: #797979;
  color: #fff;
  border-radius: 50%;
  transition: ${transitionDuration}s;
  svg {
    min-width: 20px;
    height: 20px;
  }
  @media (max-width: 1366px) {
    margin-left: 0;
    padding-left: 0;
    padding-bottom: 0;
    background-color: transparent;
  }
`;

export const Badge = styled(AntdBadge)`
  .ant-badge-count {
    flex: 1 0 auto;
    padding: 4px 9px;
    height: 22px;
    min-width: auto;
    background-color: ${({ variant }) => (variant === 'secondary' ? '#939393' : '#cd743d')};
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    box-shadow: none;
    transition: ${transitionDuration}s;
  }
  .ant-badge-count-sm {
    top: 3px;
    right: 3px;
    min-width: 14px;
    height: 14px;
    padding: 1px 4px;
    font-size: 10px;
    line-height: 12px;
  }
  @media (max-width: 1366px) {
    .ant-badge-count {
      padding: 1px 4px;
      height: 14px;
      font-size: 10px;
      line-height: 12px;
    }
    .ant-scroll-number.ant-badge-count {
      position: absolute;
      top: -12px;
      right: -4px;
    }
    .ant-scroll-number.ant-badge-count.ant-badge-count-sm {
      top: 3px;
      right: 3px;
    }
  }
  @media (max-width: 850px) {
    .ant-scroll-number.ant-badge-count {
      position: relative;
      top: auto;
      right: 0;
      height: 18px;
      padding: 2px 8px;
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

export const Footer = styled(Block)`
  margin-top: auto;
  padding: 0;
  padding-top: 12px;
  @media (max-width: 1366px) {
    padding: 0;
    padding-top: 8px;
  }
`;

export const PanelButtonWrapper = styled.div`
  display: flex;
  column-gap: 16px;
  margin-top: 12px;
  padding: 0 20px;
  width: 100%;
  transition: ${transitionDuration}s;
  @media (max-width: 1366px) {
    flex-direction: column-reverse;
    padding: 0 14px;
    row-gap: 16px;
  }
`;

export const PanelButton = styled(AntdButton)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    background-color: #cd743d;
    border: 0;
    border-radius: 3px;
    transition: ${transitionDuration}s;
    span {
      font-size: 16px;
      line-height: 19px;
      margin-left: 4px;
      overflow: hidden;
      transition: ${transitionDuration}s;
    }
    @media (max-width: 1366px) {
      height: auto;
      padding: 7px;
      span {
        font-size: 14px;
        line-height: 16px;
        margin-left: 0;
        opacity: 0;
      }
    }
    @media (max-width: 850px) {
      padding: 8px;
      span {
        margin-left: 4px;
        opacity: 1;
      }
    }
  }
`;

export const PanelIcon = styled.div`
  display: flex;
  color: #fff;
  svg {
    transition: ${transitionDuration}s;
    width: 18px;
    height: 18px;
  }
`;

export const LocalButton = styled(AntdButton)`
  &&& {
    padding: 10px 18px;
    background: #797979;
    color: #fff;
    border: 0;
    border-radius: 3px;
    font-size: 16px;
    line-height: 19px;
    @media (max-width: 1366px) {
      height: auto;
      padding: 7px;
      font-size: 14px;
      line-height: 16px;
    }
    :hover {
      background-color: #939393;
    }
    :focus {
      filter: drop-shadow(0px 0px 2px rgba(29, 29, 29, 0.3));
    }
    :active {
      background-color: #1d1d1d;
    }
    ${(props) =>
      props.disabled &&
      `
            background-color: transparent;
            color: #bebebe;
            border-color: #bebebe;
            :hover,
                :active,
                :focus {
                    color: #bebebe;
                    border-color: #bebebe;
                    background-color: #transparent;
                    text-shadow: none;
                    box-shadow: none;
                }
    `}
  }
`;

export const LocalRadioGroup = styled(AntdRadio.Group)`
  &&& {
    display: flex;
    column-gap: 4px;
    padding: 4px;
    background-color: #383838;
    border-radius: 3px;
  }
`;

export const LocalRadio = styled(AntdRadio.Button)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    width: 100%;
    height: auto;
    background-color: transparent;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    border: none;
    border-radius: 3px;
    ::before {
      display: none;
    }
    ${(props) =>
      props.disabled &&
      `
            background-color: transparent;
            color: #bebebe;
            border-color: #bebebe;
            :hover,
                :active,
                :focus {
                    color: #bebebe;
                    border-color: #bebebe;
                    background-color: #transparent;
                    text-shadow: none;
                    box-shadow: none;
                }
    `}

    &.ant-radio-button-wrapper-checked {
      background-color: #797979;
    }
  }
`;

export const IconArrow = styled.svg`
  margin-left: 8px;
  min-width: 16px;
  width: 16px;
  height: 16px;
  color: #fff;
  transition: ${transitionDuration}s;
  @media (max-width: 850px) {
    margin-left: 12px;
    min-width: 18px;
    width: 18px;
    height: 18px;
  }
`;

export const UserIconArrow = styled(IconArrow)`
  margin-right: 16px;
  @media (max-width: 850px) {
    margin-left: auto;
    transform: rotate(270deg);
  }
`;

export const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const PageWrapper = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 850px) {
    flex-direction: column;
    min-height: 100vh;
  }
`;

export const AppLinksWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: ${({ theme }) => theme.space[12]} 0 ${({ theme }) => theme.space[24]};
  padding: ${({ theme, hasTopSeparator }) => (hasTopSeparator ? theme.space[24] : 0)} 
    ${({ theme }) => theme.space[20]}
    ${({ theme, hasBottomSeparator }) => (hasBottomSeparator ? theme.space[24] : theme.space[12])};
  transition: ${transitionDuration}s;

  &:before, &:after {
    content: "";
    display: ${({ hasTopSeparator }) => (hasTopSeparator ? 'block' : 'none')}
    position: absolute;
    top: 0;
    left: ${({ theme }) => theme.space[20]};
    right: ${({ theme }) => theme.space[20]};
    height: 1px;
    background: ${({ theme }) => theme.colors['grey-5']};
  }
  
  &:after {
    display: ${({ hasBottomSeparator }) => (hasBottomSeparator ? 'block' : 'none')}
    top: auto;
    bottom: 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
    margin-top: ${({ theme }) => theme.space[8]};
    padding-top: ${({ theme, hasTopSeparator }) => (hasTopSeparator ? theme.space[16] : 0)};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
  height: 100vh;
  top: 0;
  position: fixed;
  left: 0;
  z-index: 500;
  padding: 24px 0;
  background-color: #4f4f4f;
  transition: ${transitionDuration}s;
  flex-shrink: 0;
  overflow: hidden;

  @media (max-width: 1366px) and (min-width: 851px) {
    &:not(:hover) {
      ${({ showCompanyDrawer, theme }) =>
        !showCompanyDrawer
          ? `${AppLinksWrapper} {
        padding-left: ${theme.space[10]};
        padding-right: ${theme.space[10]};
      }
      
      ${AppLinks.LinkAppName}, ${AppLinks.LinkTitle} {
        display: none;
      }

      ${AppLinks.Link} {
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${theme.sizes[32]};
        margin-left: 4px;
        transition: margin 0.15s
      }

      ${AppLinks.LinkIcon} {
        padding: 0;
      }
      `
          : ``};
    }
  }

  @media (max-width: 1366px) {
    width: 60px;

    ${({ showCompanyDrawer }) =>
      showCompanyDrawer &&
      `
           width: 280px;
            ${LogoText} {
                margin-left: 6px;
                opacity: 1;
            }
            ${ItemNavTitle} {
                transform: translateX(0px);
                opacity: 1;
            }
            ${ItemNavGroup} {
                overflow: none;
            }
            ${UserTitle} {
                margin-left: 0;
                opacity: 1;
            }
            ${UserIcon} {
                opacity: 1;
            }
            ${IconArrow} {
                opacity: 1;
            }
            ${Bell} {
                margin-left: 8px;
                padding: 6px;
                background-color: #797979;
            }
            ${Badge} {
                .ant-badge-count {
                    height: 18px;
                    padding: 2px 5px;
                    font-size: 12px;
                    line-height: 14px;
                }
                .ant-badge-count-sm {
                    height: 14px;
                    padding: 1px 4px;
                    font-size: 10px;
                    line-height: 12px;
                }
                .ant-scroll-number.ant-badge-count {
                    top: -9px;
                    right: 0;
                }
                .ant-scroll-number.ant-badge-count.ant-badge-count-sm {
                    position: absolute;
                    top: 3px;
                    right: 3px;
                }
            }
            &&& ${PanelButton} {
                padding: 8px;
                span {
                    margin-left: 4px;
                    opacity: 1;
                }
            }
            ${PanelIcon} {
                svg {
                    width: 14px;
                    height: 14px;
                }
            }
        `}
    :hover {
      width: 280px;
      ${LogoText} {
        margin-left: 6px;
        opacity: 1;
      }
      ${ItemNavTitle} {
        transform: translateX(0px);
        opacity: 1;
      }
      ${ItemNavGroup} {
        overflow: none;
      }
      ${UserTitle} {
        margin-left: 0;
        opacity: 1;
      }
      ${UserIcon} {
        opacity: 1;
      }
      ${IconArrow} {
        opacity: 1;
      }
      ${Bell} {
        margin-left: 8px;
        padding: 6px;
        background-color: #797979;
      }
      ${Badge} {
        .ant-badge-count {
          position: static;
          height: 18px;
          padding: 2px 8px;
          font-size: 12px;
          line-height: 14px;
        }
        .ant-badge-count-sm {
          height: 14px;
          padding: 1px 4px;
          font-size: 10px;
          line-height: 12px;
        }
        .ant-scroll-number.ant-badge-count.ant-badge-count-sm {
          transform: translateY(-50%);
          position: absolute;
          top: 3px;
          right: 3px;
        }
      }
      ${PanelButtonWrapper} {
        flex-direction: row;
      }
      ${PanelButton} {
        padding: 8px;
        span {
          margin-left: 4px;
          opacity: 1;
        }
      }
      ${PanelIcon} {
        svg {
          width: 14px;
          height: 14px;
        }
      }
      ${LocalButton} {
        padding: 8px 18px;
      }
    }
  }
  @media (max-width: 850px) {
    width: 100%;
  }
`;

export const ExtraIcon = styled.span`
  display: flex;
  align-items: center;
  margin-left: auto;

  svg {
    width: 20px;
    height: 20px;
    @media (max-width: 1366px) {
      width: 18px;
      height: 18px;
    }
  }
`;
