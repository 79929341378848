import { asyncPipe } from 'utils/asyncPipe';
import { getDataFromResponse } from 'utils/getDataFromResponse';
import { BannersApi } from './service.types';
import { apiGet, apiPost } from 'services/helper';

const BANNERS_API_URL = '/v1/banners';
const DISABLE_BANNER_API = 'disable';

class BannersServiceFactory {
  getBanners = () => {
    return asyncPipe(apiGet, getDataFromResponse)<BannersApi.getBanners.Response.Success>(BANNERS_API_URL);
  };

  sendRequestFromBanner = (bannerId: number, body: BannersApi.sendRequestFromBanner.Request.Body) => {
    return asyncPipe(apiPost, getDataFromResponse)<BannersApi.sendRequestFromBanner.Response.Success>(
      `${BANNERS_API_URL}/${bannerId}`,
      body
    );
  };

  disableBanner = (body: BannersApi.disableBanner.Request.Body) => {
    return asyncPipe(apiPost, getDataFromResponse)<BannersApi.disableBanner.Response.Success>(
      `${BANNERS_API_URL}/${DISABLE_BANNER_API}`,
      body
    );
  };
}

export const BannersService = new BannersServiceFactory();
