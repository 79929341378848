import { z, ZodType } from 'zod';

export const PaginationEntity = z.object({
  total: z.number(),
  count: z.number(),
  perPage: z.number(),
  currentPage: z.number(),
  totalPages: z.number(),
  links: z.object({
    next: z.string().or(z.null()).or(z.undefined()),
  }),
});
export type PaginationEntity = z.infer<typeof PaginationEntity>;
export const createListEntity = <T extends ZodType>(itemSchema: T) =>
  z.object({
    list: z.array(itemSchema),
    pagination: PaginationEntity,
  });

export const CursorPaginationEntity = z.object({
  perPage: z.number(),
  nextPageUrl: z.string().or(z.null()),
  prevPageUrl: z.string().or(z.null()),
});
export type CursorPaginationEntity = z.infer<typeof CursorPaginationEntity>;
export const createListWithCursorEntity = <T extends ZodType>(itemSchema: T) =>
  z.object({
    list: z.array(itemSchema),
    pagination: CursorPaginationEntity,
  });
