import { z } from 'zod';
import { SuccessEntity } from 'models/api/wts/common/success';
import { ProductEntity } from './product';

export const ProductActiveListEntity = z
  .object({
    activeProducts: z.array(ProductEntity),
  })
  .merge(SuccessEntity);

export type ProductActiveListEntity = z.infer<typeof ProductActiveListEntity>;
