import styled from 'styled-components';
import { CSSProperties } from 'react';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  max-width: fill-available;
  margin-left: 280px;

  @media (max-width: 1366px) {
    margin-left: 60px;
  }

  @media (max-width: 850px) {
    margin-left: 0;
  }
`;

export const Header = styled.header<{ padding?: boolean }>`
  padding: ${(props) => (props.padding ? 0 : '24px 40px')};
  box-shadow: 0 1px 2px rgb(0 0 0 / 8%);
  background-color: #fff;
  @media (max-width: 1366px) {
    padding: ${(props) => (props.padding ? 0 : '24px 32px')};
  }
  @media (max-width: 850px) {
    padding: ${(props) => (props.padding ? 0 : '16px')};
  }
`;

export const TopPane = styled.div<{ style?: CSSProperties }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Title = styled.div`
  position: relative;
  margin-right: auto;
  font-weight: 300;
  font-size: 36px;
  line-height: 1.2em;
  color: #1d1d1d;
  @media (max-width: 1366px) {
    font-size: 34px;
    line-height: 42px;
  }
  @media (max-width: 850px) {
    font-size: 28px;
    line-height: 36px;
  }
`;

export const Content = styled.div<{ padding?: boolean }>`
  padding: ${(props) => (props.padding ? 0 : '32px')};
  background-color: #f7f7f7;
  flex-grow: 1;
  height: 100%;
`;

export const HeaderBottom = styled.div`
  margin-top: 20px;
`;

export const StepsWrapper = styled.div`
  border: 1px solid #cfcfcf;
  border-radius: 3px;
  padding: 8px 35px;
  text-align: center;
  font-weight: 300;
  font-size: 22px;
  margin-right: 26px;
  display: flex;
  align-items: center;
`;

export const CurrentStep = styled.div`
  font-size: 22px;
  color: rgb(51, 51, 51);
`;

export const MaxSteps = styled.div`
  font-size: 16px;
  color: rgb(130, 130, 130);
  margin-bottom: -0.4em;
`;
