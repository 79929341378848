import React from 'react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { FinancesService } from './service';
import { Trans } from 'react-i18next';
import * as antd from 'antd';

export const getDKZ = createAsyncThunk('finances/getDKZ', async () => {
  const { data, success, message } = await FinancesService.getDKZ();

  if (!success) {
    throw new Error(message);
  }

  return data;
});

export const getActs = createAsyncThunk('finances/getActs', async (search) => {
  const { data, success, message } = await FinancesService.getActs(search);

  if (!success) {
    throw new Error(message);
  }

  return data;
});

export const createActs = createAsyncThunk('finances/createActs', async (payload, { dispatch }) => {
  const { data, success, message } = await FinancesService.createActs(payload);

  if (!success) {
    antd.message.error(message);
    throw new Error(message);
  }
  //TODO временный меседж, пока не приходит с бека
  antd.message.success(<Trans i18nKey="finances.Отчет запрошен" />);
  dispatch(getActs());

  return data;
});

export const removeAct = createAsyncThunk('finances/removeAct', async (id, { dispatch }) => {
  const { data, success, message } = await FinancesService.removeAct(id);

  if (!success) {
    throw new Error(message);
  }
  antd.message.success(message);
  dispatch(getActs());

  return data;
});
