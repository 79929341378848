import React, { useEffect, useRef } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { icons } from 'features/sidebar/Icons';
import { SearchOutlined } from '@ant-design/icons';
import { SubIcon } from '../MobileSidebar/styled';
import { useTranslation } from 'react-i18next';
import * as S from './styled';
import { Spin } from 'antd';

export function CompanyDetail({
  currentCompany,
  visible,
  onClose,
  onChangeCompany,
  sidebarRef,
  onSearch: searchCompany,
  onScroll,
  companies,
  inputSearch,
  setSearch,
  pagination,
  isLoading = false,
  isLoadingNextPage = false,
}) {
  const { t } = useTranslation();
  const searchTimer = useRef(null);
  const location = useLocation();
  const companyDetailRef = useRef(null);
  const isMobileDevice = useMediaQuery({ query: '(max-width: 850px)' });

  const updateHandler = (values) => {
    if (isLoading) {
      return;
    }
    if (isLoadingNextPage) {
      return;
    }
    if (companies?.length === 0) {
      return;
    }
    if (pagination?.currentPage === pagination?.totalPages) {
      return;
    }
    if (values.top >= 1) {
      onScroll(inputSearch, pagination?.currentPage + 1);
    }
  };

  const selectCompanyHandler = (id) => {
    onClose();
    onChangeCompany(id);
  };

  useClickOutside([companyDetailRef, sidebarRef], () => {
    onClose();
  });

  useEffect(() => {
    onClose();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const search = inputSearch?.trim() || '';
    clearTimeout(searchTimer?.current);

    searchTimer.current = setTimeout(() => {
      searchCompany(search);
    }, 400);
  }, [inputSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    clearTimeout(searchTimer?.current);
  }, []);

  const renderCompanies = (companies = []) => {
    return companies.map((company, index) => (
      <S.CompanyItem
        onClick={() => selectCompanyHandler(company.id)}
        key={index}
      >
        {company.name}
      </S.CompanyItem>
    ));
  };
  const closeHandler = (e) => {
    if (e.currentTarget) {
    }
  };

  if (!visible) {
    return null;
  }
  return (
    <S.Wrapper
      ref={companyDetailRef}
      onClick={closeHandler}
    >
      <S.Header>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <S.Title>{t('sidebar.Выберите компанию')}:</S.Title>
          {isMobileDevice && (
            <SubIcon
              onClick={() => {
                onClose();
              }}
            >
              {icons.cancel}
            </SubIcon>
          )}
        </div>
        <S.Input
          placeholder={t('sidebar.Поиск')}
          value={inputSearch}
          onChange={({ target }) => setSearch(target.value)}
          suffix={
            <SearchOutlined
              rotate="90"
              style={{ color: '#4F4F4F' }}
            />
          }
        />
        <S.CurrentCompany>{currentCompany ? currentCompany : t('sidebar.Компания не выбрана')}</S.CurrentCompany>
      </S.Header>
      <Scrollbars onUpdate={updateHandler}>
        <Spin
          spinning={isLoading}
          style={{ top: companies?.length === 0 ? 20 : 0 }}
        >
          {renderCompanies(companies)}
        </Spin>
        <div style={{ textAlign: 'center' }}>
          <Spin spinning={isLoadingNextPage} />
        </div>
      </Scrollbars>
    </S.Wrapper>
  );
}
