import types from '../actions/types';

const initialState = {
  users: {
    list: [],
    filters: null,
    loading: false,
  },
  companies: {
    list: [],
    filters: null,
    loading: false,
  },
  contracts: {
    list: [],
    filters: null,
  },
};
export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_LIST: {
      const {
        currentType,
        payload: { pagination, filters, list },
      } = action;
      return {
        ...state,
        [currentType]: {
          pagination,
          filters,
          list,
          loading: false,
        },
      };
    }
    case types.LIST_LOADING: {
      const { currentType } = action;

      return {
        ...state,
        [currentType]: {
          ...state[currentType],
          loading: true,
        },
      };
    }
    case types.GET_LIST_FAILED: {
      const { currentType } = action;

      return {
        ...state,
        [currentType]: {
          ...state[currentType],
          loading: false,
        },
      };
    }
    case types.CLEAR_LIST_FILTERS: {
      const { currentType } = action;
      return {
        ...state,
        [currentType]: {
          ...state[currentType],
          filters: null,
        },
      };
    }
    default:
      return state;
  }
}
