import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  .ant-btn-group {
    .ant-btn {
      border: 1px solid #939393;
      color: #939393;
      font-size: 16px;
      padding: 7px 15px;
    }
    .ant-btn-primary {
      font-weight: bold;
      color: #fff;
      border-color: #cd743d;
    }
  }
`;
