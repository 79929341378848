import { apiGet, apiPost } from '../../../services/helper';

export async function getBalanceShipments(filters) {
  try {
    const { data: response } = await apiGet('/v1/shipment/consignee', filters);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getCompaniesShipments() {
  try {
    const { data: response } = await apiGet('/v1/shipment/consignee/companies');
    return response;
  } catch (error) {
    throw error;
  }
}

export async function downloadDocument(document) {
  const { data } = await apiPost(`/v1/files/tessa`, document);
  return data;
}
