import { createSlice } from '@reduxjs/toolkit';
import { fetchDashboard } from './actions';

const dashboard = createSlice({
  name: 'dashboard',
  initialState: {},
  reducers: {},
  extraReducers: {
    [fetchDashboard.pending]: (state) => {
      state.loading = true;
    },
    [fetchDashboard.fulfilled]: (state, { payload }) => {
      const {
        charts: { filters, ...graphics },
        filters: curFilters,
        isIniting,
      } = payload;

      const { batches, contracts, materials } = filters;
      state.charts = graphics;
      state.filters = curFilters;
      state.loading = false;

      if (isIniting) {
        state.consignment = batches;
        state.contract = contracts;
        state.materials = materials;
      }
    },
    [fetchDashboard.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default dashboard.reducer;
