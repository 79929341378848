import types from '../actions/types';

const initialValues = {
  authErrorMessage: '',
  authenticated: true,
  balanceTypes: [],
  balancePermission: '/profile',
  companyList: [],
  isLoading: false,
};

/* SIDEBAR_OLD Тут нужны общие поля пользователя  */
export default function (state = initialValues, action) {
  switch (action.type) {
    case types.AUTH_USER:
      return {
        ...state,
        authErrorMessage: '',
        authenticated: true,
      };
    case types.UNAUTH_USER:
      return { ...initialValues, authenticated: false, init: {} };

    case types.AUTH_ERROR:
      return { ...state, authErrorMessage: action.payload };
    case types.RESTORE_USER:
      return { ...state, restoreUser: true };

    case 'INIT_APP': {
      const balanceTypes = action.balanceAccesses;
      const currentPathname = window.location.pathname;
      let balancePermissionIndex = balanceTypes.findIndex(({ id }) => {
        return id === currentPathname;
      });

      if (!~balancePermissionIndex) {
        balancePermissionIndex = 0;
      }

      const balancePermission = balanceTypes[balancePermissionIndex]
        ? balanceTypes[balancePermissionIndex].id
        : '/profile';

      return {
        ...state,
        init: {
          ...action.payload,
          permissions: [...action.payload?.permissions, 'public'],
        },
        balancePermission,
        balanceTypes,
        authenticated: true,
      };
    }

    case types.GET_COMPANY_LIST: {
      return {
        ...state,
        companyPagination: action.payload.pagination,
        companyList: action.payload.list,
      };
    }
    case types.GET_NEXT_PAGE: {
      return {
        ...state,
        companyPagination: action.payload.pagination,
        companyList: [...state.companyList, ...action.payload.list],
      };
    }
    case types.COMPANY_LOADER: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case types.COMPANY_NEXT_PAGE_LOADER_START: {
      return {
        ...state,
        isLoadingNextPage: true,
      };
    }
    case types.COMPANY_NEXT_PAGE_LOADER_END: {
      return {
        ...state,
        isLoadingNextPage: false,
      };
    }
    default:
      return state;
  }
}
