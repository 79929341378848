import React from 'react';

export const logo = (
  <svg
    width="35"
    height="48"
    viewBox="0 0 35 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.72892 18.732H10.3601V44.9021H2.72892V18.732ZM18.4639 18.732H29.6458L22.187 31.8283L29.6458 44.9092H18.4639V18.732ZM9.94438 4.53443H1.9462L1.99486 5.34133C2.14247 7.63757 2.90694 9.84926 4.20561 11.7373C5.50429 13.6253 7.28777 15.1179 9.36325 16.0536H0.083252V47.2989H13.0127V14.5876C9.02402 13.9934 5.76664 10.9136 4.81014 6.96077H11.2457L14.4155 3.74865L17.5853 6.96077H24.0319C23.0684 10.9164 19.8055 14.0018 15.8141 14.5904V47.2989H34.0612L25.2442 31.8213L34.2309 16.0493H19.4704C21.5453 15.1136 23.3284 13.6214 24.627 11.734C25.9256 9.84655 26.6904 7.63559 26.8388 5.33993L26.8833 4.53021H18.8879L14.4182 0L9.94438 4.53443Z"
      fill="url(#paint0_radial)"
    />
    <defs>
      <radialGradient
        id="paint0_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(34.6974 0.420502) scale(56.3789 76.9794)"
      >
        <stop stopColor="#FCC366" />
        <stop
          offset="0.07"
          stopColor="#F6BB63"
        />
        <stop
          offset="0.46"
          stopColor="#D69054"
        />
        <stop
          offset="0.66"
          stopColor="#CA7F4E"
        />
        <stop
          offset="0.81"
          stopColor="#D39156"
        />
        <stop
          offset="1"
          stopColor="#E2AE64"
        />
      </radialGradient>
    </defs>
  </svg>
);

export const logoText = (
  <svg
    width="160"
    height="14"
    viewBox="0 0 160 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M54.1201 2.87757H58.6212V13.0123H60.7706V2.87757H65.2717V0.933594H54.1201V2.87757Z"
      fill="white"
    />
    <path
      d="M93.916 13.0123H103.481V11.067H96.0639V7.90886H102.612V5.96489H96.0639V2.87757H103.481V0.933594H93.916V13.0123Z"
      fill="white"
    />
    <path
      d="M105.833 2.87757H110.333V13.0123H112.482V2.87757H116.983V0.933594H105.833V2.87757Z"
      fill="white"
    />
    <path
      d="M135.965 13.0123H145.53V11.067H138.113V7.90886H144.661V5.96489H138.113V2.87757H145.53V0.933594H135.965V13.0123Z"
      fill="white"
    />
    <path
      d="M147.884 2.87757H152.385V13.0123H154.533V2.87757H159.034V0.933594H147.884V2.87757Z"
      fill="white"
    />
    <path
      d="M126.146 7.03886L120.572 0.933594H119.184V13.0123H121.338V5.01857L126.146 9.96245L130.954 5.01857V13.0123H133.103V0.933594H131.722L126.146 7.03886Z"
      fill="white"
    />
    <path
      d="M11.8985 0.933594H9.44274L6.47667 5.9746H2.53856V0.933594H0.390625V13.0123H2.53856V7.91996H6.51827L9.44274 13.0123H11.9498L8.42077 6.87097L11.8985 0.933594Z"
      fill="white"
    />
    <path
      d="M49.3266 6.32952L45.4911 5.56081C44.092 5.29024 43.6885 4.97527 43.6885 4.2579C43.6885 3.43924 44.4844 2.62058 46.8889 2.62058C49.5387 2.62058 50.9434 4.14689 50.9434 4.14689C50.9434 4.14689 52.0694 3.07292 52.3301 2.81761C51.3719 1.71588 49.6774 0.673828 47.0164 0.673828C43.3473 0.673828 41.5946 2.43187 41.5946 4.41053C41.5946 6.15053 42.6554 7.18565 44.5482 7.53948L48.2824 8.25546C49.927 8.55656 50.2903 8.97422 50.2903 9.58891C50.2903 10.5102 49.4847 11.3289 46.7058 11.3289C45.0749 11.3301 43.4909 10.7833 42.2075 9.77622L41.0469 11.3539C42.4696 12.5153 44.153 13.2646 46.8625 13.2646C50.239 13.2646 52.3869 11.877 52.3869 9.52924C52.3869 7.94187 51.4828 6.74302 49.3363 6.32814"
      fill="white"
    />
    <path
      d="M74.4855 6.32952L70.65 5.56081C69.2509 5.29024 68.8473 4.97526 68.8473 4.25789C68.8473 3.43923 69.6433 2.62057 72.0464 2.62057C74.6977 2.62057 76.1038 4.14689 76.1038 4.14689C76.1038 4.14689 77.2284 3.07292 77.4904 2.81761C76.5336 1.71588 74.8378 0.673828 72.1781 0.673828C68.5076 0.673828 66.7562 2.43187 66.7562 4.41053C66.7562 6.15053 67.817 7.18564 69.7112 7.53947L73.4331 8.25684C75.0804 8.56349 75.4437 8.97976 75.4437 9.59029C75.4437 10.5116 74.6381 11.3303 71.8592 11.3303C70.2283 11.3314 68.6442 10.7847 67.3608 9.77761L66.2002 11.3553C67.6215 12.5167 69.3063 13.2659 72.0159 13.2659C75.3924 13.2659 77.5404 11.8784 77.5404 9.53062C77.5404 7.94325 76.6363 6.7444 74.4897 6.32952"
      fill="white"
    />
    <path
      d="M20.2354 7.17762H16.3999V2.87618H20.1342C22.2308 2.87618 23.1002 3.64489 23.1002 4.97556C23.1002 6.30624 22.2821 7.17762 20.2354 7.17762ZM25.2995 4.97695C25.2995 2.4169 23.3581 0.933594 20.4462 0.933594H14.252V13.0123H16.3999V9.12021H20.1591L22.6912 13.0179H25.1968L22.3833 8.71643C24.1236 8.20442 25.2953 6.92509 25.2953 4.98111"
      fill="white"
    />
    <path
      d="M85.3728 10.3016L80.9744 0.935547H78.5713L84.4008 13.271H86.2423L92.0732 0.935547H89.7713L85.3728 10.3016Z"
      fill="white"
    />
    <path
      d="M33.3584 3.64321L35.8017 8.88957H30.9123L33.3584 3.64321ZM37.7569 13.0092H40.0587L34.2347 0.673828H32.5361L26.6553 13.0092H29.0126L29.9735 10.8335H36.7488L37.7569 13.0092Z"
      fill="white"
    />
  </svg>
);
