import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { UserBlock } from './UserBlock';
import { UserMobileBlock } from './MobileSidebar/UserMobileBlock';
import { UserDetail } from './MobileSidebar/UserDetail';
import * as S from './styled';
import { CompanyBlock } from './CompanyBlock';
import { useIsAdminSection } from '../../hooks/useIsAdminSection';

export function Header({ user, company, count, userMenu, visible, onShow, onClose, onCloseAll, singleCompany }) {
  const isMobileDevice = useMediaQuery({ query: '(max-width: 850px)' });
  const userNameMobile = user?.name || user?.surname ? `${user?.name || ''} ${user?.surname || ''}` : `${user?.email}`;
  const userName = {
    name: user?.name || null,
    surname: user?.surname || null,
    email: user?.email,
  };

  const isAdminSection = useIsAdminSection();

  return (
    <S.HeaderBlock>
      {isMobileDevice ? (
        <React.Fragment>
          <UserMobileBlock
            userName={userName}
            onShow={onShow}
          />
          <UserDetail
            name={userNameMobile}
            userMenu={userMenu}
            visible={visible}
            onClose={onClose}
            onCloseAll={onCloseAll}
            isAdmin={isAdminSection}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <UserBlock
            userName={userName}
            count={count}
            isAdmin={isAdminSection}
          />
          {!isAdminSection && company && (
            <CompanyBlock
              company={company.name}
              singleCompany={singleCompany}
              onShowDetail={onShow}
            />
          )}
        </React.Fragment>
      )}
    </S.HeaderBlock>
  );
}
