import React from 'react';
import { icons } from 'features/sidebar/Icons';
import * as S from './styled';

export function CompanyBlock({ company, onShowDetail, singleCompany }) {
  return (
    <S.CompanyWrapper
      onClick={onShowDetail}
      tabIndex="0"
    >
      <S.CompanyBlock>
        <S.Company>
          <S.NavIcon>{icons.company}</S.NavIcon>
          <S.CompanyName>{company}</S.CompanyName>
        </S.Company>
        {!singleCompany && <S.IconArrow>{icons.nextRight}</S.IconArrow>}
      </S.CompanyBlock>
    </S.CompanyWrapper>
  );
}
