import { useGetOAuthTokenMutation } from 'services/auth/authApi';

export function useOAuthToken() {
  const [getOAuthToken, { isError, isLoading, isSuccess, data }] = useGetOAuthTokenMutation();

  return {
    getOAuthToken,
    isError,
    isLoading,
    isSuccess,
    data,
  };
}
