import { useRestorePasswordMutation } from 'services/auth/authApi';

export function useRestorePassword() {
  const [restorePassword, { isError, isLoading, isSuccess, error, originalArgs }] = useRestorePasswordMutation();

  return {
    restorePassword,
    isError,
    isLoading,
    isSuccess,
    error,
    originalArgs,
  };
}
