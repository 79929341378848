import { z } from 'zod';

export const ErrorEntity = z.object({
  success: z.literal(false),
  code: z.number(),
  message: z.string(),
  data: z.unknown(),
});

export type ErrorEntity = z.infer<typeof ErrorEntity>;
