import types from '../actions/types';

const initialState = {
  users: {
    edit: {},
    companies: { list: [] },
  },
  companies: {
    edit: {},
    users: [],
  },
  contracts: {
    edit: {},
  },
  tabLoader: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_TAB_CONTENT: {
      return {
        ...state,
        [action.currentType]: {
          ...state[action.currentType],
          [action.tab || 'edit']: action.payload,
        },
      };
    }
    case types.VALID_FORM: {
      const fields = [...state[action.currentType].edit.fields];
      fields.map((field) => {
        field.validateText = null;
        return field;
      });

      return {
        ...state,
        [action.currentType]: {
          edit: {
            ...state[action.currentType].edit,
            fields,
            passwordInvalidText: null,
            successMsg: action.payload,
          },
        },
      };
    }
    case types.INVALID_FORM: {
      const fields = [...state[action.currentType].edit.fields];
      if (action.payload) {
        fields.map((field) => {
          field.validateText = action.payload[field.id];
          return field;
        });
      }
      return {
        ...state,
        [action.currentType]: {
          edit: {
            ...state[action.currentType].edit,
            fields,
            passwordInvalidText: action.payload && action.payload['password'] ? action.payload['password'] : null,
          },
        },
      };
    }
    case types.CLEAR_VALID_FORM: {
      return {
        ...state,
        [action.currentType]: {
          edit: {
            ...state[action.currentType].edit,
            successMsg: null,
          },
        },
      };
    }
    case types.REMOVE_ELEMENT: {
      return { ...state, userDeleted: true };
    }
    case types.HIDE_USER_DELETED_NOTIFICATION: {
      return { ...state, userDeleted: false };
    }
    case types.LOADING_TAB_CONTENT: {
      return {
        ...state,
        tabLoader: true,
      };
    }
    case types.HIDE_TAB_CONTENT_LOADER: {
      return {
        ...state,
        tabLoader: false,
      };
    }

    default:
      return state;
  }
}
