import { mainApi } from 'services/http/mainApi';
import { createQueryFn } from 'services/http/queryFnBuilder';
import { SurveyEntity } from 'models/api/satisfaction/survey';
import { SATISFACTION_SURVEY_API, SURVEY_CLOSE, SURVEY_LATER, SURVEY_REACTION } from './consts';
import { SurveyReactionParamsSchema } from 'models/params/survey/reaction';
import { SurveyCloseParamsSchema } from 'models/params/survey/close';

export const surveyApi = mainApi.injectEndpoints({
  endpoints: (build) => ({
    getSurvey: build.query<SurveyEntity, void>({
      queryFn: createQueryFn(
        () => ({
          url: SATISFACTION_SURVEY_API,
          method: 'GET',
        }),
        SurveyEntity
      ),
    }),
    sendReaction: build.mutation<void, SurveyReactionParamsSchema>({
      queryFn: createQueryFn(({ surveyId, starId, comment, tags }) => ({
        url: `${SATISFACTION_SURVEY_API}/${surveyId}/${SURVEY_REACTION}`,
        method: 'POST',
        data: {
          starId,
          comment,
          tags,
        },
      })),
    }),
    closeSurvey: build.mutation<void, SurveyCloseParamsSchema>({
      queryFn: createQueryFn(({ surveyId, starId, comment, tags }) => ({
        url: `${SATISFACTION_SURVEY_API}/${surveyId}/${SURVEY_CLOSE}`,
        method: 'POST',
        data: {
          starId,
          comment,
          tags,
        },
      })),
    }),
    remindLater: build.query<void, number>({
      queryFn: createQueryFn((surveyId) => ({
        url: `${SATISFACTION_SURVEY_API}/${surveyId}/${SURVEY_LATER}`,
        method: 'GET',
        data: {
          surveyId,
        },
      })),
    }),
  }),
  overrideExisting: false,
});

export const { useGetSurveyQuery, useSendReactionMutation, useCloseSurveyMutation, useLazyRemindLaterQuery } =
  surveyApi;
