import { useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useClickOutside = (refs, onOutside) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      const isOutside = refs.every((ref) => ref.current && !ref.current.contains(event.target));

      if (isOutside) {
        onOutside();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs]); // eslint-disable-line react-hooks/exhaustive-deps
};
