import { apiPost } from 'services/helper';

export const restorePassword = async (response) => {
  try {
    const { data } = await apiPost(`/v1/password/reset`, response, { 'X-localization': response.localization });

    return data;
  } catch (error) {
    return error.response.data;
  }
};
