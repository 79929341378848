import { z } from 'zod';
import { FilterEntity } from 'models/api/wts/common/filter';
import { DictionaryEntity } from './dictionary';

export const InitSellFeaturesEntity = z
  .object({
    filter: z.object({
      product: z.object({
        statuses: z.array(FilterEntity),
      }),
      sale: z.object({
        statuses: z.array(FilterEntity),
      }),
    }),
    dictionary: DictionaryEntity,
  })
  .or(z.null());

export type InitSellFeaturesEntity = z.infer<typeof InitSellFeaturesEntity>;
