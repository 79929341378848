import { apiGet } from '../../../services/helper';

class RemainsServiceFactory {
  async getRemains(search = '') {
    const { data } = await apiGet(`/v1/balance/online${search}`);
    return data;
  }
}

export const RemainsService = new RemainsServiceFactory();
