import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Row, Col } from 'antd';
import moment from 'moment';
import './user.scss';

function User({ name, surname, roles, email, id, active, activeAt }) {
  const getShortName = (name, surname) => {
    const splitName = `${surname ? surname.slice(0, 1) : ''}${name ? name.slice(0, 1) : ''}`;
    return splitName.toUpperCase();
  };
  return (
    <div
      className="admin-panel-user"
      style={{ opacity: !active ? '0.6' : '1' }}
    >
      <header
        className="admin-panel-user__header"
        style={{ padding: '5px 10px' }}
      >
        <Link to={`user/${id}`}>
          <Row
            type="flex"
            gutter={10}
            style={{ marginBottom: 10 }}
          >
            <Col>
              <Avatar
                style={{
                  backgroundColor: '#f19d69',
                }}
                size="large"
              >
                {getShortName(name, surname)}
              </Avatar>
            </Col>
            <Col>
              <div
                style={{ marginBottom: 0 }}
                className="admin-panel-user__name"
              >
                {surname}&nbsp;{name}
              </div>
              <div
                style={{ marginBottom: 0 }}
                className="admin-panel-user__group"
              >
                {roles.map(({ name }) => name).join(', ')}
              </div>
            </Col>
          </Row>
          <div
            className="admin-panel-user__name"
            style={{ marginBottom: 0 }}
          >
            <span>{email}</span>
          </div>
          <div className="admin-panel-user__group">
            {activeAt ? (
              <React.Fragment>
                Последняя активность: <span>{moment(activeAt).format('DD.MM.YY h:mm')}</span>
              </React.Fragment>
            ) : (
              <React.Fragment>Пользователь не заходил</React.Fragment>
            )}
          </div>
        </Link>
      </header>
    </div>
  );
}

export default User;
