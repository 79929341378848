import { z } from 'zod';
import { FilterEntity } from 'models/api/wts/common/filter';

export const InitBuyFeaturesEntity = z
  .object({
    filter: z.object({
      priceFixation: z.object({
        statuses: z.array(FilterEntity),
      }),
      priceFixationReserve: z.object({
        statuses: z.array(FilterEntity),
      }),
    }),
  })
  .or(z.null());

export type InitBuyFeaturesEntity = z.infer<typeof InitBuyFeaturesEntity>;
