import React from 'react';
import { message } from 'antd';
import { Trans } from 'react-i18next';
import { refreshToken } from 'actions/auth';

export const checkError = (error, dispatch, section, errorType) => {
  try {
    switch (error.response.status) {
      case 401: {
        dispatch(refreshToken());
        return { type: null };
      }
      case 403: {
        message.error(errorText.errors[403], 10);
        return { type: null };
      }
      default: {
        alertGenerator(errorText[section][errorType]);
        return { type: null };
      }
    }
  } catch (tryError) {
    errorText[section] ? alertGenerator(errorText[section][errorType]) : alertGenerator();
    return { type: null };
  }
};

const alertGenerator = (text) => {
  if (!text) {
    message.error(<span>{nextAction}</span>, alertDuration);
  } else {
    message.error(
      <span>
        {text}, {nextAction}
      </span>,
      alertDuration
    );
  }
};

const textWithTrans = (text) => {
  return <Trans i18nKey={`error.${text}`} />;
};

const errorText = {
  support: {
    list: textWithTrans('Невозможно получить тикеты'),
    create: textWithTrans('Невозможно создать тикет'),
    details: textWithTrans('Невозможно получить тикет'),
    comments: textWithTrans('Невозможно получить комментарии'),
    status: textWithTrans('Невозможно сменить статус тикета'),
  },
  supply: {
    list: textWithTrans('Невозможно получить список поставок'),
    details: textWithTrans('Невозможно получить содержимое поставки'),
  },
  shipment: {
    list: textWithTrans('Невозможно получить список отгрузки'),
    details: textWithTrans('Невозможно получить содержимое отгрузки'),
  },
  reports: {
    list: textWithTrans('Невозможно получить список отчетов'),
    details: textWithTrans('Невозможно получить содержимое отгрузки'),
    contract: textWithTrans('Невозможно получить договор'),
  },
  release: {
    list: textWithTrans('Невозможно получить список выпусков'),
    details: textWithTrans('Невозможно получить содержимое выпуска'),
  },
  profile: {
    details: textWithTrans('Невозможно получить профиль'),
    subscrube: textWithTrans('Невозможно получить подписаться'),
  },
  notifications: {
    list: textWithTrans('Невозможно получить список уведомлений'),
  },
  managers: {
    list: textWithTrans('Невозможно получить список менеджеров'),
  },
  layout: {
    basic: textWithTrans('Невозможно загрузить страницу'),
  },
  finances: {
    add: textWithTrans('Невозможно добавить акт'),
    list: textWithTrans('Невозможно получить список актов'),
  },
  filters: {
    contracts: textWithTrans('Невозможно получить список договоров'),
    subcontracts: textWithTrans('Невозможно получить список доп.соглашений'),
    emails: textWithTrans('Невозможно получить список email'),
  },
  documents: {
    list: textWithTrans('Невозможно получить список документов'),
    add: textWithTrans('Невозможно добавить документ'),
    remove: textWithTrans('Невозможно удалить документ'),
  },
  dkz: {
    details: textWithTrans('Невозможно получить ДКЗ'),
  },
  company: {
    list: textWithTrans('Невозможно получить список компаний'),
    change: textWithTrans('Невозможно изменить компанию'),
  },
  errors: {
    403: textWithTrans('Нет доступа к разделу'),
    401: textWithTrans('Ошибка авторизации'),
  },
  modals: {
    nzp: textWithTrans('Невозможно отправить заявку на НЗП'),
    balance: textWithTrans('Невозможно отправить заявку на баланс'),
    files: textWithTrans('Невозможно сформировать архив файлов'),
  },
  files: {
    download: textWithTrans('Невозможно скачать файл'),
  },
  auth: {
    restore: textWithTrans('Пользователь не найден'),
  },
  proposal: {
    metal: textWithTrans('Невозможно получить список металов'),
    mmr: textWithTrans('Невозможно получить список видов сырья'),
  },
  questionary: {
    upload: textWithTrans('Формат файла должен быть одним из следующих: doc, docx, pdf, xls, xlsx, jpg, jpeg, png. '),
    filling: textWithTrans('Страница не сохранилась'),
    getQuestinairInfo: textWithTrans('Невозможно загрузить страницу'),
  },
};

const alertDuration = 5;
const nextAction = textWithTrans('Попробуйте позже');
