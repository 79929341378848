export const isPWA = () => {
  const pwaDisplayModes = ['fullscreen', 'standalone', 'minimal-ui', 'window-controls-overlay'];
  const hasPwaDisplayModes = pwaDisplayModes.some(
    (displayMode) => window.matchMedia(`(display-mode: ${displayMode})`).matches
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((window.navigator as any).standalone || hasPwaDisplayModes) {
    return true;
  }
  return false;
};
