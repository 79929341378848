import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './styled';

type OfflineAlertProps = typeof S.Alert & {
  isVisible: boolean;
};

export function OfflineAlert({ isVisible, ...props }: OfflineAlertProps) {
  const { t } = useTranslation();

  if (!isVisible) {
    return null;
  }
  return (
    <S.Alert
      message={t('error.Нет соединения с интернетом')}
      {...props}
    />
  );
}
