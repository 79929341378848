import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addLoadingMatchers } from 'store/utils/addLoadingMatchers';
import { TicketEntity } from 'models/api/support/ticket';
import { CREATE_TICKET, GET_TICKETS_LIST, getTicketsList } from '../actions/ticketsListActions';
import { RootState } from 'store';
import { MessageEntity } from 'models/api/support/message';
import { sortByDate } from 'utils/sortByDate';
import { ReadMessagesEventBody } from 'models/api/support/supportEvents';
import { isUndefined } from 'lodash-es';

type TicketsListState = {
  pagination: { lastMessageId: number | null } | null;
  isLoading: boolean;
  isTicketCreating: boolean;
  isInit: boolean;
  search: string;
};

const INITIAL_STATE: TicketsListState = {
  isInit: false,
  pagination: null,
  isLoading: false,
  isTicketCreating: false,
  search: '',
};

const ticketsListAdapter = createEntityAdapter<TicketEntity>({
  sortComparer: (a, b) => sortByDate('desc')(a.lastMessage.createdAt, b.lastMessage.createdAt),
});
export const ticketsListSelectors = ticketsListAdapter.getSelectors((state: RootState) => state.ticketsList);

const ticketsListSlice = createSlice({
  name: 'ticketsList',
  initialState: ticketsListAdapter.getInitialState<TicketsListState>(INITIAL_STATE),
  reducers: {
    setSearch: (state, { payload }: PayloadAction<string>) => {
      state.search = payload;
    },
    initTicketsList: (state) => {
      state.isInit = true;
    },
    resetTicketsList: (state) => {
      ticketsListAdapter.removeAll(state);
      state.pagination = INITIAL_STATE.pagination;
      state.isLoading = INITIAL_STATE.isLoading;
      state.isInit = INITIAL_STATE.isInit;
      state.search = INITIAL_STATE.search;
    },
    addTicket: (state, { payload }: PayloadAction<TicketEntity>) => {
      if (!state.isInit || state.search) {
        return;
      }

      ticketsListAdapter.addOne(state, payload);
    },
    updateTicket: (state, { payload }: PayloadAction<TicketEntity>) => {
      if (!state.isInit) {
        return;
      }

      const patch: Partial<TicketEntity> = { ...payload };
      delete patch.lastMessage;
      ticketsListAdapter.updateOne(state, { id: payload.id, changes: patch });
    },
    updateTicketLastMessage: (state, { payload }: PayloadAction<MessageEntity>) => {
      if (!state.isInit) {
        return;
      }

      ticketsListAdapter.updateOne(state, { id: payload.ticketId, changes: { lastMessage: payload } });
    },
    updateLastMessageReadAt: (state, { payload }: PayloadAction<ReadMessagesEventBody>) => {
      if (!state.isInit) {
        return;
      }

      const lastMessage = state.entities[payload.ticketId]?.lastMessage;
      if (!lastMessage || !payload.readMessagesIds.includes(lastMessage.id)) {
        return;
      }

      ticketsListAdapter.updateOne(state, {
        id: payload.ticketId,
        changes: { lastMessage: { ...lastMessage, readAt: payload.readAt } },
      });
    },
    readTicketByClient: (state, { payload }: PayloadAction<ReadMessagesEventBody>) => {
      if (!state.isInit) {
        return;
      }

      const lastMessage = state.entities[payload.ticketId]?.lastMessage;
      if (!lastMessage || !payload.readMessagesIds.includes(lastMessage.id)) {
        return;
      }

      ticketsListAdapter.updateOne(state, {
        id: payload.ticketId,
        changes: {
          lastMessage: {
            ...lastMessage,
            unreadMessages: {
              ...lastMessage.unreadMessages,
              byClient: false,
            },
          },
        },
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTicketsList.fulfilled, (state, { payload, meta }) => {
      if (!state.isInit) {
        return;
      }

      if (!isUndefined(meta.arg.lastMessageId)) {
        ticketsListAdapter.addMany(state, payload.list);
      } else {
        ticketsListAdapter.setAll(state, payload.list);
      }

      state.pagination = {
        lastMessageId: payload.lastMessageId,
      };
    });

    addLoadingMatchers(builder, GET_TICKETS_LIST, 'isLoading');
    addLoadingMatchers(builder, CREATE_TICKET, 'isTicketCreating');
  },
});

export const { name, actions, reducer } = ticketsListSlice;
