import { z } from 'zod';
import { UserEntity } from '../common/user';
import { CursorPaginationEntity } from 'models/api/common/list';
import { FileEntity } from 'models/api/support/file';

export const SystemMessageEntity = z.object({
  id: z.number(),
  ticketId: z.number(),
  message: z.string(),
  type: z.literal('system'),
  createdAt: z.string(),
  readAt: z.string().or(z.null()),
  user: z.null(),
  unreadMessages: z.object({
    byClient: z.boolean(),
    byManager: z.boolean(),
  }),
  files: z.array(FileEntity),
});
export type SystemMessageEntity = z.infer<typeof SystemMessageEntity>;

export const CommonMessageEntity = z.object({
  id: z.number(),
  ticketId: z.number(),
  message: z.string().or(z.null()),
  type: z.literal('common'),
  createdAt: z.string(),
  readAt: z.string().or(z.null()),
  user: UserEntity,
  unreadMessages: z.object({
    byClient: z.boolean(),
    byManager: z.boolean(),
  }),
  files: z.array(FileEntity),
});
export type CommonMessageEntity = z.infer<typeof CommonMessageEntity>;

export const MessageEntity = SystemMessageEntity.or(CommonMessageEntity);
export type MessageEntity = z.infer<typeof MessageEntity>;

export const MessagesListEntity = z.object({
  list: z.array(MessageEntity),
  leftMessages: z.number(),
  pagination: CursorPaginationEntity,
});
export type MessagesListEntity = z.infer<typeof MessagesListEntity>;
