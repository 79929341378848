import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchBasicQuestionnaireInfo, changeQuestionnaireStatus, sendForm, TYPE_PREFIX } from './actions';
import { FillingState, QuestionnaireState } from './slice.types';
import { FillingEntity } from './service.types';
import { isEmptyArray } from 'utils/isEmptyArray';
import { isInvalidQuestionnaireStep, setStepParamInUrl } from './utils';
import { SaveQuestionnaireRequestPayload } from './actions.types';
import _set from 'lodash-es/set';
import _unset from 'lodash-es/unset';
import { addLoadingMatchers } from 'store/utils/addLoadingMatchers';

const initialState: QuestionnaireState = {
  loading: false,
  body: {
    basic: {},
    activity: {},
    minedActivity: {},
    confidentiality: {},
    physicalMetal: {},
    supplyPolicy: {},
    financesTerrorism: {},
    corruptPractices: {},
    transactionMonitoring: {},
    beneficiaries: {
      entity: [[], [], []],
      individual: [[], [], []],
    },
    managment: {
      entity: [[], [], []],
      individual: [[], [], []],
    },
    financeAndEmployees: {
      finances: [['Уставный капитал'], ['Общий акционерный капитал'], ['Общий баланс'], ['Продажи'], ['Чистый доход']],
      employeesCompany: null,
    },
    industrialActivity: {},
    otherFinanceInformation: {},
    legislativeFramework: {},
    physicalOriginMetals: {},
    processingPlant: {},
    materials: {},
    transportation: {},
    metalSupplyChain: {},
    podft: {},
    authPerson: [[]],
    authorizedPerson: {
      authorizedPersons: [[]],
    },
    productionCapabilities: {},
    diligence: {},
    legalSystem: {},
    taxSystem: {},
    cooperation: {},
    pollution: {},
  },
  stepNames: {
    'Вторичное сырье': [
      'Общая информация',
      'Деятельность',
      'Бенефициарные владельцы',
      'Структура управления',
      'Финансовая информация и численность работников',
      'Происхождение физического металла',
      'Производственные возможности',
      'Политики цепочки ответственных поставок',
      'ПОДФТ (продиводействие отмыванию доховод и финансированию терроризма)',
      'Взяточничество',
      'Конфидециальность и защита информации',
      'Соблюдение требований надлежащей осмотрительности для поставщиков дран. металлов',
      'Мониторинг транзакций, полученных от физ. лиц',
      'Промышленная деятельность',
      'Уполномеченные лица с правом подписи',
      'Уполномоченное лицо',
    ],
    'Минеральное сырье': [
      'Общая информация',
      'Деятельность',
      'Бенефициарные владельцы',
      'Структура управления',
      'Финансовая информация и численность работников',
      'Правовая система',
      'Прочая финансовая информация',
      'Налоговая система',
      'Законодательная база',
      'Физическое происхождение металлов, мощности и практики бурения',
      'Перерабатывающий завод (ЗИФ)',
      'Материалы',
      'Транспортировка',
      'Взаимодействие',
      'Ответственная цепочка поставок драгоценных металлов',
      'ПОДФТ (продиводействие отмыванию доховод и финансированию терроризма)',
      'Взяточничество',
      'Загрязнение',
      'Уполномеченные лица с правом подписи',
      'Уполномоченное лицо',
    ],
  },
  indexPage: {
    title: {
      client: {
        filled: 'Анкета поставщика',
        review: 'Анкета проверяется',
        checked: 'Анкета заполнена и проверена',
      },
      manager: {
        filled: 'Анкета поставщика',
        review: 'Анкета поставщика',
        checked: 'Анкета поставщика',
      },
    },
    desc: {
      client: {
        filled:
          'После завершения анкеты потребуются подписи уполномоченных лиц. Сохранение данных происходит автоматически.',
        review:
          'Анкета заполнена и отправлена на проверку специалисту. После проверки потребуется подпись уполномоченных лиц.',
        checked: 'Для завершения, скачайте и загрузите анкету с подписью уполномоченных лиц.',
      },
      manager: {
        filled: 'Анкета еще не заполнена.',
        review: 'Анкету необходимо проверить. После проверки сформируется файл без возможности изменения.',
        checked: 'Файл направлен для подписи.',
      },
    },
  },
  questionaryTypes: {
    'Вторичное сырье': 'recyclable',
    'Минеральное сырье': 'mineral',
  },
};

const questionnaire = createSlice({
  name: 'questionnaire',
  initialState,
  reducers: {
    setQuestionnaireStep(state, { payload }) {
      const stepParam = Number(payload.step);

      if (!stepParam) {
        setStepParamInUrl(1);
        state.currentStep = 0;
      } else {
        state.currentStep = stepParam - 1;
      }
    },
    nextStepQuestionnaire(state) {
      const questionnaireType = state.body?.basic?.questionnairType || 'Вторичное сырье';

      const lastStep = state.stepNames[questionnaireType].length - 1;
      const nextStep = (state.currentStep || 0) + 1;

      state.currentStep = nextStep > lastStep ? lastStep : nextStep;
      setStepParamInUrl(state.currentStep + 1);
    },
    prevStepQuestionnaire(state) {
      const prevStep = (state.currentStep || 0) - 1;

      state.currentStep = prevStep < 0 ? 0 : prevStep;
      setStepParamInUrl(state.currentStep + 1);
    },
    saveQuestionnaireRequest(state, { payload }: PayloadAction<SaveQuestionnaireRequestPayload>) {
      const { step, id, value } = payload;

      value ? _set(state.body, [step, id], value) : _unset(state.body, [step, id]);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBasicQuestionnaireInfo.fulfilled, (state, { payload }) => {
      const {
        description,
        id,
        progress,
        preview,
        name,
        documents,
        upload,
        status,
        filling: body,
        date_end_correct: dateEnd,
      } = payload;

      const newBody = isEmptyArray(body)
        ? {}
        : Object.entries(body).reduce((acc, [key, value]) => {
            acc[key as keyof FillingEntity] = isEmptyArray(value) ? {} : value;
            return acc;
          }, {} as FillingState);

      if (isInvalidQuestionnaireStep(state.stepNames, newBody?.basic?.questionnairType)) {
        setStepParamInUrl(1);
        state.currentStep = 0;
      }

      state.basic = {
        description,
        id,
        progress,
        preview,
        name,
        upload,
        status,
        dateEnd,
      };

      state.body = { ...state.body, ...newBody };
      state.documents = documents;
    });
    builder.addCase(changeQuestionnaireStatus.fulfilled, (state, { payload }) => {
      state.basic = { ...state.basic, status: payload };
    });
    builder.addCase(sendForm.fulfilled, (state, { payload }) => {
      state.basic = { ...state.basic, progress: payload.progress };
    });
    addLoadingMatchers(builder, TYPE_PREFIX);
  },
});

export const { actions, name } = questionnaire;

export default questionnaire.reducer;
