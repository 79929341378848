import { useSupportWsConnection } from 'ducks/support/wsConnection/hooks/useSupportWsConnection';
import { useMount, usePrevious, useUpdateEffect } from 'react-use';
import { State as CentrifugeStatusEnum, State as CentrifugeStatus } from 'centrifuge/build/types';
import { useRef } from 'react';

type Timer = null | number;

const isReconnection = (prevStatus: undefined | CentrifugeStatus, status: CentrifugeStatusEnum) => {
  return prevStatus !== CentrifugeStatus.Connected && status === CentrifugeStatus.Connected;
};

const isRefreshNeeded = (timer: Timer) => {
  return !timer || Date.now() - timer > 1000;
};

export function useSupportWsReconnection(refresh: () => void) {
  const { status } = useSupportWsConnection();
  const prevStatus = usePrevious(status);
  const firstRefreshTimer = useRef<Timer>(null);

  useMount(() => {
    if (status !== CentrifugeStatus.Connected) {
      firstRefreshTimer.current = Date.now();
    }
  });

  useUpdateEffect(() => {
    if (isReconnection(prevStatus, status) && isRefreshNeeded(firstRefreshTimer.current)) {
      refresh();
      firstRefreshTimer.current = null;
    }
  }, [prevStatus, refresh, status]);
}
