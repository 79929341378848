import { useGetSurveyQuery } from 'services/satisfaction/survey';

export function useSurvey(skip?: boolean) {
  const { currentData, isFetching, isError } = useGetSurveyQuery(undefined, { skip: Boolean(skip) });

  return {
    data: currentData,
    isLoading: isFetching,
    isError,
  };
}
