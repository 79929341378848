import { useGetCountersQuery } from 'services/support/countersApi';

export function useCounters() {
  const { data, refetch } = useGetCountersQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  return {
    counters: data,
    refetchCounters: refetch,
  };
}
