import { z } from 'zod';
import { createError, ErrorSchema, isError } from './error';
import i18n from 'i18next';

export const HttpErrorSchema = ErrorSchema.merge(
  z.object({
    code: z.string().or(z.number()).optional(),
    data: z.unknown().optional(),
    hasOriginalMessage: z.boolean(),
  })
);
export type HttpError = z.infer<typeof HttpErrorSchema>;
export const isHttpError = isError<HttpError>(HttpErrorSchema);

export function createHttpError(
  code?: string | number,
  message?: string,
  data?: unknown,
  stack?: string,
  hasOriginalMessage?: boolean
): HttpError {
  return {
    ...createError(message || i18n.t('error.Ошибка получения данных'), stack),
    code,
    data,
    hasOriginalMessage: !!hasOriginalMessage,
  };
}
