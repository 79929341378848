import styled from 'styled-components';
import { Form as InnerForm } from 'antd';

export const Form = styled(InnerForm)`
  & .ant-form-item {
    margin-bottom: ${({ theme }) => theme.space['12']};
  }

  & .ant-form-item-label {
    font-size: ${({ theme }) => theme.fontSizes['14']};
    line-height: ${({ theme }) => theme.lineHeights['16']};
  }

  & .ant-form-item-label > label {
    color: ${({ theme }) => theme.colors['grey-2']};
  }
`;
