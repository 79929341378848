import React from 'react';

export const NewSectionRemains = (
  <svg
    width="333"
    height="213"
    viewBox="0 0 333 213"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M330.665 1.0066H104.335C103.69 1.0066 103.167 1.45727 103.167 2.0132V141.987C103.167 142.543 103.69 142.993 104.335 142.993H330.665C331.31 142.993 331.833 142.543 331.833 141.987V2.01321C331.833 1.45727 331.31 1.0066 330.665 1.0066ZM104.335 0C103.045 0 102 0.901339 102 2.0132V141.987C102 143.099 103.045 144 104.335 144H330.665C331.955 144 333 143.099 333 141.987V2.01321C333 0.901343 331.955 0 330.665 0H104.335Z"
      fill="#F0F4F7"
    />
    <path
      d="M107 5.56996C107 5.25518 107.263 5 107.588 5H329.412C329.737 5 330 5.25518 330 5.56996V20.43C330 20.7448 329.737 21 329.412 21H107.588C107.263 21 107 20.7448 107 20.43V5.56996Z"
      fill="#F0F4F7"
    />
    <path
      d="M293 12.5C293 14.433 291.433 16 289.5 16C287.567 16 286 14.433 286 12.5C286 10.567 287.567 9 289.5 9C291.433 9 293 10.567 293 12.5Z"
      fill="white"
    />
    <path
      d="M306 12.5C306 14.433 304.433 16 302.5 16C300.567 16 299 14.433 299 12.5C299 10.567 300.567 9 302.5 9C304.433 9 306 10.567 306 12.5Z"
      fill="white"
    />
    <path
      d="M318 12.5C318 14.433 316.433 16 314.5 16C312.567 16 311 14.433 311 12.5C311 10.567 312.567 9 314.5 9C316.433 9 318 10.567 318 12.5Z"
      fill="white"
    />
    <rect
      x="94"
      y="132"
      width="43"
      height="69"
      rx="4"
      fill="#E5EAEE"
    />
    <rect
      x="110.293"
      y="131.388"
      width="26.984"
      height="6.96362"
      fill="#C9D0D8"
    />
    <path
      d="M19.7755 135.388C19.7755 133.179 21.5663 131.388 23.7755 131.388H116.396V197.024C116.396 199.233 114.605 201.024 112.396 201.024H23.7755C21.5663 201.024 19.7755 199.233 19.7755 197.024V135.388Z"
      fill="#F0F4F7"
    />
    <path
      d="M19.7755 131.388H116.396V138.351H19.7755V131.388Z"
      fill="#E5EAEE"
    />
    <rect
      x="24.1315"
      y="184.487"
      width="13.0568"
      height="11.3159"
      rx="2"
      fill="white"
    />
    <path
      d="M56.3413 151.409C56.3413 149.967 57.5105 148.798 58.9527 148.798H78.1026C79.5448 148.798 80.714 149.967 80.714 151.409C80.714 152.851 79.5448 154.021 78.1026 154.021H58.9527C57.5105 154.021 56.3413 152.851 56.3413 151.409Z"
      fill="#C9D0D8"
    />
    <path
      d="M106.699 117.461H140.251C142.46 117.461 144.251 119.252 144.251 121.461V129.13C144.251 131.339 142.46 133.13 140.251 133.13H106.699V117.461Z"
      fill="#E5EAEE"
    />
    <path
      d="M15.424 121.461C15.424 119.252 17.2148 117.461 19.424 117.461H116.749C118.958 117.461 120.749 119.252 120.749 121.461V129.13C120.749 131.339 118.958 133.13 116.749 133.13H19.424C17.2148 133.13 15.424 131.339 15.424 129.13V121.461Z"
      fill="#F0F4F7"
    />
    <rect
      x="109.27"
      y="75.667"
      width="16.8571"
      height="39.3333"
      rx="3"
      fill="#D8DDE3"
    />
    <path
      d="M113.016 75.667H126.127V80.3495H113.016V75.667Z"
      fill="#C9D0D8"
    />
    <rect
      x="70.873"
      y="75.667"
      width="43.0794"
      height="39.3333"
      rx="3"
      fill="#E5EAEE"
    />
    <path
      d="M70.873 75.667H113.952V80.3495H70.873V75.667Z"
      fill="#D7DEE7"
    />
    <path
      d="M98.5923 102.808C98.5923 101.184 100.127 99.8682 102.021 99.8682H106.09C107.984 99.8682 109.519 101.184 109.519 102.808V106.624C109.519 108.247 107.984 109.563 106.09 109.563H102.021C100.127 109.563 98.5923 108.247 98.5923 106.624V102.808Z"
      fill="white"
    />
    <rect
      x="98.9683"
      y="69.1113"
      width="29.0317"
      height="9.36508"
      rx="3"
      fill="#D8DDE3"
    />
    <rect
      x="69"
      y="69.1113"
      width="46.8254"
      height="9.36508"
      rx="3"
      fill="#E5EAEE"
    />
    <path
      d="M159.153 179.507C159.506 178.197 160.693 177.287 162.05 177.287H188.625C189.981 177.287 191.169 178.197 191.521 179.507L196.141 196.664C196.654 198.57 195.218 200.444 193.244 200.444H157.431C155.457 200.444 154.021 198.57 154.534 196.664L159.153 179.507Z"
      fill="#EBCEBC"
    />
    <path
      d="M164.5 183L163 188"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M202.795 179.507C203.147 178.197 204.335 177.287 205.692 177.287H232.266C233.623 177.287 234.811 178.197 235.163 179.507L239.783 196.664C240.296 198.57 238.86 200.444 236.886 200.444H201.072C199.098 200.444 197.662 198.57 198.176 196.664L202.795 179.507Z"
      fill="#EBCEBC"
    />
    <path
      d="M219.425 181.74H209.708C208.306 181.74 207.092 182.71 206.782 184.077L204.283 195.1"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M181.418 154.895C181.771 153.586 182.959 152.675 184.315 152.675H210.89C212.246 152.675 213.434 153.586 213.787 154.895L218.406 172.053C218.919 173.959 217.483 175.832 215.509 175.832H179.696C177.722 175.832 176.286 173.959 176.799 172.053L181.418 154.895Z"
      fill="#EBCEBC"
    />
    <path
      d="M198.047 157.128H188.331C186.929 157.128 185.715 158.098 185.405 159.465L182.906 170.488"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M203.253 179.506C203.606 178.196 204.794 177.286 206.15 177.286H232.725C234.081 177.286 235.269 178.196 235.622 179.506L240.241 196.663C240.754 198.57 239.318 200.443 237.344 200.443H201.531C199.557 200.443 198.121 198.57 198.634 196.663L203.253 179.506Z"
      fill="#EBCEBC"
    />
    <path
      d="M208.5 183L207 188"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M246.895 179.506C247.248 178.196 248.436 177.286 249.792 177.286H276.367C277.723 177.286 278.911 178.196 279.264 179.506L283.883 196.663C284.396 198.57 282.96 200.443 280.986 200.443H245.173C243.199 200.443 241.763 198.57 242.276 196.663L246.895 179.506Z"
      fill="#EBCEBC"
    />
    <path
      d="M251.5 183L250 188"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M225.519 154.895C225.871 153.585 227.059 152.675 228.415 152.675H254.99C256.347 152.675 257.534 153.585 257.887 154.895L262.506 172.052C263.02 173.958 261.584 175.832 259.609 175.832H223.796C221.822 175.832 220.386 173.958 220.899 172.052L225.519 154.895Z"
      fill="#EBCEBC"
    />
    <path
      d="M242.149 157.127H232.432C231.03 157.127 229.816 158.098 229.506 159.464L227.007 170.487"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M180.443 155.174C180.796 153.864 181.983 152.954 183.34 152.954H209.915C211.271 152.954 212.459 153.864 212.812 155.174L217.431 172.331C217.944 174.238 216.508 176.111 214.534 176.111H178.721C176.747 176.111 175.311 174.238 175.824 172.331L180.443 155.174Z"
      fill="#EBCEBC"
    />
    <path
      d="M185.5 158L184 163"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M224.085 155.174C224.437 153.864 225.625 152.954 226.982 152.954H253.556C254.913 152.954 256.101 153.864 256.453 155.174L261.073 172.331C261.586 174.238 260.15 176.111 258.176 176.111H222.362C220.388 176.111 218.952 174.238 219.466 172.331L224.085 155.174Z"
      fill="#EBCEBC"
    />
    <path
      d="M229.5 158L228 163"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M202.708 130.563C203.061 129.253 204.249 128.343 205.605 128.343H232.18C233.536 128.343 234.724 129.253 235.077 130.563L239.696 147.72C240.209 149.626 238.773 151.5 236.799 151.5H200.986C199.012 151.5 197.576 149.626 198.089 147.72L202.708 130.563Z"
      fill="#D6946B"
    />
    <path
      d="M207.5 133L206 138"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <rect
      x="0.676758"
      y="208.627"
      width="302.994"
      height="4.02205"
      rx="2.01102"
      fill="#E5EAEE"
    />
    <rect
      x="134"
      y="40.6177"
      width="49"
      height="37"
      rx="4.5"
      stroke="#F0F4F7"
    />
    <rect
      x="193"
      y="40.6177"
      width="49"
      height="37"
      rx="4.5"
      stroke="#F0F4F7"
    />
    <rect
      x="252"
      y="40.6177"
      width="49"
      height="37"
      rx="4.5"
      stroke="#F0F4F7"
    />
  </svg>
);
