import React from 'react';

export const svgImage = (
  <svg
    viewBox="0 0 545 245"
    fill="none"
  >
    <rect
      width="545"
      height="245"
      fill="white"
    />
    <path
      d="M300.5 92H435C441.075 92 446 96.9249 446 103V202C446 208.075 441.075 213 435 213H166C159.925 213 155 208.075 155 202V138.915"
      stroke="#E5EAEE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="4 7"
    />
    <ellipse
      rx="6.5"
      ry="7"
      transform="matrix(-1 0 0 1 316.5 212)"
      fill="#F4D4BF"
    />
    <ellipse
      rx="6.5"
      ry="7"
      transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 445 175.5)"
      fill="#F4D4BF"
    />
    <circle
      r="3.5"
      transform="matrix(-1 0 0 1 293.5 212.5)"
      fill="#F4D4BF"
    />
    <ellipse
      rx="3.5"
      ry="3.5"
      transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 445.5 152.5)"
      fill="#F4D4BF"
    />
    <path
      d="M155 14.4047C155 13.0766 156.05 12 157.345 12H384.655C385.95 12 387 13.0766 387 14.4047V181.595C387 182.923 385.95 184 384.655 184H157.345C156.05 184 155 182.923 155 181.595V14.4047Z"
      fill="#F0F4F7"
    />
    <path
      d="M132 7.40466C132 6.0766 133.118 5 134.497 5H376.503C377.882 5 379 6.0766 379 7.40466V174.595C379 175.923 377.882 177 376.503 177H134.497C133.118 177 132 175.923 132 174.595V7.40466Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M376.503 6.20233H134.497C133.807 6.20233 133.248 6.74063 133.248 7.40466V174.595C133.248 175.259 133.807 175.798 134.497 175.798H376.503C377.193 175.798 377.752 175.259 377.752 174.595V7.40466C377.752 6.74063 377.193 6.20233 376.503 6.20233ZM134.497 5C133.118 5 132 6.0766 132 7.40466V174.595C132 175.923 133.118 177 134.497 177H376.503C377.882 177 379 175.923 379 174.595V7.40466C379 6.0766 377.882 5 376.503 5H134.497Z"
      fill="#F0F4F7"
    />
    <path
      d="M138 11.57C138 11.2552 138.28 11 138.625 11H374.375C374.72 11 375 11.2552 375 11.57V26.43C375 26.7448 374.72 27 374.375 27H138.625C138.28 27 138 26.7448 138 26.43V11.57Z"
      fill="#E5EAEE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M236.897 49.8631H170.075C168.062 49.8631 166.43 51.614 166.43 53.7739V112.436C166.43 114.596 168.062 116.347 170.075 116.347H236.897C238.91 116.347 240.542 114.596 240.542 112.436V53.7739C240.542 51.614 238.91 49.8631 236.897 49.8631ZM170.075 47.2559C166.72 47.2559 164 50.1741 164 53.7739V112.436C164 116.036 166.72 118.954 170.075 118.954H236.897C240.252 118.954 242.972 116.036 242.972 112.436V53.7739C242.972 50.1741 240.252 47.2559 236.897 47.2559H170.075Z"
      fill="#F0F4F7"
    />
    <path
      d="M222.902 101.476L222.902 101.476L222.896 101.483C222.851 101.54 222.795 101.588 222.731 101.623L222.972 102.061L222.731 101.623C222.667 101.658 222.597 101.68 222.525 101.687L222.576 102.185L222.525 101.687C222.452 101.695 222.379 101.688 222.31 101.666L222.164 102.142L222.309 101.666C222.24 101.645 222.176 101.611 222.121 101.564C222.12 101.564 222.12 101.564 222.119 101.563L209.47 90.806L210.81 89.1359L224.044 99.1996C224.044 99.1999 224.045 99.2002 224.045 99.2005C224.102 99.2446 224.149 99.2995 224.185 99.362C224.22 99.4249 224.243 99.4943 224.252 99.5662C224.26 99.6381 224.255 99.7109 224.235 99.7805C224.215 99.8493 224.182 99.9135 224.137 99.9696L222.902 101.476Z"
      fill="#E5EAEE"
      stroke="#E5EAEE"
    />
    <path
      d="M210.899 81.5142C209.825 88.8748 202.988 93.9717 195.628 92.8985C188.267 91.8253 183.17 84.9884 184.244 77.6278C185.317 70.2673 192.154 65.1703 199.514 66.2435C206.875 67.3167 211.972 74.1536 210.899 81.5142Z"
      stroke="#E5EAEE"
      strokeWidth="3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M288.493 87.2807H267.269C264.931 87.2807 263.037 85.3859 263.037 83.0486V54.927C263.037 52.5897 264.931 50.6949 267.269 50.6949H281.613V57.2607C281.613 58.5107 282.336 59.7158 283.151 60.5503C283.964 61.3825 285.158 62.1421 286.427 62.1421H292.725V83.0486C292.725 85.3859 290.83 87.2807 288.493 87.2807ZM295.125 59.5036L283.485 48.2949H267.269C263.606 48.2949 260.637 51.2642 260.637 54.927V83.0486C260.637 86.7114 263.606 89.6807 267.269 89.6807H288.493C292.156 89.6807 295.125 86.7114 295.125 83.0486V59.5036ZM284.013 57.2607V53.2255L287.562 56.4633L290.941 59.7421H286.427C286.069 59.7421 285.455 59.4749 284.868 58.8734C284.283 58.2742 284.013 57.6386 284.013 57.2607Z"
      fill="#F0F4F7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M288.493 141.281H267.269C264.931 141.281 263.037 139.386 263.037 137.049V108.927C263.037 106.59 264.931 104.695 267.269 104.695H281.613V111.261C281.613 112.511 282.336 113.716 283.151 114.55C283.964 115.383 285.158 116.142 286.427 116.142H292.725V137.049C292.725 139.386 290.83 141.281 288.493 141.281ZM295.125 113.504L283.485 102.295H267.269C263.606 102.295 260.637 105.264 260.637 108.927V137.049C260.637 140.711 263.606 143.681 267.269 143.681H288.493C292.156 143.681 295.125 140.711 295.125 137.049V113.504ZM284.013 111.261V107.226L287.562 110.463L290.941 113.742H286.427C286.069 113.742 285.455 113.475 284.868 112.873C284.283 112.274 284.013 111.639 284.013 111.261Z"
      fill="#F0F4F7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M334.493 87.2807H313.269C310.931 87.2807 309.037 85.3859 309.037 83.0486V54.927C309.037 52.5897 310.931 50.6949 313.269 50.6949H327.613V57.2607C327.613 58.5107 328.336 59.7158 329.151 60.5503C329.964 61.3825 331.158 62.1421 332.427 62.1421H338.725V83.0486C338.725 85.3859 336.83 87.2807 334.493 87.2807ZM341.125 59.5036L329.485 48.2949H313.269C309.606 48.2949 306.637 51.2642 306.637 54.927V83.0486C306.637 86.7114 309.606 89.6807 313.269 89.6807H334.493C338.156 89.6807 341.125 86.7114 341.125 83.0486V59.5036ZM330.013 57.2607V53.2255L333.562 56.4633L336.941 59.7421H332.427C332.069 59.7421 331.455 59.4749 330.868 58.8734C330.283 58.2742 330.013 57.6386 330.013 57.2607Z"
      fill="#F0F4F7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M334.493 141.281H313.269C310.931 141.281 309.037 139.386 309.037 137.049V108.927C309.037 106.59 310.931 104.695 313.269 104.695H327.613V111.261C327.613 112.511 328.336 113.716 329.151 114.55C329.964 115.383 331.158 116.142 332.427 116.142H338.725V137.049C338.725 139.386 336.83 141.281 334.493 141.281ZM341.125 113.504L329.485 102.295H313.269C309.606 102.295 306.637 105.264 306.637 108.927V137.049C306.637 140.711 309.606 143.681 313.269 143.681H334.493C338.156 143.681 341.125 140.711 341.125 137.049V113.504ZM330.013 111.261V107.226L333.562 110.463L336.941 113.742H332.427C332.069 113.742 331.455 113.475 330.868 112.873C330.283 112.274 330.013 111.639 330.013 111.261Z"
      fill="#F0F4F7"
    />
    <path
      d="M337 19C337 21.2091 335.209 23 333 23C330.791 23 329 21.2091 329 19C329 16.7909 330.791 15 333 15C335.209 15 337 16.7909 337 19Z"
      fill="white"
    />
    <path
      d="M350 19C350 21.2091 348.209 23 346 23C343.791 23 342 21.2091 342 19C342 16.7909 343.791 15 346 15C348.209 15 350 16.7909 350 19Z"
      fill="white"
    />
    <path
      d="M362 19C362 21.2091 360.433 23 358.5 23C356.567 23 355 21.2091 355 19C355 16.7909 356.567 15 358.5 15C360.433 15 362 16.7909 362 19Z"
      fill="white"
    />
    <path
      d="M84.5361 139.349C84.5361 138.052 85.5874 137.001 86.8841 137.001H213.4C214.696 137.001 215.748 138.052 215.748 139.349V192.89C215.748 194.187 214.696 195.238 213.4 195.238H86.8841C85.5874 195.238 84.5361 194.187 84.5361 192.89V139.349Z"
      fill="#E5EAEE"
    />
    <path
      d="M80 134.811C80 133.514 81.0512 132.463 82.348 132.463H208.863C210.16 132.463 211.211 133.514 211.211 134.811V188.352C211.211 189.649 210.16 190.7 208.863 190.7H82.348C81.0512 190.7 80 189.649 80 188.352V134.811Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M208.863 133.637H82.348C81.6996 133.637 81.174 134.163 81.174 134.811V188.352C81.174 189.001 81.6996 189.526 82.348 189.526H208.863C209.512 189.526 210.037 189.001 210.037 188.352V134.811C210.037 134.163 209.512 133.637 208.863 133.637ZM82.348 132.463C81.0512 132.463 80 133.514 80 134.811V188.352C80 189.649 81.0512 190.7 82.348 190.7H208.863C210.16 190.7 211.211 189.649 211.211 188.352V134.811C211.211 133.514 210.16 132.463 208.863 132.463H82.348Z"
      fill="#E5EAEE"
    />
    <path
      d="M84.5361 136.338C84.5361 136.014 84.7989 135.751 85.1231 135.751H205.873C206.197 135.751 206.46 136.014 206.46 136.338V144.189C206.46 144.514 206.197 144.776 205.873 144.776H85.1231C84.7989 144.776 84.5361 144.514 84.5361 144.189V136.338Z"
      fill="#E5EAEE"
    />
    <path
      d="M185.505 140.264C185.505 141.464 184.532 142.437 183.333 142.437C182.133 142.437 181.16 141.464 181.16 140.264C181.16 139.064 182.133 138.092 183.333 138.092C184.532 138.092 185.505 139.064 185.505 140.264Z"
      fill="white"
    />
    <path
      d="M192.599 140.264C192.599 141.464 191.626 142.437 190.426 142.437C189.227 142.437 188.254 141.464 188.254 140.264C188.254 139.064 189.227 138.092 190.426 138.092C191.626 138.092 192.599 139.064 192.599 140.264Z"
      fill="white"
    />
    <path
      d="M199.688 140.264C199.688 141.464 198.715 142.437 197.515 142.437C196.315 142.437 195.343 141.464 195.343 140.264C195.343 139.064 196.315 138.092 197.515 138.092C198.715 138.092 199.688 139.064 199.688 140.264Z"
      fill="white"
    />
    <path
      d="M98.3994 164.517C98.3994 162.896 99.7135 161.582 101.334 161.582H189.666C191.287 161.582 192.601 162.896 192.601 164.517V167.089C192.601 168.71 191.287 170.024 189.666 170.024H101.334C99.7135 170.024 98.3994 168.71 98.3994 167.089V164.517Z"
      fill="#F0F4F7"
    />
    <path
      d="M98.3994 164.517C98.3994 162.896 99.7135 161.582 101.334 161.582H157.253C158.874 161.582 160.188 162.896 160.188 164.517V167.089C160.188 168.71 158.874 170.024 157.253 170.024H101.334C99.7135 170.024 98.3994 168.71 98.3994 167.089V164.517Z"
      fill="#D8DDE3"
    />
    <path
      d="M386.881 128.361C388.359 128.361 389.558 129.588 389.558 131.1L389.558 140.003C389.558 141.516 388.359 142.742 386.881 142.742L377.853 142.742C376.375 142.742 375.177 141.516 375.177 140.003L375.177 131.1C375.177 129.588 376.375 128.361 377.853 128.361L386.881 128.361Z"
      fill="#DFB49A"
    />
    <path
      d="M366.326 128.491C366.326 127.198 367.518 126.149 368.988 126.149H395.745C397.215 126.149 398.407 127.198 398.407 128.491V131.551C398.407 132.845 397.215 133.893 395.745 133.893H368.988C367.518 133.893 366.326 132.845 366.326 131.551V128.491Z"
      fill="#F4D4BF"
    />
    <path
      d="M426.063 182.014C426.063 206.147 406.747 225.71 382.92 225.71C359.093 225.71 339.777 206.147 339.777 182.014C339.777 157.882 359.093 138.318 382.92 138.318C406.747 138.318 426.063 157.882 426.063 182.014Z"
      fill="#E6BA9E"
    />
    <path
      d="M388.451 181.461C388.451 185.126 385.48 188.098 381.814 188.098C378.148 188.098 375.177 185.126 375.177 181.461C375.177 177.795 378.148 174.823 381.814 174.823C385.48 174.823 388.451 177.795 388.451 181.461Z"
      fill="white"
    />
    <path
      d="M364.378 159.709C363.588 158.511 363.919 156.899 365.117 156.109C366.315 155.319 367.927 155.65 368.717 156.848L384.455 180.711C385.245 181.909 384.915 183.521 383.716 184.311C382.518 185.101 380.907 184.771 380.116 183.572L364.378 159.709Z"
      fill="white"
    />
    <path
      d="M397.448 167.906C398.607 167.059 400.233 167.311 401.08 168.47C401.927 169.628 401.675 171.254 400.517 172.101L385.007 183.445C383.849 184.292 382.223 184.04 381.376 182.882C380.528 181.723 380.781 180.097 381.939 179.25L397.448 167.906Z"
      fill="white"
    />
    <path
      d="M381.813 148.053C381.813 146.953 382.556 146.062 383.473 146.062C384.389 146.062 385.132 146.953 385.132 148.053V150.708C385.132 151.807 384.389 152.699 383.473 152.699C382.556 152.699 381.813 151.807 381.813 150.708V148.053Z"
      fill="white"
    />
    <path
      d="M381.813 213.32C381.813 212.221 382.556 211.329 383.473 211.329C384.389 211.329 385.132 212.221 385.132 213.32V215.975C385.132 217.075 384.389 217.966 383.473 217.966C382.556 217.966 381.813 217.075 381.813 215.975V213.32Z"
      fill="white"
    />
    <path
      d="M414.005 183.673C412.722 183.673 411.682 182.682 411.682 181.46C411.682 180.239 412.722 179.248 414.005 179.248L417.102 179.248C418.385 179.248 419.425 180.239 419.425 181.46C419.425 182.682 418.385 183.673 417.102 183.673L414.005 183.673Z"
      fill="white"
    />
    <path
      d="M348.406 183.673C347.307 183.673 346.415 182.682 346.415 181.46C346.415 180.239 347.307 179.248 348.406 179.248L351.061 179.248C352.161 179.248 353.052 180.239 353.052 181.46C353.052 182.682 352.161 183.673 351.061 183.673L348.406 183.673Z"
      fill="white"
    />
    <ellipse
      opacity="0.5"
      cx="383.459"
      cy="236.869"
      rx="23.5759"
      ry="2.8838"
      fill="#E5EAEE"
    />
  </svg>
);
