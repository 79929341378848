import { z } from 'zod';
import { ReserveStatusesEnum } from 'constants/metalSale';

export const ReserveEntity = z.object({
  id: z.number(),
  status: ReserveStatusesEnum,
  expiresAt: z.string().or(z.null()),
});

export type ReserveEntity = z.infer<typeof ReserveEntity>;
