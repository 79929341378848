import { createAsyncThunk } from '@reduxjs/toolkit';
import { PricesService } from './service';

export const getPrices = createAsyncThunk('prices/getPrices', async (search) => {
  const { data, success, message } = await PricesService.getPrices(search);

  if (!success) {
    throw new Error(message);
  }

  return data;
});
export const getNextPagePrices = createAsyncThunk('prices/getNextPagePrices', async ({ search, page }) => {
  const { data, success, message } = await PricesService.getNextPagePrices(search, page);

  if (!success) {
    throw new Error(message);
  }

  return data;
});
