import { useSupportWsConnection } from 'ducks/support/wsConnection/hooks/useSupportWsConnection';
import { useEffect, useRef, useState } from 'react';
import { State as CentrifugeStatusEnum } from 'centrifuge/build/types';

const NOTIFICATION_DELAY_MS = 2000;

export function useWsNotification() {
  const { status } = useSupportWsConnection();
  const [isNotificationVisible, setNotificationVisible] = useState(false);
  const timerId = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (status === CentrifugeStatusEnum.Connected) {
      setNotificationVisible(false);
    }

    if (status === CentrifugeStatusEnum.Connecting || status === CentrifugeStatusEnum.Disconnected) {
      timerId.current = setTimeout(() => {
        setNotificationVisible(true);
      }, NOTIFICATION_DELAY_MS);
    }

    return () => {
      clearTimeout(timerId.current);
    };
  }, [status]);

  return {
    isNotificationVisible,
  };
}
