import { useEffect } from 'react';
import { useModal } from './useModal';
import { SURVEY_DELAY_MS } from 'components/modals/SurveyModal/consts';
import { useAuth } from 'ducks/auth/hooks/useAuth';

export function useSurveyRender() {
  const {
    isModalVisible: isSurveyModalVisible,
    openModal: openSurveyModal,
    closeModal: closeSurveyModal,
  } = useModal(false);

  const { init } = useAuth().authState;
  const needRenderSurvey: boolean | undefined = init.needRenderSurvey;

  useEffect(() => {
    if (needRenderSurvey) {
      setTimeout(openSurveyModal, SURVEY_DELAY_MS);
    }
  }, [needRenderSurvey, openSurveyModal]);

  return {
    isSurveyModalVisible,
    closeSurveyModal,
  };
}
