import { createAsyncThunk } from '@reduxjs/toolkit';
import { WTSService } from 'services/wts/wtsApi';
import { ErrorEntity } from 'models/api/wts/metalSale/error';
import { parseDataBySchema } from 'utils/parseDataBySchema';
import { SuccessEntity } from 'models/api/wts/common/success';
import { CancelReserveParams } from 'models/params/wts/metalSale/reserveAction';
import { VerificationEntity } from 'models/api/wts/common/verification';
import { closableMessage as antdMessage } from 'components/ClosableMessage';
import dayjs from 'dayjs';
import { FixationDeleteEntity } from 'models/api/wts/metalSale/fixationDelete';
import { createUrlFormPath } from 'utils/createUrlFormPath';
import { ConnectionSetupEntity } from 'models/api/wts/common/connectionSetup';
import { AuthIdParam } from 'models/params/wts/common/connectionSetup';
import {
  INIT_FEATURES_SELL_API_PATH,
  INIT_SALE_FORM_API_PATH,
  METAL_TRANSFERS_API_PATH,
  PRODUCTS_ACTIVE_API_PATH,
  PRODUCTS_API_PATH,
  SALES_API_PATH,
  FIX_PRICE_API_PATH,
  VERIFICATION_AUTH_API_PATH,
  SPOT_PRICE_API_PATH,
  DELETE_PRODUCT_API_PATH,
  CANCEL_PRODUCT_API_PATH,
  CREATE_SALE_API_PATH,
} from './consts';
import { ProductListEntity } from 'models/api/wts/metalPurchase/productList';
import { InitSellFeaturesEntity } from 'models/api/wts/metalPurchase/initSellData';
import { MetalTransferListEntity } from 'models/api/wts/metalPurchase/metalTransferList';
import { onCloseWS, setupFailed, setupOnReconnect } from './slice';
import { SaleListEntity } from 'models/api/wts/metalPurchase/saleList';
import { ProductActiveListEntity } from 'models/api/wts/metalPurchase/productActiveList';
import { normalizeProductsActive } from './utils';
import { SaleFormEntity } from 'models/api/wts/metalPurchase/saleForm';
import { SaleFormParamsSchema } from 'models/params/wts/metalPurchase/saleForm';
import { sleep } from 'features/wts/utils/sleep';
import { SubscribeSpotParamsSchema } from 'models/params/wts/metalPurchase/subscribeSpot';
import { CreateSaleParamsSchema } from 'models/params/wts/metalPurchase/createSale';
import { CreateProductParamsSchema } from 'models/params/wts/metalPurchase/createProduct';
import { ProductEntity } from 'models/api/wts/metalPurchase/product';
import { SaleEntity } from 'models/api/wts/metalPurchase/sale';
import { FixRequestParamsSchema, SpotRequestParamsSchema } from 'models/params/wts/metalPurchase/fixationMethods';

export const setupWTSService = createAsyncThunk<ConnectionSetupEntity, AuthIdParam>(
  'wtsPurchaseAuth/setupWTSService',
  async (userId, { rejectWithValue, dispatch }) => {
    const data = await WTSService.setup(userId, dispatch, { onCloseWS, setupOnReconnect, setupFailed });
    if (data && !data.error) {
      return data;
    } else {
      return rejectWithValue(data);
    }
  }
);

export const destroyWTSService = createAsyncThunk('wtsPurchaseAuth/destroyWTSService', async () => {
  return WTSService.destroy();
});

// пагинатор со списком сделок
export const getSalesList = createAsyncThunk<SaleListEntity['paginator'], string | undefined>(
  'wtsPurchase/getSalesList',
  async (search, { rejectWithValue }) => {
    const data = await WTSService.callV2({
      path: `${SALES_API_PATH}${search}`,
      method: 'GET',
    });

    const [result, zodError] = parseDataBySchema(SaleListEntity, data);

    if (result) {
      return result.paginator;
    }

    const [apiError] = parseDataBySchema(ErrorEntity, data);

    antdMessage.error(apiError?.message || 'Не удалось получить сделок');

    return rejectWithValue(apiError || zodError || null);
  }
);

// получение начальных данных для формы создания поставки (лимиты, список типов сырья и т.п.)
export const getSaleForm = createAsyncThunk<SaleFormEntity, SaleFormParamsSchema>(
  'wtsPurchase/getSaleForm',
  async ({ publicId, delay = 0 }, { rejectWithValue }) => {
    if (delay) {
      await sleep(delay);
    }
    const response = await WTSService.callV2({
      path: createUrlFormPath(INIT_SALE_FORM_API_PATH, { publicId }),
      method: 'GET',
    });

    const [result, zodError] = parseDataBySchema(SaleFormEntity, response);

    if (result) {
      return result;
    }

    const [apiError] = parseDataBySchema(ErrorEntity, response);
    antdMessage.error(apiError?.message || 'Не удалось получить данные формы');
    return rejectWithValue(apiError || zodError || null);
  }
);

export const createSale = createAsyncThunk<SaleEntity, CreateSaleParamsSchema>(
  'wtsPurchase/createSale',
  async ({ id, ...payload }, { rejectWithValue }) => {
    const response = await WTSService.callV2({
      path: createUrlFormPath(CREATE_SALE_API_PATH, { id }),
      body: payload,
    });
    const [result, zodError] = parseDataBySchema(SaleEntity, response);

    if (result) {
      return result;
    }

    const [apiError] = parseDataBySchema(ErrorEntity, response);

    antdMessage.error(apiError?.message || 'Не удалось создать сделку');

    return rejectWithValue(apiError || zodError || null);
  }
);

export const fixThePrice = createAsyncThunk(
  'wtsPurchase/fixThePrice',
  async ({ publicId, ...payload }: FixRequestParamsSchema, { rejectWithValue }) => {
    const response = await WTSService.callV2({
      path: createUrlFormPath(FIX_PRICE_API_PATH, { publicId }),
      body: payload,
    });

    const [result, zodError] = parseDataBySchema(SuccessEntity, response);

    if (result) {
      return result;
    }

    const [apiError] = parseDataBySchema(ErrorEntity, response);

    antdMessage.error(apiError?.message || 'Не удалось зафиксировать изменения');

    return rejectWithValue(apiError || zodError || null);
  }
);

export const fixTheSpotPrice = createAsyncThunk(
  'wtsPurchase/fixTheSpotPrice',
  async ({ publicId, ...payload }: SpotRequestParamsSchema, { rejectWithValue }) => {
    const response = await WTSService.callV2({
      path: createUrlFormPath(SPOT_PRICE_API_PATH, { publicId }),
      body: payload,
    });

    const [result, zodError] = parseDataBySchema(SuccessEntity, response);

    if (result) {
      return result;
    }

    const [apiError] = parseDataBySchema(ErrorEntity, response);

    antdMessage.error(apiError?.message || 'Не удалось зафиксировать');

    return rejectWithValue(apiError || zodError || null);
  }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const subscribeSpotPrice = createAsyncThunk<any, SubscribeSpotParamsSchema>(
  'wtsPurchase/getSpotPrice',
  async ({ topicUri, getAction }, { rejectWithValue, dispatch }) => {
    try {
      return await WTSService.subscribeWithDispatch(topicUri, getAction, dispatch);
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const unsubscribeSpotPrice = createAsyncThunk<unknown, string>(
  'wtsPurchase/unsubscribeSpotPrice',
  async (topic, { rejectWithValue }) => {
    try {
      return await WTSService.unsubscribe(topic);
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const verificationCheck = createAsyncThunk(`wtsPurchaseAuth/verification`, async (_, { rejectWithValue }) => {
  const data = await WTSService.callV2({
    path: VERIFICATION_AUTH_API_PATH,
  });
  const [result, zodError] = parseDataBySchema(VerificationEntity, data);

  if (result) {
    return {
      verification: {
        isVerified: result.features.sell.canViewSection,
        role: result.role,
      },
    };
  }

  const [apiError] = parseDataBySchema(ErrorEntity, data);
  antdMessage.error(apiError?.message || 'Ошибка верификации');
  return rejectWithValue(apiError || zodError || null);
});

// удаление поставки со статусом 1 - черновик, com.wts.fixation.delete
export const deleteFixation = createAsyncThunk<FixationDeleteEntity, string>(
  `wtsPurchase/deleteFixation`,
  async (id, { rejectWithValue }) => {
    const response = await WTSService.callV2({
      path: createUrlFormPath(DELETE_PRODUCT_API_PATH, { id }),
    });

    const [result, zodError] = parseDataBySchema(FixationDeleteEntity, response);

    if (result) {
      antdMessage.success(`Фиксация успешно удалена`);
      return result;
    }

    const [apiError] = parseDataBySchema(ErrorEntity, response);

    antdMessage.error(apiError?.message || `Не удалось удалить фиксацию`);

    return rejectWithValue(apiError || zodError || null);
  }
);

export const ping = createAsyncThunk(`wtsPurchase/ping`, async (_, { rejectWithValue }) => {
  try {
    const data = await WTSService.call(`com.wts.common.ping`);

    const diff = dayjs().diff(data.now, 'millisecond', true);
    // eslint-disable-next-line no-console
    console.log([
      '[DEBUG] HealthCheck timeSync',
      {
        status: dayjs().diff(data.now, 'second', true) <= 2,
        pingStatus: data.status,
        context: {
          diff,
          local: dayjs().format(),
          server: data.now,
        },
      },
    ]);

    return { ...data, diff: Math.floor(diff / 1000) };
  } catch (error) {
    return rejectWithValue(error);
  }
});

// debug subscribe
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const subscribeDebug = createAsyncThunk<any, any>(`wtsPurchase/debug`, async (topic, { rejectWithValue }) => {
  try {
    return await WTSService.subscribe(topic);
  } catch (error) {
    rejectWithValue(error);
  }
});

// пагинатор со списком разрешений
export const getProductsList = createAsyncThunk(
  'wtsPurchase/getProductsList',
  async (payload: string | undefined, { rejectWithValue }) => {
    const data = await WTSService.callV2({ path: `${PRODUCTS_API_PATH}${payload}`, method: 'GET' });

    const [result, zodError] = parseDataBySchema(ProductListEntity, data);

    if (result) {
      return result.paginator;
    }

    antdMessage.error('Не удалось получить список разрешений');

    const [apiError] = parseDataBySchema(ErrorEntity, data);
    return rejectWithValue(apiError || zodError || null);
  }
);

export const getActiveProductsList = createAsyncThunk(
  'wtsPurchase/getActiveProductsList',
  async (_: undefined, { rejectWithValue }) => {
    const data = await WTSService.callV2({ path: PRODUCTS_ACTIVE_API_PATH, method: 'GET' });

    const [result, zodError] = parseDataBySchema(ProductActiveListEntity, data);

    if (result) {
      return normalizeProductsActive(result.activeProducts);
    }

    antdMessage.error('Не удалось получить список активных разрешений');

    const [apiError] = parseDataBySchema(ErrorEntity, data);
    return rejectWithValue(apiError || zodError || null);
  }
);

export const getMetalTransfers = createAsyncThunk<MetalTransferListEntity['items'], undefined>(
  'wtsPurchase/getMetalTransfers',
  async (_, { rejectWithValue }) => {
    const data = await WTSService.callV2({ path: METAL_TRANSFERS_API_PATH, method: 'GET' });

    const [result, zodError] = parseDataBySchema(MetalTransferListEntity, data);

    if (result) {
      return result.items;
    }

    antdMessage.error('Не удалось получить список передач');

    const [apiError] = parseDataBySchema(ErrorEntity, data);
    return rejectWithValue(apiError || zodError || null);
  }
);

export const createProduct = createAsyncThunk(
  'wtsPurchase/createProduct',
  async (payload: CreateProductParamsSchema, { rejectWithValue }) => {
    const response = await WTSService.callV2({
      path: PRODUCTS_API_PATH,
      body: payload,
      headers: [],
    });

    const [result, zodError] = parseDataBySchema(ProductEntity, response);

    if (result) {
      antdMessage.success('Разрешение успешно создано');
      return result;
    }

    antdMessage.error(response?.message || 'Не удалось создать разрешение');
    const [apiError] = parseDataBySchema(ErrorEntity, response);

    return rejectWithValue(apiError || zodError || null);
  }
);

export const cancelProduct = createAsyncThunk<SuccessEntity, CancelReserveParams>(
  'wtsPurchase/cancelProduct',
  async ({ id }: CancelReserveParams, { rejectWithValue }) => {
    const response = await WTSService.callV2({
      path: createUrlFormPath(CANCEL_PRODUCT_API_PATH, { id }),
    });

    const [result, zodError] = parseDataBySchema(SuccessEntity, response);

    if (result) {
      antdMessage.success('Разрешение отозвано у поставщика');
      return result;
    }

    const [apiError] = parseDataBySchema(ErrorEntity, response);
    antdMessage.error(apiError?.message || 'Не удалось отозвать разрешение у поставщика');

    return rejectWithValue(apiError || zodError || null);
  }
);

export const getInitSellFeatures = createAsyncThunk<InitSellFeaturesEntity, undefined>(
  'wtsPurchase/getInitSellFeatures',
  async (_, { rejectWithValue }) => {
    const data = await WTSService.callV2({ path: INIT_FEATURES_SELL_API_PATH, method: 'GET' });

    const [result, zodError] = parseDataBySchema(InitSellFeaturesEntity, data);

    if (result) {
      return result;
    }

    antdMessage.error('Не удалось получить список инициализационных данных');

    const [apiError] = parseDataBySchema(ErrorEntity, data);

    return rejectWithValue(apiError || zodError || null);
  }
);
