import { createAsyncThunk } from '@reduxjs/toolkit';
import { MessageParams, MessagesListParams } from 'types/support';
import { messagesApi } from 'services/support/messagesApi';
import { actions as ticketsListActions } from '../../ticketsList/slices/ticketsListSlice';
import { actions } from '../slices/messagesSlice';

export const MESSAGES_PREFIX = 'messages';
export const GET_MESSAGES = `${MESSAGES_PREFIX}/getMessages`;
export const CREATE_MESSAGE = `${MESSAGES_PREFIX}/createMessage`;
export const READ_MESSAGES = `${MESSAGES_PREFIX}/readMessages`;

export const getMessages = createAsyncThunk(GET_MESSAGES, async (payload: MessagesListParams) => {
  const { data } = await messagesApi.getMessages(payload);
  return data;
});

export const createMessage = createAsyncThunk(CREATE_MESSAGE, async (payload: MessageParams, { dispatch }) => {
  const { data } = await messagesApi.createMessage(payload);
  dispatch(actions.addMessage(data));
  dispatch(ticketsListActions.updateTicketLastMessage(data));
  return data;
});

export const readMessages = createAsyncThunk(READ_MESSAGES, async (ticketId: number, { dispatch }) => {
  const { data } = await messagesApi.readMessages(ticketId);
  dispatch(actions.updateMessagesReadAt(data));
  dispatch(ticketsListActions.readTicketByClient(data));
  dispatch(ticketsListActions.updateLastMessageReadAt(data));
  return data;
});
