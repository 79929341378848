import styled from 'styled-components';
import { Alert as AntdAlert } from 'antd';

export const Alert = styled(AntdAlert)`
  &&& {
    position: fixed;
    left: 50%;
    bottom: 10%;
    transform: translateX(-50%);
    width: max-content;
    z-index: 1000;
    padding: 16px;
    font-size: 16px;
    line-height: 19px;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgb(147, 147, 147, 0.4);
    &.ant-alert-info {
      background-color: #d7dce0 !important;
      border: none !important;
    }
    animation: appear 0.3s cubic-bezier(0.41, 0.08, 0.55, 1.81);
    @keyframes appear {
      0% {
        opacity: 0;
        transform: scale(0.9) translateX(-55%);
      }
      100% {
        opacity: 1;
        transform: scale(1) translateX(-50%);
      }
    }
  }
`;
