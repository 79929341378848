import {
  OAUTH_CLIENT_ID,
  OAUTH_POPUP_NAME,
  OAUTH_RESPONSE_TYPE,
  OAUTH_SCOPES,
  OAUTH_URL,
  POPUP_HEIGHT,
  POPUP_WIDTH,
} from 'features/Login/SSO/consts';
import { OAUTH_PATH } from 'constants/routePaths';
import { OAUTH_HASH_LENGTH } from 'constants/oauth';

export const generateHash = () => {
  const VALID_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const randomValues = new Uint8Array(OAUTH_HASH_LENGTH);
  window.crypto.getRandomValues(randomValues);
  const result = randomValues.map((x) => VALID_CHARS.codePointAt(x % VALID_CHARS.length) as number);
  return String.fromCharCode(...Array.from(result));
};

export function getRedirectUri() {
  return `${window.location.origin}${OAUTH_PATH}`;
}

export const getOAuthUrl = (state: string) => {
  const result = new URL(OAUTH_URL);
  result.searchParams.set('response_type', OAUTH_RESPONSE_TYPE);
  result.searchParams.set('client_id', OAUTH_CLIENT_ID);
  result.searchParams.set('redirect_uri', getRedirectUri());
  result.searchParams.set('scope', OAUTH_SCOPES.join(' '));
  result.searchParams.set('state', state);

  return result.toString();
};

const getOAuthPopupSize = () => {
  const MARGIN = 50;
  const top = window.screenY + MARGIN;
  const left = window.screenX + window.outerWidth - POPUP_WIDTH - MARGIN;

  return `height=${POPUP_HEIGHT},width=${POPUP_WIDTH},top=${top},left=${left}`;
};

export const openOAuthPopup = (state: string) => {
  return window.open(getOAuthUrl(state), OAUTH_POPUP_NAME, getOAuthPopupSize());
};
