import React, { Suspense, useEffect, useState } from 'react';
import { PrivateRoute } from 'components/PrivateRoute';
import { adminRoutes, publicRoutes, wtsRoutes } from '../routes';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import history from '../store/history';
import { Sidebar } from '../features/sidebar';
import GlobalLoader from './GlobalLoader';
import ruRu from 'antd/es/locale/ru_RU';
import enGB from 'antd/es/locale/en_GB';
import { ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';
import { BadLink } from '../pages/auth/BadLinkReset/BadLink';
import { RestorePassword } from '../pages/auth/BadLinkReset/RestorePassword';
import { PageWrapper } from '../features/sidebar/styled';
import { Fallback } from './Fallback';
import { useVersionChecker } from 'hooks/useVersionChecker';
import { useTranslation } from 'react-i18next';
import { useIsAdmin } from 'hooks/useIsAdmin';
import { isBoolean } from 'lodash-es';
import { BALANCE_PATH, OAUTH_PATH } from 'constants/routePaths';
import { OfflineAlert } from './OfflineAlert';
import { LoginPage } from 'pages/auth/LoginPage';
import { useGlobalAppEnv } from 'hooks/useGlobalAppEnv';
import { OAuthPage } from 'pages/auth/OAuthPage';
import { SurveyModal } from './modals/SurveyModal';
import { useSurveyRender } from 'hooks/useSurveyRender';
import { Page } from './layout/Page';
import Loader from 'react-loaders';

function Root() {
  const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
  useVersionChecker();
  useGlobalAppEnv();

  const { loader, localization } = useSelector((state) => ({
    loader: state.loader?.globalLoader,
    localization: state.auth?.init?.user?.localization,
  }));

  const { isSurveyModalVisible, closeSurveyModal } = useSurveyRender();

  const { isAdmin } = useIsAdmin();
  const { i18n } = useTranslation();
  const { language } = i18n;
  const isRu = language === 'ru';

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const onlineHandler = () => {
    setIsOnline(true);
  };
  const offlineHandler = () => {
    setIsOnline(false);
  };

  useEffect(() => {
    window.addEventListener('offline', offlineHandler);
    window.addEventListener('online', onlineHandler);
    return () => {
      window.removeEventListener('offline', offlineHandler);
      window.removeEventListener('online', onlineHandler);
    };
  }, []);

  useEffect(() => {
    if (localization) {
      i18n.changeLanguage(localization);
    }
  }, [localization]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isIE11) {
    return <Fallback />;
  }

  return (
    <ConfigProvider locale={isRu ? ruRu : enGB}>
      {!loader ? (
        <Router history={history}>
          <PageWrapper>
            <OfflineAlert
              isVisible={!isOnline}
              closable
            />
            <SurveyModal
              visible={isSurveyModalVisible}
              onCancel={closeSurveyModal}
            />
            <Sidebar />
            <Suspense
              fallback={
                <Page>
                  <Loader
                    type="ball-spin-fade-loader"
                    className="loader-container"
                    active
                  />
                </Page>
              }
            >
              <Switch>
                <Route
                  path={OAUTH_PATH}
                  render={OAuthPage}
                />
                <Route
                  path="/signin"
                  render={LoginPage}
                />
                <Route
                  path="/reset"
                  component={RestorePassword}
                />
                <Route
                  path="/badlink"
                  component={BadLink}
                />
                <Route
                  path="/"
                  exact
                  render={(match) => (
                    <Redirect
                      to={BALANCE_PATH}
                      {...match}
                    />
                  )}
                />
                {wtsRoutes.map((wtsRoute) => (
                  <PrivateRoute
                    key={wtsRoute.path}
                    {...wtsRoute}
                  />
                ))}
                {isAdmin &&
                  adminRoutes.map((adminRoute, index) => (
                    <PrivateRoute
                      key={index}
                      {...adminRoute}
                      exact={isBoolean(adminRoute.exact) ? adminRoute.exact : true}
                    />
                  ))}
                {publicRoutes.map((publicRoute, index) => (
                  <PrivateRoute
                    key={index}
                    {...publicRoute}
                    exact={isBoolean(publicRoute.exact) ? publicRoute.exact : true}
                  />
                ))}
              </Switch>
            </Suspense>
          </PageWrapper>
        </Router>
      ) : (
        <GlobalLoader />
      )}
    </ConfigProvider>
  );
}

export default Root;
