import { TicketEntity, TicketsListEntity } from 'models/api/support/ticket';
import { ADMIN_TICKETS_API } from './consts';
import { asyncPipe } from 'utils/asyncPipe';
import { getDataFromResponse } from 'utils/getDataFromResponse';
import { UploadFile } from 'antd/lib/upload/interface';
import { axiosInstance } from 'services/axiosInstance';

export type CreateAdminTicketRequest = {
  companyId: number;
  recipientId: number;
  message: string;
  customTheme: string;
  files?: UploadFile[];
};

export type TicketsQueryParams = {
  userId?: number;
  search?: string;
  categoryIds?: string;
  statusIds?: string;
  responsibleIds?: string;
  creatorIds?: string;
  companyIds?: string;
  contractCfoCodes?: string;
  lastMessageId?: number;
  onlyOpen?: boolean;
};

class AdminTicketsListApi {
  getAdminTicketsList = (params: TicketsQueryParams) => {
    return asyncPipe(axiosInstance.get, getDataFromResponse)<ApiResponseEntity<TicketsListEntity>>(ADMIN_TICKETS_API, {
      params,
    });
  };

  createAdminTicket = (data: FormData) => {
    return asyncPipe(axiosInstance.post, getDataFromResponse)<ApiResponseEntity<TicketEntity>>(ADMIN_TICKETS_API, data);
  };
}

export const adminTicketsListApi = new AdminTicketsListApi();
