import React from 'react';
import * as S from 'features/Login/Locales/Locales.styled';
import { useLocales } from 'features/Login/Locales/hooks/useLocales';

type LocalesProps = {
  onChange?: () => void;
};

export function Locales({ onChange }: LocalesProps) {
  const { onLanguageChange, value, items } = useLocales(onChange);

  return (
    <S.Locales>
      <S.LocaleRadioGroup
        value={value}
        onChange={onLanguageChange}
      >
        {items.map((locale) => (
          <S.LocaleRadio
            key={locale}
            value={locale}
          >
            {locale.toUpperCase()}
          </S.LocaleRadio>
        ))}
      </S.LocaleRadioGroup>
    </S.Locales>
  );
}
