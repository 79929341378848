import types from '../actions/types';
const initialState = {
  access: {},
};
export default function layoutReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_PAGE: {
      return {
        ...state,
        header: action.payload?.title,
        description: action.payload?.description,
        filters: action.payload?.filters,
        hideSidebar: true,
        access: action.payload?.access,
      };
    }
    case types.TOGGLE_SIDEBAR: {
      return { ...state, showMainSidebar: !state.showMainSidebar };
    }
    case types.HIDE_SIDEBAR: {
      return { ...state, showMainSidebar: false };
    }
    case types.GET_NOTIFICATIONS_COUNT: {
      return { ...state, notificationCount: action.payload.count };
    }
    default:
      return state;
  }
}
