import React from 'react';
import * as S from './styled';
import { Button } from 'antd';
import { Subsection } from './Subsection';

export function Subsections({ sections = [], style, affix }) {
  return (
    <S.Wrapper style={style}>
      <Button.Group>
        {sections.map((subsection) => (
          <Subsection
            key={subsection.link}
            {...subsection}
          />
        ))}
      </Button.Group>
      {affix}
    </S.Wrapper>
  );
}
