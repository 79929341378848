import { createSlice } from '@reduxjs/toolkit';
import { BannersState } from './slice.types';
import { getBanners } from './actions';
import { addLoadingMatchers } from 'store/utils/addLoadingMatchers';
import { GET_SUPPLIES_ACTION_PREFIX } from '../../balance/Supplies/ducks/actions';

export const INITIAL_STATE: BannersState = {
  data: [],
  requestLoading: false,
};

const slice = createSlice({
  name: 'banners',
  initialState: INITIAL_STATE,
  reducers: {
    reset: (state) => {
      state.data = INITIAL_STATE.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBanners.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    addLoadingMatchers(builder, GET_SUPPLIES_ACTION_PREFIX, 'requestLoading');
  },
});

export const { name, actions, reducer } = slice;
