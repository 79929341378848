import React from 'react';
import { icons } from 'features/sidebar/Icons';
import * as S from '../styled';

export function CompanyMobile({ company, onShowDetail }) {
  return (
    <S.CompanyWrapper
      onClick={onShowDetail}
      tabIndex="0"
    >
      <S.CompanyBlockMobile>
        <S.NavIcon>{icons.company}</S.NavIcon>
        <S.CompanyName>{company}</S.CompanyName>
      </S.CompanyBlockMobile>
      <S.IconArrow>{icons.nextRight}</S.IconArrow>
    </S.CompanyWrapper>
  );
}
