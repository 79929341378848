import styled from 'styled-components';
import { Switch as AntdSwitch, Button as AntdButton } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 43px;
  @media (max-width: 850px) {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
  }
`;

export const SwitchContainer = styled.div`
  display: flex;
  @media (max-width: 850px) {
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
  }
`;

export const Switch = styled(AntdSwitch)`
  //background-color: #cd743d;
  border-radius: 16px;
  @media (max-width: 850px) {
    margin-left: 16px;
  }
`;

export const SwitchTitle = styled.div`
  margin-left: 7px;
  color: #797979;
  font-size: 16px;
  line-height: 19px;
  white-space: nowrap;
  @media (max-width: 850px) {
    font-size: 14px;
    line-height: 22px;
  }
`;

export const Button = styled(AntdButton)`
  margin-left: 43px;
  background-color: #cd743d;
  border-radius: 3px;
  @media (max-width: 850px) {
    margin-top: 20px;
    margin-left: 0;
    width: 100%;
    font-size: 16px;
    line-height: 19px;
  }
`;
