/* eslint-disable no-template-curly-in-string */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { usePassword } from 'hooks/usePassword';
import Logo from 'components/Logo';
import { EMAIL_ADDRESS_REGEXP } from 'constants/emailAddress';
import * as actions from 'pages/auth/BadLinkReset/actions';
import * as S from 'pages/auth/BadLinkReset/RestorePassword/styled';

const PASSWORD = 'password';
const CONFIRM = 'confirm';

export function RestorePassword() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');
  const localization = query.get('localization');
  const { t, i18n } = useTranslation();
  const { onChange: changePasswordHandler, checkPasswordValidationRules } = usePassword();

  const currentLanguage = i18n.language;
  const authenticated = useSelector((state) => state.auth?.authenticated);

  const errorText = {
    required: t('error.Поле обязательное'),
    email: t('error.Введите корректный email'),
  };

  useEffect(() => {
    if (localization) {
      i18n.changeLanguage(localization);
    }
  }, [i18n, localization]);

  if (authenticated) {
    return <Redirect to="/signin" />;
  }

  const passCompare = (getFieldValue) => ({
    validator(_, value) {
      if (!value || getFieldValue(PASSWORD) === value) {
        return Promise.resolve();
      }

      return Promise.reject(t('auth.Пароли не совпадают'));
    },
  });

  const onFinish = async ({ password, email }) => {
    if (email && token && password) {
      const result = await dispatch(actions.resetPassword({ email, token, password, localization: currentLanguage }));

      if (result?.payload?.success) {
        history.push('/signin');
      }
    } else {
      history.push('/badlink');
    }
  };

  if (!token) {
    return <Redirect to="/signin" />;
  }

  return (
    <S.Wrapper>
      <S.Container>
        <S.LogoContainer>
          <Logo />
        </S.LogoContainer>
        <Form
          onFinish={onFinish}
          form={form}
          validateMessages={{
            required: errorText.required,
            string: {
              min: t('auth.Длина должна быть больше или равна') + ' ${min}',
            },
          }}
        >
          <S.FormItem
            name="email"
            rules={[
              {
                required: true,
                pattern: EMAIL_ADDRESS_REGEXP,
                message: errorText.email,
              },
            ]}
          >
            <Input
              prefix={<MailOutlined />}
              placeholder={t('auth.Электронная почта')}
            />
          </S.FormItem>
          <S.FormItem
            name={PASSWORD}
            rules={[
              {
                required: true,
                min: 8,
              },
              () => checkPasswordValidationRules(),
            ]}
          >
            <Input.Password
              onChange={changePasswordHandler}
              prefix={<LockOutlined />}
              type="password"
              placeholder={t('auth.Пароль')}
            />
          </S.FormItem>
          <S.FormItem
            name={CONFIRM}
            dependencies={[PASSWORD]}
            rules={[
              {
                required: true,
              },
              ({ getFieldValue }) => passCompare(getFieldValue),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder={t('auth.Подтвердить пароль')}
            />
          </S.FormItem>
          <S.FormItem>
            <Button
              block
              type="primary"
              htmlType="submit"
            >
              {t('auth.Сохранить')}
            </Button>
          </S.FormItem>
        </Form>
      </S.Container>
    </S.Wrapper>
  );
}
