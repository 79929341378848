/* eslint-disable no-console */
import store from '../store/index';
import history from '../store/history';
import axios from 'axios';
import { isPWA } from 'utils/isPWA';
export const actionTriggering = async (Action, context = {}) => {
  try {
    await axios.post('https://webanalitics.krastsvetmet.ru/api/events/cpa', {
      Action,
      ...context,
      ShadowMode: isShadowMode(),
      ...getPageInformation(),
      ...getConnection(),
      ...getUserData(),
      ...getDeviceViewport(),
      ...getDisplayMode(),
    });
  } catch (error) {
    console.log(error);
  }
};

export const getPageInformation = () => {
  try {
    return {
      PageURL: window.location.href,
      HttpReferer: document.referrer,
      ...getPageLocation(),
    };
  } catch (error) {
    return catchError(error);
  }
};

export const getPageLocation = () => {
  try {
    const { pathname: PagePathName, search: PageSearchQuery } = history.location;
    return { PagePathName, PageSearchQuery };
  } catch (error) {
    return catchError(error);
  }
};

export const getConnection = () => {
  try {
    const { effectiveType: ConnectionType, rtt: ConnectionRTT } =
      navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    return { ConnectionType, ConnectionRTT };
  } catch (error) {
    return catchError(error);
  }
};

export const getUserData = () => {
  try {
    const data = store.getState();
    const { user, company } = data?.auth?.init || {};

    const { notificationCount: NotificationsCount } = data.layout;

    return {
      CompanyName: company?.name,
      UserName: `${user?.name} ${user?.surname}`,
      NotificationsCount,
      ClientId: user?.id,
      UserRoles: user?.roles?.map(({ code }) => code),
      CompanyTypes: company?.roles?.map(({ id }) => id),
      CompanyTypeCode: company?.roles?.map(({ code }) => code),
      CompanyId: company?.id,
    };
  } catch (error) {
    return catchError(error);
  }
};

export const getDeviceViewport = () => {
  try {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    return {
      ScreenWidth: width,
      ScreenHeight: height,
    };
  } catch (error) {
    return catchError(error);
  }
};

export const isShadowMode = () => {
  return Boolean(localStorage.getItem('AD_JWT_ACCESS'));
};

const PWA_DISPLAY_MODE = 'PWA';
const BROWSER_DISPLAY_MODE = 'browser';

export const getDisplayMode = () => {
  try {
    if (isPWA()) {
      return { DisplayMode: PWA_DISPLAY_MODE };
    }
    return { DisplayMode: BROWSER_DISPLAY_MODE };
  } catch (error) {
    return catchError(error);
  }
};

const catchError = (error) => {
  console.log(error);
  return {};
};

// Date
// DateTime
// IP
// Browser
// BrowserVersion
// Platform

// ConnectionRTT
// ConnectionType
// ScreenWidth
// ScreenHeight
// ShadowMode
// Action
// ActionTargetId
// ActionTargetName
// ActionTargetType
// HttpReferer
// PageURL
// PagePathName
// PageSearchQuery
// UserName
// CompanyName
// NotificationsCount

// ClientId
// UserRole
// CompanyId
// CompanyType

// TestId
// TestValue
// SessionId
// SessionTime

// DateStart
// DateEnd
