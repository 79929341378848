import { createAsyncThunk } from '@reduxjs/toolkit';
import { CodesService } from './service';
import * as antd from 'antd';
import { INITIAL_STATE } from './slice';
import { clone } from 'ramda';
import { normalizeErrors, getTotalErrors } from '../utils';

export const generateCode = createAsyncThunk('invoices/codes/generateCode', async (payload) => {
  try {
    const { data, success } = await CodesService.generateCode(payload);
    const result = clone(INITIAL_STATE);
    if (success) {
      result.generatedFile.name = data.generatedFile.name;
      result.generatedFile.url = window.URL.createObjectURL(data.generatedFile.blob);
    } else {
      result.errors.data = normalizeErrors(data);
      result.errors.total = getTotalErrors(data);
    }
    return result;
  } catch (err) {
    antd.message.error(err.message);
    throw err;
  }
});
