import { useState, useCallback, useMemo, useEffect } from 'react';
import { Form } from 'antd';
import { SurveyModalFormSchema } from '../models';
import { useSurvey } from 'ducks/satisfaction/survey/hooks/useSurvey';
import { useSendReaction } from 'ducks/satisfaction/survey/hooks/useSendReaction';
import { SurveyReactionParamsSchema } from 'models/params/survey/reaction';
import { SurveyCloseParamsSchema } from 'models/params/survey/close';
import { useCloseSurvey } from 'ducks/satisfaction/survey/hooks/useCloseSurvey';
import { useRemindLater } from 'ducks/satisfaction/survey/hooks/useRemindLater';
import { getSelectedTagsInStar, getTagsByStar } from '../utils';
import { showMessage } from 'utils/showMessage';
import { CHARACTERS_LENGTH_TO_DISPLAY_COUNT } from '../consts';
import { StarEntity } from 'models/api/satisfaction/survey';

type SurveyModalConfig = { skip?: boolean; onCancel: () => void };

export function useSurveyModal({ skip, onCancel }: SurveyModalConfig) {
  const [form] = Form.useForm<SurveyModalFormSchema>();
  const [star, setStar] = useState<StarEntity | undefined>(undefined);
  const [isCountVisible, setCountVisible] = useState(false);
  const { data } = useSurvey(skip);
  const { sendReaction, isLoading: isSendLoading, isSuccess: isSendSuccess, error: reactionError } = useSendReaction();
  const { close } = useCloseSurvey();
  const { remindLater } = useRemindLater();

  const tagsByStar = useMemo(() => getTagsByStar(data?.tags, star), [data, star]);

  const sendReactionHandler = useCallback(
    (params: SurveyReactionParamsSchema) => {
      sendReaction(params);
    },
    [sendReaction]
  );

  useEffect(() => {
    if (reactionError) {
      showMessage(reactionError.message ?? '', 'error');
    }
  }, [reactionError]);

  const closeHandler = useCallback(() => {
    const formValues = form.getFieldsValue(['starId', 'tags', 'comment']);
    const parsed = SurveyModalFormSchema.safeParse(formValues);

    if (data) {
      let payload: SurveyCloseParamsSchema = {
        surveyId: data.id,
      };

      if (parsed.success) {
        payload = {
          ...payload,
          ...parsed.data,
          tags: parsed.data.tags ? getSelectedTagsInStar(tagsByStar, parsed.data.tags) : undefined,
        };
      }

      close(payload);
    }

    onCancel();
  }, [form, data, tagsByStar, close, onCancel]);

  const remindLaterHandler = useCallback(
    (id: number) => {
      remindLater(id);
      onCancel();
    },
    [remindLater, onCancel]
  );

  const onFormChange = useCallback(
    (allValues: unknown) => {
      const parsed = SurveyModalFormSchema.safeParse(allValues);

      if (parsed.success && data) {
        const dataById = data.stars.find(({ id }) => id === parsed.data.starId);

        setStar({
          id: parsed.data.starId,
          title: dataById?.title || '',
          tags: dataById?.tags || [],
        });
        setCountVisible(!!parsed.data.comment && parsed.data.comment.length >= CHARACTERS_LENGTH_TO_DISPLAY_COUNT);
      }
    },
    [data]
  );

  const onFormSubmit = useCallback(
    (values: unknown) => {
      const parsed = SurveyModalFormSchema.safeParse(values);

      if (parsed.success && data) {
        const selectedTagsInStar = parsed.data.tags ? getSelectedTagsInStar(tagsByStar, parsed.data.tags) : undefined;

        sendReactionHandler({
          surveyId: data.id,
          ...parsed.data,
          tags: selectedTagsInStar,
        });
      }
    },
    [data, tagsByStar, sendReactionHandler]
  );

  return {
    data,
    form,
    isSendSuccess,
    star,
    tagsByStar,
    isSendLoading,
    isCountVisible,
    onRemindLater: remindLaterHandler,
    onClose: closeHandler,
    onFormChange,
    onFormSubmit,
  };
}
