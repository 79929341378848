import { useCallback, useState } from 'react';
import { useScreen } from 'hooks/useScreen';
import { MAX_VISIBLE_TAGS_MOBILE } from '../consts';
import { TagEntity } from 'models/api/satisfaction/survey';
import { FormInstance } from 'antd';
import { isEmpty } from 'lodash';
import { SurveyModalFormSchema } from '../models';

type CheckableTagsConfig = {
  form: FormInstance<SurveyModalFormSchema>;
  tags: TagEntity[];
};

export function useCheckableTags({ form, tags }: CheckableTagsConfig) {
  const { isSmallScreen } = useScreen();
  const [selectedTags, setSelectedTags] = useState<TagEntity[]>([]);
  const [isShowMore, setShowMore] = useState(!isSmallScreen);
  const visibleTags = isShowMore ? tags : tags.slice(0, MAX_VISIBLE_TAGS_MOBILE);

  const changeHandler = useCallback(
    (tag: TagEntity, checked: boolean) => {
      const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t.id !== tag.id);
      setSelectedTags(nextSelectedTags);
      form.setFieldsValue({ tags: !isEmpty(nextSelectedTags) ? nextSelectedTags.map(({ id }) => id) : undefined });
    },
    [selectedTags, form]
  );

  return {
    tags: visibleTags,
    selectedTags,
    onChange: changeHandler,
    isShowMore,
    onShowMore: () => setShowMore(true),
  };
}
