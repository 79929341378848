import styled from 'styled-components';
import userImage from 'features/Login/LoginForm/images/login@2x.png';
import passwordImage from 'features/Login/LoginForm/images/pass@2x.png';

export const Field = styled.div<{ variant: 'username' | 'password' }>`
  position: relative;
  width: 100%;
  box-sizing: border-box;

  &&& .ant-input-password {
    padding: 0;
    border: none;
    box-shadow: none;
    .ant-input-suffix {
      margin: 0;
      position: absolute;
      right: ${({ theme }) => theme.space[12]};
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &&& .ant-input {
    background-color: ${({ theme }) => theme.colors['grey-20']};
    height: ${({ theme }) => theme.sizes[42]};
    border-radius: 3px;
    border: none;
    box-shadow: none;
    padding: ${({ theme }) => theme.space[12]} ${({ theme }) => theme.space[36]} ${({ theme }) => theme.space[12]} 45px;
    font-size: ${({ theme }) => theme.fontSizes[14]};
    line-height: ${({ theme }) => theme.fontSizes[18]};
  }

  &:before {
    content: '';
    width: ${({ theme }) => theme.sizes[20]};
    height: ${({ theme }) => theme.sizes[20]};
    background-size: contain;
    background-position: 50%;
    position: absolute;
    z-index: 2;
    top: ${({ theme }) => theme.space[10]};
    left: 11px;
    background-image: url(${({ variant }) => (variant === 'username' ? userImage : passwordImage)});
  }
`;
