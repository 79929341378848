import React from 'react';
import { Button } from 'components/ui/Button';
import keyIcon from 'icons/key.svg';
import { useTranslation } from 'react-i18next';
import { useOAuth } from 'features/Login/SSO/hooks/useOAuth';
import { useAuth } from 'ducks/auth/hooks/useAuth';

export function SSO({ initApp }: Pick<ReturnType<typeof useAuth>, 'initApp'>) {
  const { t } = useTranslation();
  const { isLoading, onClick } = useOAuth({ initApp });

  return (
    <React.Fragment>
      <Button
        type="button"
        variant="outlined"
        width="100%"
        icon={keyIcon}
        onClick={onClick}
        loading={isLoading}
        disabled={isLoading}
      >
        {t('auth.Войти с помощью SSO')}
      </Button>
    </React.Fragment>
  );
}
