import { icons } from 'features/sidebar/Icons';
import {
  ADMIN_EARLY_SHIPMENT_PATH,
  EARLY_SHIPMENT_PATH,
  REPORTS_PATH,
  SUPPORT_TICKETS_PATH,
} from 'constants/routePaths';
import { ADMIN_SUPPORT_PATH } from 'constants/routePaths';
import { PermissionsEnum } from 'constants/permissions';
import { SidebarCodesEnum } from 'constants/sidebarCodes';

export const mockData = {
  public: {
    mainNav: {
      frequently: [
        {
          permission: 'balance',
          name: 'Баланс металла',
          code: SidebarCodesEnum.enum.balance,
          link: '/balance',
        },
        {
          permission: 'finances',
          name: 'Отчеты',
          code: 'finances',
          link: REPORTS_PATH,
        },
        {
          //TODO поменять пермишен
          permission: 'balance',
          name: 'Мировые цены',
          code: SidebarCodesEnum.enum.prices,
          link: '/prices',
        },
        {
          permission: 'search.documents',
          name: 'Поиск документов',
          code: SidebarCodesEnum.enum.other,
          link: '/documents-search',
        },
        // { permission: 'reports.agent', name: 'Прочие услуги', code: 'other', link: '/other' },
        {
          permission: 'consignee',
          name: 'Поставки',
          code: SidebarCodesEnum.enum.shipment,
          link: '/shipments',
          // count: 3,
          hideOnMobile: true,
        },
        {
          permission: 'dashboard',
          name: 'Аналитика',
          code: SidebarCodesEnum.enum.dashboard,
          link: '/dashboard',
          // count: 101,
          hideOnMobile: true,
        },
        {
          permission: PermissionsEnum.Orders,
          name: 'Технические изделия',
          code: SidebarCodesEnum.enum.orders,
          link: '/orders',
        },
        {
          permission: 'balance.remains',
          name: 'Остатки металлов',
          code: SidebarCodesEnum.enum.remains,
          link: '/remains',
        },
        {
          permission: 'early.shipment',
          name: 'Досрочная отгрузка',
          code: SidebarCodesEnum.enum['early-shipment'],
          link: EARLY_SHIPMENT_PATH,
        },
      ],
      rarely: [
        {
          permission: 'inventory',
          name: 'Опись на поставку',
          code: SidebarCodesEnum.enum.inventory,
          link: '/inventory',
          hideOnMobile: true,
          extraIcon: icons.qr,
        },
        // { permission: 'fixation', name: 'Онлайн-фиксация', code: 'fixation', link: '/fixation' },
        // { permission: 'proposal', name: 'План поставок', code: 'proposal', link: '/proposal' },
      ],
      metalSale: [
        {
          permission: 'public',
          name: 'Продажа металла',
          code: SidebarCodesEnum.enum['metal-sale'],
          link: '/metal-sale',
          hideOnMobile: true,
        },
        {
          permission: 'public',
          name: 'Покупка металла',
          code: SidebarCodesEnum.enum['metal-purchase'],
          link: '/metal-purchase',
          hideOnMobile: true,
        },
      ],
    },
    footerNav: [
      {
        permission: 'support',
        name: 'Задать вопрос',
        code: SidebarCodesEnum.enum.support,
        link: SUPPORT_TICKETS_PATH,
        // count: 13
      },
      {
        permission: 'managers',
        name: 'Мой менеджер',
        code: SidebarCodesEnum.enum.managers,
        link: '/managers',
      },
      {
        permission: 'documents',
        name: 'Прочие документы',
        code: SidebarCodesEnum.enum.documents,
        link: '/documents',
      },
      {
        permission: 'lbma',
        name: 'Анкета поставщика',
        code: SidebarCodesEnum.enum.questionnaire,
        link: '/questionnaire',
      },
    ],
    userMenu: [
      {
        permission: 'public',
        name: 'Мой профиль',
        code: SidebarCodesEnum.enum.user,
        link: '/profile',
      },
      {
        permission: 'public',
        name: 'Выйти',
        code: SidebarCodesEnum.enum.exit,
        link: '/exit',
      },
    ],
  },
  admin: {
    mainNav: {
      frequently: [
        {
          permission: 'admin',
          name: 'Список пользователей',
          code: SidebarCodesEnum.enum.users,
          link: '/admin/users',
        },
        {
          permission: 'admin',
          name: 'Список компаний',
          code: SidebarCodesEnum.enum.company,
          link: '/admin/companies',
        },
        {
          permission: 'admin',
          name: 'Список договоров',
          code: SidebarCodesEnum.enum.other,
          link: '/admin/contracts',
        },
        {
          permission: 'admin',
          name: 'Обратная связь',
          code: SidebarCodesEnum.enum.support,
          link: ADMIN_SUPPORT_PATH,
        },
        {
          permission: 'admin',
          name: 'Досрочная отгрузка',
          code: SidebarCodesEnum.enum['early-shipment'],
          link: ADMIN_EARLY_SHIPMENT_PATH,
        },
        // {permission: 'admin', name: 'План поставок', code: 'proposal', link: '/admin/proposal'},
      ],
    },
    userMenu: [
      {
        permission: 'public',
        name: 'Мой профиль',
        code: SidebarCodesEnum.enum.user,
        link: '/admin/profile',
      },
      {
        permission: 'public',
        name: 'Выйти',
        code: SidebarCodesEnum.enum.exit,
        link: '/exit',
      },
    ],
  },
};
