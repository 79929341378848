import { RootState, useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { getLayotData } from 'actions/auth';

export function useAuth() {
  const dispatch = useAppDispatch();
  const authState = useSelector(({ auth }: RootState) => auth);

  const initApp = useCallback(() => {
    dispatch(getLayotData());
  }, [dispatch]);

  return {
    initApp,
    authState: {
      ...authState,
      init: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...((authState as any).init as Record<string, any>),
      },
    },
  };
}
