import { z } from 'zod';

export const ResponseEntity = z.object({
  success: z.boolean(),
  code: z.number(),
  message: z.string(),
  data: z.any(),
});
export type ResponseEntity = z.infer<typeof ResponseEntity>;
export const isResponseEntity = (data: unknown): data is ResponseEntity => ResponseEntity.safeParse(data).success;
