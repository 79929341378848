import types from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case types.GET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: action.payload.list,
        paginate: action.payload.paginate,
      };
    }
    default:
      return state;
  }
}
