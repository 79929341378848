import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './App.less';
import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import 'moment/locale/ru';
import 'url-search-params-polyfill';
import './fonts/Roboto/roboto.scss';
import 'loaders.css/loaders.min.css';
import './common.scss';
import './style.scss';
import store from './store';
import { getLayotData, refreshToken } from 'actions/auth';
import { JWT_ACCESS } from './actions/connect';
import Root from './components/Root';
import { parseQueryStr } from './components/filters/getDateParams';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import GlobalLoader from 'components/GlobalLoader';
import './i18n';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { FeatureFlagsProvider } from 'providers/FeatureFlagsProvider';

const jwt_access = localStorage.getItem(JWT_ACCESS);
const search = window.location.search;
const local = parseQueryStr(search.slice(1));

if (jwt_access) {
  store.dispatch(getLayotData(null, local.company));
} else {
  store.dispatch(refreshToken());
}

render(
  <FeatureFlagsProvider>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Suspense fallback={<GlobalLoader />}>
          <Root />
        </Suspense>
      </Provider>
    </ThemeProvider>
  </FeatureFlagsProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
