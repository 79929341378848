import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { useCallback, useEffect } from 'react';
import { useRestorePassword } from 'ducks/auth/hooks/useRestorePassword';
import { loginAnalytics } from 'features/Login/loginAnalytics';
import { RestorePasswordErrorsSchema, RestorePasswordSchema } from 'features/Login/models';
import { setFormFieldsErrors } from 'utils/setFormFieldsErrors';

export function useRestorePasswordForm() {
  const { i18n } = useTranslation();
  const { language } = i18n;

  const [form] = Form.useForm();

  const { restorePassword, isSuccess, isLoading, originalArgs, error } = useRestorePassword();

  const onSubmit = useCallback(
    (values: unknown) => {
      const result = RestorePasswordSchema.safeParse(values);

      if (result.success) {
        restorePassword({ email: result.data.email || '', language });
      }
    },
    [language, restorePassword]
  );

  useEffect(() => {
    if (isSuccess && originalArgs) {
      loginAnalytics.successRestore(originalArgs.email);
    }
  }, [isSuccess, originalArgs]);

  useEffect(() => {
    if (error && originalArgs) {
      loginAnalytics.errorRestore(originalArgs.email);
      setFormFieldsErrors({ error, form, errorSchema: RestorePasswordErrorsSchema });
    }
  }, [form, error, originalArgs]);

  return {
    form,
    isSubmitting: isLoading,
    isSuccess,
    onSubmit,
    error,
  };
}
