import { z } from 'zod';
import { createListEntity } from './list';

export const CompanyEntity = z.object({
  id: z.number(),
  typeLegalId: z.number().or(z.null()),
  typeLegalDesc: z.string().or(z.null()),
  name: z.string().or(z.null()),
  inn: z.string().or(z.null()),
  kpp: z.string().or(z.null()),
  address: z.string().or(z.null()),
  createdAt: z.string().or(z.null()),
  updatedAt: z.string().or(z.null()),
  deletedAt: z.string().or(z.null()),
  isEnCompany: z.boolean().or(z.null()),
});

export type CompanyEntity = z.infer<typeof CompanyEntity>;

export const CompaniesListEntity = createListEntity(CompanyEntity);
export type CompaniesListEntity = z.infer<typeof CompaniesListEntity>;
