import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MessageEntity } from 'models/api/support/message';
import { addLoadingMatchers } from 'store/utils/addLoadingMatchers';
import { CREATE_ADMIN_MESSAGES, GET_ADMIN_MESSAGES, getAdminMessages } from '../actions/adminMessagesActions';
import { getCursorValue } from 'utils/getCursorValue';
import { isNull } from 'lodash-es';
import { RootState } from 'store';
import { sortByDate } from 'utils/sortByDate';
import { ReadMessagesEventBody } from 'models/api/support/supportEvents';

type AdminMessagesState = {
  adminTicketId: number | null;
  pagination: { left: number; cursor: string; limit: number } | null;
  isLoading: boolean;
  isAdminMessageCreating: boolean;
};

const INITIAL_STATE: AdminMessagesState = {
  adminTicketId: null,
  pagination: null,
  isLoading: false,
  isAdminMessageCreating: false,
};

const adminMessagesListAdapter = createEntityAdapter<MessageEntity>({
  sortComparer: (a, b) => sortByDate('asc')(a.createdAt, b.createdAt),
});

export const adminMessagesListSelectors = adminMessagesListAdapter.getSelectors(
  (state: RootState) => state.adminMessages
);

const adminMessagesSlice = createSlice({
  name: 'adminMessages',
  initialState: adminMessagesListAdapter.getInitialState<AdminMessagesState>(INITIAL_STATE),
  reducers: {
    resetAdminMessages: (state) => {
      adminMessagesListAdapter.removeAll(state);
      state.pagination = INITIAL_STATE.pagination;
      state.isLoading = INITIAL_STATE.isLoading;
      state.isAdminMessageCreating = INITIAL_STATE.isAdminMessageCreating;
      state.adminTicketId = INITIAL_STATE.adminTicketId;
    },
    addAdminMessage: (state, { payload }: PayloadAction<MessageEntity>) => {
      if (isNull(state.adminTicketId) || payload.ticketId !== state.adminTicketId) {
        return;
      }

      adminMessagesListAdapter.addOne(state, payload);
    },
    updateAdminMessagesReadAt: (state, { payload }: PayloadAction<ReadMessagesEventBody>) => {
      if (isNull(state.adminTicketId) || payload.ticketId !== state.adminTicketId) {
        return;
      }

      adminMessagesListAdapter.updateMany(
        state,
        payload.readMessagesIds.map((id) => ({ id, changes: { readAt: payload.readAt } }))
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdminMessages.fulfilled, (state, { payload, meta }) => {
      if (!isNull(state.adminTicketId) && meta.arg.ticketId !== state.adminTicketId) {
        return;
      }

      state.adminTicketId = meta.arg.ticketId;

      if (meta.arg.cursor) {
        adminMessagesListAdapter.addMany(state, payload.list);
      } else {
        adminMessagesListAdapter.setAll(state, payload.list);
      }

      state.pagination = {
        limit: payload.pagination.perPage,
        cursor: getCursorValue(payload.pagination.nextPageUrl),
        left: payload.leftMessages,
      };
    });
    addLoadingMatchers(builder, GET_ADMIN_MESSAGES, 'isLoading');
    addLoadingMatchers(builder, CREATE_ADMIN_MESSAGES, 'isAdminMessageCreating');
  },
});

export const { name, actions, reducer } = adminMessagesSlice;
