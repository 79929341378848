import { asyncPipe } from 'utils/asyncPipe';
import { getDataFromResponse } from 'utils/getDataFromResponse';
import { apiGet } from 'services/helper';
import { SuppliesApi } from './service.types';
import { skipEmptyValues } from 'utils/skipEmptyValues';

class SuppliesServiceFactory {
  getSupplies = (params: SuppliesApi.getSupplies.Request.Params = {}) => {
    return asyncPipe(apiGet, getDataFromResponse)<SuppliesApi.getSupplies.Response.Success>(
      '/v1/supplies',
      skipEmptyValues(params)
    );
  };

  getSupply = (supplyId: number) => {
    return asyncPipe(apiGet, getDataFromResponse)<SuppliesApi.getSupply.Response.Success>(`/v1/supplies/${supplyId}`);
  };
}

export const SuppliesService = new SuppliesServiceFactory();
