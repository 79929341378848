import styled from 'styled-components';
import { Input as AntdInput } from 'antd';

export const Wrapper = styled.div`
  position: fixed;
  left: 280px;
  top: 0;
  z-index: 9999;
  height: 100%;
  width: 277px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  background-color: #383838;
  /* @media (max-width: 1366px) {
        left: 60px;
    } */

  @media (max-width: 850px) {
    width: 100%;
    left: 0;
    top: 0;
    height: 100vh;
  }
`;

export const Header = styled.div`
  margin: 0 20px;
`;

export const Input = styled(AntdInput)`
  &&& {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 19px;
    border: 1px solid #bebebe;
    border-radius: 3px;
  }
`;

export const Title = styled.div`
  margin: 32px 0;
  color: #fff;
  font-size: 16px;
  line-height: 19px;
`;

export const CurrentCompany = styled.div`
  margin-bottom: 24px;
  padding: 12px;
  background-color: #797979;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  border-radius: 3px;
`;

export const CompanyItem = styled.div`
  padding: 12px 20px;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  :hover {
    cursor: pointer;
    background-color: #4f4f4f;
  }
  :focus {
    box-shadow: 0px 8px 44px rgba(29, 29, 29, 0.15);
  }
  :active {
    background-color: #1d1d1d;
  }
`;
