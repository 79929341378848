import styled, { DefaultTheme } from 'styled-components';
import ReactSVG from 'react-inlinesvg';
import { ResponsiveValue, SizeVariants } from './types';
import { SvgIconProps } from './SvgIcon';
import { isEmpty, isNumber, isObject } from 'lodash-es';

const getSizesByVariants = (variant: SizeVariants) => {
  const sizes = {
    small: {
      sm: {
        width: 12,
        height: 12,
      },
      md: {
        width: 14,
        height: 14,
      },
      lg: {
        width: 14,
        height: 14,
      },
    },
    medium: {
      sm: {
        width: 14,
        height: 14,
      },
      md: {
        width: 16,
        height: 16,
      },
      lg: {
        width: 16,
        height: 16,
      },
    },
    large: {
      sm: {
        width: 16,
        height: 16,
      },
      md: {
        width: 20,
        height: 20,
      },
      lg: {
        width: 20,
        height: 20,
      },
    },
  };

  return sizes[variant];
};

const normalizeValue = (value: ResponsiveValue) => {
  if (isObject(value)) {
    return {
      width: isNumber(value.width) ? `${value.width}px` : value.width,
      height: isNumber(value.height) ? `${value.height}px` : value.height,
    };
  }
  if (isNumber(value)) {
    return {
      width: `${value}px`,
      height: `${value}px`,
    };
  }
  return {
    width: value,
    height: value,
  };
};

const sortResponsive = (responsiveKeys: (keyof DefaultTheme['breakpoints'])[], theme: DefaultTheme) => {
  const sorted = responsiveKeys.sort((a, b) => theme.breakpoints[b] - theme.breakpoints[a]);

  return sorted;
};

export const SVG = styled(ReactSVG)<Partial<SvgIconProps>>`
  ${({ theme, size, responsive }) => {
    const responsiveMap =
      responsive && !isEmpty(responsive)
        ? sortResponsive(Object.keys(responsive) as Array<keyof typeof responsive>, theme)
            .map((breakpoint) => {
              return `
                @media screen and (max-width: ${theme.breakpoints[breakpoint] - 1}px) {
                    width: ${normalizeValue(responsive[breakpoint]).width};
                    height: ${normalizeValue(responsive[breakpoint]).height};
                }
                `;
            })
            .join(' ')
        : '';

    const sizes = size
      ? `
            width: ${getSizesByVariants(size).lg.width}px;
            height: ${getSizesByVariants(size).lg.height}px;
  
            @media screen and (max-width: ${theme.breakpoints.lg - 1}px) {
                width: ${getSizesByVariants(size).md.width}px;
                height: ${getSizesByVariants(size).md.height}px;
            }
            @media screen and (max-width: ${theme.breakpoints.md - 1}px) {
                width: ${getSizesByVariants(size).sm.width}px;
                height: ${getSizesByVariants(size).sm.height}px;
            }  
        `
      : '';

    return `${sizes}${responsiveMap}`;
  }}
`;
