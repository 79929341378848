/**
 * Конфиг для активации фич на проекте.
 * Если фича будет активна в зависимости от окружения, воспользуйтесь утилитами.
 *
 * @example
 * {
 *   news: false,
 *   suppliesV2: isActiveStage(1),
 *   oAuth: isDevEnv(),
 *   qaSelectors: !isProduction(),
 *   techOrders: { filtersV2: false }
 * }
 *
 * Перезапустите сборку, если изменили флаг.
 */
export const featureFlagConfig = {} as const;
