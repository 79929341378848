import { BRAND, z } from 'zod';
import { HttpErrorCodesEnum } from './types';
import { createHttpError, HttpErrorSchema } from './httpError';
import i18n from 'i18next';
import { isError } from './error';

export const unauthorizedErrorSchema = z
  .object({
    code: z.literal(HttpErrorCodesEnum.Unauthorized),
  })
  .merge(HttpErrorSchema)
  .brand<'Unauthorized'>();

export type UnauthorizedError = z.infer<typeof unauthorizedErrorSchema>;

export function createUnauthorizedError(message?: string, data?: unknown): UnauthorizedError {
  return {
    ...createHttpError(
      HttpErrorCodesEnum.Unauthorized,
      message || i18n.t('error.Ошибка авторизации'),
      data,
      undefined,
      !!message
    ),
    [BRAND]: {
      Unauthorized: true,
    },
  };
}

export const isUnauthorizedError = isError(unauthorizedErrorSchema);
