import styled from 'styled-components';
import { Form as InnerForm } from 'antd';
import { Button } from 'components/ui/Button';

export const LoginForm = styled(InnerForm)`
  &&& .ant-form-item {
    margin: 0;
  }
`;

export const FieldsList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.space[18]};
`;

export const SubmitButton = styled(Button)`
  margin-top: ${({ theme }) => theme.space[16]};
`;

export const ForgetPassword = styled.div`
  cursor: pointer;
  display: inline-flex;
  color: ${({ theme }) => theme.colors['grey-5']};
  font-size: ${({ theme }) => theme.fontSizes[12]};
  line-height: ${({ theme }) => theme.lineHeights[14]};
  margin-top: ${({ theme }) => theme.space[8]};
  padding-bottom: ${({ theme }) => theme.space[4]};
  border-bottom: 1px dashed ${({ theme }) => theme.colors['grey-1']};
  transition: all 0.15s;

  &:hover {
    opacity: 0.8;
  }
`;
