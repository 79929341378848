/* eslint-disable no-console */
import axios from 'axios';
import types from './types';
import download from 'downloadjs';
import { actionTriggering } from 'analytics';
import { accesses } from '../components/Access/index';
import { basicParams, ROOT_URL, JWT_ACCESS, JWT_REFRESH, AD_JWT_ACCESS } from './connect';
import { checkError } from 'actions/errorText';
import { apiGet } from 'services/helper';
import { batch } from 'react-redux';
import { supportWsApi } from 'services/ws/supportWsApi';
import { message } from 'antd';
import { STUB_DOWNLOAD_DOCS_TITLE } from 'components/StubDownloadDocs';
import { isIOS } from 'utils/isIOS';
import { actions as supportWsConnectionActions } from 'ducks/support/wsConnection/slices/supportWsConnectionSlice';

export function signoutUser() {
  return (dispatch) => {
    dispatch({ type: types.SHOW_LOADER });
    dispatch({ type: types.HIDE_SIDEBAR });
    supportWsApi.disconnect();

    axios
      .get(`${ROOT_URL}/v1/logout`, {
        headers: {
          Authorization: `Bearer  ${localStorage.getItem(JWT_ACCESS)}`,
          credentials: 'include',
        },
      })
      .then(() => {
        actionTriggering('logout');
        clearInterval(notificationTimer);
        if (localStorage.getItem(AD_JWT_ACCESS)) {
          localStorage.setItem(JWT_ACCESS, localStorage.getItem(AD_JWT_ACCESS));
          localStorage.removeItem(AD_JWT_ACCESS);
          window.location.href = '/';
        } else {
          localStorage.removeItem(JWT_ACCESS);
          localStorage.removeItem(JWT_REFRESH);

          dispatch({ type: types.HIDE_LOADER });
          dispatch({ type: types.UNAUTH_USER });
        }
      })
      .catch(function () {
        localStorage.removeItem(JWT_ACCESS);
        localStorage.removeItem(JWT_REFRESH);

        dispatch({ type: types.HIDE_LOADER });
        dispatch({ type: types.UNAUTH_USER });
      });
  };
}

export function getLayotData(showLoader) {
  return async (dispatch) => {
    !showLoader && dispatch({ type: types.SHOW_LOADER });
    try {
      const { data: response } = await apiGet('/v1/init');

      const balanceAccesses = [];

      const balanceSections = [
        {
          permission: accesses.BALANCE_SUPPLY,
          section: {
            id: '/balance/supplies',
            value: 'supply',
            label: 'Сырье',
          },
        },
        {
          permission: accesses.BALANCE_RELEASE,
          section: {
            id: '/balance/release',
            value: 'release',
            label: 'Выпуск',
          },
        },
        {
          permission: accesses.BALANCE_SHIPMENT,
          section: {
            id: '/balance/shipment',
            value: 'shipment',
            label: 'Отгрузка',
          },
        },
      ];

      balanceSections.forEach(({ permission, section }) => {
        if (response.data.permissions.includes(permission)) {
          balanceAccesses.push(section);
        }
      });

      batch(() => {
        dispatch({
          type: 'INIT_APP',
          payload: response.data,
          balanceAccesses,
        });

        dispatch({
          type: types.SAVE_ACCESS,
          payload: response.data.permissions,
        });

        dispatch({ type: types.HIDE_LOADER });
      });

      initUpdateNotificationCount(dispatch);
      !showLoader && actionTriggering('enterpoint');

      supportWsApi.connect((status) => dispatch(supportWsConnectionActions.setStatus(status)));
    } catch (error) {
      console.log(error);
      dispatch(refreshToken());
    }
  };
}

let notificationTimer = null;

export const initUpdateNotificationCount = (dispatch, restart) => {
  if (notificationTimer === null || restart) {
    getNotificationCount(dispatch);
  }

  clearInterval(notificationTimer);

  notificationTimer = setInterval(() => {
    getNotificationCount(dispatch);
  }, 30000);
};

export const getNotificationCount = (dispatch) => {
  axios
    .get(`${ROOT_URL}/company/notifications/status`, {
      headers: {
        Authorization: `Bearer  ${localStorage.getItem(JWT_ACCESS)}`,
        credentials: 'include',
      },
    })
    .then((response) => {
      dispatch({
        type: types.GET_NOTIFICATIONS_COUNT,
        payload: response.data.data,
      });
    })
    .catch((error) => {
      console.log('Notifications: ', error);
    });
};

let isRefreshing = false;
export const refreshToken = () => (dispatch) => {
  const refresh = localStorage.getItem(JWT_REFRESH);

  if (isRefreshing) {
    return;
  }

  supportWsApi.disconnect();
  if (refresh) {
    dispatch({ type: types.SHOW_LOADER });
    isRefreshing = true;

    return axios
      .post(`${ROOT_URL}/v1/refresh`, {
        ...{
          grantType: 'refresh_token',
          refreshToken: refresh,
        },
        ...basicParams,
      })
      .then((response) => {
        localStorage.setItem(JWT_ACCESS, response.data.data.accessToken);
        localStorage.setItem(JWT_REFRESH, response.data.data.refreshToken);
        dispatch({ type: types.AUTH_USER });
        dispatch(getLayotData());
      })
      .catch((e) => {
        if (e?.response?.status === 401) {
          localStorage.removeItem(JWT_ACCESS);
          localStorage.removeItem(JWT_REFRESH);
          dispatch({ type: types.UNAUTH_USER });
        }
        dispatch({ type: types.HIDE_LOADER });
      })
      .finally(() => {
        isRefreshing = false;
      });
  } else {
    localStorage.removeItem(JWT_ACCESS);
    localStorage.removeItem(JWT_REFRESH);
    dispatch({ type: types.HIDE_LOADER });
    dispatch({ type: types.UNAUTH_USER });
  }
};

export const getFile = (link, preview) => {
  return (dispatch) => {
    if (isIOS()) {
      message.warn(STUB_DOWNLOAD_DOCS_TITLE);
      return '';
    } else {
      return axios
        .get(`${ROOT_URL}${link}`, {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem(JWT_ACCESS)}`,
            credentials: 'include',
          },
        })
        .then((response) => {
          if (!preview) {
            download(response.data.data.data, response.data.data.name);
          } else {
            dispatch({
              type: types.SAVE_IMAGE_PREVIEW,
              payload: response.data.data.data,
            });
          }
          return response.data.data.name;
        })
        .catch((error) => {
          checkError(error, dispatch, 'files', 'download');
        });
    }
  };
};
