import { createSlice } from '@reduxjs/toolkit';
import {
  fetchUsers,
  updateAvatar,
  updatePassword,
  updateUsers,
  createUser,
  attachRole,
  detachRole,
  updateLocale,
} from './actions';
import { message } from 'antd';

const profile = createSlice({
  name: 'profile',
  initialState: {
    user: null,
    meta: null,
    loading: false,
    loadingUpdate: false,
    loadingPassword: false,
    loadingAvatar: false,
    loadingRole: false,
    loadingLocale: false,
  },
  extraReducers: {
    [fetchUsers.pending]: (state) => {
      state.loading = true;
    },
    [fetchUsers.fulfilled]: (state, { payload }) => {
      state.user = payload.user;
      state.meta = payload.meta;
      state.loading = false;
    },
    [fetchUsers.rejected]: (state) => {
      state.loading = false;
    },

    [createUser.pending]: (state) => {
      state.loadingUpdate = true;
    },
    [createUser.fulfilled]: (state) => {
      message.success('Пользователь создан');
      state.loadingUpdate = false;
    },
    [createUser.rejected]: (state) => {
      state.loadingUpdate = false;
    },

    [updateUsers.pending]: (state) => {
      state.loadingUpdate = true;
    },
    [updateUsers.fulfilled]: (state) => {
      message.success('Пользователь обновлен');
      state.loadingUpdate = false;
    },
    [updateUsers.rejected]: (state) => {
      state.loadingUpdate = false;
    },

    [updatePassword.pending]: (state) => {
      state.loadingPassword = true;
    },
    [updatePassword.fulfilled]: (state) => {
      state.loadingPassword = false;
    },
    [updatePassword.rejected]: (state) => {
      state.loadingPassword = false;
    },

    [updateAvatar.pending]: (state) => {
      state.loadingAvatar = true;
    },
    [updateAvatar.fulfilled]: (state) => {
      state.loadingAvatar = false;
    },
    [updateAvatar.rejected]: (state) => {
      state.loadingAvatar = false;
    },
    [attachRole.pending]: (state) => {
      state.loadingRole = true;
    },
    [attachRole.fulfilled]: (state) => {
      state.loadingRole = false;
    },
    [attachRole.rejected]: (state) => {
      state.loadingRole = false;
    },
    [detachRole.pending]: (state) => {
      state.loadingRole = true;
    },
    [detachRole.fulfilled]: (state) => {
      state.loadingRole = false;
    },
    [detachRole.rejected]: (state) => {
      state.loadingRole = false;
    },
    [updateLocale.pending]: (state) => {
      state.loadingLocale = true;
    },
    [updateLocale.fulfilled]: (state) => {
      state.loadingLocale = false;
    },
    [updateLocale.rejected]: (state) => {
      state.loadingLocale = false;
    },
  },
});

export default profile.reducer;
