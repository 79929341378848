import React from 'react';
import * as S from 'features/Login/components/Field/Field.styled';
import { Form, Input, InputProps } from 'antd';
import { useTranslation } from 'react-i18next';

type FieldProps = {
  variant: 'password' | 'username';
  name: string;
};

export function Field({ variant, name, ...inputProps }: FieldProps & InputProps) {
  const { t } = useTranslation();

  return (
    <S.Field variant={variant}>
      <Form.Item
        name={name}
        rules={[
          {
            required: true,
            message: t('auth.Обязательное поле'),
          },
        ]}
      >
        {variant === 'password' ? <Input.Password {...inputProps} /> : <Input {...inputProps} />}
      </Form.Item>
    </S.Field>
  );
}
