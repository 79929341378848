import styled from 'styled-components';
import { ImageAlignment } from '../ducks/service.types';

export const Banner = styled.div<{ imageAlignment: ImageAlignment }>`
  position: relative;
  display: flex;
  align-items: center;
  height: 200px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xxl - 1}px) {
    justify-content: ${({ imageAlignment }) => (imageAlignment === 'left' ? 'start' : 'space-between')};
  }
`;

export const Image = styled.div<{ alignment: ImageAlignment }>`
  position: absolute;
  top: 0;
  right: ${({ alignment }) => (alignment === 'right' ? '0' : 'auto')};
  left: ${({ alignment }) => (alignment === 'left' ? '0' : 'auto')};
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: ${({ alignment }) => (alignment === 'left' ? 'left center' : 'right center')};
  z-index: ${({ theme }) => theme.zIndices.paper};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xl - 1}px) {
    position: static;
    max-width: ${({ alignment }) => (alignment === 'left' ? '239px' : '320px')};
    min-width: 230px;
    flex: 0 1 auto;
    order: ${({ alignment }) => (alignment === 'left' ? '0' : '2')};
    background-size: cover;
    background-position: center;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
    min-width: 156px;
  }
`;

export const Content = styled.div<{ imageAlignment: ImageAlignment; bannerHasDescription: boolean }>`
  order: 1;
  flex: 0 0 auto;
  width: 100%;
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.paper + 1};
  padding-left: ${({ imageAlignment }) => (imageAlignment === 'left' ? '587px' : '80px')};
  display: flex;
  align-items: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xxl - 1}px) {
    width: auto;
    padding: ${({ imageAlignment, bannerHasDescription }) =>
      imageAlignment === 'left' ? '0 64px' : `0px ${bannerHasDescription ? '48px' : '60px'}`};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
    padding: ${({ imageAlignment, bannerHasDescription }) =>
      imageAlignment === 'left' ? '0 48px' : `0px ${bannerHasDescription ? '36px' : '60px'}`};
  }
`;

export const Main = styled.div`
  max-width: 380px;
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['22']};
  line-height: ${({ theme }) => theme.lineHeights['28']};
  color: ${({ theme }) => theme.colors['grey-8']};
  white-space: pre-wrap;
`;

export const SubTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['14']};
  line-height: 1.5;
  color: ${({ theme }) => theme.colors['grey-5']};
  margin-top: ${({ theme }) => theme.space['8']};
  margin-bottom: ${({ theme }) => theme.space['12']};
  white-space: pre-wrap;
`;

export const Extra = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 160px;
  margin-left: ${({ theme }) => theme.lineHeights['32']};
  align-items: start;
`;

export const PriceDescription = styled.span`
  font-size: ${({ theme }) => theme.fontSizes['16']};
  line-height: 1.5;
  color: ${({ theme }) => theme.colors['grey-5']};
  margin-bottom: ${({ theme }) => theme.space['8']};
`;

export const Price = styled.span`
  font-size: ${({ theme }) => theme.fontSizes['30']};
  line-height: ${({ theme }) => theme.lineHeights['38']};
  color: ${({ theme }) => theme.colors['grey-8']};
  margin-bottom: ${({ theme }) => theme.space['12']};
`;

export const LinkMoreText = styled.span`
  position: relative;
  display: block;
  white-space: nowrap;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.colors['grey-9']};
  transition: border 0.3s;
`;

export const LinkMore = styled.a`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors['grey-5']};
  font-size: ${({ theme }) => theme.fontSizes['14']};
  line-height: ${({ theme }) => theme.lineHeights['22']};

  svg {
    width: 16px;
    margin-left: ${({ theme }) => theme.space['4']};
  }

  &:hover ${LinkMoreText} {
    border-color: ${({ theme }) => theme.colors['orange-4']};
  }
`;

export const Description = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['14']};
  line-height: 1.5;
  color: ${({ theme }) => theme.colors['grey-5']};
  max-width: 296px;
  margin-left: ${({ theme }) => theme.space['40']};

  svg {
    margin-right: ${({ theme }) => theme.space['10']};
  }
`;
