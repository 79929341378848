import React from 'react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { DocumentSearvice } from './service';
import download from 'downloadjs';
import { Trans } from 'react-i18next';
import { message } from 'antd';
import { STUB_DOWNLOAD_DOCS_TITLE } from 'components/StubDownloadDocs';
import { isIOS } from 'utils/isIOS';

export const getTessaDocuments = createAsyncThunk('docsSearch/getTessaDocuments', async (search) => {
  const requests = [DocumentSearvice.getAllTessaDocuments(search), DocumentSearvice.getFilters()];

  const [documentsResponse, filtersResponse] = await Promise.allSettled(requests);

  return {
    documents: documentsResponse?.value?.data || {},
    filters: filtersResponse?.value?.data || null,
    currentSearch: search,
  };
});

export const downloadTessaDocument = createAsyncThunk('docsSearch/downloadTessaDocument', async (document) => {
  try {
    if (isIOS()) {
      message.warn(STUB_DOWNLOAD_DOCS_TITLE);
    } else {
      const { data } = await DocumentSearvice.downloadDocument(document);

      download(data.file, `${data.name}.${data.extension}`, `application/${data.extension}`);
      return {
        document,
        file: data,
      };
    }
  } catch (error) {
    message.error(<Trans i18nKey="docSearch.Не удалось скачать файл" />);
  }
});
