import { useLazyRemindLaterQuery } from 'services/satisfaction/survey';

export function useRemindLater() {
  const [remindLater, { isFetching, isError }] = useLazyRemindLaterQuery();

  return {
    remindLater,
    isLoading: isFetching,
    isError,
  };
}
