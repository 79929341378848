import React from 'react';
import { Page } from 'components/layout/Page';
import Users from './Users';
import UsersHeader from './UsersHeader';
import { UserHeader } from '../User/layout/Header';

const UserCreatePage = React.lazy(() => import('../User/UserCreate'));
const UserUpdatePage = React.lazy(() => import('../User/UserUpdate'));
const UserCompaniesPage = React.lazy(() => import('../User/UserCompanies'));

function ProfileContractsPage() {
  return (
    <Page
      title="Список пользователей"
      headerBottom={<UsersHeader />}
    >
      <Users />
    </Page>
  );
}

export const UsersListPage = [
  {
    path: '/admin/users',
    component: ProfileContractsPage,
  },
  {
    path: '/admin/users/create',
    component: UserCreatePage,
  },
  {
    path: '/admin/user/:userId',
    component: () => (
      <Page
        title="Редактирование пользователя"
        headerBottom={<UserHeader />}
        resetContentPadding
      >
        <UserUpdatePage />
      </Page>
    ),
    exact: true,
  },
  {
    path: '/admin/user/:userId/companies',
    component: () => (
      <Page
        title="Редактирование пользователя"
        headerBottom={<UserHeader />}
        resetContentPadding
      >
        <UserCompaniesPage />
      </Page>
    ),
  },
];
