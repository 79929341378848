import styled from 'styled-components';

export const LoginWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: ${({ theme }) => theme.space[20]} ${({ theme }) => theme.space[10]};
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors['grey-19']};
  z-index: 10;
`;

export const LoginCard = styled.div`
  width: 100%;
  max-width: 360px;
  background-color: ${({ theme }) => theme.colors['white']};
  border-radius: 2px;
  padding: ${({ theme }) => theme.space[24]} 27px;
`;

export const LogoWrapper = styled.div`
  max-width: 200px;
  margin: auto;
  margin-bottom: 17px;
`;

export const AppLinksWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors['grey-11']};
  padding: ${({ theme }) => theme.space[16]} 27px 0;
  margin: ${({ theme }) => theme.space[20]} -27px 0;
`;

export const Separator = styled.div`
  padding: ${({ theme }) => theme.space[12]} 0;
  font-size: ${({ theme }) => theme.fontSizes[12]};
  line-height: ${({ theme }) => theme.lineHeights[14]};
  color: ${({ theme }) => theme.colors['grey-1']};
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.space[12]};

  &:before,
  &:after {
    content: '';
    height: 1px;
    width: 100%;
    flex: 0 1 auto;
    background: ${({ theme }) => theme.colors['grey-11']};
  }
`;
