import { createSlice } from '@reduxjs/toolkit';
import { getBalanceShipments, getCompanies } from '../../services/balance-shipments';
import { getFile } from '../../services/files';
import { message } from 'antd';
import download from 'downloadjs';

//удалить этот файл после рефакторинга, убедиться, что больше нигде не используется

const dashboard = createSlice({
  name: 'bankShipments',
  initialState: {},
  reducers: {
    getShipmentsStart(state) {
      state.loading = true;
    },
    getShipmentsSuccess(state, { payload }) {
      const { list, filters, pagination, totals } = payload.data;

      state.shipments = list;
      state.filters = { ...filters, ...payload.filters };
      state.totals = totals;
      state.paginate = pagination;
      state.loading = false;
    },
    getShipmentsFailed(state) {
      state.loading = false;
    },
    getCompaniesStart() {},
    getCompaniesSuccess(state, { payload }) {
      state.companies = payload;
    },
    getCompaniesFailed() {},
  },
});

export const {
  getShipmentsStart,
  getShipmentsSuccess,
  getShipmentsFailed,

  getCompaniesStart,
  getCompaniesSuccess,
  getCompaniesFailed,
} = dashboard.actions;

export const fetchShipments = (filters) => async (dispatch) => {
  dispatch(getShipmentsStart());
  try {
    const { success, data } = await getBalanceShipments(filters);
    if (success) {
      dispatch(getShipmentsSuccess({ data, filters }));
    } else {
      message.error('При получение данных, произошла ошибка');
      dispatch(getShipmentsFailed());
    }
  } catch (error) {
    message.error('Произошла ошибка сервера!');
    dispatch(getShipmentsFailed());
  }
};

export const fetchCompanies = (search) => async (dispatch) => {
  dispatch(getCompaniesStart());
  try {
    const { success, data } = await getCompanies(search);
    if (success) {
      dispatch(getCompaniesSuccess(data));
    } else {
      message.error('При получение данных, произошла ошибка');
      dispatch(getCompaniesFailed());
    }
  } catch (error) {
    message.error('Произошла ошибка сервера!');
    dispatch(getCompaniesFailed());
  }
};

export const fetchDocument = (body, name) => async () => {
  const downloadMessage = message.loading('Формирование файла..', 0);
  try {
    const { success, data } = await getFile(body);
    if (success) {
      const fileName = data.name || name;
      download(data.file, `${fileName}.${data.extension}`);
    } else {
      message.error('При получение данных, произошла ошибка');
    }
    downloadMessage();
  } catch (e) {
    message.error('Произошла ошибка сервера!');
    downloadMessage();
  }
};

export default dashboard.reducer;
