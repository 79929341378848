import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  METAL_PURCHASE_FIXATION_PATH,
  METAL_PURCHASE_LIST_PATH,
  METAL_PURCHASE_METALS_PATH,
  METAL_PURCHASE_PATH,
  METAL_PURCHASE_PRODUCTS_LIST_PATH,
  METAL_PURCHASE_RESERVE_PATH,
  METAL_PURCHASE_SALES_LIST_PATH,
  METAL_SALE_FIXATION_PATH,
  METAL_SALE_FIXATION_STEPS_PATH,
  METAL_SALE_FIXATIONS_LIST_PATH,
  METAL_SALE_LIST_PATH,
  METAL_SALE_METALS_PATH,
  METAL_SALE_PATH,
  METAL_SALE_RESERVE_PATH,
  METAL_SALE_RESERVE_STEPS_PATH,
  METAL_SALE_RESERVES_LIST_PATH,
  METAL_SALE_START_PATH,
  METAL_SALE_STEPS_PATH,
} from 'constants/routePaths';
import { Page } from 'pages/types';

const MetalSalePage = React.lazy(() => import('pages/wtsPages/metal-sale/MetalSalePage'));
const MetalSaleStartPage = React.lazy(() => import('pages/wtsPages/metal-sale/MetalSaleStartPage'));
const FixationStepsPage = React.lazy(() => import('pages/wtsPages/metal-sale/FixationStepsPage'));
const FixationMetalsPage = React.lazy(() => import('pages/wtsPages/metal-sale/FixationMetalsPage'));
const FixationsListPage = React.lazy(() => import('pages/wtsPages/metal-sale/FixationsListPage'));
const MetalSaleListPage = React.lazy(() => import('pages/wtsPages/metal-sale/MetalSaleListPage'));
const ReservesListPage = React.lazy(() => import('pages/wtsPages/metal-sale/ReservesListPage'));
const ReserveStepsPage = React.lazy(() => import('pages/wtsPages/metal-sale/ReserveStepsPage'));
const MetalSaleStepsPage = React.lazy(() => import('pages/wtsPages/metal-sale/MetalSaleStepsPage'));
const MetalSaleMetalsPage = React.lazy(() => import('pages/wtsPages/metal-sale/MetalSaleMetalsPage'));
const ReserveMetalsPage = React.lazy(() => import('pages/wtsPages/metal-sale/ReserveMetalsPage'));

// WTS на продажу
const MetalPurchasePage = React.lazy(() => import('pages/wtsPages/metalPurchase/MetalPurchasePage'));
const MetalPurchaseListPage = React.lazy(() => import('pages/wtsPages/metalPurchase/MetalPurchaseListPage'));
const ProductsListPage = React.lazy(() => import('pages/wtsPages/metalPurchase/ProductsListPage'));
const SalesListPage = React.lazy(() => import('pages/wtsPages/metalPurchase/SalesListPage'));
const MetalPurchaseMetalsPage = React.lazy(() => import('pages/wtsPages/metalPurchase/MetalPurchaseMetalsPage'));
const FixationPurchaseMetalsPage = React.lazy(() => import('pages/wtsPages/metalPurchase/FixationPurchaseMetalsPage'));

export const wtsPages: Page[] = [
  {
    path: METAL_SALE_PATH,
    component: MetalSalePage,
    permission: null,
    nestedRoutes: [
      {
        path: METAL_SALE_START_PATH,
        component: MetalSaleStartPage,
      },
      {
        path: METAL_SALE_LIST_PATH,
        component: MetalSaleListPage,
        exact: true,
        nestedRoutes: [
          {
            path: METAL_SALE_FIXATIONS_LIST_PATH,
            component: FixationsListPage,
            exact: true,
          },
          {
            path: METAL_SALE_RESERVES_LIST_PATH,
            component: ReservesListPage,
            exact: true,
          },
        ],
      },
      {
        path: METAL_SALE_STEPS_PATH,
        component: MetalSaleStepsPage,
        nestedRoutes: [
          {
            path: METAL_SALE_FIXATION_STEPS_PATH,
            component: FixationStepsPage,
          },
          {
            path: METAL_SALE_RESERVE_STEPS_PATH,
            component: ReserveStepsPage,
          },
        ],
      },
      {
        path: METAL_SALE_METALS_PATH,
        component: MetalSaleMetalsPage,
        nestedRoutes: [
          {
            path: METAL_SALE_FIXATION_PATH,
            component: FixationMetalsPage,
          },
          {
            path: METAL_SALE_RESERVE_PATH,
            component: ReserveMetalsPage,
          },
        ],
      },
      {
        path: '*',
        component: () => <Redirect to={METAL_SALE_FIXATIONS_LIST_PATH} />,
      },
    ],
  },
  {
    path: METAL_PURCHASE_PATH,
    component: MetalPurchasePage,
    permission: null,
    nestedRoutes: [
      {
        path: METAL_PURCHASE_LIST_PATH,
        component: MetalPurchaseListPage,
        exact: true,
        nestedRoutes: [
          {
            path: METAL_PURCHASE_PRODUCTS_LIST_PATH,
            component: ProductsListPage,
            exact: true,
          },
          {
            path: METAL_PURCHASE_SALES_LIST_PATH,
            component: SalesListPage,
            exact: true,
          },
        ],
      },
      {
        path: METAL_PURCHASE_METALS_PATH,
        component: MetalPurchaseMetalsPage,
        nestedRoutes: [
          {
            path: METAL_PURCHASE_FIXATION_PATH,
            component: FixationPurchaseMetalsPage,
          },
          {
            path: METAL_PURCHASE_RESERVE_PATH,
            component: ReserveMetalsPage,
          },
        ],
      },
      {
        path: '*',
        component: () => <Redirect to={METAL_PURCHASE_PRODUCTS_LIST_PATH} />,
      },
    ],
  },
];
