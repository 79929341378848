import { z } from 'zod';
import { ProductEntity } from './product';
import { CompanyGroupEntity } from '../common/companyGroup';
import { DiscountEntity } from '../common/discount';
import { FixationSellMethodsEntity } from './fixationMethods';
import { FixSellPriceEntity, SpotSellPriceEntity } from './methodsPrice';

export const SaleFormEntity = z.object({
  id: z.string(),
  topicUri: z.string(),
  lotsCount: z.number(),
  createdAt: z.string(),
  weight: z.number(),
  product: ProductEntity,
  sellCompanyGroup: CompanyGroupEntity,
  discount: DiscountEntity,
  methods: FixationSellMethodsEntity,
  price: SpotSellPriceEntity.or(FixSellPriceEntity).or(z.null()),
});

export type SaleFormEntity = z.infer<typeof SaleFormEntity>;
