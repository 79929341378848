import { actionTriggering } from 'analytics';
import { BannerEntity } from './ducks/service.types';

class BannersAnalytics {
  readonly REQUEST_CLICK = 'banners__request-click';
  readonly REQUEST_SUBMIT = 'banners__request-submit';
  readonly CLOSE = 'banners__close';

  constructor(protected dispatch = actionTriggering) {}

  /** Клик по кнопке "Заказать консультацию"
   * @param service Название услуги
   * */
  requestClick = (service: BannerEntity['titleForm']) => {
    this.dispatch(this.REQUEST_CLICK, {
      ActionTargetValue: service,
    });
  };

  /** Отправка заявки
   * @param service Название услуги
   * */
  requestSubmit = (service: BannerEntity['titleForm']) => {
    this.dispatch(this.REQUEST_SUBMIT, {
      ActionTargetValue: service,
    });
  };

  /** Закрытие баннера * */
  close = () => {
    this.dispatch(this.CLOSE);
  };
}

export const bannersAnalytics = new BannersAnalytics();
