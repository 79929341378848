import { ChangeEvent, useCallback, useState } from 'react';

export function useEmail() {
  const [email, setEmail] = useState('');

  const onEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }, []);

  return {
    email,
    onEmailChange,
  };
}
