import { createSlice } from '@reduxjs/toolkit';
import { getShipments, getCompanies } from './actions';

const shipments = createSlice({
  name: 'shipments',
  initialState: {
    documents: [],
    list: [],
    pagination: null,
    filters: null,
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [getShipments.pending]: (state) => {
      state.loading = true;
    },
    [getShipments.fulfilled]: (state, { payload }) => {
      state.list = payload.shipments.list;
      state.pagination = payload.shipments.pagination;
      state.documents = payload.documents;
      state.filters = payload.filters;
      state.loading = false;
    },
    [getShipments.rejected]: (state) => {
      state.loading = false;
    },
    [getCompanies.fulfilled]: (state, { payload }) => {
      state.companies = payload;
    },
  },
});

export default shipments.reducer;
