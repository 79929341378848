import { createSlice } from '@reduxjs/toolkit';
import { getDKZ, getActs, createActs } from 'features/finances/duck/actions';

const slice = createSlice({
  name: 'finances',
  initialState: {
    dkz: null,
    acts: null,
    createLoading: false,
    actsLoading: false,
    dkzLoading: false,
  },

  extraReducers: {
    [getDKZ.pending]: (state) => {
      state.dkzLoading = true;
    },
    [getDKZ.fulfilled]: (state, { payload }) => {
      state.dkzLoading = false;
      state.dkz = payload;
    },
    [getDKZ.rejected]: (state) => {
      state.dkzLoading = false;
    },
    [getActs.pending]: (state) => {
      state.actsLoading = true;
    },
    [getActs.fulfilled]: (state, { payload }) => {
      state.actsLoading = false;
      state.acts = payload;
    },
    [getActs.rejected]: (state) => {
      state.actsLoading = false;
    },
    [createActs.pending]: (state) => {
      state.createLoading = true;
    },
    [createActs.fulfilled]: (state) => {
      state.createLoading = false;
    },
    [createActs.rejected]: (state) => {
      state.createLoading = false;
    },
  },
});

export default slice.reducer;
