import styled from 'styled-components';

export const Content = styled.span`
  word-break: break-word;
  position: relative;
  max-width: 290px;
  padding-right: 20px;
  display: inline-flex;
`;

export const Cross = styled.div`
  position: absolute;
  top: 2px;
  right: 0;
`;
