import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State as CentrifugeStatusEnum } from 'centrifuge/build/types';

type WsConnectionState = { status: CentrifugeStatusEnum };

const INITIAL_STATE: WsConnectionState = {
  status: CentrifugeStatusEnum.Connecting,
};

const supportWsConnectionSlice = createSlice({
  name: 'supportWsConnection',
  initialState: INITIAL_STATE,
  reducers: {
    setStatus: (state, { payload }: PayloadAction<CentrifugeStatusEnum>) => {
      state.status = payload;
    },
  },
});

export const { name, actions, reducer } = supportWsConnectionSlice;
