import { TicketEntity, TicketsListEntity } from 'models/api/support/ticket';
import { TICKETS_API } from './consts';
import { asyncPipe } from 'utils/asyncPipe';
import { getDataFromResponse } from 'utils/getDataFromResponse';
import { UploadFile } from 'antd/lib/upload/interface';
import { axiosInstance } from 'services/axiosInstance';

export type CreateTicketRequest = {
  companyId: number;
  categoryId: number;
  subCategoryId: number;
  themeId: number;
  message: string;
  files?: UploadFile[];
};

export type TicketsListParams = { lastMessageId?: number; search?: string };

class TicketsListApi {
  getTicketsList = (params: TicketsListParams) => {
    return asyncPipe(axiosInstance.get, getDataFromResponse)<ApiResponseEntity<TicketsListEntity>>(TICKETS_API, {
      params,
    });
  };

  createTicket = (data: FormData) => {
    return asyncPipe(axiosInstance.post, getDataFromResponse)<ApiResponseEntity<TicketEntity>>(TICKETS_API, data);
  };
}

export const ticketsListApi = new TicketsListApi();
