import types from '../actions/types';

const initialState = {
  metals: [],
  mmrs: [],
  proposalSupplyList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_METALS: {
      return {
        ...state,
        metals: action.payload.list,
      };
    }
    case types.GET_MMR: {
      return {
        ...state,
        mmrs: action.payload.list,
      };
    }
    case types.GET_PROPOSAL_SUPPLY_LIST: {
      return {
        ...state,
        proposalSupplyList: action.payload.list,
        paginate: action.payload.paginate,
        proposalSupplyUpdate: null,
      };
    }
    case types.GET_PROPOSAL_UPDATE_SUPPLY: {
      return {
        ...state,
        proposalSupplyUpdate: action.payload,
      };
    }
    default:
      return state;
  }
}
