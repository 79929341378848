import React from 'react';
import { Link } from 'react-router-dom';
import { icons } from 'features/sidebar/Icons';
import { NavItem } from '../NavItem';
import { useTranslation } from 'react-i18next';
import * as S from './styled';

export function UserDetail({ name, userMenu, visible, onClose, onCloseAll, isAdmin }) {
  const { t } = useTranslation();

  const renderMenuItems = (pages) => {
    const menuItems = pages?.map((props, idx) => {
      return (
        <NavItem
          iconName={props?.code}
          {...props}
          key={idx}
        >
          <Link to={props?.link}>
            <S.NavTitle>{isAdmin ? props?.name : t(`sidebar.${props?.name}`)}</S.NavTitle>
          </Link>
        </NavItem>
      );
    });

    return menuItems;
  };

  const renderDrawerContent = () => {
    return (
      <React.Fragment>
        <S.UserHeader>
          <S.SubIcon onClick={onClose}>{icons.nextLeft}</S.SubIcon>
          {name}
          <S.SubIcon onClick={onCloseAll}>{icons.cancel}</S.SubIcon>
        </S.UserHeader>
        {renderMenuItems(userMenu)}
      </React.Fragment>
    );
  };
  return (
    <S.UserDetailDrawer
      placement="left"
      visible={visible}
      width="100%"
      closable={false}
    >
      {renderDrawerContent()}
    </S.UserDetailDrawer>
  );
}
