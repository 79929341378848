import React from 'react';
import SVG from 'react-inlinesvg';
import starIcon from 'icons/starOutlined.svg';
import * as S from './Rate.styled';
import { RateProps } from 'antd';

export function Rate(props: RateProps) {
  return (
    <S.Rate
      character={<SVG src={starIcon} />}
      {...props}
    />
  );
}
