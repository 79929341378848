import styled from 'styled-components';
import { Rate as InnerRate } from 'antd';

export const Rate = styled(InnerRate)`
  height: ${({ theme }) => theme.sizes[30]};

  .ant-rate-star-first,
  .ant-rate-star-second {
    color: ${({ theme }) => theme.colors['grey-1']};
  }

  .ant-rate-star-half .ant-rate-star-first,
  .ant-rate-star-full .ant-rate-star-second {
    svg {
      filter: drop-shadow(rgba(240, 175, 48, 0.48) 0px 0px 8px);
      height: ${({ theme }) => theme.sizes[30]};
      width: ${({ theme }) => theme.sizes[30]};
    }

    path {
      fill: ${({ theme }) => theme.colors['orange-8']};
      stroke: ${({ theme }) => theme.colors['orange-8']};
    }
  }

  .ant-rate-star:not(:last-child) {
    margin-right: ${({ theme }) => theme.space[12]};
  }
`;
