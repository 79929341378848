import React from 'react';
import Loader from 'react-loaders';
import { connect } from 'react-redux';
import './style.scss';

function GlobalLoader({ show }) {
  return (
    <div className={show ? 'global-loader global-loader--active' : 'global-loader'}>
      <Loader type="ball-spin-fade-loader" />
    </div>
  );
}

const mapStateToProps = (state) => ({
  show: state.loader.globalLoader,
});

export default connect(mapStateToProps, null)(GlobalLoader);
