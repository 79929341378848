import { apiPost } from '../../../services/helper';
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const changeLocalizationService = async (lng) => {
  const { data } = await apiPost(`/v1/localization`, { locale: lng });
  return data;
};

export const changeLocalizationAction = createAsyncThunk('localization/changeLocalization', async (lng) => {
  const data = await changeLocalizationService(lng);
  return data;
});

const slice = createSlice({
  name: 'localization',
  initialState: {
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [changeLocalizationAction.pending]: (state) => {
      state.loading = true;
    },
    [changeLocalizationAction.fulfilled]: (state) => {
      state.loading = false;
    },
    [changeLocalizationAction.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default slice.reducer;
