import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { RootState } from 'store';
import { RouteComponentProps } from 'react-router';
import { Page } from 'pages/types';

export function PrivateRoute({
  component: ChildComponent,
  componentProps,
  path,
  exact,
  children,
  permission,
  bannersSection,
  nestedRoutes,
  isNavVisible,
  label,
}: Page) {
  const authenticated = useSelector((state: RootState) => state.auth.authenticated);

  const renderChildComponent = (props: RouteComponentProps) => {
    if (!authenticated) {
      return (
        <Redirect
          to={{
            pathname: '/signin',
            state: {
              from: props.location,
              message: 'Необходимо авторизоваться!',
            },
          }}
        />
      );
    }

    if (ChildComponent) {
      return (
        <ChildComponent
          pageConfig={{
            path,
            permission,
            bannersSection,
            exact,
            nestedRoutes,
            isNavVisible,
            label,
          }}
          {...componentProps}
        />
      );
    }
  };

  return (
    <Route
      path={path}
      exact={exact}
      render={ChildComponent && ((props) => renderChildComponent(props))}
    >
      {children}
    </Route>
  );
}
