import React, { useEffect } from 'react';
import { useModal } from '../../hooks/useModal.hook';
import { useMedia } from '../../hooks/useMedia';
import history from '../../store/history';
import { actionTriggering } from '../../analytics';
import { NewSectionDocSearch, MODAL_NAME } from '../modals/DocSearchModal';

export function WelcomeDocSearch() {
  const isSmallScreen = useMedia(['(max-width: 1200px)'], [true], false);
  const { visible, showModal, hideModal } = useModal(MODAL_NAME);

  const handleSubmit = () => {
    hideModal();
    history.push('/documents-search');
  };

  const hideModalHandler = () => {
    actionTriggering('remains__welcome-modal--close');
    hideModal();
  };

  useEffect(() => {
    if (!isSmallScreen) {
      const showKey = localStorage.getItem(MODAL_NAME);

      if (!showKey) {
        localStorage.setItem(MODAL_NAME, true);
        actionTriggering('docSearch__show-welcome-modal');
        showModal();
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <NewSectionDocSearch
        show={visible}
        onSubmit={handleSubmit}
        hideModal={hideModalHandler}
      />
    </React.Fragment>
  );
}
