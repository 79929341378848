import { matchPath } from 'react-router-dom';
import { METAL_SALE_FIXATION_PATH } from 'constants/routePaths';
import { ApiReserveMetalFormEntity, ReserveMetalFormEntity } from 'models/api/wts/metalSale/reserveMetalForm';

export function isFixationRoute() {
  return Boolean(
    matchPath(window.location.pathname, {
      path: METAL_SALE_FIXATION_PATH,
      exact: false,
      strict: false,
    })
  );
}

export function getReservePrice(reserveMetalForm: ReserveMetalFormEntity, fixationContentId: string) {
  return reserveMetalForm.items.find((price) => price.fixationContentId === fixationContentId);
}

export function normalizeReserveMetalForm(data: ApiReserveMetalFormEntity): ReserveMetalFormEntity {
  return {
    ...data,
    items: data.items.map((item) => ({
      ...item,
      amount: {
        value: item.amount,
        limit: item.limitAmount,
      },
    })),
  };
}
