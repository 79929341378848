import { apiGet } from '../../../services/helper';

class PricesServiceFactory {
  getPrices = async (search = '') => {
    const { data } = await apiGet(`/v1/prices${search}`);

    return data;
  };
  getNextPagePrices = async (search = '', page) => {
    const { data } = await apiGet(`/v1/prices${search}`, page);

    return data;
  };
}

export const PricesService = new PricesServiceFactory();
