import { apiPost } from '../../../../services/helper';

/**
 * @param {string} contentDisposition - "attachment; filename=codes_128x128.zip"
 */
const getFileName = (contentDisposition) => {
  if (!contentDisposition) {
    return;
  }
  return contentDisposition.split('; ')[1].split('=')[1];
};

export const API_ACTION_URL = '/v1/codes/generate';

class CodesServiceFactory {
  generateCode = async (payload) => {
    try {
      const { data, headers } = await apiPost(
        API_ACTION_URL,
        payload,
        {
          'Content-Type': 'multipart/form-data',
        },
        {
          responseType: 'blob',
        }
      );

      return {
        code: 200,
        message: '',
        success: true,
        data: {
          generatedFile: {
            name: getFileName(headers['content-disposition']),
            blob: data,
          },
        },
      };
    } catch (err) {
      if (err.response.status >= 500) {
        throw err;
      }

      const json = await err.response.data.text();
      const data = JSON.parse(json);

      if (err.response.status === 400) {
        return data;
      }
      throw new Error(data?.message);
    }
  };
}

export const CodesService = new CodesServiceFactory();
