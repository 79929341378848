import React from 'react';
import { Rate } from 'components/ui/Rate';
import { Button } from 'components/ui/Button';
import { FinishContent } from './FinishContent';
import { useScreen } from 'hooks/useScreen';
import { useSurveyModal } from './hooks/useSurveyModal';
import { useAuth } from 'ducks/auth/hooks/useAuth';
import { ExtendedContent } from './ExtendedContent';
import { Form, ModalProps, Spin } from 'antd';
import { RATE_TOOLTIPS } from './consts';
import { useQaAttr } from 'hooks/useQA';
import * as S from './SurveyModal.styled';
import { SvgIcon } from 'icons';

type SurveyModalProps = { onCancel: () => void } & ModalProps;

export function SurveyModal({ onCancel, ...props }: SurveyModalProps) {
  const { isSmallScreen } = useScreen();
  const {
    data,
    form,
    isSendSuccess,
    star,
    tagsByStar,
    isSendLoading,
    isCountVisible,
    onRemindLater,
    onClose,
    onFormChange,
    onFormSubmit,
  } = useSurveyModal({
    skip: !props.visible,
    onCancel,
  });
  const { qaAttr } = useQaAttr('survey-modal');
  const { authenticated } = useAuth().authState;

  if (!authenticated || !data) {
    return null;
  }

  const { id, title } = data;

  return (
    <S.Modal
      {...props}
      mask={isSmallScreen}
      maskClosable={false}
      footer={null}
      centered={isSmallScreen}
      wrapClassName={isSmallScreen ? '' : 'survey__modal-wrapper'}
      width={isSmallScreen ? 300 : 414}
      onCancel={isSendSuccess ? onCancel : onClose}
      closeIcon={<SvgIcon name="cross" />}
      {...qaAttr(String(id))}
    >
      {isSendSuccess ? (
        <FinishContent onClick={onCancel} />
      ) : (
        <Spin spinning={isSendLoading}>
          <S.Content>
            <S.Title {...qaAttr('title')}>{title}</S.Title>
            <Form
              layout="vertical"
              form={form}
              onValuesChange={(_, values) => onFormChange(values)}
              onFinish={onFormSubmit}
            >
              <Form.Item
                name="starId"
                noStyle
              >
                <Rate
                  tooltips={RATE_TOOLTIPS}
                  allowClear={false}
                />
              </Form.Item>
              {!!star && (
                <ExtendedContent
                  form={form}
                  isVisible={!!star}
                  description={star.title}
                  tags={tagsByStar}
                  isCountVisible={isCountVisible}
                />
              )}
              <S.ButtonWrapper>
                {!!star ? (
                  <Form.Item noStyle>
                    <Button
                      type="submit"
                      size="xmd"
                      {...qaAttr('submit-btn')}
                    >
                      Отправить отзыв
                    </Button>
                  </Form.Item>
                ) : (
                  <Button
                    variant="outlined"
                    size="xmd"
                    onClick={() => onRemindLater(id)}
                    {...qaAttr('remind-later-btn')}
                  >
                    Спросить позже
                  </Button>
                )}
              </S.ButtonWrapper>
            </Form>
          </S.Content>
        </Spin>
      )}
    </S.Modal>
  );
}
