import { BannersSection } from 'constants/banners';
import { RootState, useAppDispatch } from 'store';
import { useCallback } from 'react';
import * as asyncActions from './actions';
import { DisableBannerPayload, SendRequestFromBannerPayload } from './actions.types';
import { useSelector } from 'react-redux';
import { name, actions } from './slice';
import { ACTIVE_BANNERS_SECTIONS } from '../consts';
import { bannersLocalStorage, getBannersBySection } from '../utils';

export function useBanners(section: BannersSection | undefined) {
  const isBannersVisible = useCallback(() => section && ACTIVE_BANNERS_SECTIONS.includes(section), [section]);
  const dispatch = useAppDispatch();

  const state = useSelector((state: RootState) =>
    isBannersVisible()
      ? {
          banners: getBannersBySection(section, state[name].data),
          requestLoading: state[name].requestLoading,
        }
      : {}
  );

  const getBanners = useCallback(() => {
    if (!isBannersVisible()) {
      return;
    }
    return dispatch(asyncActions.getBanners());
  }, [isBannersVisible, dispatch]);

  const sendRequestFromBanner = useCallback(
    (payload: SendRequestFromBannerPayload) => {
      if (!isBannersVisible()) {
        return;
      }
      return dispatch(asyncActions.sendRequestFromBanner(payload));
    },
    [isBannersVisible, dispatch]
  );

  const reset = useCallback(() => {
    if (!isBannersVisible()) {
      return;
    }
    dispatch(actions.reset());
  }, [dispatch, isBannersVisible]);

  const disableBanners = useCallback(
    (payload: DisableBannerPayload) => {
      bannersLocalStorage.removeBannersKeysFromStorage();
      dispatch(asyncActions.disableBanner(payload));
      dispatch(actions.reset());
    },
    [dispatch]
  );

  return {
    enable: isBannersVisible(),
    getBanners,
    sendRequestFromBanner,
    reset,
    disableBanners,
    ...state,
  };
}
