import React from 'react';
import * as S from 'pages/auth/BadLinkReset/BadLink/styled';
import Logo from 'components/Logo';
import { useHistory } from 'react-router-dom';

export function BadLink() {
  const history = useHistory();

  return (
    <S.Wrapper>
      <S.Container>
        <S.LogoContainer>
          <Logo />
        </S.LogoContainer>
        <S.Text>Ссылка недействительна</S.Text>
        <S.Button onClick={() => history.push('/signin')}>Вернуться</S.Button>
      </S.Container>
    </S.Wrapper>
  );
}
