import React, { useCallback } from 'react';
import { SendRequestFromBannerPayload } from '../ducks/actions.types';
import { Form } from 'antd';
import { Input } from 'antd';
import { Button } from '../../../components/ui/Button';
import { BannerEntity } from '../ducks/service.types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RequestNamesEnum } from '../consts';
import * as S from './styled';
import DottyLoader from '../../../components/DottyLoader';
import { PhoneInput } from '../../../components/ui/PhoneInput';

type RequestFormProps = {
  onSubmit: (payload: SendRequestFromBannerPayload) => void;
  isLoading: boolean;
  activeBanner: null | BannerEntity;
};

export function RequestForm({ onSubmit, isLoading, activeBanner }: RequestFormProps) {
  const { user, companyName } = useSelector((state: RootState) => {
    //@ts-expect-error
    const { init } = state?.auth;
    return {
      user: {
        name: init?.user?.name,
        email: init?.user?.email,
      },
      companyName: init?.company?.name,
    };
  });

  const [form] = Form.useForm();

  const onFinish = useCallback(
    (formValues) => {
      if (!activeBanner) {
        return;
      }
      onSubmit({ ...formValues, bannerId: activeBanner.id });
    },
    [activeBanner, onSubmit]
  );

  const requiredFieldsRule = { required: true };

  return (
    <S.Form
      form={form}
      onFinish={onFinish}
      initialValues={{
        [RequestNamesEnum.Name]: user.name,
        [RequestNamesEnum.Email]: user.email,
        [RequestNamesEnum.Company]: companyName,
        [RequestNamesEnum.Service]: activeBanner?.titleForm,
      }}
      layout="vertical"
    >
      <Form.Item
        name={RequestNamesEnum.Name}
        label="Имя"
        rules={[requiredFieldsRule]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={RequestNamesEnum.Service}
        label="Услуга"
        rules={[requiredFieldsRule]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={RequestNamesEnum.Email}
        label="E-mail"
        rules={[requiredFieldsRule]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={RequestNamesEnum.Phone}
        label="Телефон"
        rules={[requiredFieldsRule]}
      >
        <PhoneInput />
      </Form.Item>
      <Form.Item
        name={RequestNamesEnum.Company}
        label="Компания"
        rules={[requiredFieldsRule]}
      >
        <Input />
      </Form.Item>
      <Button
        type="submit"
        mt={12}
        width="100%"
        disabled={isLoading}
      >
        {isLoading ? <DottyLoader /> : 'Отправить заявку'}
      </Button>
    </S.Form>
  );
}
