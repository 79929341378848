import { FormInstance } from 'antd';
import { isBadRequestError } from 'errors/badRequestError';
import { ZodType } from 'zod';

export function setFormFieldsErrors<T>({
  error,
  form,
  errorSchema,
}: {
  error: unknown;
  form: FormInstance;
  errorSchema: ZodType<T>;
}) {
  if (isBadRequestError(error)) {
    const parsedError = errorSchema.safeParse(error.data);
    if (parsedError.success) {
      const result = Object.entries(parsedError.data).map(([name, errors]) => ({
        name,
        errors,
      }));
      form.setFields(result);
    }
  }
}
