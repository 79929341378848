import styled from 'styled-components';
import { Radio as AntdRadio } from 'antd';

export const Locales = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LocaleRadioGroup = styled(AntdRadio.Group)`
  &&& {
    padding: ${({ theme }) => theme.space[20]} 0 0;
    display: inline-flex;
    column-gap: ${({ theme }) => theme.space[4]};
    text-align: center;
  }
`;

export const LocaleRadio = styled(AntdRadio.Button)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 100%;
    height: auto;
    background-color: transparent;
    color: ${({ theme }) => theme.colors['grey-1']};
    font-size: ${({ theme }) => theme.fontSizes[12]};
    line-height: 1;
    border: none;
    box-shadow: none;
    user-select: none;
    transition: opacity 0.15s;

    ::before {
      display: none;
    }

    :hover {
      opacity: 0.8;
    }

    ${({ disabled, theme }) =>
      disabled &&
      `
        background-color: transparent;
        color: ${theme.colors['grey-6']};
        border-color: ${theme.colors['grey-6']};
        :hover, :active, :focus {
            color:${theme.colors['grey-6']};
            border-color: ${theme.colors['grey-6']};
            background-color: transparent;
            text-shadow: none;
            box-shadow: none;
        }
    `}

    &.ant-radio-button-wrapper-checked {
      color: ${({ theme }) => theme.colors['grey-2']};
      pointer-events: none;

      :hover {
        opacity: 1;
      }
    }
  }
`;
