import { createSlice } from '@reduxjs/toolkit';
import { getNextPagePrices, getPrices } from './actions';

const CODES = 'pricesCodesDesctop';
const CODES_MOBILE = 'pricesCodesMobile';

const slice = createSlice({
  name: 'prices',
  initialState: {
    loading: false,
    loadingNext: false,
    list: [],
    filters: null,
    pagination: null,
    codes:
      localStorage.getItem(CODES) && localStorage.getItem(CODES) !== '' ? localStorage.getItem(CODES).split(',') : [],
    codesMobile: localStorage.getItem(CODES_MOBILE) ?? '',
  },
  reducers: {
    setFilters(state, { payload }) {
      state.codes = !payload.isMobile ? payload.codes : state.codes;
      state.codesMobile = payload.isMobile ? payload.codes : state.codesMobile;

      if (!payload.isMobile) {
        const sortOrder = [...state.filters?.metals, ...state.filters?.currencies].map(({ code }) => code);
        state.codes.sort((a, b) => {
          return sortOrder.indexOf(a) - sortOrder.indexOf(b);
        });
      }

      localStorage.setItem(payload.isMobile ? CODES_MOBILE : CODES, payload.codes);
    },
  },
  extraReducers: {
    [getPrices.pending]: (state) => {
      state.loading = true;
    },
    [getPrices.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.list = payload.prices.list;
      state.filters = payload.filters;
      state.pagination = payload.prices.pagination;
    },
    [getPrices.rejected]: (state) => {
      state.loading = false;
    },
    [getNextPagePrices.pending]: (state) => {
      state.loadingNext = true;
    },
    [getNextPagePrices.fulfilled]: (state, { payload }) => {
      state.loadingNext = false;
      state.list = [...state.list, ...payload.prices.list];
      state.pagination = payload.prices.pagination;
    },
    [getNextPagePrices.rejected]: (state) => {
      state.loadingNext = false;
    },
  },
});

export const { setFilters } = slice.actions;

export default slice.reducer;
