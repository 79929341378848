import { createSlice } from '@reduxjs/toolkit';
import { resetPassword } from 'pages/auth/BadLinkReset/actions';

const badLinkReset = createSlice({
  name: 'badLinkReset',
  initialState: {
    loadingPassword: false,
  },
  extraReducers: {
    [resetPassword.pending]: (state) => {
      state.loadingPassword = true;
    },
    [resetPassword.fulfilled]: (state) => {
      state.loadingPassword = false;
    },
    [resetPassword.rejected]: (state) => {
      state.loadingPassword = false;
    },
  },
});

export default badLinkReset.reducer;
