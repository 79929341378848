import { z } from 'zod';
import { CompanyGroupEntity } from 'models/api/wts/common/companyGroup';
import { FixationStatusEntity } from 'models/api/wts/metalSale/fixationStatus';
import { RawTypeEntity } from 'models/api/wts/metalSale/rawType';
import { ReserveEntity } from 'models/api/wts/metalSale/reserve';

export const FixationFormEntity = z.object({
  id: z.string(),
  companyGroup: CompanyGroupEntity,
  status: FixationStatusEntity,
  rawType: RawTypeEntity.pick({ id: true, title: true }),
  reserve: ReserveEntity.or(z.null()),
});

export type FixationFormEntity = z.infer<typeof FixationFormEntity>;
