import { asyncPipe } from '../utils/asyncPipe';
import { apiGet } from './helper';
import { getDataFromResponse } from '../utils/getDataFromResponse';
import { AdminCompaniesApi } from './adminCompaniesService.types';

class AdminCompaniesServiceFactory {
  getCompanies = (params: AdminCompaniesApi.getCompanies.Request.Params) => {
    return asyncPipe(apiGet, getDataFromResponse)<AdminCompaniesApi.getCompanies.Response.Success>(
      '/v1/admin/companies',
      params
    );
  };

  getCompanyById = (companyId: string) => {
    return asyncPipe(
      apiGet,
      getDataFromResponse
    )<AdminCompaniesApi.getCompanyById.Response.Success>(`/v1/admin/companies/${companyId}`);
  };
}

export const AdminCompaniesService = new AdminCompaniesServiceFactory();
