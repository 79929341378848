import { createSlice } from '@reduxjs/toolkit';
import { getRemains } from './actions';

const slice = createSlice({
  name: 'remains',
  initialState: {
    date: null,
    list: [],
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [getRemains.pending]: (state) => {
      state.loading = true;
    },
    [getRemains.fulfilled]: (state, { payload }) => {
      state.list = payload.list;
      state.date = payload.date;
      state.loading = false;
    },
    [getRemains.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default slice.reducer;
