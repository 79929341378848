import React from 'react';
import { Button } from 'components/ui/Button';
import { SvgIcon } from 'icons';
import ratedSvg from './rated.svg';
import * as S from './FinishContent.styled';
import { useQaAttr } from 'hooks/useQA';

type FinishContentProps = {
  onClick: () => void;
};

export function FinishContent({ onClick }: FinishContentProps) {
  const { qaAttr } = useQaAttr('survey-modal_finish');

  return (
    <S.Wrapper>
      <SvgIcon
        path={ratedSvg}
        size={false}
        style={{ width: 128 }}
      />
      <S.Title>Спасибо за оценку!</S.Title>
      <S.Description>Ваше мнение помогает нам стать лучше</S.Description>
      <S.ButtonWrapper>
        <Button
          size="xmd"
          onClick={onClick}
          {...qaAttr('close-btn')}
        >
          Закрыть
        </Button>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
}
