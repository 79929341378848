import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { Footer } from '../Footer';
import { MainNav } from '../MainNav';
import { logo, logoText } from 'features/sidebar/logo';
import { icons } from 'features/sidebar/Icons';
import * as S from './styled';
import { Header } from '../Header';
import { mockData } from '../mock';

export function Detail({
  user,
  mainNav,
  userMenu,
  visible,
  onClose,
  singleCompany,
  localization,
  changeLocalization,
  isAdmin,
  isManager,
  supportCounters,
}) {
  const [userDetail, setUserDetail] = useState(false);

  const showUserDetail = () => {
    setUserDetail(true);
  };

  const closeUserDetail = () => {
    setUserDetail(false);
  };

  const closeAllHandler = () => {
    closeUserDetail();
    onClose();
  };

  useEffect(() => {
    if (!visible) {
      setUserDetail(false);
    }
  }, [visible]);

  const renderDrawerContent = () => {
    return (
      <React.Fragment>
        <S.Logo>
          <S.Group>
            <S.LogoIconDetail>{logo}</S.LogoIconDetail>
            <S.LogoText>{logoText}</S.LogoText>
          </S.Group>
          <S.SubIcon onClick={onClose}>{icons.cancel}</S.SubIcon>
        </S.Logo>

        <Header
          user={user}
          userMenu={userMenu}
          visible={userDetail}
          onShow={showUserDetail}
          onClose={closeUserDetail}
          onCloseAll={closeAllHandler}
          singleCompany={singleCompany}
        />

        <Scrollbars>
          <MainNav
            mainNav={mainNav}
            isAdmin={isAdmin}
            count={supportCounters?.openedTickets}
          />
          <Footer
            pages={mockData[isAdmin ? 'admin' : 'public'].footerNav}
            localization={localization}
            changeLocalization={changeLocalization}
            count={isManager ? supportCounters?.openedTickets : supportCounters?.unreadMessages}
          />
        </Scrollbars>
      </React.Fragment>
    );
  };
  return (
    <S.Drawer
      placement="left"
      visible={visible}
      onClose={onClose}
      width="100%"
      closable={false}
    >
      {renderDrawerContent()}
    </S.Drawer>
  );
}
