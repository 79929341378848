import { z, ZodType } from 'zod';
import { SuccessEntity } from 'models/api/wts/common/success';

export const createListEntity = <T extends ZodType>(itemSchema: T) =>
  z
    .object({
      paginator: z.object({
        items: z.array(itemSchema),
        currentPage: z.number(),
        totalItems: z.number(),
        totalPage: z.number(),
      }),
    })
    .merge(SuccessEntity);
