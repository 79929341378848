import { createAsyncThunk } from '@reduxjs/toolkit';
import { MessageParams, MessagesListParams } from 'types/support';
import { adminMessagesApi } from 'services/admin/support/adminMessagesApi';
import { actions } from '../slices/adminMessagesSlice';
import { messagesApi } from 'services/support/messagesApi';

export const ADMIN_MESSAGES_PREFIX = 'adminMessages';
export const GET_ADMIN_MESSAGES = `${ADMIN_MESSAGES_PREFIX}/getAdminMessages`;
export const CREATE_ADMIN_MESSAGES = `${ADMIN_MESSAGES_PREFIX}/createAdminMessage`;
export const READ_ADMIN_MESSAGES = `${ADMIN_MESSAGES_PREFIX}/readAdminMessages`;

export const getAdminMessages = createAsyncThunk(GET_ADMIN_MESSAGES, async (payload: MessagesListParams) => {
  const { data } = await adminMessagesApi.getAdminMessages(payload);
  return data;
});

export const createAdminMessage = createAsyncThunk(
  CREATE_ADMIN_MESSAGES,
  async (payload: MessageParams, { dispatch }) => {
    const { data } = await adminMessagesApi.createAdminMessage(payload);
    dispatch(actions.addAdminMessage(data));
    return data;
  }
);

export const readAdminMessages = createAsyncThunk(READ_ADMIN_MESSAGES, async (ticketId: number, { dispatch }) => {
  const { data } = await messagesApi.readMessages(ticketId);
  dispatch(actions.updateAdminMessagesReadAt(data));
  return data;
});
