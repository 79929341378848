import types from '../actions/types';

const initialState = {
  globalLoader: true,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case types.SHOW_LOADER: {
      return { ...state, globalLoader: true };
    }
    case types.HIDE_LOADER: {
      return { ...state, globalLoader: false };
    }
    default:
      return state;
  }
}
