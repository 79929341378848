/* QUESTIONNAIRE */
export const QUESTIONNAIRE_SELLER_PATH = '/questionnaire/seller';

/* BALANCE */
export const BALANCE_PATH = '/balance';
export const BALANCE_SUPPLIES_PATH = `${BALANCE_PATH}/supplies`;
export const BALANCE_PRODUCTS_PATH = `${BALANCE_PATH}/products`;
export const BALANCE_RELEASE_PATH = '/balance/release';
export const BALANCE_SHIPMENT_PATH = '/balance/shipment';

/* REPORTS */
export const REPORTS_PATH = '/reports';

/* SUPPORT */
export const SUPPORT_PATH = '/support';
export const SUPPORT_TICKETS_PATH = `${SUPPORT_PATH}/tickets`;
export const SUPPORT_TICKET_PATH = `${SUPPORT_TICKETS_PATH}/:id`;
export const SUPPORT_FAQ_PATH = `${SUPPORT_PATH}/faq`;

export const ADMIN_SUPPORT_PATH = '/admin/support';
export const ADMIN_SUPPORT_FAQ_PATH = '/admin/support/faq';
export const ADMIN_SUPPORT_FEEDBACK_PATH = '/admin/support/tickets';
export const ADMIN_SUPPORT_TICKETS_PATH = `${ADMIN_SUPPORT_FEEDBACK_PATH}/:list`;
export const MY_TICKET_PARAM = 'my';
export const ALL_TICKET_PARAM = 'all';
export const ADMIN_SUPPORT_MY_TICKETS_PATH = `${ADMIN_SUPPORT_FEEDBACK_PATH}/${MY_TICKET_PARAM}`;
export const ADMIN_SUPPORT_ALL_TICKETS_PATH = `${ADMIN_SUPPORT_FEEDBACK_PATH}/${ALL_TICKET_PARAM}`;
export const ADMIN_SUPPORT_TICKET_PATH = `${ADMIN_SUPPORT_TICKETS_PATH}/:id`;

/* METAL SALE */
export const METAL_SALE_PATH = '/metal-sale';
export const METAL_SALE_START_PATH = `${METAL_SALE_PATH}/start`;

export const METAL_SALE_LIST_PATH = `${METAL_SALE_PATH}/(.*-list)`;
export const METAL_SALE_FIXATIONS_LIST_PATH = `${METAL_SALE_PATH}/fixations-list`;
export const METAL_SALE_RESERVES_LIST_PATH = `${METAL_SALE_PATH}/reserves-list`;

export const METAL_SALE_STEPS_PATH = `${METAL_SALE_LIST_PATH}/steps`;
export const METAL_SALE_FIXATION_STEPS_PATH = `${METAL_SALE_FIXATIONS_LIST_PATH}/steps/:id?`;
export const METAL_SALE_RESERVE_STEPS_PATH = `${METAL_SALE_RESERVES_LIST_PATH}/steps/:id?`;

export const METAL_SALE_METALS_PATH = `${METAL_SALE_LIST_PATH}/metals`;
export const METAL_SALE_FIXATION_PATH = `${METAL_SALE_FIXATIONS_LIST_PATH}/metals/:id`;
export const METAL_SALE_RESERVE_PATH = `${METAL_SALE_RESERVES_LIST_PATH}/metals/:id`;

/* METAL PURCHASE */
export const METAL_PURCHASE_PATH = '/metal-purchase';
export const METAL_PURCHASE_START_PATH = `${METAL_PURCHASE_PATH}/start`;

export const METAL_PURCHASE_LIST_PATH = `${METAL_PURCHASE_PATH}/(.*-list)`;
export const METAL_PURCHASE_SALES_LIST_PATH = `${METAL_PURCHASE_PATH}/sales-list`;
export const METAL_PURCHASE_PRODUCTS_LIST_PATH = `${METAL_PURCHASE_PATH}/products-list`;

export const METAL_PURCHASE_STEPS_PATH = `${METAL_PURCHASE_LIST_PATH}/steps`;

export const METAL_PURCHASE_METALS_PATH = `${METAL_PURCHASE_LIST_PATH}/metals`;
export const METAL_PURCHASE_FIXATION_PATH = `${METAL_PURCHASE_SALES_LIST_PATH}/metals/:id`;
export const METAL_PURCHASE_RESERVE_PATH = `${METAL_PURCHASE_PRODUCTS_LIST_PATH}/metals/:id`;

/* EARLY SHIPMENT */
export const EARLY_SHIPMENT_PATH = '/early-shipment';
export const EARLY_SHIPMENT_PATH_METALS = `${EARLY_SHIPMENT_PATH}/metals`;
export const EARLY_SHIPMENT_PATH_REQUESTS = `${EARLY_SHIPMENT_PATH}/requests`;

export const ADMIN_EARLY_SHIPMENT_PATH = '/admin/early-shipment';
export const ADMIN_EARLY_SHIPMENT_REQUESTS_PATH = `${ADMIN_EARLY_SHIPMENT_PATH}/:list`;

export const MY_EARLY_SHIPMENT_PARAM = 'my';
export const ALL_EARLY_SHIPMENT_PARAM = 'all';

export const ADMIN_EARLY_SHIPMENT_MY_REQUESTS_PATH = `${ADMIN_EARLY_SHIPMENT_PATH}/${MY_EARLY_SHIPMENT_PARAM}`;
export const ADMIN_EARLY_SHIPMENT_ALL_REQUESTS_PATH = `${ADMIN_EARLY_SHIPMENT_PATH}/${ALL_EARLY_SHIPMENT_PARAM}`;

/* OTHER */
export const NOT_FOUND_PATH = '/404';

export const PROFILE_PATH = '/profile';

export const OAUTH_PATH = '/oauth';
