import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const slice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    show(state, { payload: name }) {
      state[name] = true;
    },
    hide(state, { payload: name }) {
      state[name] = false;
    },
    destroy(state, { payload: name }) {
      delete state[name];
    },
  },
});

const { actions, reducer } = slice;

export const { show, hide, destroy } = actions;

export default reducer;
