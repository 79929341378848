import React from 'react';
import { icons } from 'features/sidebar/Icons';
import * as S from '../styled';

export function UserMobileBlock({ userName, onShow }) {
  const { name, surname, email } = userName;
  const clickHandler = () => {
    onShow();
  };
  const renderUserTitle = () =>
    name || surname ? (
      <S.UserTitle>
        {name && <div>{name}</div>}
        {surname && <div>{surname}</div>}
      </S.UserTitle>
    ) : (
      <S.UserTitle>{email}</S.UserTitle>
    );

  return (
    <S.UserBlock>
      <S.UserInfo onClick={clickHandler}>
        <S.UserIcon>{icons.user}</S.UserIcon>
        {renderUserTitle()}
        <S.UserIconArrow>{icons.nextDown}</S.UserIconArrow>
      </S.UserInfo>
    </S.UserBlock>
  );
}
