import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import axios from 'axios';

import { basicHeaders, ROOT_URL, JWT_ACCESS } from '../../../actions/connect';
import getFeedbackData from '../questions';
import { actionTriggering } from '../../../analytics';

const reformatCheckboxes = (values) => {
  const { checkboxSection } = getFeedbackData();
  const result = {};
  if (checkboxSection) {
    const keys = checkboxSection.checkboxes.map(({ value }) => value);

    keys.forEach((key) => {
      result[key] = !!values.includes(key);
    });
  }
  return JSON.stringify(result);
};

const getBody = (body) => {
  const result = {
    ...body,
    rating: JSON.stringify(body.rating),
    params: reformatCheckboxes(body.params),
    route: window.location.pathname.slice(1),
  };

  if (!result.comments) {
    delete result.comments;
  }

  return result;
};

const feedback = createSlice({
  name: 'feedback',
  initialState: {
    loading: false,
    visibleEstimate: false,
    visibleSuccess: false,
    initValues: {
      route: '',
      comments: '',
      rating: {},
      params: [],
    },
  },
  reducers: {
    startRequest(state) {
      state.loading = true;
    },
    toggleEstimateModal(state) {
      state.visibleEstimate = !state.visibleEstimate;
    },
    toggleSuccessModal(state) {
      state.visibleSuccess = !state.visibleSuccess;
    },
    finishRequest(state) {
      state.loading = false;
    },
    successRequest(state) {
      state.visibleSuccess = !state.visibleSuccess;
      state.visibleEstimate = !state.visibleEstimate;
      state.loading = false;
    },
  },
});

export const { startRequest, toggleEstimateModal, toggleSuccessModal, finishRequest, successRequest } =
  feedback.actions;

export const handleSubmit = (body, resetForm) => async (dispatch) => {
  const url = `${ROOT_URL}/rating`;
  const data = getBody(body);
  const config = {
    headers: {
      ...basicHeaders(localStorage.getItem(JWT_ACCESS)),
    },
  };

  dispatch(startRequest());
  try {
    const { data: success } = await axios.post(url, data, config);

    if (success) {
      dispatch(successRequest());
      resetForm();

      actionTriggering('feedback-send-success');
    } else {
      message.error('При получении данных произошла ошибка');

      actionTriggering('feedback-send-fail');
    }
  } catch (e) {
    message.error('Произошла ошибка сервера!');
    dispatch(finishRequest());

    actionTriggering('feedback-send-fail');
  }
};

export default feedback.reducer;
