import { useSelector, useDispatch } from 'react-redux';
import { hide, show, destroy } from '../features/modals';

export const useModal = (name) => {
  const modal = useSelector(({ modals }) => modals);
  const dispatch = useDispatch();

  const visible = modal[name];

  const hideModal = () => dispatch(hide(name));
  const showModal = () => dispatch(show(name));
  const destroyModal = () => dispatch(destroy(name));

  return { visible, showModal, hideModal, destroyModal };
};
