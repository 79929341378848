import styled from 'styled-components';

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  color: #fff;
  ${({ size }) =>
    size === 'large'
      ? `
            width: 100%;`
      : size === 'medium' &&
        `
                width: 80%;
                margin: auto;`}
`;

export const LogoImg = styled.img`
  width: 100%;
`;
