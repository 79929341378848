import { z } from 'zod';
import { DivisionEntity } from './division';
import { MetalTransferContentEntity } from './metalTransferContent';

export const MetalTransferEntity = z.object({
  id: z.number(),
  publicId: z.number(),
  createdAt: z.string(),
  division: DivisionEntity,
  contents: z.array(MetalTransferContentEntity),
});

export type MetalTransferEntity = z.infer<typeof MetalTransferEntity>;
