import { ProductActiveListEntity } from 'models/api/wts/metalPurchase/productActiveList';
import { SalesTableState } from './types';
import { SpotSellSubscriptionEntity } from 'models/api/wts/metalPurchase/spotSellSubscription';
import { SpotSubscriptionEntity } from 'models/api/wts/metalSale/spotSubscription';
import { SaleTableStatusEnum } from 'constants/metalPurchase';

export function normalizeProductsActive(data: ProductActiveListEntity['activeProducts']): SalesTableState[] {
  return data.map((product) => ({
    id: product.id,
    product,
    sellCompanyGroup: product.discounts.map((item) => item.sellCompanyGroup),
    status: product.status as SaleTableStatusEnum,
    weight: product.weight,
    createdAt: product.createdAt,
    updatedAt: product.updatedAt,
  }));
}

export const convertSpotPrice = (
  data: SpotSellSubscriptionEntity & {
    topic: string;
  }
): SpotSubscriptionEntity & {
  topic: string;
} => {
  return { ...data, fixationContentId: data.saleId };
};
