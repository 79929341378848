export const OAUTH_POPUP_NAME = 'RCS 0AUTH POPUP';

export const OAUTH_URL = 'https://fs.knfmp.net/adfs/oauth2/authorize/';
export const OAUTH_RESPONSE_TYPE = 'code';
export const OAUTH_CLIENT_ID = '4cecfd22-164d-4f57-8ddd-2fc75f1f50c9';
export const OAUTH_SCOPES = [
  'openid',
  'email',
  'profile',
  'allatclaims',
  'user_impersonation',
  'winhello_cert',
  'vpn_cert',
  'logon_cert',
];

export const POPUP_HEIGHT = 500;
export const POPUP_WIDTH = 500;
