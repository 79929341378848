import { createSlice } from '@reduxjs/toolkit';
import { SuppliesState } from './slice.types';
import {
  GET_SUPPLIES_ACTION_PREFIX,
  GET_SUPPLY_ACTION_PREFIX,
  getSupplies,
  getSupply,
  SUPPLIES_PREFIX,
} from './actions';
import { addLoadingMatchers } from 'store/utils/addLoadingMatchers';
import { getCursorValue } from '../utils';

export const INITIAL_STATE: SuppliesState = {
  list: { data: [], loading: false },
  cursorToNextPage: '',
  detail: {
    data: null,
    loading: false,
  },
};

const slice = createSlice({
  name: SUPPLIES_PREFIX,
  initialState: INITIAL_STATE,
  reducers: {
    resetAll: (state) => {
      state.list.data = INITIAL_STATE.list.data;
      state.list.loading = INITIAL_STATE.list.loading;
      state.cursorToNextPage = INITIAL_STATE.cursorToNextPage;

      state.detail.data = INITIAL_STATE.detail.data;
      state.detail.loading = INITIAL_STATE.detail.loading;
    },
    resetPagination: (state) => {
      state.cursorToNextPage = INITIAL_STATE.cursorToNextPage;
    },
    resetDetail: (state) => {
      state.detail.data = INITIAL_STATE.detail.data;
      state.detail.loading = INITIAL_STATE.detail.loading;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSupplies.fulfilled, (state, { payload, meta }) => {
      state.list.data = (meta.arg?.cursor ? state.list.data : []).concat(payload.list);
      state.cursorToNextPage = getCursorValue(payload.pagination);
    });
    builder.addCase(getSupply.fulfilled, (state, { payload }) => {
      state.detail.data = payload;
    });

    addLoadingMatchers(builder, GET_SUPPLIES_ACTION_PREFIX, 'list.loading');
    addLoadingMatchers(builder, GET_SUPPLY_ACTION_PREFIX, 'detail.loading');
  },
});

export const { name, actions, reducer } = slice;
