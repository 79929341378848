import { z, ZodType } from 'zod';
import { MessageEntity } from 'models/api/support/message';
import { TicketEntity } from 'models/api/support/ticket';
import { SupportCounters } from './counters';

export const SupportEventsEnum = z.enum([
  'CreateSupportTicket',
  'CreateSupportTicketMessage',
  'ReadSupportTicket',
  'UpdateSupportTicket',
  'UpdateSupportCounters',
]);
export type SupportEventsEnum = z.infer<typeof SupportEventsEnum>;

export const createSupportEvent = <T extends ZodType>(eventType: SupportEventsEnum, bodySchema: T) =>
  z.object({
    body: bodySchema,
    type: z.literal(eventType),
  });

export const CreateTicketEventEntity = createSupportEvent('CreateSupportTicket', TicketEntity);
export type CreateTicketEventEntity = z.infer<typeof CreateTicketEventEntity>;

export const UpdateTicketEventEntity = createSupportEvent('UpdateSupportTicket', TicketEntity);
export type UpdateTicketEventEntity = z.infer<typeof UpdateTicketEventEntity>;

export const CreateMessageEventEntity = createSupportEvent('CreateSupportTicketMessage', MessageEntity);
export type CreateMessageEventEntity = z.infer<typeof CreateMessageEventEntity>;

export const ReadMessagesEventBody = z.object({
  ticketId: z.number(),
  userId: z.number(),
  readMessagesIds: z.array(z.number()),
  readAt: z.string(),
});
export type ReadMessagesEventBody = z.infer<typeof ReadMessagesEventBody>;

export const ReadMessagesEventEntity = createSupportEvent('ReadSupportTicket', ReadMessagesEventBody);
export type ReadMessagesEventEntity = z.infer<typeof ReadMessagesEventEntity>;

export const UpdateCountersEventEntity = createSupportEvent('UpdateSupportCounters', SupportCounters);
export type UpdateCountersEventEntity = z.infer<typeof UpdateCountersEventEntity>;

export const SupportEventEntity = CreateTicketEventEntity.or(UpdateTicketEventEntity)
  .or(CreateMessageEventEntity)
  .or(ReadMessagesEventEntity)
  .or(UpdateCountersEventEntity);
export type SupportEventEntity = z.infer<typeof SupportEventEntity>;

const isEvent =
  <TType>(zodSchema: ZodType<TType>) =>
  (event: unknown): event is TType =>
    zodSchema.safeParse(event).success;
export const isSupportEvent = isEvent(SupportEventEntity);
