import React from 'react';
import types from '../actions/types';
import { Trans } from 'react-i18next';

const initialState = {
  filters: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_SUPPORT_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case types.GET_TICKETS: {
      return {
        ...state,
        tickets: action.payload.list,
        paginate: action.payload.paginate,
        filters: action.filters || null,
      };
    }
    case types.CLEAR_SUPPORT_FILTERS: {
      return {
        ...state,
        filters: null,
      };
    }

    case types.ADMIN_GET_COMMENTS: {
      return {
        ...state,
        comments: action.payload,
      };
    }
    case types.GET_TICKET_DETAILS: {
      return {
        ...state,
        details: action.payload.ticket,
        clients: action.payload.clients,
        managers: action.payload.managers,
      };
    }
    case types.HIDE_TICKET_DETAILS_LOADER: {
      return {
        ...state,
        showLoader: false,
      };
    }
    case types.SHOW_TICKET_DETAILS_LOADER: {
      return {
        ...state,
        showLoader: true,
      };
    }
    case types.CLOSE_TIKET: {
      const status = {
        close: <Trans i18nKey="feedback.Закрыто" />,
        open: <Trans i18nKey="feedback.Открыто" />,
      };

      return {
        ...state,
        details: {
          ...state.details,
          status: status[action.payload],
          status_code: action.payload,
        },
      };
    }
    case types.SAVE_IMAGE_PREVIEW: {
      return {
        ...state,
        ticketImagePreview: action.payload,
      };
    }
    case types.CLOSE_TICKET_IMAGE_PREVIEW_MODAL: {
      return {
        ...state,
        ticketImagePreview: null,
      };
    }
    default:
      return state;
  }
}
