import { z } from 'zod';
import { FixationInitFormEntity } from 'models/api/wts/metalSale/fixationInitForm';
import { SuccessEntity } from 'models/api/wts/common/success';

export const FixationMethodsEntity = z
  .object({
    data: FixationInitFormEntity,
  })
  .merge(SuccessEntity);

export type FixationMethodsEntity = z.infer<typeof FixationMethodsEntity>;
