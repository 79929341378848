import React from 'react';
import * as S from './Error.styled';
import SVG from 'react-inlinesvg';
import warningIcon from 'icons/warningTriangle.svg';
import { SpaceProps } from 'styled-system';

type ErrorProps = {
  message?: string;
} & SpaceProps;

export function Error({ message, ...props }: ErrorProps) {
  return (
    <S.Error {...props}>
      <S.ErrorIcon>
        <SVG src={warningIcon} />
      </S.ErrorIcon>
      {message}
    </S.Error>
  );
}
