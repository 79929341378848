import { useSendReactionMutation } from 'services/satisfaction/survey';

export function useSendReaction() {
  const [sendReaction, { isError, isLoading, isSuccess, error }] = useSendReactionMutation();

  return {
    sendReaction,
    isError,
    isLoading,
    isSuccess,
    error,
  };
}
