import { BatchEntity, DocumentsEntity, PaginationEntity } from './ducks/service.types';
import { DATE_API_FORMAT, DATE_CLIENT_FORMAT, SuppliesParamsEnum, SupplyIndicatorsEnum } from './consts';
import moment from 'moment';
import { parseQueryString } from '../../../utils/parseQueryString';
import { SearchParamsPayload } from './hooks/useSuppliesSearch';
import { skipEmptyValues } from '../../../utils/skipEmptyValues';
import i18n from 'i18next';
import { iconsList } from 'icons';
import { theme } from 'theme';

export const getCursorValue = ({ nextPageUrl }: PaginationEntity) => {
  if (!nextPageUrl) {
    return '';
  }
  return new URL(nextPageUrl).searchParams.get(SuppliesParamsEnum.Cursor) || '';
};

export const createSearchParams = (search: string) => {
  const searchParams = parseQueryString(search);

  if (!(SuppliesParamsEnum.DateTo in searchParams && SuppliesParamsEnum.DateFrom in searchParams)) {
    delete searchParams[SuppliesParamsEnum.DateTo];
    delete searchParams[SuppliesParamsEnum.DateFrom];
  }

  if (SuppliesParamsEnum.SubContractId in searchParams && !(SuppliesParamsEnum.ContractId in searchParams)) {
    delete searchParams[SuppliesParamsEnum.SubContractId];
  }

  if (SuppliesParamsEnum.OnlyMain in searchParams && !(SuppliesParamsEnum.ContractId in searchParams)) {
    delete searchParams[SuppliesParamsEnum.OnlyMain];
  }

  if (SuppliesParamsEnum.SubContractId in searchParams && SuppliesParamsEnum.OnlyMain in searchParams) {
    delete searchParams[SuppliesParamsEnum.OnlyMain];
    delete searchParams[SuppliesParamsEnum.SubContractId];
  }

  type StringOrUndefined = string | undefined;

  const isValidDate = (value: StringOrUndefined) => Boolean(value) && moment(value, DATE_API_FORMAT, true).isValid();
  const isValidNum = (value: StringOrUndefined) => Boolean(value) && !isNaN(Number(value));

  const map: Record<
    Keys<SearchParamsPayload>,
    {
      isValid: (value: StringOrUndefined) => boolean;
      formatter?: (value: string) => Values<SearchParamsPayload>;
    }
  > = {
    dateFrom: {
      isValid: isValidDate,
    },
    dateTo: {
      isValid: isValidDate,
    },
    num: {
      isValid: Boolean,
    },
    contractId: {
      isValid: isValidNum,
      formatter: Number,
    },
    subContractId: {
      isValid: isValidNum,
      formatter: Number,
    },
    onlyMain: {
      isValid: (value: StringOrUndefined) => Boolean(value) && Number(value) === 1,
      formatter: () => 1,
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Object.entries(map).reduce((acc: Record<string, any>, [name, utils]) => {
    const query = searchParams[name];

    if (utils.isValid(query)) {
      acc[name] = utils.formatter ? utils.formatter(query) : query;
    }
    return acc;
  }, {});
};

export const stringifySearchParams = (searchParams: SearchParamsPayload) =>
  `?${new URLSearchParams(skipEmptyValues(searchParams))}`;

export const getNumFormatter = (minimumFractionDigits = 0, options?: Intl.NumberFormatOptions) => {
  return new Intl.NumberFormat(i18n.language, {
    minimumFractionDigits,
    ...options,
  });
};

export const formatMass = (massInGram: number) =>
  getNumFormatter(1, {
    style: 'unit',
    unit: 'gram',
  }).format(massInGram);

export const formatDate = (date: string) => moment(date, DATE_API_FORMAT).format(DATE_CLIENT_FORMAT);

export const groupDocsByExt = (docsList: DocumentsEntity[]) =>
  docsList.reduce((acc: Record<string, DocumentsEntity[]>, doc) => {
    doc.extensions.forEach((ext) => {
      acc[ext] = (acc[ext] || []).concat(doc);
    });
    return acc;
  }, {});

export const groupBatchesByPlanDate = (batchesList: BatchEntity[]) =>
  batchesList.reduce((acc: Record<string, { num: string; addWork: number | null }>, batch) => {
    const BATCHES_NUMS_SEPARATOR = ', ';

    if (batch.planDate) {
      acc[batch.planDate] = {
        num: (acc[batch.planDate] ? acc[batch.planDate].num.split(BATCHES_NUMS_SEPARATOR) : [])
          .concat(batch.num)
          .join(BATCHES_NUMS_SEPARATOR),
        addWork: batch.addWork,
      };
    }

    return acc;
  }, {});

export const planDocsExist = (batches: BatchEntity[]) => batches.some((batch) => batch.planDate);

export const valueOrHyphen = (value: string | undefined) => value || '—';

export const splitLineInTheMiddle = (str: string) => {
  const trimmedStr = str.trim();
  const halfStrLength = Math.trunc(trimmedStr.length / 2);

  const splitLineAfterCenter = new RegExp(`^(.{${halfStrLength}}.*?)\\s(.*)`);

  if (splitLineAfterCenter.test(trimmedStr)) {
    return trimmedStr.replace(splitLineAfterCenter, '$1\n$2');
  }

  const splitLineBeforeCenter = /^(.*)\s(\S+)$/;
  return trimmedStr.replace(splitLineBeforeCenter, '$1\n$2');
};

export function getTagTitleByAddWork(addWork: number) {
  const map: Record<number, string> = {
    [SupplyIndicatorsEnum[4]]: 'Доработка сырья завершена',
    [SupplyIndicatorsEnum[3]]: 'Доработка сырья',
  };

  return map[addWork];
}

export function getDetailTagTitleByAddWork(addWork: number) {
  const map: Record<number, string> = {
    [SupplyIndicatorsEnum[4]]: 'Доработка завершена',
    [SupplyIndicatorsEnum[3]]: 'Партия с доработкой',
  };

  return map[addWork];
}

export function getTagColorByAddWork(addWork: number) {
  const map: Record<number, string> = {
    [SupplyIndicatorsEnum[4]]: theme.colors.green,
    [SupplyIndicatorsEnum[3]]: theme.colors['orange-8'],
    [SupplyIndicatorsEnum[2]]: theme.colors['orange-8'],
  };

  return map[addWork];
}

export function getTagIconByAddWork(addWork: number) {
  const map: Record<number, keyof typeof iconsList> = {
    [SupplyIndicatorsEnum[4]]: 'checkCircle',
    [SupplyIndicatorsEnum[3]]: 'warningCircle2',
  };

  return map[addWork];
}
