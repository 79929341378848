import React from 'react';
import { Icons } from 'components/Icons';
import * as S from './styled';

export const STUB_DOWNLOAD_DOCS_TITLE = 'Для скачивания воспользуйтесь десктопной версией';

export function StubDownloadDocs({ ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <S.Wrapper {...props}>
      <S.Icon>{Icons.infoCircle}</S.Icon>
      {STUB_DOWNLOAD_DOCS_TITLE}
    </S.Wrapper>
  );
}
