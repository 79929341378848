const VALUES_IN_PX = {
  0: '0px',
  2: '2px',
  4: '4px',
  6: '6px',
  8: '8px',
  10: '10px',
  12: '12px',
  14: '14px',
  16: '16px',
  18: '18px',
  20: '20px',
  22: '22px',
  24: '24px',
  26: '26px',
  28: '28px',
  30: '30px',
  32: '32px',
  34: '34px',
  36: '36px',
  38: '38px',
  40: '40px',
  42: '42px',
  44: '44px',
  46: '46px',
  48: '48px',
  50: '50px',
  52: '52px',
  54: '54px',
  56: '56px',
  58: '58px',
  60: '60px',
};

export const theme = {
  colors: {
    'grey-1': '#939393',
    'grey-2': '#1d1d1d',
    'grey-3': '#6C7175',
    'grey-4': '#383838',
    'grey-5': '#797979',
    'grey-6': '#BEBEBE',
    'grey-7': '#f7f7f7',
    'grey-8': '#2F3133',
    'grey-9': '#D7DCE0',
    'grey-10': '#4F4F4F',
    'grey-11': '#EEEEEE',
    'grey-12': '#F7F9FA',
    'grey-13': '#fafafa',
    'grey-14': '#E0E0E0',
    'grey-15': '#E5EAEE',
    'grey-16': '#f5f5f5',
    'grey-17': '#FCFCFC',
    'grey-18': '#A4ACB3',
    'grey-19': '#1f150b',
    'grey-20': '#f6f6f6',
    'grey-21': '#212529',
    'grey-22': '#91989E',
    'grey-23': '#2F3133',
    'grey-28': '#424547',
    'grey-32': '#4b4f52',
    blue: '#3B6BC9',
    'blue-1': '#F0F4F7',
    'blue-2': '#C9D0D8',
    'blue-3': '#E8EAEB',
    'blue-4': '#C9CDD1',
    'blue-5': '#e8f0fe',
    'blue-6': '#1D65B8',
    'blue-7': '#2D81E0',
    'blue-8': '#EBEEF2',
    'blue-grey-40': '#566166',
    'blue-grey-48': '#66737A',
    'blue-grey-54': '#74838A',
    'blue-grey-66': '#8D9FA8',
    'blue-grey-78': '#AFBFC7',
    'blue-grey-88': '#CEDAE0',
    'blue-grey-94': '#E6EDF0',
    white: '#ffffff',
    black: '#000000',
    orange: '#CD743D',
    'orange-2': '#ea8343',
    'orange-3': '#a44c14',
    'orange-4': '#d99464',
    'orange-5': '#F4D4BF',
    'orange-6': '#F2A627',
    'orange-7': '#FAF1EB',
    'orange-8': '#f0af30',
    'orange-9': '#FFFBED',
    'orange-10': '#d6946b',
    'orange-11': '#dfb49a',
    green: '#41AF29',
    'green-1': '#0D701B',
    red: '#F05630',
    yellow: '#ffe58f',
    'yellow-68': '#AD7400',
    'yellow-100': '#FFF8EB',
    'red-2': '#D0441D',
    'red-3': '#e26d6d',
  },
  lineHeights: VALUES_IN_PX,
  space: VALUES_IN_PX,
  sizes: VALUES_IN_PX,
  fontSizes: VALUES_IN_PX,
  zIndices: {
    paper: 1,
    sticky: 99,
    appHeader: 100,
    modal: 1000,
  },
  breakpoints: {
    xxl: 1920,
    xl: 1600,
    lg: 1367,
    md: 1024,
    sm: 320,
  },
  boxShadows: {
    'xs-light': '0px 4px 8px rgba(99, 111, 117, 0.08), 0px -2px 4px rgba(99, 111, 117, 0.08)',
    's-light': '0px 4px 16px rgba(99, 111, 117, 0.08), 0px -4px 8px rgba(99, 111, 117, 0.08)',
    'm-light': '0px 16px 32px rgba(99, 111, 117, 0.08), 0px -8px 24px rgba(99, 111, 117, 0.08)',
  },
} as const;
