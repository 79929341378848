import { useCloseSurveyMutation } from 'services/satisfaction/survey';

export function useCloseSurvey() {
  const [close, { isError }] = useCloseSurveyMutation();

  return {
    close,
    isError,
  };
}
