import styled from 'styled-components';
import { Modal as AntdModal } from 'antd';

export const Modal = styled(AntdModal)`
  &&& {
    top: 0;
    padding-bottom: 0;
    .ant-modal-body {
      padding: ${({ theme }) => theme.sizes[40]} ${({ theme }) => theme.sizes[32]};
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
      margin-top: ${({ theme }) => theme.sizes[12]};
      margin-bottom: ${({ theme }) => theme.sizes[12]};
      margin-left: ${({ theme }) => theme.sizes[10]};
      margin-right: ${({ theme }) => theme.sizes[10]};

      .ant-modal-body {
        padding: ${({ theme }) => theme.sizes[24]} ${({ theme }) => theme.sizes[16]};
      }
      .ant-modal-close-x {
        width: ${({ theme }) => theme.sizes[36]};
        height: ${({ theme }) => theme.sizes[36]};
        line-height: ${({ theme }) => theme.fontSizes[36]};
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Title = styled.div`
  margin-bottom: ${({ theme }) => theme.sizes[16]};
  font-size: ${({ theme }) => theme.fontSizes[20]};
  line-height: ${({ theme }) => theme.lineHeights[28]};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    margin-bottom: ${({ theme }) => theme.sizes[12]};
    font-size: ${({ theme }) => theme.fontSizes[16]};
    line-height: ${({ theme }) => theme.lineHeights[24]};
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.sizes[32]};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    margin-top: ${({ theme }) => theme.sizes[20]};
  }
`;
