import { createSlice } from '@reduxjs/toolkit';
import { generateCode } from './actions';

export const INITIAL_STATE = {
  generatedFile: {
    name: null,
    url: null,
  },
  errors: {
    data: null,
    total: 0,
  },
};

const slice = createSlice({
  name: 'codeGenerator',
  initialState: INITIAL_STATE,
  reducers: {
    reset: () => INITIAL_STATE,
  },
  extraReducers: {
    [generateCode.pending]: (state) => {
      state.loading = true;
    },
    [generateCode.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.generatedFile = payload.generatedFile;
      state.errors = payload.errors;
    },
    [generateCode.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { name, actions } = slice;

export default slice.reducer;
