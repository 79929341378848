import styled from 'styled-components';
import { Modal as AntdModal, Button as AntdButton, Carousel as AntdCarousel } from 'antd';

export const Modal = styled(AntdModal)`
  &&& {
    min-width: 665px;
    .ant-modal-header {
      padding: 16px;
      border-color: #eeeeee;
    }
    .ant-modal-title {
      color: #797979;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
    }
    .ant-modal-body {
      padding: 40px;
      height: 541px;
      text-align: center;
      > div {
        height: 100%;
        > div {
          height: 100%;
          > div {
            height: 100%;
          }
        }
      }
    }
    .ant-modal-close-x {
      width: 52px;
      height: 52px;
      line-height: 60px;
      color: #939393;
      cursor: pointer;
      svg {
        width: 20px;
        min-width: 20px;
        height: 20px;
      }
    }
  }
`;

export const Carousel = styled(AntdCarousel)`
  &&& {
    height: 100%;
    .slick-dots {
      margin-right: 30%;
      margin-left: 30%;
      margin-bottom: 0;
    }
    .slick-dots li {
      margin: 0 8px;
      width: 7px;
      height: 7px;
      border: 1px solid #cd743d;
      border-radius: 50%;
    }
    .slick-dots li button {
      background-color: transparent;
      height: 100%;
    }
    .slick-dots li.slick-active {
      background-color: #cd743d;
    }
  }
`;

export const CarouselContainer = styled.div`
  &&& {
    display: flex !important;
    flex-direction: column;
  }
`;

export const CarouselBody = styled.div`
  margin-bottom: auto;
`;

export const ImageContainer = styled.div`
  margin-bottom: 24px;
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 23px;
  color: #1d1d1d;
`;

export const Description = styled.div`
  margin-top: 12px;
  padding: 0 65px;
  font-size: 14px;
  line-height: 16px;
  color: #797979;
`;

export const Button = styled(AntdButton)`
  &&& {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    height: auto;
    background-color: #cd743d;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    border-radius: 3px;
  }
`;

export const ArrowIcon = styled.div`
  margin-left: 2px;
  margin-bottom: 2px;
  width: 14px;
  min-width: 14px;
  height: 14px;
`;

export const ButtonBack = styled(AntdButton)`
  &&& {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 8px 12px;
    height: auto;
    background-color: transparent;
    color: #797979;
    font-size: 14px;
    line-height: 16px;
    border-radius: 3px;
  }
`;
