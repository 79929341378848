import styled from 'styled-components';
import { Form as AntdForm, Input as AntdInput, Button as AntdButton } from 'antd';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: #1f150b;
`;

export const Container = styled.div`
  width: 390px;
  background-color: #fff;
  border-radius: 3px;
  padding: 20px 32px;
`;

export const LogoContainer = styled.div`
  max-width: 200px;
  margin: auto;
  margin-bottom: 42px;
`;

export const Form = styled(AntdForm)``;

export const FormItem = styled(AntdForm.Item)`
  &&& {
    &.ant-form-item-has-error .ant-input,
    &.ant-form-item-has-error .ant-input-affix-wrapper {
      box-shadow: none;
    }
    .ant-form-item-explain {
      margin-bottom: 8px;
    }
  }
`;

export const Input = styled(AntdInput.Password)`
  &&& {
    padding: 12px 12px 12px 45px;
    background-color: #f6f6f6;
    border-radius: 3px;
    font-size: 14px;
    border: none;
    width: 100%;
    box-sizing: border-box;
    &.ant-input-affix-wrapper > input.ant-input {
      background-color: #f6f6f6;
    }
  }
`;

export const Button = styled(AntdButton)`
  &&& {
    background: linear-gradient(180deg, #df955b 0%, #cd743d 100%);
    box-shadow: inset 0px -1px 0px rgba(139, 42, 42, 0.326455);
    border-radius: 3px;
    border: none;
    display: block;
    color: #fff;
    width: 100%;
    padding: 10px 0;
    margin-bottom: 20px;
    cursor: pointer;
    :hover {
      background-color: inherit;
    }
    :focus {
      background: linear-gradient(180deg, #cd743d 0%, #df955b 100%);
    }
    :active {
      background-color: #a6a6a6;
    }
  }
`;
