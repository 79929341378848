import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[24]};
  line-height: ${({ theme }) => theme.lineHeights[32]};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    font-size: ${({ theme }) => theme.fontSizes[20]};
    line-height: ${({ theme }) => theme.lineHeights[28]};
  }
`;

export const Description = styled.div`
  margin-top: ${({ theme }) => theme.sizes[8]};
  font-size: ${({ theme }) => theme.fontSizes[14]};
  line-height: ${({ theme }) => theme.lineHeights[22]};
`;

export const ButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.sizes[32]};
`;
