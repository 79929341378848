import moment from 'moment';

export function parseQueryStr(query) {
  var vars = query.split('&');
  var query_string = {};
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    var key = decodeURIComponent(pair[0]);
    var value = decodeURIComponent(pair[1]);

    if (typeof query_string[key] === 'undefined') {
      query_string[key] = decodeURIComponent(value);
    } else if (typeof query_string[key] === 'string') {
      var arr = [query_string[key], decodeURIComponent(value)];
      query_string[key] = arr;
    } else {
      query_string[key].push(decodeURIComponent(value));
    }
  }
  return query_string;
}

export function getDateParams(dateTo, dateFrom) {
  //Date Format 29-12-2018
  const dateRegEx = /((0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-[12]\d{3})/;
  let date_to = null,
    date_from = null;

  if (dateRegEx.test(dateTo)) {
    date_to = moment(dateTo, 'DD-MM-YYYY');
  }
  if (dateRegEx.test(dateFrom)) {
    date_from = moment(dateFrom, 'DD-MM-YYYY');
  }

  return {
    date_to,
    date_from,
  };
}
