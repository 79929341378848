import { mainApi } from 'services/http/mainApi';
import { createQueryFn } from 'services/http/queryFnBuilder';
import { COUNTERS_API } from 'services/support/consts';
import { SupportCounters, SupportCountersInner } from 'models/api/support/counters';
import { supportWsApi } from 'services/ws/supportWsApi';

export const countersApi = mainApi.injectEndpoints({
  endpoints: (build) => ({
    getCounters: build.query<SupportCountersInner, void>({
      queryFn: createQueryFn(
        () => ({
          url: COUNTERS_API,
          method: 'GET',
        }),
        SupportCountersInner
      ),
      async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        const onCountersUpdate = (counters: SupportCounters) => {
          updateCachedData(() => {
            return counters.supportCounters;
          });
        };

        try {
          await cacheDataLoaded;
          supportWsApi.subscribe('UpdateSupportCounters', onCountersUpdate);
        } catch {}
        await cacheEntryRemoved;
        supportWsApi.unsubscribe('UpdateSupportCounters', onCountersUpdate);
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetCountersQuery } = countersApi;
