import React from 'react';
import { icons } from 'features/sidebar/Icons';
import * as S from './styled';
import { useSelector } from 'react-redux';
import { isPermissionExist } from 'features/sidebar/utils';

export function NavItem({ iconName, count, theme = 'default', tabIndex, children, permission, extraIcon }) {
  const { permissions } = useSelector((state) => state?.auth?.init) || {};

  if (!isPermissionExist(permissions, permission)) {
    return null;
  }

  const itemTheme = {
    default: '#fff',
    secondary: '#bebebe',
  };
  const renderIcon = (iconName) => {
    // на случай пермишенов с точкой - нужно в icon сделать чрз _ => reports.agent = reports_agent
    // let prepareIconName = iconName.split('.').join('_');

    return <S.NavIcon>{Object.keys(icons).map((key) => key === iconName && icons[key])}</S.NavIcon>;
  };

  return (
    <S.NavItem
      variant={itemTheme[theme]}
      tabIndex={tabIndex}
    >
      <S.ItemNavGroup>
        {renderIcon(iconName)}
        {children}
        <S.ExtraIcon>{extraIcon}</S.ExtraIcon>
      </S.ItemNavGroup>
      {!!count && (
        <S.Badge
          count={count}
          variant={iconName === 'support' && 'secondary'}
        />
      )}
    </S.NavItem>
  );
}
