import React, { ComponentClass, FC } from 'react';
import InputMask, { Props as InputMaskProps } from 'react-input-mask';
import { InputProps } from 'antd';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { LocalesEnum } from '../../../constants/locales';

type PhoneInputProps = {
  component?: FC<InputProps> | ComponentClass<InputProps>;
} & InputProps &
  Partial<Pick<InputMaskProps, 'mask'>>;

export function PhoneInput({
  mask = '+7 (999) 999-99-99',
  component: Component = Input,
  placeholder,
  disabled,
  onChange,
  value,
  ...props
}: PhoneInputProps) {
  const { i18n } = useTranslation();

  if (i18n.language === LocalesEnum.Ru) {
    return (
      <InputMask
        mask={mask}
        disabled={disabled}
        onChange={onChange}
        value={value}
      >
        {(inputProps: InputProps) => (
          <Component
            placeholder={placeholder || '+7 (___) ___-__-__'}
            {...inputProps}
            {...props}
            disabled={disabled}
          />
        )}
      </InputMask>
    );
  }

  return (
    <Component
      placeholder={placeholder || 'Phone number'}
      disabled={disabled}
      onChange={onChange}
      value={value}
      {...props}
    />
  );
}
