import React from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { notification } from 'antd';
import { Timer } from 'components/Timer';
import i18n from 'i18next';

let timer;

export const useVersionChecker = () => {
  const handler = () => {
    const prevVersion = localStorage.getItem('hash_version') || 0;
    axios
      .get(`/version.json?t=${Date.now()}`)
      .then((latestVersion) => {
        const currentHash = latestVersion.data.hash;
        if (!prevVersion) {
          localStorage.setItem('hash_version', currentHash);
          return;
        }
        if (prevVersion !== currentHash) {
          localStorage.setItem('hash_version', currentHash);

          notification.warn({
            message: i18n.t('notifications.Получено новое обновление'),
            description: (
              <Timer
                duration={5}
                prefix={i18n.t('notifications.Перезагрузка страницы через')}
              />
            ),
            duration: 5,
          });
          setTimeout(() => window.location.reload(), 5500);
        }
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.log(error));
  };

  const subscribe = () => {
    if (timer) {
      clearInterval(timer);
    }
    timer = setInterval(handler, 20000);
  };

  const unsubscribe = () => {
    if (timer) {
      clearInterval(timer);
    }
  };

  useEffect(() => {
    subscribe();

    return unsubscribe;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
