import React from 'react';
import { Redirect } from 'react-router-dom';
import { PermissionsEnum } from 'constants/permissions';
import * as routePath from 'constants/routePaths';
import { Page } from './types';
import { ProfilePage } from './Admin/Profile';
import { UsersListPage } from './Admin/Users';

const AdminContractsPage = React.lazy(() => import('./Admin/Contracts'));
const AdminContractPage = React.lazy(() => import('./Admin/Contract'));
const AdminCompaniesPage = React.lazy(() => import('./Admin/Companies'));
const AdminCompanyPage = React.lazy(() => import('./Admin/Company'));
const AdminSupportPage = React.lazy(() => import('./Admin/support/AdminSupportPage'));
const SupportFAQPage = React.lazy(() => import('./support/SupportFAQPage'));
const AdminSupportTicketsPage = React.lazy(() => import('./Admin/support/AdminSupportTicketsPage'));
const AdminSupportTicketPage = React.lazy(() => import('./Admin/support/AdminSupportTicketPage'));
const AdminEarlyShipmentPage = React.lazy(() => import('./Admin/earlyShipment/AdminEarlyShipmentPage'));
const AdminEarlyShipmentRequestsPage = React.lazy(() => import('./Admin/earlyShipment/AdminEarlyShipmentRequestsPage'));

export const adminPages: Page[] = [
  {
    path: '/admin',
    component: () => <Redirect to="/admin/users" />,
  },
  ...ProfilePage,
  ...UsersListPage,
  {
    path: routePath.ADMIN_EARLY_SHIPMENT_PATH,
    component: AdminEarlyShipmentPage,
    exact: false,
    nestedRoutes: [
      {
        path: routePath.ADMIN_EARLY_SHIPMENT_REQUESTS_PATH,
        component: AdminEarlyShipmentRequestsPage,
        exact: true,
      },
    ],
  },
  {
    path: routePath.ADMIN_SUPPORT_PATH,
    component: AdminSupportPage,
    permission: PermissionsEnum.Support,
    exact: false,
    nestedRoutes: [
      {
        path: routePath.ADMIN_SUPPORT_TICKETS_PATH,
        component: AdminSupportTicketsPage,
        exact: true,
        permission: PermissionsEnum.Support,
      },
      {
        path: routePath.ADMIN_SUPPORT_TICKET_PATH,
        component: AdminSupportTicketPage,
        exact: true,
        permission: PermissionsEnum.Support,
      },
      {
        path: routePath.ADMIN_SUPPORT_FAQ_PATH,
        component: SupportFAQPage,
        exact: true,
        permission: PermissionsEnum.Support,
      },
    ],
  },
  {
    path: '/admin/contracts',
    component: AdminContractsPage,
  },
  {
    path: '/admin/contract/:id',
    component: AdminContractPage,
  },
  {
    path: '/admin/companies',
    component: AdminCompaniesPage,
  },
  {
    path: '/admin/company/:id',
    component: AdminCompanyPage,
  },
];
