import { mainApi } from '../http/mainApi';
import { createQueryFn } from '../http/queryFnBuilder';
import { LoginParamsSchema } from 'models/params/auth/login';
import { LoginEntity } from 'models/api/auth/login';
import { LOGIN_API, OAUTH_TOKEN_API, RESTORE_PASSWORD_API } from 'services/auth/consts';
import { RestorePasswordParamsSchema } from 'models/params/auth/restorePassword';
import { OAuthTokenParamsSchema } from 'models/params/auth/oauthToken';
import { OAuthTokenEntity } from 'models/api/auth/oauthToken';

export const authApi = mainApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<LoginEntity, LoginParamsSchema>({
      queryFn: createQueryFn(
        (data: LoginParamsSchema) => ({
          url: LOGIN_API,
          method: 'POST',
          data: { grantType: 'password', username: data.username, password: data.password },
          headers: { 'X-localization': data.language, 'Content-Type': 'multipart/form-data' },
        }),
        LoginEntity
      ),
    }),
    getOAuthToken: build.mutation<OAuthTokenEntity, OAuthTokenParamsSchema>({
      queryFn: createQueryFn(
        (data: OAuthTokenParamsSchema) => ({
          url: OAUTH_TOKEN_API,
          method: 'POST',
          data,
        }),
        OAuthTokenEntity
      ),
    }),
    restorePassword: build.mutation<void, RestorePasswordParamsSchema>({
      queryFn: createQueryFn((data: RestorePasswordParamsSchema) => ({
        url: RESTORE_PASSWORD_API,
        method: 'POST',
        data: { email: data.email },
        headers: { 'X-localization': data.language },
      })),
    }),
  }),
  overrideExisting: false,
});

export const { useLoginMutation, useGetOAuthTokenMutation, useRestorePasswordMutation } = authApi;
