import styled from 'styled-components';
import { Modal as AntdModal, Button as AntdButton } from 'antd';

export const Modal = styled(AntdModal)`
  &&& {
    max-width: 665px;
    .ant-modal-content {
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.0481771);
      border-radius: 3px;
    }
    .ant-modal-header {
      padding: 16px;
      border-color: #eeeeee;
    }
    .ant-modal-title {
      color: #797979;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
    }
    .ant-modal-body {
      padding: 40px 60px;
      text-align: center;
    }
    .ant-modal-close-x {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 52px;
      height: 52px;
      color: #939393;
      cursor: pointer;
      svg {
        min-width: 20px;
        width: 20px;
        height: 20px;
      }
    }
    @media (max-width: 850px) {
      margin-right: 6%;
      margin-left: 6%;
      .ant-modal-header {
        display: flex;
        align-items: flex-end;
        padding: 16px 20px;
        height: 52px;
      }
      .ant-modal-title {
        font-size: 14px;
        line-height: 16px;
      }
      .ant-modal-body {
        margin-top: 80px;
        padding: 20px;
        text-align: center;
      }
    }
  }
`;

export const ImageContainer = styled.div`
  margin-bottom: 24px;
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 23px;
  color: #1d1d1d;
  @media (max-width: 850px) {
    font-size: 18px;
    line-height: 21px;
  }
`;

export const Description = styled.div`
  margin-top: 16px;
  padding: 0 43px;
  font-size: 14px;
  line-height: 22px;
  color: #797979;
  @media (max-width: 850px) {
    font-size: 12px;
    line-height: 18px;
    padding: 0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`;

export const Button = styled(AntdButton)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 12px;
    width: auto;
    height: auto;
    background-color: transparent;
    color: #797979;
    font-size: 14px;
    line-height: 16px;
    border-radius: 3px;
    border-color: #797979;
    --antd-wave-shadow-color: none;
    :hover,
    :focus {
      background-color: transparent;
      color: #939393;
      border-color: #939393;
    }
    :focus {
      box-shadow: 0px 0px 4px rgba(147, 147, 147, 0.5);
    }
    :active {
      background-color: transparent;
      color: #1d1d1d;
      border-color: #1d1d1d;
    }
    ${(props) =>
      props.disabled &&
      `
            background-color: transparent;
            color: #bebebe;
            border-color: #bebebe;
            :hover,
                :active,
                :focus {
                    color: #bebebe;
                    border-color: #bebebe;
                    background-color: #transparent;
                    text-shadow: none;
                    box-shadow: none;
                }
    `}
    &.ant-btn-primary {
      background-color: #cd743d;
      color: #fff;
      border-color: #cd743d;
      :hover,
      :focus {
        background-color: #ea8343;
        border-color: #ea8343;
      }
      :active {
        background-color: #a44c14;
        border-color: #a44c14;
      }
      ${(props) =>
        props.disabled &&
        `
            background-color: #F4D4BF;
            border-color:#F4D4BF;
            :hover,
                :active,
                :focus {
                    border-color: #F4D4BF;
                    background-color: #F4D4BF;
                    text-shadow: none;
                    box-shadow: none;
                }
    `}
    }
    @media (max-width: 850px) {
      margin-top: 80px;
      width: 100%;
    }
  }
`;
