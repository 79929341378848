import types from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case types.GET_CONTRACTS: {
      return { ...state, contracts: action.payload.reverse() };
    }
    case types.GET_SUB_CONTRACTS: {
      return { ...state, subContracts: action.payload.reverse() };
    }
    case types.GET_USER_EMAILS: {
      return {
        ...state,
        emails: action.payload,
      };
    }
    default:
      return state;
  }
}
