import { FormNamesEnum } from './consts';
import { last, sort } from 'ramda';

/**
 * @param {Object} data - object with errors, grouped by index of string
 * @returns {Object} - object with errors, grouped by error's text
 */
export function normalizeErrors(data) {
  const fileWithLineNumberTemplate = new RegExp(String.raw`${FormNamesEnum.File}\.\d+`);

  const getLineNumber = (key) => Number(key.split('.')[1]) + 1;

  return Object.entries(data).reduce((acc, [key, errors]) => {
    errors.forEach((error) => {
      acc[error] = (acc[error] || []).concat(fileWithLineNumberTemplate.test(key) ? [getLineNumber(key)] : []);
    });
    return acc;
  }, {});
}

/**
 * @param {Object} data - object with errors, grouped by index of string
 * @returns {number} - total of errors
 */
export function getTotalErrors(data) {
  return Object.values(data).reduce((acc, errors) => {
    return acc + errors.length;
  }, 0);
}

export function decline(number, [one, few, many]) {
  const n = Math.abs(number) % 100;
  const n1 = n % 10;
  if (n > 10 && n < 20) {
    return many;
  }
  if (n1 > 1 && n1 < 5) {
    return few;
  }
  if (n1 === 1) {
    return one;
  }
  return many;
}

export const trimDot = (str) => (str.endsWith('.') ? str.slice(0, str.length - 1) : str);

export const debounce = (fn, delay) => {
  let timerId = null;
  return (...args) => {
    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(fn, delay, ...args);
  };
};

/**
 * @param {number[]} numberList - list with numbers
 * @returns {(string | numbers)[]} - list with intervals
 */
export const getIntervalsList = (numberList) => {
  const sortedNumberListByAsc = sort((a, b) => a - b, numberList);

  let result = [];
  let sequence = [];

  const createInterval = (sequence) => {
    if (sequence.length <= 2) {
      return sequence;
    } else {
      return [`${sequence[0]}-${last(sequence)}`];
    }
  };

  for (let i = 0; i <= sortedNumberListByAsc.length; i++) {
    const currentEl = sortedNumberListByAsc[i];

    if (i === 0) {
      sequence.push(currentEl);
      continue;
    }

    if (currentEl - last(sequence) === 1) {
      sequence.push(currentEl);
    } else {
      result = result.concat(createInterval(sequence));
      sequence = [currentEl];
    }

    if (i === sortedNumberListByAsc.length - 1) {
      result = result.concat(createInterval(sequence));
      sequence = [];
    }
  }

  return result;
};
