import { apiGet, apiPost, apiDelete } from '../../../services/helper';

export const getUsers = async (id) => {
  const { data } = await apiGet(`/v1/admin/users/${id}`);
  return data;
};

export const updateUsers = async (id, userData) => {
  const { data } = await apiPost(`/v1/admin/users/${id}`, userData);
  return data;
};

export const createUser = async (userDate) => {
  // there isnt avatar, u have to use updateAvatar
  const { data } = await apiPost('/v1/admin/users', userDate);
  return data;
};

export const updatePassword = async (id, password) => {
  const { data } = await apiPost(`/v1/admin/users/${id}/password`, password);
  return data;
};

export const updateAvatar = async (id, file) => {
  const { data } = await apiPost(`/v1/admin/users/${id}/avatar`, file, {
    'Content-Type': 'multipart/form-data',
  });
  return data;
};

export const deleteUser = async (id) => {
  const { data } = await apiDelete(`/v1/admin/users/${id}`);
  return data;
};

export const attachRole = async (userId, roleId) => {
  const { data } = await apiPost(`/v1/admin/users/${userId}/role/attach`, {
    roleId,
  });
  return data;
};

export const detachRole = async (userId, roleId) => {
  const { data } = await apiPost(`/v1/admin/users/${userId}/role/detach`, {
    roleId,
  });
  return data;
};

export const updateLocale = async (userId, locale) => {
  const { data } = await apiPost(`/v1/admin/users/${userId}/locale`, { locale });
  return data;
};
