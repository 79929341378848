import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Subsections } from '../../../../components/Subsections';
import { UserDetail } from '../../../../features/User/UserDetail';
import { SubsectionsAffix } from './SubsectionsAffix';

export function UserHeader() {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 850px)' });
  return (
    <header>
      <UserDetail>
        {(id, isSyncedAd) => (
          <Subsections
            affix={
              !isSmallScreen && (
                <SubsectionsAffix
                  id={id}
                  isSyncedAd={isSyncedAd}
                />
              )
            }
            style={{ marginTop: 20 }}
            sections={[
              {
                title: 'Общее',
                link: `/admin/user/${id}`,
              },
              {
                title: 'Компании',
                link: `/admin/user/${id}/companies`,
              },
            ]}
          />
        )}
      </UserDetail>
    </header>
  );
}
