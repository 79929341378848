import { z } from 'zod';
import { ReserveFixationContentEntity } from 'models/api/wts/metalSale/fixationContent';
import { FixationFormEntity } from 'models/api/wts/metalSale/fixationForm';
import { ReserveEntity } from 'models/api/wts/metalSale/reserve';

export const ReserveMetalFormEntity = z.object({
  fixation: FixationFormEntity.omit({ reserve: true }).merge(
    z.object({
      reserve: ReserveEntity,
    })
  ),
  items: z.array(ReserveFixationContentEntity),
});

export type ReserveMetalFormEntity = z.infer<typeof ReserveMetalFormEntity>;

export const ApiReserveMetalFormEntity = ReserveMetalFormEntity.omit({ items: true }).merge(
  z.object({
    items: z.array(
      ReserveFixationContentEntity.omit({ amount: true }).merge(
        z.object({
          amount: z.number(),
          limitAmount: z.number(),
        })
      )
    ),
  })
);

export type ApiReserveMetalFormEntity = z.infer<typeof ApiReserveMetalFormEntity>;
