import { PermissionsEnum } from 'constants/permissions';
import { isEmpty } from 'lodash-es';

// TODO требуется уточнить у бэкендеров список всех пермишенов и отрефакторить код, где они используются
export function isPermissionExist(permissionsList: string[], permission: string) {
  if (permission === 'balance') {
    return (
      permissionsList.includes(PermissionsEnum.Supply) ||
      permissionsList.includes(PermissionsEnum.Release) ||
      permissionsList.includes(PermissionsEnum.Shipment)
    );
  } else if (permission === PermissionsEnum.Finances) {
    return permissionsList.includes('finances.dkz') || permissionsList.includes('finances.act');
  }

  return permissionsList.includes(permission);
}

export const isEmptyNavBlock = (pages: never[], permissionsList: string[]) =>
  isEmpty(pages.filter(({ permission }) => isPermissionExist(permissionsList, permission)));
