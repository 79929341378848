import { createSlice } from '@reduxjs/toolkit';

const invoices = createSlice({
  name: 'invoices',
  initialState: {
    checkboxes: [],
    filters: [],
    step: 1,
  },
  reducers: {
    startRequest(state) {
      state.loading = true;
    },
    handleCheckboxes(state, { payload }) {
      state.checkboxes = [...payload];
    },
    unselectCheckboxes(state) {
      state.checkboxes = [];
    },
    handleChangeStep(state, { payload }) {
      const { step } = payload;
      state.step = state.step + +step;
    },
  },
});

export const { handleCheckboxes, unselectCheckboxes, handleChangeStep } = invoices.actions;

export default invoices.reducer;
