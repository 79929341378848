import React from 'react';
import types from '../actions/types';
import { Trans } from 'react-i18next';

const initialState = {
  searchStr: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_TICKETS: {
      return {
        ...state,
        list: action.payload.list,
        paginate: action.payload.paginate,
        ticketDetails: null,
        ticketComments: null,
        searchStr: action.searchStr || '',
      };
    }
    case types.GET_MODAL_INFROMATION: {
      return {
        ...state,
        isManager: action.is_manager,
        company_users: action.company_user,
        desc: action.desc,
      };
    }
    case types.GET_TICKET_TEMPLATES: {
      return {
        ...state,
        templates: action.templates,
      };
    }
    case types.GET_TICKET_DETAILS: {
      return {
        ...state,
        ticketDetails: action.payload,
      };
    }
    case types.GET_TICKET_MESSAGES: {
      return {
        ...state,
        ticketComments: action.payload,
      };
    }
    case types.CLOSE_TIKET: {
      const status = {
        close: <Trans i18nKey="feedback.Закрыто" />,
        open: <Trans i18nKey="feedback.Открыто" />,
      };

      return {
        ...state,
        ticketDetails: {
          ...state.ticketDetails,
          ticket: {
            ...state.ticketDetails.ticket,
            ...{
              status: status[action.payload],
              status_code: action.payload,
            },
          },
        },
      };
    }

    case types.SAVE_IMAGE_PREVIEW: {
      return {
        ...state,
        ticketImagePreview: action.payload,
      };
    }
    case types.CLOSE_TICKET_IMAGE_PREVIEW_MODAL: {
      return {
        ...state,
        ticketImagePreview: null,
      };
    }
    default:
      return state;
  }
}
