import { useCallback, useState } from 'react';
import { loginAnalytics } from 'features/Login/loginAnalytics';

export function useRestorePasswordVisible() {
  const [isPasswordRestoring, setPasswordRestoring] = useState(false);

  const openPasswordRestoring = useCallback(() => {
    setPasswordRestoring(true);
    loginAnalytics.openRestorePasswordForm();
  }, []);

  const closePasswordRestoring = useCallback(() => {
    setPasswordRestoring(false);
    loginAnalytics.closeRestorePasswordForm();
  }, []);

  return {
    isPasswordRestoring,
    openPasswordRestoring,
    closePasswordRestoring,
  };
}
