import { createAsyncThunk } from '@reduxjs/toolkit';
import download from 'downloadjs';
import { message } from 'antd';
import * as shipmentsService from './service';

export const getShipments = createAsyncThunk('shipments/getShipments', async (filters) => {
  const { data } = await shipmentsService.getBalanceShipments(filters);

  return { ...data, filters };
});

export const getCompanies = createAsyncThunk('shipments/getCompanies', async () => {
  const { data } = await shipmentsService.getCompaniesShipments();

  return data;
});

export const downloadTessaDocument = createAsyncThunk('shipments/downloadTessaDocument', async (document) => {
  try {
    const { data } = await shipmentsService.downloadDocument(document);

    download(data.file, `${data.name}.${data.extension}`, `application/${data.extension}`);
    return {
      document,
      file: data,
    };
  } catch (error) {
    message.error('Не удалось скачать файл');
  }
});
