import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getQuestions, getRubrics } from './actions';
import { FAQState } from './slice.types';
import { ALL_QUESTIONS_RUBRIC_ID } from 'features/support/FAQ/consts';
import { isActiveRubricExist } from 'features/support/FAQ/utils';

export const INITIAL_STATE: FAQState = {
  questions: {
    data: [],
    loading: false,
  },
  rubrics: {
    data: [],
    loading: false,
  },
  activeRubricId: null,
  search: '',
};

const slice = createSlice({
  name: 'faq',
  initialState: INITIAL_STATE,
  reducers: {
    setActiveRubric: (state, action: PayloadAction<number>) => {
      state.activeRubricId = action.payload;
      state.search = INITIAL_STATE.search;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      if (!isActiveRubricExist(state.activeRubricId)) {
        state.activeRubricId = ALL_QUESTIONS_RUBRIC_ID;
      }
      state.search = action.payload;
    },
    reset: (state) => {
      state.questions = INITIAL_STATE.questions;
      state.rubrics = INITIAL_STATE.rubrics;
      state.activeRubricId = INITIAL_STATE.activeRubricId;
      state.search = INITIAL_STATE.search;
    },
    resetActiveRubric: (state) => {
      state.search = INITIAL_STATE.search;
      state.questions = INITIAL_STATE.questions;
      state.activeRubricId = INITIAL_STATE.activeRubricId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getQuestions.pending, (state) => {
      state.questions.loading = true;
    });
    builder.addCase(getQuestions.fulfilled, (state, action) => {
      state.questions.data = action.payload;
      state.questions.loading = false;
    });
    builder.addCase(getQuestions.rejected, (state) => {
      state.questions.loading = false;
    });

    builder.addCase(getRubrics.pending, (state) => {
      state.rubrics.loading = true;
    });
    builder.addCase(getRubrics.fulfilled, (state, action) => {
      state.rubrics.data = action.payload;
      state.rubrics.loading = false;
    });
    builder.addCase(getRubrics.rejected, (state) => {
      state.rubrics.loading = false;
    });
  },
});

export const { name, actions, reducer } = slice;
