import React from 'react';
import { Icons } from 'components/Icons';
import { svgImage } from 'components/modals/DocSearchModal/svgImage';
import * as S from './styled';

export const MODAL_NAME = 'NewSectionDocSearch';

export function NewSectionDocSearch({ title = 'Новый раздел', show, hideModal, onSubmit }) {
  return (
    <S.Modal
      title={title}
      visible={show}
      footer={null}
      onCancel={hideModal}
      centered={true}
      closeIcon={Icons.cancel}
      width="auto"
    >
      <S.ImageContainer>{svgImage}</S.ImageContainer>
      <S.Title>Добавлен новый раздел &#171;Поиск документов&#187;</S.Title>
      <S.Description>
        Быстрый доступ к документам, пакетное скачивание, фильтрация, отслеживание статусов подписания и др.
      </S.Description>
      <S.ButtonContainer>
        <S.Button
          type="primary"
          onClick={onSubmit}
        >
          Перейти в раздел
        </S.Button>
      </S.ButtonContainer>
    </S.Modal>
  );
}
