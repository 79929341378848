import { z } from 'zod';
import { MetalEntity } from 'models/api/wts/common/metal';
import { DiscountEntity } from 'models/api/wts/common/discount';
import { FixationMethodsEnum, TimeTypeEnum } from 'constants/metalSale';
import { AmountEntity } from 'models/api/wts/metalSale/amount';
import { CurrencyDateEntity, MetalDateEntity } from '../common/calendars';
import { SpotMethodEntity } from '../common/spot';
import { createCalendarEntity } from '../common/createCalendar';

export const MetalCalendarEntity = createCalendarEntity(z.array(MetalDateEntity));

export const CurrencyCalendarEntity = createCalendarEntity(z.array(CurrencyDateEntity));

export const FixOrKitkoMethodEntity = z.object({
  metal: MetalCalendarEntity,
  currency: CurrencyCalendarEntity,
});

export const FixationContentMethodsEntity = z
  .object({
    [FixationMethodsEnum.enum.spot]: SpotMethodEntity,
    [FixationMethodsEnum.enum.kitco]: FixOrKitkoMethodEntity,
    [FixationMethodsEnum.enum.fix]: FixOrKitkoMethodEntity,
  })
  .partial();

export const FixOrKitkoPriceEntity = z.object({
  metal: z
    .object({
      date: z.string(),
      type: TimeTypeEnum,
      ouncePrice: z.number().or(z.null()),
      gramPrice: z.number().or(z.null()),
    })
    .or(z.null()),
  currency: z
    .object({
      date: z.string(),
      price: z.number().or(z.null()),
    })
    .or(z.null()),
  type: FixationMethodsEnum,
});

export const SpotPriceEntity = z.object({
  metal: z.number().or(z.null()),
  currency: z.number().or(z.null()),
  type: FixationMethodsEnum,
});

export const FixationContentEntity = z.object({
  fixationContentId: z.string(),
  amount: z.number(),
  metal: MetalEntity,
  discount: DiscountEntity,
  methods: FixationContentMethodsEntity,
  price: FixOrKitkoPriceEntity.or(SpotPriceEntity).or(z.null()),
});

export const ReserveFixationContentEntity = z.object({
  fixationContentId: z.string(),
  amount: AmountEntity,
  metal: MetalEntity,
  discount: DiscountEntity,
  methods: z.object({
    [FixationMethodsEnum.enum.spot]: SpotMethodEntity,
  }),
});

export type SpotPriceEntity = z.infer<typeof SpotPriceEntity>;
export type FixOrKitkoPriceEntity = z.infer<typeof FixOrKitkoPriceEntity>;
export type ReserveFixationContentEntity = z.infer<typeof ReserveFixationContentEntity>;
export type FixationContentEntity = z.infer<typeof FixationContentEntity>;
export type FixationContentMethodsEntity = z.infer<typeof FixationContentMethodsEntity>;
export type FixOrKitkoMethodEntity = z.infer<typeof FixOrKitkoMethodEntity>;
