import { actionTriggering } from 'analytics';
import { AppLinksLocation } from 'features/AppLinks/AppLinks';

class AppLinksAnalytics {
  readonly CLICK_LINK = 'app-links__click';

  constructor(protected dispatch = actionTriggering) {}

  /** Клик по ссылке
   * @param store Маркетплейс
   * @param location Откуда был переход
   * */
  clickLink = (store: 'app-store' | 'google-play', location: AppLinksLocation) => {
    this.dispatch(this.CLICK_LINK, {
      ActionTargetName: store,
      ActionTargetValue: location,
    });
  };
}

export const appLinksAnalytics = new AppLinksAnalytics();
