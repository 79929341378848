import axios from 'axios';
import { message } from 'antd';
import { ROOT_URL, JWT_ACCESS } from './connect';
import types from './types';
import { checkError } from 'actions/errorText';

/*
getRole необходим для получения ролей относительно прав текущего пользователя
*/
export const getRoles = () => {
  return axios({
    method: 'GET',
    url: `${ROOT_URL}/v1/admin/roles`,
    headers: {
      Authorization: `Bearer  ${localStorage.getItem(JWT_ACCESS)}`,
    },
  })
    .then((response) => ({
      type: types.GET_ADMIN_ROLES,
      payload: response.data.data,
    }))
    .catch((error) => (dispatch) => {
      checkError(error, dispatch, 'entityUtils', 'roles');
    });
};
/*
getRoleSync необходим для получения полного списка ролей
*/
// export const getRolesSync = () => {
//     return axios({
//         method: 'GET',
//         url: `${ROOT_URL}/admin/filters/roles/`,
//         headers: {
//             Authorization: `Bearer  ${localStorage.getItem(JWT_ACCESS)}`,
//         },
//     })
//         .then((response) => ({
//             type: types.GET_ADMIN_ROLES,
//             payload: response.data.data,
//         }))
//         .catch((error) => (dispatch) => {
//             checkError(error, dispatch, 'entityUtils', 'roles');
//         });
// };

export const getRelationTop = (currentType, relation, relationID, search) => {
  return axios
    .get(`${ROOT_URL}/v1/admin/${relation}/${relationID}/${currentType}/search`, {
      params: {
        search,
      },
      headers: {
        Authorization: `Bearer  ${localStorage.getItem(JWT_ACCESS)}`,
      },
    })
    .then((response) => ({
      type: types.GET_RELATION_TOP,
      payload: response.data.data,
      currentType,
    }))
    .catch((error) => (dispatch) => {
      checkError(error, dispatch, 'entityUtils', 'top');
    });
};

export const getReport = (link) => {
  return (dispatch) => {
    const stopLoading = message.loading('', 0);
    axios
      .get(`${ROOT_URL}/v1/admin/${link}`, {
        headers: {
          Authorization: `Bearer  ${localStorage.getItem(JWT_ACCESS)}`,
        },
      })
      .then((response) => {
        stopLoading();
        message.success(response.data.message);
      })
      .catch((error) => {
        checkError(error, dispatch, 'entityUtils', 'top');
        stopLoading();
      });
  };
};
