export function parseQueryStr(query) {
  var vars = query.split('&');
  var query_string = {};
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    var key = decodeURIComponent(pair[0]);
    var value = decodeURIComponent(pair[1]);

    if (typeof query_string[key] === 'undefined') {
      query_string[key] = decodeURIComponent(value);
    } else if (typeof query_string[key] === 'string') {
      var arr = [query_string[key], decodeURIComponent(value)];
      query_string[key] = arr;
    } else {
      query_string[key].push(decodeURIComponent(value));
    }
  }
  return query_string;
}

export function setParameter(url, param, paramVal) {
  var parts = url.split('?');
  var baseUrl = parts[0];
  var oldQueryString = parts[1];
  var newParameters = [];
  if (oldQueryString) {
    var oldParameters = oldQueryString.split('&');
    for (var i = 0; i < oldParameters.length; i++) {
      // eslint-disable-next-line eqeqeq
      if (oldParameters[i].split('=')[0] != param) {
        newParameters.push(oldParameters[i]);
      }
    }
  }
  if (paramVal !== '' && paramVal !== null && typeof paramVal !== 'undefined') {
    newParameters.push(param + '=' + encodeURI(paramVal));
  }
  if (newParameters.length > 0) {
    changeUrl(baseUrl + '?' + newParameters.join('&'));
  } else {
    changeUrl(baseUrl);
  }
}

export const changeUrl = (url) => {
  window.history.pushState(null, '', url);
};
