import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const getHash = () => (process.env.NODE_ENV === 'development' ? '' : `?hash=${Date.now()}`);

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // fallbackLng: 'ru',
    // debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: true,
    },
    backend: {
      loadPath: `/locales/{{lng}}/translation.json${getHash()}`,
    },
  });

export default i18n;
