import React from 'react';
import { useMount } from 'react-use';
import { Spin } from 'antd';
import * as S from './OAuth.styled';
import { sendMessageToWindowOpener } from 'features/OAuth/utils';
import { useStorage } from 'hooks/useStorage';
import { OAUTH_STATE_STORAGE_KEY, OAUTH_STATE_STORAGE_TYPE } from 'constants/oauth';
import { parseQueryString } from 'utils/parseQueryString';
import { OAuthPageParamsSchema, OAuthStateSchema } from 'models/params/auth/oauth';

export function OAuth() {
  const { storageValue } = useStorage({
    type: OAUTH_STATE_STORAGE_TYPE,
    key: OAUTH_STATE_STORAGE_KEY,
    schema: OAuthStateSchema,
  });

  useMount(() => {
    const searchParams = parseQueryString(window.location.search);
    const parsedResult = OAuthPageParamsSchema.safeParse(searchParams);

    if (parsedResult.success && parsedResult.data.state === storageValue) {
      sendMessageToWindowOpener({ code: parsedResult.data.code, state: parsedResult.data.state });
      return;
    }

    sendMessageToWindowOpener(null);
  });

  return (
    <S.OAuth>
      <Spin />
    </S.OAuth>
  );
}
