import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosResponse } from 'axios';
import type { AxiosRequestConfig } from 'axios';
import { HttpErrorCodesEnum } from '../../errors/types';
import { createUnauthorizedError } from '../../errors/unauthorizedError';
import { createBadRequestError } from '../../errors/badRequestError';
import { createNotFoundError } from '../../errors/notFoundError';
import { createServerInternalError } from 'errors/serverInternalError';
import { createHttpError } from 'errors/httpError';
import { getDataFromResponse } from 'utils/getDataFromResponse';
import { createError, Error as AppError } from '../../errors/error';
import { createAccessDeniedError } from 'errors/accessDeniedError';
import { BaseQueryApi, QueryReturnValue } from '@reduxjs/toolkit/src/query/baseQueryTypes';
import { axiosInstance } from 'services/axiosInstance';

export type AxiosBaseQueryArgs = {
  url: string;
  method: AxiosRequestConfig['method'];
  data?: AxiosRequestConfig['data'];
  params?: AxiosRequestConfig['params'];
  headers?: AxiosRequestConfig['headers'];
};

export type AxiosBaseQueryMeta = {
  message?: string;
};

function createErrorFromAxiosResponse({ data, status }: AxiosResponse) {
  const map = {
    [HttpErrorCodesEnum.Unauthorized]: createUnauthorizedError,
    [HttpErrorCodesEnum.BadRequest]: createBadRequestError,
    [HttpErrorCodesEnum.NotFound]: createNotFoundError,
    [HttpErrorCodesEnum.InternalServerError]: createServerInternalError,
    [HttpErrorCodesEnum.AccessDenied]: createAccessDeniedError,
  };

  const code = status.toString() as unknown as HttpErrorCodesEnum;

  return (
    map[code]?.(data?.message, data?.data) ||
    createHttpError(status, data?.message, data?.data, undefined, !!data?.message)
  );
}

export function axiosBaseQuery(
  { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  // eslint-disable-next-line @typescript-eslint/ban-types
): BaseQueryFn<AxiosBaseQueryArgs, unknown, AppError, {}, AxiosBaseQueryMeta> {
  return async function (
    { url, method, data, params, headers }: AxiosBaseQueryArgs,
    api: BaseQueryApi, // eslint-disable-line @typescript-eslint/no-unused-vars
    extraOptions: Record<string, unknown> // eslint-disable-line @typescript-eslint/no-unused-vars
  ): Promise<QueryReturnValue<unknown, AppError, AxiosBaseQueryMeta>> {
    try {
      const result: AxiosResponse<ApiResponseEntity<unknown>> = await axiosInstance({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
      });
      return { data: getDataFromResponse(result).data, meta: { message: result.data.message } };
    } catch (err) {
      return {
        error:
          axios.isAxiosError(err) && err.response
            ? createErrorFromAxiosResponse(err.response)
            : createError(err instanceof Error ? err.message : ''),
      };
    }
  };
}
