import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import auth from './auth';
import layout from './layout';
import loader from './loader';
import profile from './profile';
import managers from './managers';
import documents from './documents';
import notifications from './notifications';
import dkz from './dkz';
import balance from './balance';
import finances from '../features/finances/duck/slice';
import reports from './reports';
import filters from './filters';
import support from 'reducers/support';
import proposal from './proposal';
import permissions from './permissions';
import access from './access';
import services from './services';
import feedback from '../components/Feedback/Features';
import dashboard from '../features/dashboard/duck/slice';
import bankShipments from '../features/bank-shipments';
import Inventory from './inventory';
import modals from '../features/modals';
import prices from '../components/DocumentsPrices/Features';
import wts from 'ducks/wts/metalSale/slice';
import wtsPurchase from 'ducks/wts/metalPurchase/slice';
import questionnaire from 'features/questionnaire/duck/slice';
import badLinkReset from '../pages/auth/BadLinkReset/slice';
import adminList from './adminList';
import adminUtils from './adminUtils';
import adminDetails from './adminDetails';
import adminSupport from 'reducers/adminSupport';
import profileAdmin from '../components/ProfileAdmin/slice';
import remains from '../features/remains/duck/slice';
import docsSearch from '../features/docSearch/duck/slice';
import shipments from '../features/bank-shipments/duck/slice';
import localization from '../features/sidebar/service';
import worldPrices from '../features/prices/duck/slice';
import codeGenerator from '../features/invoices/codes/duck/slice';
import { reducer as faq } from 'features/support/FAQ/ducks/slice';
import { reducer as banners } from 'features/Banners/ducks/slice';
import { reducer as supplies } from 'features/balance/Supplies/ducks/slice';
import { reducer as adminMessages } from 'ducks/admin/support/messages/slices/adminMessagesSlice';
import { reducer as messages } from 'ducks/support/messages/slices/messagesSlice';
import { reducer as ticketsList } from 'ducks/support/ticketsList/slices/ticketsListSlice';
import { reducer as adminTicketsList } from 'ducks/admin/support/ticketsList/slices/adminTicketsListSlice';
import { reducer as supportWsConnection } from 'ducks/support/wsConnection/slices/supportWsConnectionSlice';
import { mainApi } from 'services/http/mainApi';

const rootReducer = combineReducers({
  auth,
  form,
  layout,
  managers,
  documents,
  notifications,
  loader,
  profile,
  profileAdmin,
  permissions,
  dkz,
  balance,
  finances,
  reports,
  filters,
  support,
  proposal,
  access,
  services,
  feedback,
  dashboard,
  bankShipments,
  modals,
  ...Inventory,
  prices,
  questionnaire,
  badLinkReset,
  adminUtils,
  adminList,
  adminDetails,
  adminSupport,
  remains,
  wts,
  wtsPurchase,
  docsSearch,
  shipments,
  localization,
  worldPrices,
  codeGenerator,
  faq,
  banners,
  supplies,
  adminMessages,
  messages,
  ticketsList,
  adminTicketsList,
  supportWsConnection,
  [mainApi.reducerPath]: mainApi.reducer,
});

export default rootReducer;
