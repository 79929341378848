// eslint-disable-next-line @typescript-eslint/no-explicit-any
type HandlerType = (...args: any[]) => any;

export const asyncPipe =
  <H extends HandlerType>(handler: H, ...otherHandlers: HandlerType[]) =>
  <R>(...params: Parameters<H>): Promise<R> => {
    const promise = Promise.resolve().then(() => handler(...params));

    return otherHandlers.reduce((acc, cb) => {
      // eslint-disable-next-line no-param-reassign
      acc = acc.then(cb);
      return acc;
    }, promise);
  };
