import styled from 'styled-components';

export const Dot = styled('i')`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  animation: jumpDot linear 2s infinite;

  @keyframes jumpDot {
    0% {
      transform: translateY(0px);
    }
    16.66% {
      transform: translateY(-3px);
    }
    33.32% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  :nth-child(1) {
    animation-delay: 0.65s;
  }
  :nth-child(2) {
    animation-delay: 0s;
  }
  :nth-child(3) {
    animation-delay: 0.295s;
  }
`;

export const DottyLoader = styled.span<{ variant: 'light' | 'dark' }>`
  display: inline-grid;
  height: 19px;
  align-items: center;
  grid-auto-flow: column;
  grid-column-gap: 3px;

  ${Dot} {
    background-color: ${({ variant, theme }) => (variant === 'light' ? theme.colors.white : theme.colors['grey-5'])};
  }
`;
