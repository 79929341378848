import React, { CSSProperties, PropsWithChildren, ReactNode } from 'react';
import WelcomeModals from '../../WelcomeModals';
import * as S from './styled';
import { Header, HeaderTitle, HeaderDescription } from 'components/layout/Header';
import { Banners, BannersProps } from '../../../features/Banners';
import { useMount } from 'react-use';
import { scrollToTop } from 'utils/scrollToTop';

type PageProps = PropsWithChildren<
  Partial<{
    title: ReactNode;
    description: ReactNode;
    headerBottom: ReactNode;
    actions: ReactNode;
    resetContentPadding: boolean;
    resetHeaderPadding: boolean;
    steps: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
    style: CSSProperties;
    headerStyle: CSSProperties;
    customHeader: ReactNode;
    banners?: BannersProps;
    isHeaderFixed?: boolean;
    headerTopPaneStyle?: CSSProperties;
    isCallOrderVisible?: boolean;
    className?: string;
  }>
>;

export function Page({
  title,
  description,
  headerBottom,
  actions,
  children,
  resetContentPadding,
  resetHeaderPadding,
  steps,
  style,
  headerStyle,
  isHeaderFixed,
  customHeader,
  headerTopPaneStyle,
  banners = {},
  className,
}: PageProps) {
  useMount(() => {
    scrollToTop();
  });

  const showNativeHeader = title || actions || description || headerBottom;
  const { section: bannersSection, ...bannersStylingProps } = banners;

  return (
    <S.Wrapper
      className={className}
      style={style}
    >
      {customHeader ||
        (showNativeHeader && (
          <Header
            resetPadding={resetHeaderPadding}
            style={headerStyle}
            isFixed={isHeaderFixed}
          >
            <S.TopPane style={headerTopPaneStyle}>
              {steps && (
                <S.StepsWrapper>
                  <S.CurrentStep>{steps?.[0]}</S.CurrentStep>
                  {steps?.[1] && <S.MaxSteps>/ {steps?.[1]}</S.MaxSteps>}
                </S.StepsWrapper>
              )}
              {title && <HeaderTitle mr="auto">{title}</HeaderTitle>}
              {actions}
            </S.TopPane>
            {description && <HeaderDescription mt={4}>{description}</HeaderDescription>}
            {headerBottom && <S.HeaderBottom>{headerBottom}</S.HeaderBottom>}
          </Header>
        ))}
      <Banners
        {...bannersStylingProps}
        section={bannersSection}
      />
      <S.Content padding={resetContentPadding}>{children}</S.Content>
      {/*{isCallOrderVisible !== false && <CallOrder />} TODO кнопка временно скрыта, требуются доработки */}
      <WelcomeModals />
    </S.Wrapper>
  );
}
