import { BRAND, z } from 'zod';
import { createHttpError, HttpErrorSchema } from './httpError';
import { HttpErrorCodesEnum } from './types';
import i18n from 'i18next';
import { isError } from './error';

export const BadRequestErrorSchema = z
  .object({
    code: z.literal(HttpErrorCodesEnum.BadRequest),
  })
  .merge(HttpErrorSchema)
  .brand<'BadRequest'>();

export type BadRequestError = z.infer<typeof BadRequestErrorSchema>;

export function createBadRequestError(message?: string, data?: unknown): BadRequestError {
  return {
    ...createHttpError(
      HttpErrorCodesEnum.BadRequest,
      message || i18n.t('error.Ошибка запроса'),
      data,
      undefined,
      !!message
    ),
    [BRAND]: {
      BadRequest: true,
    },
  };
}

export const isBadRequestError = isError<BadRequestError>(BadRequestErrorSchema);
