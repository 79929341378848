import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import { AnyAction } from 'redux';
import { set } from 'lodash-es';

const isPendingAction = (action: AnyAction, prefix: string) => {
  return action.type?.startsWith(`${prefix}/`) && action.type?.endsWith('/pending');
};

const isRejectedAction = (action: AnyAction, prefix: string) => {
  return action.type?.startsWith(`${prefix}/`) && action.type?.endsWith('/rejected');
};

const isFulfilledAction = (action: AnyAction, prefix: string) => {
  return action.type?.startsWith(`${prefix}/`) && action.type?.endsWith('/fulfilled');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addLoadingMatchers = (builder: ActionReducerMapBuilder<any>, prefix: string, path = 'loading'): void => {
  builder.addMatcher(
    (action) => isRejectedAction(action, prefix) || isFulfilledAction(action, prefix),
    (state) => {
      set(state, path, false);
    }
  );
  builder.addMatcher(
    (action) => isPendingAction(action, prefix),
    (state) => {
      set(state, path, true);
    }
  );
};
