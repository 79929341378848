import { useDispatch, useSelector } from 'react-redux';

// import * as actions from '../components/Profile/actions';

import * as actions from '../components/ProfileAdmin/actions';

export const useProfile = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state?.profileAdmin);

  const fetchUsers = (id) => {
    dispatch(actions.fetchUsers(id));
  };
  const createUser = async (userData) => {
    return dispatch(actions.createUser(userData));
  };
  const updateUsers = async (id, userData) => {
    return dispatch(actions.updateUsers({ id, userData }));
  };
  const updatePassword = (id, password) => {
    dispatch(actions.updatePassword({ id, password }));
  };
  const updateAvatar = (id, file) => {
    dispatch(actions.updateAvatar({ id, file }));
  };
  const deleteUser = (id) => {
    dispatch(actions.deleteUser(id));
  };
  const attachRole = (userId, roleId) => {
    dispatch(actions.attachRole({ userId, roleId }));
  };
  const detachRole = (userId, roleId) => {
    dispatch(actions.detachRole({ userId, roleId }));
  };
  const updateLocale = (userId, locale) => {
    dispatch(actions.updateLocale({ userId, locale }));
  };

  return {
    store,
    fetchUsers,
    createUser,
    updateUsers,
    updatePassword,
    updateAvatar,
    deleteUser,
    attachRole,
    detachRole,
    updateLocale,
  };
};
