import types from '../actions/types';

const initialState = {
  paginate: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_DOCUMENTS: {
      return { ...state, documents: action.payload };
    }
    case types.REMOVE_DOCUMENT: {
      const documents = state.documents.map((document) => {
        if (document.id === action.payload) {
          document.file = null;
          document.link = null;
        }
        return document;
      });
      return { ...state, documents };
    }
    case types.ADD_DOCUMENT: {
      const documents = state.documents.map((document) => {
        if (document.id === action.id) {
          document.file = action.payload.file;
          document.link = action.payload.link;
        }
        return document;
      });
      return { ...state, documents };
    }
    default:
      return state;
  }
}
