import React from 'react';
import * as S from './styled';

type DottyLoaderProps = {
  variant?: 'light' | 'dark';
};

export default function DottyLoader({ variant = 'light' }: DottyLoaderProps) {
  return (
    <S.DottyLoader variant={variant}>
      <S.Dot />
      <S.Dot />
      <S.Dot />
    </S.DottyLoader>
  );
}
