import React, { useEffect } from 'react';
import { useMedia } from '../../hooks/useMedia';
import { useModal } from '../../hooks/useModal.hook';
import { useSelector } from 'react-redux';
import { actionTriggering } from '../../analytics';
import { NewSectionRemains, MODAL_NAME } from '../modals/Remains';

export function WelcomeRemains() {
  const isSmallScreen = useMedia(['(max-width: 1200px)'], [true], false);
  const { visible, showModal, hideModal } = useModal(MODAL_NAME);
  const { init } = useSelector(({ auth }) => auth);
  const allowedOrders = init?.permissions?.find((permission) => permission === 'balance.remains');
  const hideModalHandler = () => {
    actionTriggering('remains__welcome-modal--close');
    hideModal();
  };

  useEffect(() => {
    if (!isSmallScreen) {
      const showKey = localStorage.getItem(MODAL_NAME);

      if (!showKey && allowedOrders) {
        localStorage.setItem(MODAL_NAME, true);
        actionTriggering('remains__welcome-modal--show');
        showModal();
      }
    }
  }, [allowedOrders]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <NewSectionRemains
        show={visible}
        hideModal={hideModalHandler}
        loading={false}
      />
    </React.Fragment>
  );
}
