import axios from 'axios';
import { accessToken } from 'utils/accessToken';
import { HttpErrorCodesEnum } from 'errors/types';
import store from 'store';
import { refreshToken } from 'actions/auth';
import { ROOT_URL } from 'actions/connect';

export const axiosInstance = axios.create({
  baseURL: ROOT_URL,
});

axiosInstance.interceptors.request.use((config) => ({
  ...config,
  headers: {
    Authorization: `Bearer  ${accessToken.getToken()}`,
    ...config.headers,
  },
}));

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isAxiosError(error) && error.response?.status === HttpErrorCodesEnum.Unauthorized) {
      store.dispatch(refreshToken());
    }
    return Promise.reject(error);
  }
);
