import { useStorage } from 'hooks/useStorage';
import { z } from 'zod';
import { useMount } from 'react-use';
import { isNull } from 'lodash-es';

export const AppEnvSchema = z.literal('test').or(z.literal('prod'));

const APP_ENV_KEY = 'APP_ENV';

export function useAppEnv() {
  const { setStorageValue: setAppEnv, storageValue: appEnv } = useStorage({
    type: 'sessionStorage',
    key: APP_ENV_KEY,
    schema: AppEnvSchema,
  });

  useMount(() => {
    if (isNull(appEnv)) {
      setAppEnv('prod');
    }
  });

  return {
    appEnv,
    setAppEnv,
    isTestAppEnv: appEnv === 'test',
  };
}
