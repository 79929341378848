import { BannersSection } from 'constants/banners';
import { BannerEntity } from './ducks/service.types';
import { isUndefined } from 'lodash-es';

export const getBannersBySection = (section: BannersSection | undefined, banners: BannerEntity[]) => {
  if (isUndefined(section)) {
    return [];
  }
  return banners.filter((banner) => banner.sections.includes(section));
};

//TODO удали этот класс, если прошел год после этого коммита
//логику отображения банеров унесли на бек
class BannersLocalStorage {
  readonly DISABLED_BANNERS = '_isBannersDisabled';
  readonly DISABLED_BANNERS_V2 = '_isBannersDisabledV2';
  readonly DISABLED_BANNERS_V3 = '_isBannersDisabledV3';

  removeBannersKeysFromStorage() {
    localStorage.removeItem(this.DISABLED_BANNERS);
    localStorage.removeItem(this.DISABLED_BANNERS_V2);
    localStorage.removeItem(this.DISABLED_BANNERS_V3);
  }
}

export const bannersLocalStorage = new BannersLocalStorage();
