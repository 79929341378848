import { BRAND, z } from 'zod';
import { HttpErrorCodesEnum } from './types';
import { createHttpError, HttpErrorSchema } from './httpError';
import i18n from 'i18next';
import { isError } from './error';

export const NotFoundErrorSchema = z
  .object({
    code: z.literal(HttpErrorCodesEnum.NotFound),
  })
  .merge(HttpErrorSchema)
  .brand<'NotFound'>();

export type NotFoundError = z.infer<typeof NotFoundErrorSchema>;

export function createNotFoundError(message?: string, data?: unknown): NotFoundError {
  return {
    ...createHttpError(HttpErrorCodesEnum.NotFound, message || i18n.t('error.Не найдено'), data, undefined, !!message),
    [BRAND]: {
      NotFound: true,
    },
  };
}

export const isNotFoundError = isError(NotFoundErrorSchema);
