import styled from 'styled-components';
import { Input } from 'antd';

const { TextArea: AntdTextArea } = Input;

export const Wrapper = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  flex-direction: column;
  animation: appear 0.2s forwards ease-out;
  @keyframes appear {
    from {
      transform: translateY(20%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

export const Description = styled.div`
  margin-top: ${({ theme }) => theme.sizes[24]};
  font-size: ${({ theme }) => theme.fontSizes[14]};
  line-height: ${({ theme }) => theme.lineHeights[22]};
  white-space: pre-wrap;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    margin-top: ${({ theme }) => theme.sizes[12]};
  }
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: ${({ theme }) => theme.sizes[8]};
  margin-top: ${({ theme }) => theme.sizes[8]};
  margin-bottom: ${({ theme }) => theme.sizes[24]};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    margin-bottom: ${({ theme }) => theme.sizes[12]};
  }
`;

export const TextArea = styled(AntdTextArea)<{ isCountVisible: boolean }>`
  ::after {
    display: ${({ isCountVisible }) => (isCountVisible ? 'block' : 'none')};
    position: absolute;
    bottom: -20px;
    right: 0;
  }
`;
