import React from 'react';
import history from '../../../store/history';
import { Spin } from 'antd';
import { actionTriggering } from '../../../analytics';
import { MetalTracking, NewSectionRemains as NewSectionRemainsPicture, RawAmount } from './picture';
import * as S from './styled';
import { Icons } from 'components/Icons';

export const MODAL_NAME = 'NewSectionRemains';

const slides = [
  {
    id: 0,
    title: <span>Добавлен новый раздел &#171;Остатки металла&#187;</span>,
    description: 'Быстрый просмотр информации об общем количестве вашего металла в Красцветмете',
    image: NewSectionRemainsPicture,
  },
  {
    id: 1,
    title: 'Отслеживание металла в переработке',
    description: 'Вы сможете оперативно узнать статус металла',
    image: MetalTracking,
  },
  {
    id: 2,
    title: 'Информация о количестве сырья',
    description: `Вы сможете узнать какое количество сырья находится на этапе анализа`,
    image: RawAmount,
  },
];

export function NewSectionRemains({ title = 'Новый раздел', show, hideModal, loading }) {
  const finishHandler = () => {
    history.push('/remains');
    actionTriggering('remains__welcome-modal--goto');
    hideModal();
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function NextArrow({ onClick }) {
    const clickHandler = (event) => {
      actionTriggering('remains__welcome-modal--next');
      onClick && onClick(event);
    };
    if (!onClick) {
      return <S.Button onClick={finishHandler}>Перейти к разделу</S.Button>;
    }
    return (
      <S.Button
        onClick={clickHandler}
        disabled={!onClick}
      >
        Далее
        <S.ArrowIcon>{Icons.arrowRight}</S.ArrowIcon>
      </S.Button>
    );
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function PrevArrow({ onClick }) {
    const clickHandler = (event) => {
      actionTriggering('remains__welcome-modal--prev');
      onClick && onClick(event);
    };
    if (onClick) {
      return (
        <S.ButtonBack
          onClick={clickHandler}
          disabled={!onClick}
        >
          Назад
        </S.ButtonBack>
      );
    }
    return null;
  }

  return (
    <S.Modal
      title={title}
      visible={show}
      footer={null}
      onCancel={hideModal}
      centered={true}
      closeIcon={Icons.cancel}
    >
      <Spin spinning={loading}>
        <S.Carousel
          infinite={false}
          arrows
          nextArrow={<NextArrow />}
          prevArrow={<PrevArrow />}
        >
          {slides.map((textData) => (
            <S.CarouselContainer key={textData.id}>
              <S.CarouselBody>
                <S.ImageContainer>{textData.image}</S.ImageContainer>
                <S.Title>{textData.title}</S.Title>
                <S.Description>{textData.description}</S.Description>
              </S.CarouselBody>
            </S.CarouselContainer>
          ))}
        </S.Carousel>
      </Spin>
    </S.Modal>
  );
}
