import { asyncPipe } from 'utils/asyncPipe';
import { getDataFromResponse } from 'utils/getDataFromResponse';
import { MessageParams, MessagesListParams } from 'types/support';
import { CommonMessageEntity, MessagesListEntity } from 'models/api/support/message';
import { TICKETS_API } from './consts';
import { ReadMessagesEventBody } from 'models/api/support/supportEvents';
import { axiosInstance } from 'services/axiosInstance';

class MessagesApi {
  getMessages = ({ ticketId, ...params }: MessagesListParams) => {
    return asyncPipe(axiosInstance.get, getDataFromResponse)<ApiResponseEntity<MessagesListEntity>>(
      `${TICKETS_API}/${ticketId}/messages`,
      { params }
    );
  };

  createMessage = ({ ticketId, data }: MessageParams) => {
    return asyncPipe(axiosInstance.post, getDataFromResponse)<ApiResponseEntity<CommonMessageEntity>>(
      `${TICKETS_API}/${ticketId}/messages`,
      data,
      {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  };

  readMessages = (ticketId: number) => {
    return asyncPipe(
      axiosInstance.get,
      getDataFromResponse
    )<ApiResponseEntity<ReadMessagesEventBody>>(`${TICKETS_API}/${ticketId}/messages/read`);
  };
}

export const messagesApi = new MessagesApi();
