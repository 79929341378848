import React from 'react';
const ProfileUpdatePage = React.lazy(() => import('./ProfileUpdate'));
const ProfileCompaniesPage = React.lazy(() => import('./ProfileCompanies'));

export const ProfilePage = [
  {
    path: '/admin/profile',
    component: ProfileUpdatePage,
  },
  {
    path: '/admin/profile/companies',
    component: ProfileCompaniesPage,
  },
];
