import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers';
import promise from 'redux-promise';
import { ThunkDispatch as _ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { useDispatch } from 'react-redux';
import { mainApi } from '../services/http/mainApi';

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ThunkDispatch = _ThunkDispatch<RootState, any, AnyAction>;
export type DispatchAbortPromise = { abort(reason?: string): void };
export type ThunkActionResult<T> = DispatchAbortPromise & { unwrap(): Promise<T> };

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(promise, mainApi.middleware),
});

export default store;

export const useAppDispatch = () => useDispatch<AppDispatch>();
