import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { JWT_ACCESS, basicHeaders, ROOT_URL } from '../actions/connect';

export const apiGet = <R>(url: AxiosRequestConfig['url'], params?: AxiosRequestConfig['params']): AxiosPromise<R> =>
  axios.get<R>(`${ROOT_URL}${url}`, {
    headers: {
      ...basicHeaders(localStorage.getItem(JWT_ACCESS)),
    },
    params,
  });

export const apiPost = <R>(
  url: AxiosRequestConfig['url'],
  body?: AxiosRequestConfig['data'],
  headers?: AxiosRequestConfig['headers'],
  options?: AxiosRequestConfig
): AxiosPromise<R> =>
  axios({
    url: `${ROOT_URL}${url}`,
    method: 'POST',
    data: body,
    headers: {
      ...basicHeaders(localStorage.getItem(JWT_ACCESS)),
      ...headers,
    },
    ...options,
  });

export const apiDelete = <R>(url: AxiosRequestConfig['url'], params?: AxiosRequestConfig): AxiosPromise<R> =>
  axios.delete(`${ROOT_URL}${url}`, {
    headers: {
      ...basicHeaders(localStorage.getItem(JWT_ACCESS)),
    },
    ...params,
  });
