import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from 'features/Login/LoginForm/LoginForm.styled';
import { Field } from 'features/Login/components/Field';
import { useLoginForm } from 'features/Login/LoginForm/hooks/useLoginForm';
import { LoginNamesEnum } from 'features/Login/consts';
import { useAuth } from 'ducks/auth/hooks/useAuth';
import { useEmail } from 'features/Login/hooks/useEmail';
import { useRestorePasswordVisible } from 'features/Login/hooks/useRestorePasswordVisible';
import { Error } from 'features/Login/components/Error';
import { isHttpError } from 'errors/httpError';
import { useQaAttr } from 'hooks/useQA';

type LoginFormProps = {
  authError: string | null;
} & Pick<ReturnType<typeof useAuth>, 'initApp'> &
  ReturnType<typeof useEmail> &
  Pick<ReturnType<typeof useRestorePasswordVisible>, 'openPasswordRestoring'>;

export function LoginForm({ openPasswordRestoring, onEmailChange, email, initApp }: LoginFormProps) {
  const { t } = useTranslation();
  const { qaAttr } = useQaAttr('auth');

  const { form, isSubmitting, onSubmit, error } = useLoginForm({ initApp });

  return (
    <S.LoginForm
      form={form}
      onFinish={onSubmit}
      layout="vertical"
      initialValues={{
        [LoginNamesEnum.enum.username]: email,
      }}
    >
      {isHttpError(error) && error.hasOriginalMessage && <Error message={error.message} />}
      <S.FieldsList>
        <Field
          name={LoginNamesEnum.enum.username}
          variant="username"
          placeholder={t('auth.Электронная почта')}
          type="text"
          onChange={onEmailChange}
          {...qaAttr('login')}
        />
        <Field
          name={LoginNamesEnum.enum.password}
          variant="password"
          placeholder={t('auth.Пароль')}
          type="password"
          {...qaAttr('password')}
        />
      </S.FieldsList>
      <S.ForgetPassword onClick={openPasswordRestoring}>{t('auth.Забыли пароль')}?</S.ForgetPassword>
      <S.SubmitButton
        disabled={isSubmitting}
        loading={isSubmitting}
        width="100%"
        type="submit"
        {...qaAttr('submit-btn')}
      >
        {t('auth.Войти')}
      </S.SubmitButton>
    </S.LoginForm>
  );
}
