import React, { PropsWithChildren, ReactNode } from 'react';
import * as S from './styled';
import { SpaceProps, WidthProps } from 'styled-system';
import { isString } from 'lodash-es';
import SVG from 'react-inlinesvg';
import DottyLoader from '../../DottyLoader';

export type ButtonVariants = 'primary' | 'outlined';
export type SizesVariants = 'sm' | 'md' | 'xmd' | 'lg';

export type ButtonProps = PropsWithChildren<
  {
    icon?: ReactNode;
    iconPosition?: 'left' | 'right';
    variant?: ButtonVariants;
    size?: SizesVariants;
    loading?: boolean;
  } & SpaceProps &
    WidthProps &
    Omit<JSX.IntrinsicElements['button'], 'ref'>
>;

export function Button({
  children,
  icon,
  variant = 'primary',
  size = 'lg',
  iconPosition = 'left',
  loading,
  ...props
}: ButtonProps) {
  const renderIcon = (icon: ReactNode) => (
    <S.ButtonIcon size={size}>{isString(icon) ? <SVG src={icon} /> : icon}</S.ButtonIcon>
  );

  return (
    <S.Button
      {...props}
      loading={!!loading}
      variant={variant}
      size={size}
    >
      <S.ButtonContent>
        {icon && iconPosition === 'left' && renderIcon(icon)}
        {children}
        {icon && iconPosition === 'right' && renderIcon(icon)}
      </S.ButtonContent>
      {loading && (
        <S.ButtonLoader>
          <DottyLoader variant={variant === 'primary' ? 'light' : 'dark'} />
        </S.ButtonLoader>
      )}
    </S.Button>
  );
}
