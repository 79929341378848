import { createCalendarEntity } from './createCalendar';
import { z } from 'zod';

export const MetalDateEntity = z.object({
  day: z.string(),
  times: z.object({
    am: z.string().or(z.null()),
    pm: z.string().or(z.null()),
  }),
});

export type MetalDateEntity = z.infer<typeof MetalDateEntity>;

export const CurrencyDateEntity = z.object({
  day: z.string(),
  time: z.string(),
});

export type CurrencyDateEntity = z.infer<typeof CurrencyDateEntity>;

export const MetalCalendarEntity = createCalendarEntity(z.array(MetalDateEntity));

export type MetalCalendarEntity = z.infer<typeof MetalCalendarEntity>;

export const CurrencyCalendarEntity = createCalendarEntity(z.array(CurrencyDateEntity));

export type CurrencyCalendarEntity = z.infer<typeof CurrencyCalendarEntity>;
