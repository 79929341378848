import React from 'react';
import { Menu, Tooltip } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { icons } from 'features/sidebar/Icons';
import * as S from './styled';
import { useTranslation } from 'react-i18next';

export function UserBlock({ userName, count, isAdmin }) {
  const { name, surname, email } = userName;
  const history = useHistory();
  const { t } = useTranslation();

  const menu = (
    <S.Menu onClick={({ key }) => history.push(key)}>
      <Menu.Item
        key={isAdmin ? '/admin/profile' : '/profile'}
        icon={<S.MenuIcon>{icons.user}</S.MenuIcon>}
      >
        {isAdmin ? 'Мой профиль' : t(`sidebar.Мой профиль`)}
      </Menu.Item>
      <Menu.Item
        key="/exit"
        icon={<S.MenuIcon>{icons.exit}</S.MenuIcon>}
      >
        {isAdmin ? 'Выйти' : t(`sidebar.Выйти`)}
      </Menu.Item>
    </S.Menu>
  );
  const renderUserTitle = () =>
    name || surname ? (
      <S.UserTitle>
        {name && <div>{name}</div>}
        {surname && <div>{surname}</div>}
      </S.UserTitle>
    ) : (
      <S.UserTitle>{email}</S.UserTitle>
    );

  return (
    <S.UserBlock>
      <S.Dropdown
        mouseLeaveDelay={0.01}
        overlay={menu}
        placement="bottomCenter"
        arrow
        trigger={['hover']}
        getPopupContainer={(triggerNode) => triggerNode}
      >
        <S.UserInfo>
          <S.UserIcon>{icons.user}</S.UserIcon>
          {renderUserTitle()}
          <S.UserIconArrow>{icons.nextDown}</S.UserIconArrow>
        </S.UserInfo>
      </S.Dropdown>

      <Tooltip
        title={t('sidebar.Уведомления')}
        placement="bottomRight"
      >
        <Link to="/notifications">
          <S.Badge
            count={count}
            size="small"
          >
            <S.Bell>{icons.bell}</S.Bell>
          </S.Badge>
        </Link>
      </Tooltip>
    </S.UserBlock>
  );
}
