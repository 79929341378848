import React from 'react';
import { message } from 'antd';
import { uniqueId } from 'lodash-es';
import crossIcon from 'icons/cross.svg';
import SVG from 'react-inlinesvg';
import * as S from './ClosableMessage.styled';

const config = (key: string, content: string, duration?: number) => {
  const onClose = () => {
    message.destroy(key);
  };

  return {
    key,
    content: (
      <S.Content>
        {content}
        <S.Cross
          onClick={onClose}
          className="message-close"
        >
          <SVG
            width={16}
            height={16}
            src={crossIcon}
          />
        </S.Cross>
      </S.Content>
    ),
    duration: duration ?? 3,
  };
};

export const closableMessage = {
  error: (content: string, duration?: number) => {
    const key = uniqueId('error');
    message.error(config(key, content, duration));
  },
  success: (content: string, duration?: number) => {
    const key = uniqueId('success');
    message.success(config(key, content, duration));
  },
};
