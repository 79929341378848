import { z } from 'zod';
import { FixationFormEntity } from 'models/api/wts/metalSale/fixationForm';
import { FixationContentEntity } from 'models/api/wts/metalSale/fixationContent';

export const FixationConfigEntity = z.object({
  fixationContentId: z.string(),
  metalPriceMarkup: z.number().or(z.null()),
  currencyPriceMarkup: z.number().or(z.null()),
});

export type FixationConfigEntity = z.infer<typeof FixationConfigEntity>;

export const FixationInitFormEntity = z.object({
  fixation: FixationFormEntity,
  configs: z.array(FixationConfigEntity),
  items: z.array(FixationContentEntity),
});

export type FixationInitFormEntity = z.infer<typeof FixationInitFormEntity>;
