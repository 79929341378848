import { adminRoutes, publicRoutes } from 'routes';
import { PROFILE_PATH } from 'constants/routePaths';
import { isUndefined } from 'lodash-es';
import { LoginFormSchema } from 'features/Login/models';
import { FormInstance } from 'antd';

export const getNextPage = (permissions: string[] = [], currentPage = PROFILE_PATH) => {
  for (const route of [...publicRoutes, ...adminRoutes]) {
    const { path, permission } = route;

    if (!isUndefined(path) && currentPage.indexOf(path) !== -1) {
      if (!permission || permissions.includes(permission)) {
        return path;
      }
    }
  }

  return PROFILE_PATH;
};

export const resetLoginFormFieldsErrors = (form: FormInstance) => {
  const result = LoginFormSchema.keyof().options.map((name) => ({ name, errors: [] }));
  form.setFields(result);
};
