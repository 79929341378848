import { useMediaQuery } from 'react-responsive';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeCompany, getCompanyList, getNextPage } from '../../actions/company';
import { useLocation } from 'react-router-dom';
import { MobileSidebar } from './MobileSidebar';
import { Detail } from './MobileSidebar/Detail';
import { mockData } from './mock';
import { CompanyDetail } from './CompanyDetail/CompanyDetail';
import * as S from './styled';
import { logo, logoText } from 'features/sidebar/logo';
import { Header } from './Header';
import Scrollbars from 'react-custom-scrollbars';
import { MainNav } from './MainNav';
import { Footer } from './Footer';
import { useIsAdminSection } from '../../hooks/useIsAdminSection';
import { changeLocalizationAction } from './service';
import { getLayotData } from 'actions/auth';
import { useTranslation } from 'react-i18next';
import { actionTriggering } from 'analytics';
import { usePermissions } from 'hooks/usePermissions';
import { useCounters } from 'ducks/support/counters/hooks/useCounters';
import { useSupportWsReconnection } from 'hooks/useSupportWsReconnection';

export function PublicSidebar() {
  const isAdminSection = useIsAdminSection();
  const applicationPart = isAdminSection ? 'admin' : 'public';
  const { isPermissionExist } = usePermissions();
  const isMobileDevice = useMediaQuery({ query: '(max-width: 850px)' });
  const [detail, setDetail] = useState(false);
  const [showCompanyDrawer, setShowCompanyDrawer] = useState(true);
  const [inputSearchCompany, setInputSearchCompany] = useState('');

  const { i18n } = useTranslation();
  const { language } = i18n;

  const showDetail = () => {
    setDetail(true);
  };

  const closeDetail = () => {
    setDetail(false);
  };

  const sidebarRef = useRef(null);

  const [scrollVisible, setScrollVisible] = useState(true);

  const onHoverHandler = () => {
    return setScrollVisible(false);
  };
  const onOutHandler = () => {
    return setScrollVisible(true);
  };

  const { counters: supportCounters, refetchCounters } = useCounters();
  useSupportWsReconnection(refetchCounters);

  const { user, company } = useSelector((state) => state?.auth?.init) || {};
  const notificationCount = useSelector((state) => state?.layout?.notificationCount);
  const {
    companyList: companies,
    companyPagination,
    isLoading,
    isLoadingNextPage,
  } = useSelector((state) => state.auth);
  const singleCompany = companies <= 0;
  const isManager = isPermissionExist('admin');
  const dispatch = useDispatch();

  const changeCompanyHandler = (id) => {
    dispatch(changeCompany(id));
  };

  const searchCompanyHandler = (search) => {
    actionTriggering('layout__search-company', {
      SearchQuery: search,
    });
    dispatch(getCompanyList(search, company.id));
  };

  const scrollHandler = (search, page) => {
    dispatch(getNextPage(search, company.id, page));
  };

  const { pathname } = useLocation();

  const changeLocalization = async (lng) => {
    const { payload } = await dispatch(changeLocalizationAction(lng));
    if (payload?.success) {
      dispatch(getLayotData());
    } else {
      console.error('ERROR in function changeLocalization. Data is: ', payload);
    }
  };

  const closeCompanyDetailHandler = () => {
    setShowCompanyDrawer(false);
  };

  const showDetailHandler = () => {
    setInputSearchCompany('');
    dispatch(getCompanyList('', company.id, 1));
    setShowCompanyDrawer(true);
  };

  useEffect(() => {
    setDetail(false);
  }, [pathname]);

  const authenticated = useSelector((state) => state.auth?.authenticated);
  if (!authenticated) {
    return null;
  }

  if (isMobileDevice) {
    return (
      <React.Fragment>
        <MobileSidebar
          company={company}
          count={notificationCount}
          onShow={showDetail}
          onShowDetail={showDetailHandler}
        />
        <Detail
          user={user}
          mainNav={mockData[applicationPart].mainNav}
          footerNav={mockData[applicationPart].footerNav}
          userMenu={mockData[applicationPart].userMenu}
          visible={detail}
          onClose={closeDetail}
          singleCompany={singleCompany}
          localization={language}
          changeLocalization={changeLocalization}
          isAdmin={isAdminSection}
          isManager={isManager}
          supportCounters={supportCounters}
        />
        <CompanyDetail
          visible={showCompanyDrawer}
          currentCompany={company?.name}
          companies={companies}
          onChangeCompany={changeCompanyHandler}
          onClose={closeCompanyDetailHandler}
          onSearch={searchCompanyHandler}
          onScroll={scrollHandler}
          inputSearch={inputSearchCompany}
          setSearch={setInputSearchCompany}
          pagination={companyPagination}
          isLoading={isLoading}
          isLoadingNextPage={isLoadingNextPage}
        />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <S.Wrapper
        ref={sidebarRef}
        onMouseOver={onHoverHandler}
        onMouseOut={onOutHandler}
        showCompanyDrawer={showCompanyDrawer}
      >
        <S.Logo>
          <S.LogoIcon>{logo}</S.LogoIcon>
          <S.LogoText>{logoText}</S.LogoText>
        </S.Logo>
        <Header
          user={user}
          company={company}
          count={notificationCount}
          singleCompany={singleCompany}
          onShow={showDetailHandler}
        />
        <Scrollbars
          autoHide={scrollVisible}
          autoHideTimeout={100}
        >
          <S.ScrollContainer>
            <MainNav
              mainNav={mockData[applicationPart].mainNav}
              isAdmin={isAdminSection}
              count={supportCounters?.openedTickets}
            />
            <Footer
              pages={mockData[applicationPart].footerNav}
              localization={language}
              changeLocalization={changeLocalization}
              count={isManager ? supportCounters?.openedTickets : supportCounters?.unreadMessages}
            />
          </S.ScrollContainer>
        </Scrollbars>
        <CompanyDetail
          sidebarRef={sidebarRef}
          visible={showCompanyDrawer}
          currentCompany={company?.name}
          companies={companies}
          onChangeCompany={changeCompanyHandler}
          onClose={closeCompanyDetailHandler}
          onSearch={searchCompanyHandler}
          onScroll={scrollHandler}
          inputSearch={inputSearchCompany}
          setSearch={setInputSearchCompany}
          pagination={companyPagination}
          isLoading={isLoading}
          isLoadingNextPage={isLoadingNextPage}
        />
      </S.Wrapper>
    </React.Fragment>
  );
}
