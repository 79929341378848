import { useDispatch } from 'react-redux';
import { loginWithUser } from '../../actions/adminDetail';

export const LoginAsUser = ({ children }) => {
  const dispatch = useDispatch();

  const loginHandler = (id) => {
    dispatch(loginWithUser(id));
  };

  return children(loginHandler);
};
