import { actionTriggering } from 'analytics';

class LoginAnalytics {
  readonly CLOSE_RESTORE_PASSWORD_FORM = 'signin__cant_getin-click-back';
  readonly OPEN_RESTORE_PASSWORD_FORM = 'signin__cant_getin-click';
  readonly SUCCESS_SIGN_IN = 'signin__send-request-success';
  readonly ERROR_SIGN_IN = 'signin__send-request-failed';
  readonly SUCCESS_RESTORE_PASSWORD = 'signin__send-restore-success';
  readonly ERROR_RESTORE_PASSWORD = 'signin__send-restore-failed';

  constructor(protected dispatch = actionTriggering) {}

  /** Закрыть форму восстановления пароля */
  closeRestorePasswordForm = () => {
    this.dispatch(this.CLOSE_RESTORE_PASSWORD_FORM);
  };

  /** Открыть форму восстановления пароля */
  openRestorePasswordForm = () => {
    this.dispatch(this.OPEN_RESTORE_PASSWORD_FORM);
  };

  /** Успешная авторизация
   * @param username Логин пользователя
   * */
  successLogin = (username: string) => {
    this.dispatch(this.SUCCESS_SIGN_IN, {
      ActionTargetName: username,
    });
  };

  /** Ошибка авторизации
   * @param username Логин пользователя
   * */
  errorLogin = (username: string) => {
    this.dispatch(this.ERROR_SIGN_IN, {
      ActionTargetName: username,
      ActionTargetType: 'server_error',
    });
  };

  /** Успешное восстановление пароля
   * @param username Логин пользователя
   * */
  successRestore = (username: string) => {
    this.dispatch(this.SUCCESS_RESTORE_PASSWORD, {
      ActionTargetName: username,
    });
  };

  /** Ошибка восстановления пароля
   * @param username Логин пользователя
   * */
  errorRestore = (username: string) => {
    this.dispatch(this.ERROR_RESTORE_PASSWORD, {
      ActionTargetName: username,
      ActionTargetType: 'server_error',
    });
  };
}

export const loginAnalytics = new LoginAnalytics();
