import { z } from 'zod';

export const TicketStatusEnum = z.enum([
  'new',
  'incoming',
  'in_progress.client',
  'in_progress.manager',
  'in_progress',
  'closed',
]);

export type TicketStatusEnum = z.infer<typeof TicketStatusEnum>;
