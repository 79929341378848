import styled from 'styled-components';
import { space } from 'styled-system';

export const Error = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.space[8]};
  padding: ${({ theme }) => theme.space[12]};
  background-color: ${({ theme }) => theme.colors['orange-9']};
  border: 1px solid ${({ theme }) => theme.colors['orange-6']};
  border-radius: 3px;
  margin-bottom: ${({ theme }) => theme.space[16]};
  color: ${({ theme }) => theme.colors['grey-5']};
  font-size: ${({ theme }) => theme.fontSizes[12]};
  line-height: ${({ theme }) => theme.lineHeights[14]};
  ${space}
`;

export const ErrorIcon = styled.i`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: ${({ theme }) => theme.sizes[20]};
  height: ${({ theme }) => theme.sizes[20]};

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: ${({ theme }) => theme.colors['orange-6']};
    }
  }
`;
