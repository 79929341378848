import { z } from 'zod';
import { OAUTH_HASH_LENGTH, OAUTH_MESSAGE_TYPE } from 'constants/oauth';

export const OAuthStateSchema = z.string().length(OAUTH_HASH_LENGTH);
export const OAuthCodeSchema = z.string().min(1);

export const OAuthPageParamsSchema = z.object({
  code: OAuthCodeSchema,
  state: OAuthStateSchema,
});

export const OAuthMessageSchema = z.object({
  type: z.literal(OAUTH_MESSAGE_TYPE),
  payload: z
    .object({
      code: OAuthCodeSchema,
      state: OAuthStateSchema,
    })
    .or(z.null()),
});

export type OAuthMessageSchema = z.infer<typeof OAuthMessageSchema>;
