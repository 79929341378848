import './style.scss';
import React from 'react';
import { useCheckableTags } from '../hooks/useCheckableTags';
import { Tag, Form, FormInstance } from 'antd';
import { TagEntity } from 'models/api/satisfaction/survey';
import * as S from './ExtendedContent.styled';
import { SurveyModalFormSchema } from '../models';
import { useQaAttr } from 'hooks/useQA';
import { isItemExisting } from 'utils/isItemExisting';

const { CheckableTag } = Tag;

type ExtendedContentProps = {
  form: FormInstance<SurveyModalFormSchema>;
  isVisible: boolean;
  description: string;
  tags: TagEntity[];
  isCountVisible: boolean;
};

export function ExtendedContent({ form, isVisible, description, tags, isCountVisible }: ExtendedContentProps) {
  const { tags: visibleTags, selectedTags, isShowMore, onChange, onShowMore } = useCheckableTags({ form, tags });
  const { qaAttr } = useQaAttr('survey-modal');

  return (
    <S.Wrapper isVisible={isVisible}>
      <S.Description {...qaAttr('description')}>{description}</S.Description>
      <S.TagsWrapper>
        <Form.Item
          name="tags"
          noStyle
        >
          {visibleTags.map((tag) => (
            <CheckableTag
              key={tag.id}
              checked={isItemExisting(selectedTags, ({ id }) => id === tag.id)}
              onChange={(checked) => onChange(tag, checked)}
              style={{ border: '1px solid currentColor', userSelect: 'none' }}
              {...qaAttr(`checkable-tag-${tag.id}`)}
            >
              {tag.title}
            </CheckableTag>
          ))}
          {!isShowMore && (
            <CheckableTag
              checked={false}
              onChange={onShowMore}
              style={{ border: '1px solid currentColor' }}
              {...qaAttr('show-more')}
            >
              Ещё...
            </CheckableTag>
          )}
        </Form.Item>
      </S.TagsWrapper>
      <Form.Item
        name="comment"
        noStyle
      >
        <S.TextArea
          rows={2}
          placeholder="Ваш комментарий (по желанию)"
          autoSize={{ minRows: 2, maxRows: 5 }}
          maxLength={1000}
          showCount
          isCountVisible={isCountVisible}
          {...qaAttr('text-area')}
        />
      </Form.Item>
    </S.Wrapper>
  );
}
