import { z } from 'zod';

export const LoginEntity = z.object({
  accessToken: z.string(),
  expiresIn: z.number(),
  refreshToken: z.string(),
  tokenType: z.string(),
});

export type LoginEntity = z.infer<typeof LoginEntity>;
