import { z } from 'zod';
import { UserEntity } from '../common/user';
import { MessageEntity } from './message';
import { CompanyEntity } from '../common/company';
import { TicketStatusEnum } from 'constants/ticketStatus';

export const TicketStatusEntity = z.object({
  code: TicketStatusEnum,
  clientName: z.string(),
  managerName: z.string(),
});
export type TicketStatusEntity = z.infer<typeof TicketStatusEntity>;

export const TicketEntity = z.object({
  id: z.number(),
  status: TicketStatusEntity,
  createdAt: z.string(),
  updatedAt: z.string(),
  theme: z.string().or(z.null()),
  category: z.string().or(z.null()),
  company: CompanyEntity,
  createdByManager: z.boolean(),
  author: UserEntity,
  responsible: UserEntity.or(z.null()),
  recipient: UserEntity.or(z.null()),
  lastMessage: MessageEntity,
  isRated: z.boolean(),
  closedAt: z.string().or(z.null()),
  expiredAt: z.string().or(z.null()),
});
export type TicketEntity = z.infer<typeof TicketEntity>;

export const TicketsListEntity = z.object({
  list: z.array(TicketEntity),
  lastMessageId: z.number().or(z.null()),
});
export type TicketsListEntity = z.infer<typeof TicketsListEntity>;
