import axios from 'axios';
import queryString from 'query-string';
import types from './types';
import { ROOT_URL, JWT_ACCESS } from './connect';
import { checkError } from 'actions/errorText';
import history from '../store/history';
import { AdminCompaniesService } from 'services/adminCompaniesService';
import { AdminCompaniesApi } from 'services/adminCompaniesService.types';
import { AdminContractsApi } from 'services/adminContractsService.types';
import { AdminContractsService } from 'services/adminContractsService';
import { createAsyncThunk } from '@reduxjs/toolkit';

const AdminListEnum = {
  Companies: 'companies',
  Contracts: 'contracts',
  Users: 'users',
} as const;

const setLoading = (currentType: Values<typeof AdminListEnum>) => ({
  type: types.LIST_LOADING,
  currentType,
});

const setRejection = (currentType: Values<typeof AdminListEnum>) => ({
  type: types.GET_LIST_FAILED,
  currentType,
});

//TODO временное решение для совместимости с логикой компонента, нужно переписать на rtk
export const getCompanies = createAsyncThunk(
  'admin/getCompanies',
  (payload: AdminCompaniesApi.getCompanies.Request.Params, { dispatch }) => {
    dispatch(setLoading(AdminListEnum.Companies));

    return AdminCompaniesService.getCompanies(payload)
      .then((response) => {
        dispatch({
          type: types.GET_LIST,
          payload: {
            list: response.data.list,
            pagination: response.data.pagination,
            filters: payload,
          },
          currentType: AdminListEnum.Companies,
        });
      })
      .catch((error) => {
        checkError(error, dispatch, 'entityList', 'list');

        dispatch(setRejection(AdminListEnum.Companies));
      });
  }
);

//TODO временное решение для совместимости с логикой компонента, нужно переписать на rtk
export const getContracts = createAsyncThunk(
  'admin/getContracts',
  (payload: AdminContractsApi.getContracts.Request.Params, { dispatch }) => {
    dispatch(setLoading(AdminListEnum.Contracts));

    return AdminContractsService.getContracts(payload)
      .then((response) => {
        dispatch({
          type: types.GET_LIST,
          payload: {
            list: response.data.list,
            pagination: response.data.pagination,
            filters: payload,
          },
          currentType: AdminListEnum.Contracts,
        });
      })
      .catch((error) => {
        checkError(error, dispatch, 'entityList', 'list');

        dispatch(setRejection(AdminListEnum.Contracts));
      });
  }
);

//TODO временное решение для совместимости с логикой компонента, нужно переписать на rtk
export const getUsers = createAsyncThunk('admin/getUsers', (filters: ApiRequestParams, { dispatch }) => {
  dispatch(setLoading(AdminListEnum.Users));

  return axios
    .get(`${ROOT_URL}/v1/admin/${AdminListEnum.Users}`, {
      params: filters,
      headers: {
        Authorization: `Bearer  ${localStorage.getItem(JWT_ACCESS)}`,
      },
    })
    .then((response) => {
      dispatch({
        type: types.GET_LIST,
        payload: {
          list: response.data.data.list,
          pagination: response.data.data.pagination,
          filters,
        },
        currentType: AdminListEnum.Users,
      });
    })
    .catch((error) => {
      checkError(error, dispatch, 'entityList', 'list');

      dispatch(setRejection(AdminListEnum.Users));
    });
});

export const resetFilters = (currentType: Values<typeof AdminListEnum>) => {
  return {
    type: types.CLEAR_LIST_FILTERS,
    currentType,
  };
};

export const updateQueryParams = (params: ApiRequestParams) => {
  history.push({
    search: queryString.stringify(clearFilters(params)),
  });
};

const clearFilters = (filters: ApiRequestParams) => {
  return Object.entries(filters)
    .filter(([, value]) => value && value !== '')
    .reduce((final, [key, value]) => ({ ...final, [key]: value }), {});
};
