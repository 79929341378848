import { z } from 'zod';

export const CompanyGroupEntity = z.object({
  id: z.string(),
  name: z.string(),
});

export type CompanyGroupEntity = z.infer<typeof CompanyGroupEntity>;

export const CompanyGroupListEntity = z.object({
  companies: z.array(CompanyGroupEntity),
});

export type CompanyGroupListEntity = z.infer<typeof CompanyGroupListEntity>;
