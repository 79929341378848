import { z } from 'zod';
import { MetalEntity } from '../common/metal';
import { FixationEntity } from './fixation';

export const MetalManageFormEntity = z.object({
  metal: MetalEntity,
  limitAmount: z.number().or(z.undefined()),
  id: z.string().or(z.undefined()),
  amount: z.number().or(z.undefined()),
  fix: z.boolean().or(z.undefined()),
});

export type MetalManageFormEntity = z.infer<typeof MetalManageFormEntity>;

export const ManageFormEntity = z.object({
  metals: z.array(MetalManageFormEntity),
  priceFixation: FixationEntity.or(z.null()),
});

export type ManageFormEntity = z.infer<typeof ManageFormEntity>;
