import { z } from 'zod';
import { MetalSignatureEnum } from 'constants/metalSale';

export const MetalEntity = z.object({
  id: z.number(),
  name: z.string(),
  signature: MetalSignatureEnum,
});

export type MetalEntity = z.infer<typeof MetalEntity>;
