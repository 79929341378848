import { z } from 'zod';
import { MetalTransferEntity } from './metalTransfer';
import { SuccessEntity } from '../common/success';

export const MetalTransferListEntity = z
  .object({
    items: z.array(MetalTransferEntity),
  })
  .merge(SuccessEntity);

export type MetalTransferListEntity = z.infer<typeof MetalTransferListEntity>;
