import { z } from 'zod';
import { MetalEntity } from 'models/api/wts/common/metal';

export const MetalTransferContentEntity = z.object({
  id: z.number(),
  amount: z.number(),
  available: z.number(),
  metal: MetalEntity,
  metalTransfer: z.object({
    publicId: z.number(),
    createdAt: z.string(),
  }),
});

export type MetalTransferContentEntity = z.infer<typeof MetalTransferContentEntity>;
