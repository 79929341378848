import types from '../actions/types';

export default function (state = [], action) {
  switch (action.type) {
    case types.GET_MANAGERS: {
      return action.payload;
    }
    default:
      return state;
  }
}
