import { asyncPipe } from 'utils/asyncPipe';
import { getDataFromResponse } from 'utils/getDataFromResponse';
import { MessageParams, MessagesListParams } from 'types/support';
import { CommonMessageEntity, MessagesListEntity } from 'models/api/support/message';
import { ADMIN_TICKETS_API } from './consts';
import { axiosInstance } from 'services/axiosInstance';

class AdminMessagesApi {
  getAdminMessages = ({ ticketId, ...params }: MessagesListParams) => {
    return asyncPipe(axiosInstance.get, getDataFromResponse)<ApiResponseEntity<MessagesListEntity>>(
      `${ADMIN_TICKETS_API}/${ticketId}/messages`,
      { params }
    );
  };

  createAdminMessage = ({ ticketId, data }: MessageParams) => {
    return asyncPipe(axiosInstance.post, getDataFromResponse)<ApiResponseEntity<CommonMessageEntity>>(
      `${ADMIN_TICKETS_API}/${ticketId}/messages`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  };
}

export const adminMessagesApi = new AdminMessagesApi();
