import React from 'react';

export const RawAmount = (
  <svg
    width="406"
    height="217"
    viewBox="0 0 406 217"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="213"
      width="405"
      height="4"
      rx="2"
      fill="#F0F4F7"
    />
    <path
      d="M0 4.81437C0 3.22794 1.16081 1.94189 2.59274 1.94189H253.928C255.36 1.94189 256.52 3.22795 256.52 4.81438V204.532C256.52 206.118 255.36 207.404 253.928 207.404H2.59274C1.16081 207.404 0 206.118 0 204.532V4.81437Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M108.939 55.5339H39.5411C37.4505 55.5339 35.7557 57.6254 35.7557 60.2055V130.28C35.7557 132.86 37.4505 134.952 39.5411 134.952H108.939C111.03 134.952 112.725 132.86 112.725 130.28V60.2055C112.725 57.6254 111.03 55.5339 108.939 55.5339ZM39.5411 52.4194C36.0568 52.4194 33.2322 55.9054 33.2322 60.2055V130.28C33.2322 134.581 36.0568 138.067 39.5411 138.067H108.939C112.424 138.067 115.248 134.581 115.248 130.28V60.2055C115.248 55.9054 112.424 52.4194 108.939 52.4194H39.5411Z"
      fill="#F0F4F7"
    />
    <path
      d="M94.4205 117.238L94.4205 117.238L94.4157 117.245C94.3596 117.327 94.2912 117.392 94.2164 117.44C94.1417 117.487 94.063 117.514 93.9857 117.523C93.9086 117.533 93.8336 117.524 93.7642 117.499C93.6954 117.475 93.6309 117.435 93.5741 117.381C93.5736 117.381 93.5732 117.38 93.5727 117.38L80.3898 104.484L81.871 102.36L95.6209 114.387C95.6213 114.387 95.6217 114.388 95.6221 114.388C95.68 114.44 95.731 114.506 95.7705 114.586C95.8103 114.667 95.8376 114.76 95.8479 114.86C95.8583 114.96 95.8514 115.064 95.8264 115.165C95.8015 115.265 95.7597 115.358 95.704 115.438C95.7036 115.439 95.7032 115.439 95.7028 115.44L94.4205 117.238Z"
      fill="#E5EAEE"
      stroke="#E5EAEE"
    />
    <path
      d="M82.0413 93.3605C80.8925 102.423 73.6319 108.469 66.0509 107.198C58.4698 105.926 53.0123 97.7477 54.1612 88.6848C55.31 79.6219 62.5706 73.5762 70.1516 74.8476C77.7327 76.119 83.1902 84.2976 82.0413 93.3605Z"
      stroke="#E5EAEE"
      strokeWidth="3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M162.53 100.698H140.488C138.314 100.698 136 98.5523 136 95.176V61.5835C136 58.2072 138.314 56.0611 140.488 56.0611H155.454C155.437 56.1405 155.429 56.2222 155.429 56.3054V64.3737C155.429 65.7241 156.117 67.0892 156.957 68.0785C157.772 69.0385 159.015 69.9713 160.383 69.9713H167.018V95.176C167.018 98.5523 164.704 100.698 162.53 100.698ZM169.418 67.0504L157.329 53.6611H140.488C136.684 53.6611 133.6 57.2081 133.6 61.5835V95.176C133.6 99.5514 136.684 103.098 140.488 103.098H162.53C166.334 103.098 169.418 99.5514 169.418 95.176V67.0504ZM157.829 64.3737V59.3041L161.533 63.1916L165.457 67.5713H160.383C160.06 67.5713 159.425 67.2776 158.786 66.525C158.172 65.8017 157.829 64.968 157.829 64.3737Z"
      fill="#F0F4F7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M162.53 165.206H140.488C138.314 165.206 136 163.06 136 159.683V126.091C136 122.715 138.314 120.568 140.488 120.568H155.453C155.437 120.647 155.429 120.728 155.429 120.811V128.879C155.429 130.229 156.117 131.594 156.957 132.584C157.772 133.544 159.015 134.477 160.383 134.477H167.018V159.683C167.018 163.06 164.704 165.206 162.53 165.206ZM169.418 131.558L157.329 118.168H140.488C136.684 118.168 133.6 121.715 133.6 126.091V159.683C133.6 164.059 136.684 167.606 140.488 167.606H162.53C166.334 167.606 169.418 164.059 169.418 159.683V131.558ZM157.829 128.879V123.809L161.533 127.697L165.457 132.077H160.383C160.06 132.077 159.425 131.783 158.786 131.03C158.172 130.307 157.829 129.473 157.829 128.879Z"
      fill="#F0F4F7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M210.299 100.698H188.257C186.083 100.698 183.769 98.5523 183.769 95.176V61.5835C183.769 58.2072 186.082 56.0611 188.257 56.0611H203.224C203.208 56.1405 203.199 56.2222 203.199 56.3054V64.3737C203.199 65.7241 203.887 67.0892 204.728 68.0785C205.543 69.0385 206.785 69.9713 208.153 69.9713H214.786V95.176C214.786 98.5523 212.473 100.698 210.299 100.698ZM217.186 67.0504L205.098 53.6611H188.257C184.453 53.6611 181.369 57.2081 181.369 61.5835V95.176C181.369 99.5514 184.453 103.098 188.257 103.098H210.299C214.103 103.098 217.186 99.5514 217.186 95.176V67.0504ZM205.599 64.3737V59.3041L209.304 63.1916L213.228 67.5713H208.153C207.83 67.5713 207.196 67.2776 206.557 66.525C205.943 65.8017 205.599 64.968 205.599 64.3737Z"
      fill="#F0F4F7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M210.299 165.206H188.257C186.083 165.206 183.769 163.06 183.769 159.683V126.091C183.769 122.715 186.082 120.568 188.257 120.568H203.224C203.208 120.647 203.199 120.728 203.199 120.811V128.879C203.199 130.229 203.887 131.594 204.728 132.584C205.543 133.544 206.785 134.477 208.153 134.477H214.786V159.683C214.786 163.06 212.473 165.206 210.299 165.206ZM217.186 131.558L205.098 118.168H188.257C184.453 118.168 181.369 121.715 181.369 126.091V159.683C181.369 164.059 184.453 167.606 188.257 167.606H210.299C214.103 167.606 217.186 164.059 217.186 159.683V131.558ZM205.599 128.879V123.809L209.304 127.697L213.228 132.077H208.153C207.83 132.077 207.196 131.783 206.557 131.03C205.943 130.307 205.599 129.473 205.599 128.879Z"
      fill="#F0F4F7"
    />
    <path
      d="M212.9 18.6644C212.9 21.3033 211.041 23.4426 208.746 23.4426C206.452 23.4426 204.592 21.3033 204.592 18.6644C204.592 16.0255 206.452 13.8862 208.746 13.8862C211.041 13.8862 212.9 16.0255 212.9 18.6644Z"
      fill="white"
    />
    <path
      d="M226.401 18.6644C226.401 21.3033 224.541 23.4426 222.247 23.4426C219.952 23.4426 218.093 21.3033 218.093 18.6644C218.093 16.0255 219.952 13.8862 222.247 13.8862C224.541 13.8862 226.401 16.0255 226.401 18.6644Z"
      fill="white"
    />
    <path
      d="M238.864 18.6644C238.864 21.3033 237.237 23.4426 235.229 23.4426C233.222 23.4426 231.594 21.3033 231.594 18.6644C231.594 16.0255 233.222 13.8862 235.229 13.8862C237.237 13.8862 238.864 16.0255 238.864 18.6644Z"
      fill="white"
    />
    <path
      d="M10.3848 3.53702C10.3848 1.99671 11.6302 0.748047 13.1664 0.748047H282.817C284.354 0.748047 285.599 1.99672 285.599 3.53703V197.449C285.599 198.989 284.354 200.237 282.817 200.237H13.1664C11.6302 200.237 10.3848 198.989 10.3848 197.449V3.53702Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M283.261 3.2233H17.7391C16.9827 3.2233 16.3695 3.77099 16.3695 4.4466V174.553C16.3695 175.229 16.9827 175.777 17.7391 175.777H283.261C284.017 175.777 284.63 175.229 284.63 174.553V4.4466C284.63 3.77099 284.017 3.2233 283.261 3.2233ZM17.7391 2C16.2263 2 15 3.09538 15 4.4466V174.553C15 175.905 16.2263 177 17.7391 177H283.261C284.774 177 286 175.905 286 174.553V4.4466C286 3.09538 284.774 2 283.261 2H17.7391Z"
      fill="#F0F4F7"
    />
    <path
      d="M28.259 10.0485C28.259 9.63959 28.5537 9.30811 28.9173 9.30811H277.214C277.578 9.30811 277.872 9.63959 277.872 10.0485V29.3522C277.872 29.7611 277.578 30.0926 277.214 30.0926H28.9173C28.5537 30.0926 28.259 29.7611 28.259 29.3522V10.0485Z"
      fill="#F0F4F7"
    />
    <path
      d="M236.585 20.4639C236.585 22.673 234.794 24.4639 232.585 24.4639C230.376 24.4639 228.585 22.673 228.585 20.4639C228.585 18.2547 230.376 16.4639 232.585 16.4639C234.794 16.4639 236.585 18.2547 236.585 20.4639Z"
      fill="white"
    />
    <path
      d="M250.735 20.4639C250.735 22.673 248.944 24.4639 246.735 24.4639C244.526 24.4639 242.735 22.673 242.735 20.4639C242.735 18.2547 244.526 16.4639 246.735 16.4639C248.944 16.4639 250.735 18.2547 250.735 20.4639Z"
      fill="white"
    />
    <path
      d="M265.975 20.4639C265.975 22.673 264.184 24.4639 261.975 24.4639C259.765 24.4639 257.975 22.673 257.975 20.4639C257.975 18.2547 259.765 16.4639 261.975 16.4639C264.184 16.4639 265.975 18.2547 265.975 20.4639Z"
      fill="white"
    />
    <rect
      x="363.304"
      y="46"
      width="5.73444"
      height="152.536"
      rx="2.86722"
      fill="#E5EAEE"
    />
    <rect
      x="331.191"
      y="46"
      width="4.58755"
      height="96.3386"
      rx="2.29378"
      fill="#E5EAEE"
    />
    <rect
      x="318.575"
      y="75.8188"
      width="8.02821"
      height="59.6382"
      rx="4"
      transform="rotate(-90 318.575 75.8188)"
      fill="#E5EAEE"
    />
    <rect
      x="360.75"
      y="64.3506"
      width="11.4689"
      height="13.7627"
      rx="5"
      fill="#D8DDE3"
    />
    <rect
      x="327.75"
      y="64.3501"
      width="11.4689"
      height="13.7627"
      rx="5"
      fill="#D8DDE3"
    />
    <rect
      x="359.863"
      y="126.282"
      width="12.6158"
      height="18.3502"
      rx="5"
      fill="#D8DDE3"
    />
    <rect
      x="345.343"
      y="200.332"
      width="7.70575"
      height="42.3816"
      rx="2"
      transform="rotate(-90 345.343 200.332)"
      fill="#D8DDE3"
    />
    <path
      d="M337.706 143.025C337.519 143.959 336.698 144.632 335.744 144.632L330.078 144.632C329.125 144.632 328.304 143.959 328.117 143.025L325.935 132.115C325.687 130.877 326.634 129.723 327.896 129.723L337.926 129.723C339.188 129.723 340.135 130.877 339.888 132.115L337.706 143.025Z"
      fill="#D8DDE3"
    />
    <path
      d="M170.045 184.67C170.398 183.36 171.586 182.45 172.942 182.45H191.56C192.916 182.45 194.104 183.36 194.456 184.67L197.484 195.916C197.997 197.822 196.561 199.696 194.587 199.696H169.915C167.94 199.696 166.504 197.822 167.018 195.916L170.045 184.67Z"
      fill="#EBCEBC"
    />
    <path
      d="M174.435 186.96L173.318 190.684"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M202.547 184.67C202.9 183.36 204.087 182.45 205.444 182.45H224.061C225.417 182.45 226.605 183.36 226.958 184.67L229.986 195.916C230.499 197.822 229.063 199.696 227.089 199.696H202.416C200.442 199.696 199.006 197.822 199.519 195.916L202.547 184.67Z"
      fill="#EBCEBC"
    />
    <path
      d="M215.084 185.766H208.459C207.058 185.766 205.843 186.736 205.534 188.102L203.808 195.715"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M186.627 166.341C186.98 165.031 188.168 164.121 189.524 164.121H208.141C209.498 164.121 210.686 165.031 211.038 166.341L214.066 177.587C214.579 179.493 213.143 181.367 211.169 181.367H186.496C184.522 181.367 183.086 179.493 183.599 177.587L186.627 166.341Z"
      fill="#EBCEBC"
    />
    <path
      d="M199.164 167.438H192.539C191.138 167.438 189.923 168.408 189.613 169.774L187.888 177.387"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M202.888 184.67C203.241 183.36 204.429 182.45 205.785 182.45H224.403C225.759 182.45 226.947 183.36 227.299 184.67L230.327 195.916C230.84 197.822 229.404 199.696 227.43 199.696H202.758C200.783 199.696 199.347 197.822 199.861 195.916L202.888 184.67Z"
      fill="#EBCEBC"
    />
    <path
      d="M207.204 186.96L206.086 190.684"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M235.543 184.822C235.896 183.512 237.084 182.602 238.44 182.602H257.058C258.414 182.602 259.602 183.512 259.954 184.822L262.982 196.068C263.495 197.974 262.059 199.848 260.085 199.848H235.413C233.438 199.848 232.003 197.974 232.516 196.068L235.543 184.822Z"
      fill="#EBCEBC"
    />
    <path
      d="M239.227 186.96L238.11 190.684"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M219.47 166.341C219.823 165.031 221.011 164.121 222.367 164.121H240.984C242.341 164.121 243.529 165.031 243.881 166.341L246.909 177.587C247.422 179.493 245.986 181.367 244.012 181.367H219.339C217.365 181.367 215.929 179.493 216.442 177.587L219.47 166.341Z"
      fill="#EBCEBC"
    />
    <path
      d="M232.008 167.438H225.383C223.982 167.438 222.767 168.408 222.457 169.774L220.732 177.387"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M185.901 166.549C186.254 165.239 187.441 164.329 188.798 164.329H207.415C208.771 164.329 209.959 165.239 210.312 166.549L213.34 177.795C213.853 179.701 212.417 181.574 210.443 181.574H185.77C183.796 181.574 182.36 179.701 182.873 177.795L185.901 166.549Z"
      fill="#EBCEBC"
    />
    <path
      d="M190.075 168.342L188.958 172.065"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M218.402 166.549C218.755 165.239 219.943 164.329 221.299 164.329H239.916C241.273 164.329 242.461 165.239 242.813 166.549L245.841 177.795C246.354 179.701 244.918 181.574 242.944 181.574H218.271C216.297 181.574 214.861 179.701 215.375 177.795L218.402 166.549Z"
      fill="#EBCEBC"
    />
    <path
      d="M222.843 168.342L221.726 172.065"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M202.482 148.22C202.835 146.91 204.023 146 205.379 146H223.997C225.353 146 226.541 146.91 226.893 148.22L229.921 159.466C230.434 161.372 228.998 163.246 227.024 163.246H202.352C200.377 163.246 198.941 161.372 199.455 159.466L202.482 148.22Z"
      fill="#EBCEBC"
    />
    <path
      d="M206.459 150.468L205.342 154.192"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <rect
      x="120.146"
      y="141.742"
      width="20.2346"
      height="60.0985"
      rx="4"
      fill="#E5EAEE"
    />
    <rect
      x="120.146"
      y="141.742"
      width="20.2346"
      height="6.00985"
      fill="#C9D0D8"
    />
    <path
      d="M52.2627 145.742C52.2627 143.533 54.0536 141.742 56.2627 141.742H124.715V197.84C124.715 200.049 122.925 201.84 120.715 201.84H56.2627C54.0536 201.84 52.2627 200.049 52.2627 197.84V145.742Z"
      fill="#F0F4F7"
    />
    <path
      d="M52.2627 141.742H124.715V147.752H52.2627V141.742Z"
      fill="#E5EAEE"
    />
    <rect
      x="55.5251"
      y="187.568"
      width="9.79091"
      height="9.76601"
      rx="2"
      fill="white"
    />
    <path
      d="M84.1111 159.021C84.1111 157.777 85.1201 156.768 86.3648 156.768H100.134C101.378 156.768 102.387 157.777 102.387 159.021C102.387 160.266 101.378 161.275 100.134 161.275H86.3648C85.1201 161.275 84.1111 160.266 84.1111 159.021Z"
      fill="#C9D0D8"
    />
    <path
      d="M117.444 129.723H141.603C143.812 129.723 145.603 131.514 145.603 133.723V139.245C145.603 141.454 143.812 143.245 141.603 143.245H117.444V129.723Z"
      fill="#E5EAEE"
    />
    <path
      d="M49 133.724C49 131.514 50.7909 129.724 53 129.724H123.98C126.189 129.724 127.98 131.514 127.98 133.724V139.246C127.98 141.455 126.189 143.246 123.98 143.246H53C50.7909 143.246 49 141.455 49 139.246V133.724Z"
      fill="#F0F4F7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M97.0842 85.5816C104.832 85.5816 111.112 79.1947 111.112 71.3162C111.112 63.4376 104.832 57.0508 97.0842 57.0508C89.3369 57.0508 83.0564 63.4376 83.0564 71.3162C83.0564 79.1947 89.3369 85.5816 97.0842 85.5816ZM97.2119 64.1104C97.2119 63.4014 96.6466 62.8266 95.9494 62.8266C95.2521 62.8266 94.6869 63.4014 94.6869 64.1104V72.2621C94.6869 72.6914 94.8978 73.0922 95.2491 73.3303L101.261 77.4062C101.841 77.7995 102.625 77.6401 103.012 77.0501C103.399 76.4601 103.242 75.663 102.662 75.2697L97.2119 71.575V64.1104Z"
      fill="#D8DDE3"
    />
    <path
      d="M96.6631 101.05L96.6631 111.747"
      stroke="#C9D0D8"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="5 8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M310.501 148C308.783 148 307.391 149.359 307.391 151.035V169.2C307.328 169.303 307.269 169.408 307.213 169.516L295.69 191.517C293.574 195.556 296.58 200.349 301.228 200.349H325.013C329.709 200.349 332.711 195.466 330.501 191.423L318.476 169.422C318.413 169.305 318.346 169.192 318.275 169.081V151.035C318.275 149.359 316.883 148 315.165 148H310.501Z"
      fill="#C9D0D8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M310.501 148C308.783 148 307.391 149.359 307.391 151.035V169.2C307.328 169.303 307.269 169.408 307.213 169.516L295.69 191.517C293.574 195.556 296.58 200.349 301.228 200.349H325.013C329.709 200.349 332.711 195.466 330.501 191.423L318.476 169.422C318.413 169.305 318.346 169.192 318.275 169.081V151.035C318.275 149.359 316.883 148 315.165 148H310.501Z"
      fill="#F0F4F7"
    />
    <mask
      id="mask0"
      maskUnits="userSpaceOnUse"
      x="295"
      y="148"
      width="37"
      height="53"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M310.501 148C308.783 148 307.391 149.359 307.391 151.035V169.2C307.328 169.303 307.269 169.408 307.213 169.516L295.69 191.517C293.574 195.556 296.58 200.349 301.228 200.349H325.013C329.709 200.349 332.711 195.466 330.501 191.423L318.476 169.422C318.413 169.305 318.346 169.192 318.275 169.081V151.035C318.275 149.359 316.883 148 315.165 148H310.501Z"
        fill="#F0F4F7"
      />
    </mask>
    <g mask="url(#mask0)">
      <rect
        x="293.658"
        y="186.926"
        width="38.9263"
        height="20.1343"
        fill="#D8DDE3"
      />
    </g>
    <rect
      x="308.123"
      y="179.047"
      width="3.74801"
      height="6.24668"
      rx="1.874"
      transform="rotate(30 308.123 179.047)"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M214.03 103.342C221.675 103.342 227.873 97.1449 227.873 89.4998C227.873 81.8548 221.675 75.6572 214.03 75.6572C206.385 75.6572 200.188 81.8548 200.188 89.4998C200.188 97.1449 206.385 103.342 214.03 103.342ZM221.14 86.7209C221.626 86.2343 221.626 85.4455 221.14 84.959C220.653 84.4725 219.865 84.4725 219.378 84.959L212.645 91.6915L209.374 88.4196C208.887 87.9331 208.098 87.9331 207.612 88.4196C207.125 88.9062 207.125 89.695 207.612 90.1815L211.765 94.3343C212.251 94.8208 213.04 94.8208 213.526 94.3343L221.14 86.7209Z"
      fill="#D6946B"
    />
    <line
      x1="213.628"
      y1="121.149"
      x2="213.958"
      y2="132.261"
      stroke="#E6BA9E"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="5 8"
    />
  </svg>
);
