export const ROOT_URL = '/api';

export const basicParams = {
  client_id: 1,
  client_secret: '4yIkjMI2cqgIq1DUwOKQfByAYz9A8Au7YiraA5FG',
  scope: '*',
};

export const basicHeaders = (jwt) => ({
  Authorization: `Bearer  ${jwt}`,
});

export const JWT_ACCESS = 'JWT_ACCESS';
export const JWT_REFRESH = 'JWT_REFRESH';
export const AD_JWT_ACCESS = 'AD_JWT_ACCESS';
