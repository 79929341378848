export const MAIN_API_PATH = '/api';
export const PRICE_FIXATION_API_PATH = `${MAIN_API_PATH}/pricefixation`;
export const FIXATION_API_PATH = `${MAIN_API_PATH}/fixation`;
export const INIT_FIX_FORM_API_PATH = `${MAIN_API_PATH}/init-fix-form`;
export const COMPANY_GROUPS_API_PATH = `${MAIN_API_PATH}/company_groups`;
export const RAW_TYPES_API_PATH = `${MAIN_API_PATH}/raw_types`;
export const VERIFICATION_AUTH_API_PATH = `${MAIN_API_PATH}/auth/verification`;
export const RESERVE_API_PATH = `${MAIN_API_PATH}/reserve`;

export const FIX_PRICE_API_PATH = `${FIXATION_API_PATH}/fix`;
export const KITCO_PRICE_API_PATH = `${FIXATION_API_PATH}/kitco`;
export const SPOT_PRICE_API_PATH = `${FIXATION_API_PATH}/spot`;
export const FIXATION_FORM_RESERVE_API_PATH = `${FIXATION_API_PATH}/form-reserve`;

export const FIXATION_LIST_API_PATH = `${PRICE_FIXATION_API_PATH}/list`;
export const RESERVES_LIST_API_PATH = `${PRICE_FIXATION_API_PATH}/list-reserve`;
export const CREATE_FIXATION_API_PATH = `${PRICE_FIXATION_API_PATH}/create`;
export const CREATE_RESERVE_API_PATH = `${PRICE_FIXATION_API_PATH}/create-reserve`;
export const UPDATE_FIXATION_API_PATH = `${PRICE_FIXATION_API_PATH}/update`;
export const UPDATE_RESERVE_API_PATH = `${PRICE_FIXATION_API_PATH}/update-reserve`;
export const DELETE_FIXATION_API_PATH = `${PRICE_FIXATION_API_PATH}/delete`;
export const INIT_MANAGE_FORM_API_PATH = `${PRICE_FIXATION_API_PATH}/form`;
export const UPDATE_RESERVE_DISCOUNT_API_PATH = `${PRICE_FIXATION_API_PATH}/content/:fixationContentId/discount`;
export const UPDATE_RESERVE_AMOUNT_API_PATH = `${PRICE_FIXATION_API_PATH}/content/:fixationContentId/amount`;

export const APPROVE_RESERVE_API_PATH = `${RESERVE_API_PATH}/:id/approve`;
export const CANCEL_RESERVE_API_PATH = `${RESERVE_API_PATH}/:id/cancel`;
export const EXTEND_RESERVE_API_PATH = `${RESERVE_API_PATH}/:id/extend`;
export const DELETE_RESERVE_API_PATH = `${RESERVE_API_PATH}/:id`;
export const INIT_FEATURES_BUY_API_PATH = `${MAIN_API_PATH}/features/buy`;
