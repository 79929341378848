import moment from 'moment';

export function sortByDate(order: 'asc' | 'desc') {
  return (a: string, b: string) => {
    if (order === 'asc') {
      return moment(a).diff(b);
    }
    return moment(b).diff(a);
  };
}
