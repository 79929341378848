import { z } from 'zod';
import { MetalCalendarEntity } from '../common/calendars';
import { CurrencyCalendarEntity } from '../common/calendars';
import { SpotMethodEntity } from '../common/spot';
import { FixationMethodsEnum } from 'constants/metalSale';

export const SpotSellMethodEntity = z
  .object({
    canOfferCurrencyPerOunce: z.boolean(),
    canOfferRubPerGram: z.boolean(),
  })
  .merge(SpotMethodEntity);

export const FixationSellMethodsEntity = z
  .object({
    [FixationMethodsEnum.enum.fix]: z.object({
      metalCalendar: MetalCalendarEntity,
      currencyCalendar: CurrencyCalendarEntity,
    }),
    [FixationMethodsEnum.enum.spot]: SpotSellMethodEntity,
  })
  .partial();

export type FixationSellMethodsEntity = z.infer<typeof FixationSellMethodsEntity>;
export type SpotSellMethodEntity = z.infer<typeof SpotSellMethodEntity>;
