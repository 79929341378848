import types from '../actions/types';

const initialState = {
  users: [],
  roles: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_ADMIN_ROLES: {
      return {
        ...state,
        roles: action.payload.list,
      };
    }
    case types.GET_RELATION_TOP: {
      return {
        ...state,
        [action.currentType]: action.payload,
      };
    }

    default:
      return state;
  }
}
