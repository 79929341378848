import { z } from 'zod';

export const OAuthTokenEntity = z.object({
  accessToken: z.string(),
  expiresIn: z.number(),
  refreshToken: z.string().or(z.undefined()).or(z.null()),
  tokenType: z.string(),
});

export type OAuthTokenEntity = z.infer<typeof OAuthTokenEntity>;
