import { asyncPipe } from 'utils/asyncPipe';
import { getDataFromResponse } from 'utils/getDataFromResponse';
import { axiosInstance } from 'services/axiosInstance';
import { TokenEntity } from 'models/api/ws/token';
import { WS_TOKEN_API } from 'services/ws/consts';

class TokenWsApi {
  getToken = () => {
    return asyncPipe(axiosInstance.get, getDataFromResponse)<ApiResponseEntity<TokenEntity>>(WS_TOKEN_API);
  };
}

export const tokenWsApi = new TokenWsApi();
