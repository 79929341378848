import React from 'react';

export const MetalTracking = (
  <svg
    width="375"
    height="207"
    viewBox="0 0 375 207"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36 3.34721C36 2.23695 37.0545 1.33691 38.3552 1.33691H266.665C267.966 1.33691 269.02 2.23696 269.02 3.34721V143.119C269.02 144.229 267.966 145.129 266.665 145.129H38.3552C37.0545 145.129 36 144.229 36 143.119V3.34721Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M134.96 38.8408H71.9193C70.0202 38.8408 68.4807 40.3045 68.4807 42.1102V91.152C68.4807 92.9576 70.0202 94.4214 71.9193 94.4214H134.96C136.859 94.4214 138.398 92.9576 138.398 91.152V42.1102C138.398 40.3045 136.859 38.8408 134.96 38.8408ZM71.9193 36.6611C68.7542 36.6611 66.1884 39.1008 66.1884 42.1102V91.152C66.1884 94.1614 68.7542 96.601 71.9193 96.601H134.96C138.125 96.601 140.691 94.1614 140.691 91.152V42.1102C140.691 39.1008 138.125 36.6611 134.96 36.6611H71.9193Z"
      fill="#F0F4F7"
    />
    <path
      d="M121.747 81.9483L121.747 81.9483L121.74 81.9559C121.707 81.9925 121.665 82.0251 121.613 82.0504L121.827 82.4787L121.613 82.0505C121.561 82.0758 121.5 82.0932 121.434 82.0993C121.368 82.1053 121.3 82.0997 121.233 82.0816C121.167 82.0637 121.106 82.0344 121.054 81.996L109.168 73.0388L110.335 71.7493L122.808 80.1542C122.808 80.1545 122.809 80.1548 122.809 80.155C122.863 80.1918 122.905 80.2364 122.936 80.2847C122.967 80.3332 122.985 80.3839 122.992 80.4329L123.494 80.345L122.992 80.4329C122.999 80.4818 122.994 80.5288 122.98 80.5719C122.966 80.6142 122.943 80.6542 122.912 80.6898L121.747 81.9483Z"
      fill="#E5EAEE"
      stroke="#E5EAEE"
    />
    <path
      d="M110.32 65.2877C109.343 71.2236 103.054 75.5152 96.0613 74.6118C89.0686 73.7083 84.4243 68.004 85.4009 62.0681C86.3776 56.1321 92.6667 51.8405 99.6593 52.744C106.652 53.6474 111.296 59.3517 110.32 65.2877Z"
      stroke="#E5EAEE"
      strokeWidth="3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M183.639 69.7293H163.616C161.205 69.7293 159.759 68.0571 159.759 66.5849V43.0752C159.759 41.603 161.205 39.9308 163.616 39.9308H177.079V45.0263C177.079 46.1985 177.832 47.2662 178.602 47.9642C179.391 48.6799 180.518 49.3039 181.689 49.3039H187.495V66.5849C187.495 68.0571 186.049 69.7293 183.639 69.7293ZM189.895 48.4255V66.5849C189.895 69.647 187.094 72.1293 183.639 72.1293H163.616C160.16 72.1293 157.359 69.647 157.359 66.5849V43.0752C157.359 40.0131 160.16 37.5308 163.616 37.5308H178.914L189.895 46.9012V47.7824C189.953 47.9894 189.955 48.2116 189.895 48.4255ZM179.479 45.0263V41.8929L182.766 44.5507L185.503 46.9039H181.689C181.324 46.9039 180.746 46.6696 180.214 46.1867C179.663 45.6861 179.479 45.215 179.479 45.0263Z"
      fill="#F0F4F7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M183.639 114.874H163.616C161.205 114.874 159.759 113.202 159.759 111.729V88.2197C159.759 86.7475 161.205 85.0753 163.616 85.0753H177.079V90.1704C177.079 91.3426 177.832 92.4103 178.602 93.1083C179.391 93.8241 180.518 94.4481 181.689 94.4481H187.495V111.729C187.495 113.202 186.049 114.874 183.639 114.874ZM189.895 93.5697V111.729C189.895 114.792 187.094 117.274 183.639 117.274H163.616C160.16 117.274 157.359 114.792 157.359 111.729V88.2197C157.359 85.1576 160.16 82.6753 163.616 82.6753H178.914L189.895 92.0457V92.9265C189.953 93.1335 189.955 93.3558 189.895 93.5697ZM179.479 90.1704V87.0371L182.766 89.6948L185.503 92.0481H181.689C181.324 92.0481 180.746 91.8137 180.214 91.3309C179.663 90.8302 179.479 90.3592 179.479 90.1704Z"
      fill="#F0F4F7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M227.033 69.7293H207.01C204.599 69.7293 203.153 68.0571 203.153 66.5849V43.0752C203.153 41.603 204.599 39.9308 207.01 39.9308H220.474V45.0263C220.474 46.1985 221.227 47.2662 221.997 47.9642C222.785 48.6799 223.913 49.3039 225.084 49.3039H230.89V66.5849C230.89 68.0571 229.444 69.7293 227.033 69.7293ZM233.29 48.4278V66.5849C233.29 69.647 230.488 72.1293 227.033 72.1293H207.01C203.555 72.1293 200.753 69.647 200.753 66.5849V43.0752C200.753 40.0131 203.555 37.5308 207.01 37.5308H222.309L233.29 46.9012V47.7801C233.348 47.9885 233.35 48.2124 233.29 48.4278ZM222.874 45.0263V41.8929L226.161 44.5507L228.898 46.9039H225.084C224.719 46.9039 224.141 46.6696 223.609 46.1867C223.057 45.6861 222.874 45.215 222.874 45.0263Z"
      fill="#F0F4F7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M227.033 114.874H207.01C204.599 114.874 203.153 113.202 203.153 111.729V88.2197C203.153 86.7475 204.599 85.0753 207.01 85.0753H220.474V90.1704C220.474 91.3426 221.227 92.4103 221.997 93.1083C222.785 93.8241 223.913 94.4481 225.084 94.4481H230.89V111.729C230.89 113.202 229.444 114.874 227.033 114.874ZM233.29 93.5719V111.729C233.29 114.792 230.488 117.274 227.033 117.274H207.01C203.555 117.274 200.753 114.792 200.753 111.729V88.2197C200.753 85.1576 203.555 82.6753 207.01 82.6753H222.309L233.29 92.0457V92.9243C233.348 93.1326 233.35 93.3565 233.29 93.5719ZM222.874 90.1704V87.0371L226.161 89.6948L228.898 92.0481H225.084C224.719 92.0481 224.141 91.8137 223.609 91.3309C223.057 90.8302 222.874 90.3592 222.874 90.1704Z"
      fill="#F0F4F7"
    />
    <path
      d="M229.397 13.0403C229.397 14.8871 227.708 16.3843 225.624 16.3843C223.54 16.3843 221.85 14.8871 221.85 13.0403C221.85 11.1935 223.54 9.69629 225.624 9.69629C227.708 9.69629 229.397 11.1935 229.397 13.0403Z"
      fill="white"
    />
    <path
      d="M241.661 13.0403C241.661 14.8871 239.972 16.3843 237.888 16.3843C235.804 16.3843 234.114 14.8871 234.114 13.0403C234.114 11.1935 235.804 9.69629 237.888 9.69629C239.972 9.69629 241.661 11.1935 241.661 13.0403Z"
      fill="white"
    />
    <path
      d="M252.981 13.0403C252.981 14.8871 251.503 16.3843 249.679 16.3843C247.855 16.3843 246.377 14.8871 246.377 13.0403C246.377 11.1935 247.855 9.69629 249.679 9.69629C251.503 9.69629 252.981 11.1935 252.981 13.0403Z"
      fill="white"
    />
    <path
      d="M45.4351 2.45186C45.4351 1.37388 46.5664 0.5 47.9619 0.5H292.91C294.305 0.5 295.437 1.37388 295.437 2.45186V138.16C295.437 139.238 294.305 140.112 292.91 140.112H47.9619C46.5664 140.112 45.4351 139.238 45.4351 138.16V2.45186Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M291.022 2.37128H46.0742C45.3764 2.37128 44.8108 2.83439 44.8108 3.40565V147.24C44.8108 147.812 45.3764 148.275 46.0742 148.275H291.022C291.72 148.275 292.286 147.812 292.286 147.24V3.40565C292.286 2.83439 291.72 2.37128 291.022 2.37128ZM46.0742 1.33691C44.6787 1.33691 43.5474 2.26312 43.5474 3.40565V147.24C43.5474 148.383 44.6787 149.309 46.0742 149.309H291.022C292.418 149.309 293.549 148.383 293.549 147.24V3.40565C293.549 2.26312 292.418 1.33691 291.022 1.33691H46.0742Z"
      fill="#F0F4F7"
    />
    <path
      d="M50.1498 6.88859C50.1498 6.59254 50.4316 6.35254 50.7792 6.35254H288.201C288.549 6.35254 288.831 6.59254 288.831 6.88859V20.8645C288.831 21.1606 288.549 21.4006 288.201 21.4006H50.7792C50.4316 21.4006 50.1498 21.1606 50.1498 20.8645V6.88859Z"
      fill="#F0F4F7"
    />
    <path
      d="M251.554 13.3535C251.554 15.5379 249.783 17.3087 247.598 17.3087C245.414 17.3087 243.643 15.5379 243.643 13.3535C243.643 11.1692 245.414 9.39844 247.598 9.39844C249.783 9.39844 251.554 11.1692 251.554 13.3535Z"
      fill="white"
    />
    <path
      d="M264.408 13.3535C264.408 15.5379 262.637 17.3087 260.452 17.3087C258.268 17.3087 256.497 15.5379 256.497 13.3535C256.497 11.1692 258.268 9.39844 260.452 9.39844C262.637 9.39844 264.408 11.1692 264.408 13.3535Z"
      fill="white"
    />
    <path
      d="M278.251 13.3535C278.251 15.5379 276.48 17.3087 274.295 17.3087C272.111 17.3087 270.34 15.5379 270.34 13.3535C270.34 11.1692 272.111 9.39844 274.295 9.39844C276.48 9.39844 278.251 11.1692 278.251 13.3535Z"
      fill="white"
    />
    <rect
      x="68.6335"
      y="41.2651"
      width="59.5528"
      height="45.9001"
      rx="4.5"
      stroke="#F0F4F7"
    />
    <rect
      x="137.837"
      y="41.2651"
      width="58.5917"
      height="45.9001"
      rx="4.5"
      stroke="#F0F4F7"
    />
    <rect
      x="206.082"
      y="41.2651"
      width="58.5917"
      height="45.9001"
      rx="4.5"
      stroke="#F0F4F7"
    />
    <rect
      x="337.514"
      y="119.563"
      width="7.21715"
      height="5.15511"
      rx="2"
      fill="white"
    />
    <path
      d="M374.63 187.792C374.63 177.724 366.468 169.562 356.4 169.562H107.608C97.5392 169.562 89.3771 177.724 89.3771 187.792C89.3771 197.861 97.5392 206.023 107.608 206.023H356.4C366.468 206.023 374.63 197.861 374.63 187.792Z"
      fill="#F0F4F7"
    />
    <path
      d="M135.671 139.262C136.023 137.952 137.211 137.042 138.568 137.042H170.036C171.392 137.042 172.58 137.952 172.933 139.262L178.531 160.055C179.044 161.961 177.608 163.834 175.634 163.834H132.97C130.996 163.834 129.56 161.961 130.073 160.055L135.671 139.262Z"
      fill="#EBCEBC"
    />
    <path
      d="M141.5 143L140 148"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M326.555 108.594C326.908 107.284 328.095 106.374 329.452 106.374H360.92C362.277 106.374 363.465 107.284 363.817 108.594L369.415 129.387C369.928 131.293 368.492 133.166 366.518 133.166H323.854C321.88 133.166 320.444 131.293 320.957 129.387L326.555 108.594Z"
      fill="#D6946B"
    />
    <path
      d="M332.5 113L331 118"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M374.63 142.216C374.63 140.143 372.95 138.463 370.877 138.463H320.475C318.402 138.463 316.722 140.143 316.722 142.216C316.722 144.289 318.402 145.97 320.475 145.97H370.877C372.95 145.97 374.63 144.289 374.63 142.216Z"
      fill="#F0F4F7"
    />
    <rect
      width="270.24"
      height="22.52"
      rx="11.26"
      transform="matrix(-1 0 0 1 368.196 175.996)"
      fill="white"
    />
    <circle
      cx="237.156"
      cy="187.816"
      r="5.02998"
      transform="rotate(-180 237.156 187.816)"
      stroke="#D8DDE3"
      strokeWidth="5"
    />
    <path
      d="M314.045 187.816C314.045 185.157 316.353 182.786 319.472 182.786C322.591 182.786 324.898 185.157 324.898 187.816C324.898 190.474 322.591 192.846 319.472 192.846C316.353 192.846 314.045 190.474 314.045 187.816Z"
      stroke="#D8DDE3"
      strokeWidth="5"
    />
    <path
      d="M153.003 187.816C153.003 185.157 155.31 182.786 158.429 182.786C161.548 182.786 163.855 185.157 163.855 187.816C163.855 190.474 161.548 192.846 158.429 192.846C155.31 192.846 153.003 190.474 153.003 187.816Z"
      stroke="#D8DDE3"
      strokeWidth="5"
    />
    <ellipse
      cx="244.924"
      cy="161.317"
      rx="18.191"
      ry="3.95997"
      fill="#EBCEBC"
    />
    <rect
      x="226.733"
      y="130.627"
      width="36.3821"
      height="30.6898"
      fill="#EBCEBC"
    />
    <ellipse
      cx="244.924"
      cy="130.627"
      rx="18.191"
      ry="3.95997"
      fill="#DFB49A"
    />
    <rect
      width="6.42036"
      height="4.99362"
      rx="2"
      transform="matrix(-1 0 0 1 236.721 139.551)"
      fill="white"
    />
    <rect
      x="0.369873"
      y="108.222"
      width="72.1715"
      height="97.9471"
      rx="9"
      fill="#E5EAEE"
    />
    <rect
      x="52.5648"
      y="186.979"
      width="8.63458"
      height="7.84962"
      rx="3"
      fill="#C9D0D8"
    />
    <rect
      x="11.7111"
      y="121.626"
      width="49.4891"
      height="37.1168"
      rx="3"
      fill="white"
    />
    <rect
      x="40.7911"
      y="186.979"
      width="9.41954"
      height="7.84962"
      rx="3"
      fill="#C9D0D8"
    />
  </svg>
);
