import { z } from 'zod';
import { createListEntity } from './list';

export const UserRoleEntity = z.object({
  id: z.number(),
  code: z.string(),
  name: z.string(),
  description: z.string(),
});

export const UserEntity = z.object({
  id: z.number(),
  isSyncedAD: z.boolean(),
  active: z.number(),
  email: z.string(),
  name: z.string().or(z.null()),
  surname: z.string().or(z.null()),
  patronymic: z.string().or(z.null()),
  phoneWork: z.string().or(z.null()),
  phoneMobile: z.string().or(z.null()),
  position: z.string().or(z.null()),
  avatar: z.string().or(z.null()),
  uuid: z.string(),
  localization: z.string(),
  activeAt: z.string().or(z.null()),
  createdAt: z.string().or(z.null()),
  updateAt: z.string().or(z.null()),
  deletedAt: z.string().or(z.null()).or(z.undefined()),
  roles: z.array(UserRoleEntity).or(z.undefined()),
});

export type UserRoleEntity = z.infer<typeof UserRoleEntity>;
export type UserEntity = z.infer<typeof UserEntity>;

export const UsersListEntity = createListEntity(UserEntity);
export type UsersListEntity = z.infer<typeof UsersListEntity>;

export const UsersEntity = z.array(UserEntity);
export type UsersEntity = z.infer<typeof UsersEntity>;
