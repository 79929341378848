import { useProfile } from 'hooks/useProfile';
import { useParams } from 'react-router';

export function UserDetail({ children }) {
  const { userId } = useParams();
  const { store } = useProfile();
  const isSyncedAd = store.user?.isSyncedAD;

  return userId ? children(userId, isSyncedAd) : null;
}
