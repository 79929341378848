import { z } from 'zod';

export const SellCompanyGroupsDictionaryEntity = z.object({
  id: z.string(),
  title: z.string(),
  metalIds: z.array(z.number()),
});

export const ProductionNomenclaturesDictionaryEntity = z.object({
  id: z.number(),
  title: z.string(),
  metalId: z.number(),
  divisionId: z.number(),
});

export const DictionaryEntity = z.object({
  sellCompanyGroups: z.array(SellCompanyGroupsDictionaryEntity),
  productionNomenclatures: z.array(ProductionNomenclaturesDictionaryEntity),
});

export type DictionaryEntity = z.infer<typeof DictionaryEntity>;
