import { createSlice } from '@reduxjs/toolkit';
import { getTessaDocuments, downloadTessaDocument } from 'features/docSearch/duck/actions';

const slice = createSlice({
  name: 'docsSearch',
  initialState: {
    pagination: null,
    list: [],
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [getTessaDocuments.pending]: (state, { meta }) => {
      state.loading = true;

      /* Сохраняем активный фильтр на момент подгрузки документов (search) */
      state.loadingSearch = meta.arg;
    },
    [getTessaDocuments.fulfilled]: (state, { payload }) => {
      if (payload.currentSearch === state.loadingSearch) {
        state.list = payload?.documents.list;
        state.pagination = payload?.documents.pagination;
        state.filters = payload?.filters || state.filters;
      }
      state.loading = false;
    },
    [getTessaDocuments.rejected]: (state) => {
      state.loading = false;
    },
    [downloadTessaDocument.pending]: (state, { meta }) => {
      const id = meta?.arg?.ids;
      const isZipArchiv = Array.isArray(id);
      const key = isZipArchiv ? 'zipArchiv' : `${id}${meta?.arg?.type}`;

      state.loadingFiles = {
        ...state.loadingFiles,
        [key]: true,
      };
    },
    [downloadTessaDocument.fulfilled]: (state, { payload }) => {
      const id = payload?.document?.ids;
      const type = payload?.document?.type;
      const isZipArchiv = Array.isArray(id);
      const key = isZipArchiv ? 'zipArchiv' : `${id}${type}`;
      if (id && type) {
        state.loadingFiles = {
          ...state.loadingFiles,
          [key]: false,
        };
      } else {
        state.loadingFiles = null;
      }
    },
    [downloadTessaDocument.rejected]: (state) => {
      state.loadingFiles = null;
    },
  },
});

export default slice.reducer;
