/* eslint-disable @typescript-eslint/no-unused-vars */
import { message } from 'antd';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiGet, apiDelete, apiPost } from '../../../services/helper';
import download from 'downloadjs';

export const fetchBatches = createAsyncThunk('inventoryApi/fetchBatches', async (id) => {
  const requestUrls = ['/v1/metals'];
  if (id) {
    requestUrls.push(`/v1/inventory/${id}/batches`);
    requestUrls.push(`/v1/inventory/${id}`);
  }

  const [{ data: metalsData }, batchResponse, inventoryData] = await Promise.all(requestUrls.map((url) => apiGet(url)));

  return {
    inventory: inventoryData?.data.data,
    batches: batchResponse?.data.data.batches || [],
    handbook: {
      metals: metalsData.data,
      materials: batchResponse?.data.data.materials,
    },
  };
});

//apiGet(`/v1/inventory/${inventoryId}`);

export const fetchDistributions = createAsyncThunk('inventoryApi/fetchDistributions', async (id) => {
  const requestsUrl = [`/v1/inventory/${id}/distribution`, '/v1/metals'];

  const [{ data: distributionsResponse }, { data: metalsData }] = await Promise.all(
    requestsUrl.map((url) => apiGet(url))
  );

  return {
    distributions: distributionsResponse?.data || null,
    handbook: { metals: metalsData.data },
  };
});

export const createBatch = createAsyncThunk('inventoryApi/createBatch', async ({ id, body }) => {
  const { data } = await apiPost(`/v1/inventory/${id}/batches`, body);

  return data;
});

export const fetchPreview = createAsyncThunk('inventoryApi/fetchPreview', async (id) => {
  const { data: response } = await apiGet(`/v1/inventory/${id}/preview`);

  return response.data;
});

export const completeInventory = createAsyncThunk('inventoryApi/completeInventory', async ({ id, history }) => {
  const { data: response } = await apiGet(`/v1/inventory/${id}/apply`);
  if (response.data) {
    history.push('/inventory');
  }

  return response.data;
});

export const downloadFinishedInventory = createAsyncThunk(
  'inventoryApi/downloadFinishedInventory',
  async ({ id, file = null }) => {
    try {
      let fileId = file;
      let finalResponse = false;

      if (!file) {
        const { data: response } = await apiGet(`/v1/inventory/${id}/apply`);
        fileId = response.data?.fileId;
        finalResponse = response;
      }

      const { data: responseFile1 } = await apiPost(`/v1/file/inventory`, {
        ids: [fileId],
      });

      download(responseFile1.data.file, responseFile1.data.name);

      return responseFile1.data; // todo проверить что ничего не сломалось
      // return finalResponse.data; // todo проверить что ничего не сломалось, быстрый фикс}
    } catch (error) {
      message.error('Ошибка сервера. Не удалось скачать файл');
    }
  }
);

export const downloadExcelTag = createAsyncThunk('inventoryApi/downloadExcelTag', async ({ id }) => {
  try {
    const finalResponse = false;

    const url = `/v1/inventory/${id}/tags`;
    const { data: responseFile1 } = await apiGet(url);

    download(responseFile1.data.file, responseFile1.data.name);

    // return finalResponse.data;
    return responseFile1.data;
  } catch (error) {
    message.error('Ошибка сервера. Не удалось скачать файл');
  }
});

const initialState = {
  step: 0,
  loadingList: false,
  list: [],
  filters: [],
  pagination: '',
  activeFilters: {
    companyId: undefined,
    contractId: undefined,
  },
  companies: [],
  batches: [],
  handbook: null,
  inventory: {},
};

const invoices = createSlice({
  name: 'inventoryApi',
  initialState,
  reducers: {
    reset: () => initialState,
    handleSetStep(state, { payload }) {
      const { step } = payload;
      state.step = step;
    },
    getInventoryStart(state) {
      state.loadingList = true;
    },
    getInventorySuccess(state, { payload }) {
      const { list, pagination } = payload;
      state.list = list;
      state.pagination = pagination;
      state.loadingList = false;
    },
    getInventoryFailed(state) {
      state.loadingList = false;
    },
    getFiltersSuccess(state, { payload }) {
      const { companies, contracts } = payload;
      state.filters = { companies, contracts };
    },
    setFilters(state, { payload }) {
      const { name, value } = payload;
      state.activeFilters = {
        ...state.activeFilters,
        [name]: value,
      };
      if (!value) {
        delete state.activeFilters[name];
      }
      if (name !== 'page') {
        delete state.activeFilters['page'];
      }
    },
    deleteInventory(state, { payload }) {
      const { id, index } = payload;
      state.list.splice(index, 1);
    },
    setCompanies(state, { payload }) {
      const { list } = payload;
      state.companies = list;
    },
    saveBatchesStart(state) {
      state.pending = true;
    },
    saveBatchesFulfilled(state) {
      state.pending = false;
    },
    saveBatchesReject(state) {
      state.pending = false;
    },

    fetchDistributionStart(state) {
      state.pending = true;
    },
    fetchDistributionFulfilled(state, { payload }) {
      state.pending = false;
      state.distribution = payload;
    },
    fetchDistributionReject(state) {
      state.pending = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBatches.pending, (state) => {
      state.batchesPending = true;
    });
    builder.addCase(fetchBatches.fulfilled, (state, { payload }) => {
      state.batches = payload.batches;
      state.handbook = payload.handbook;
      state.inventory = payload.inventory;
      state.batchesPending = false;
    });
    builder.addCase(fetchBatches.rejected, (state) => {
      state.batchesPending = false;
    });

    /* fetchDistributions */
    builder.addCase(fetchDistributions.pending, (state) => {
      state.distributionsPending = true;
    });
    builder.addCase(fetchDistributions.fulfilled, (state, { payload }) => {
      state.distributions = payload.distributions;
      state.handbook = payload.handbook;
      state.distributionsPending = false;
    });
    builder.addCase(fetchDistributions.rejected, (state) => {
      state.distributionsPending = false;
    });

    /* fetchDistributions */
    builder.addCase(fetchPreview.pending, (state) => {
      state.previewPending = true;
    });
    builder.addCase(fetchPreview.fulfilled, (state, { payload }) => {
      state.preview = payload;
      state.previewPending = false;
    });
    builder.addCase(fetchPreview.rejected, (state) => {
      state.previewPending = false;
    });
  },
});

export const {
  getInventoryStart,
  getInventorySuccess,
  getInventoryFailed,
  getFiltersSuccess,
  setFilters,
  deleteInventory,
  setCompanies,
  handleSetStep,
  reset,

  /* Save Batches */
  saveBatchesStart,
  saveBatchesFulfilled,
  saveBatchesReject,

  /* Distribution */
  fetchDistributionStart,
  fetchDistributionFulfilled,
  fetchDistributionReject,
} = invoices.actions;

/* List */
export const fetchInventory =
  (search, history = false) =>
  async (dispatch) => {
    dispatch(getInventoryStart());
    try {
      const {
        data: { success, data },
      } = await apiGet(`/v1/inventory${search}`);
      if (success) {
        const { list, pagination } = data;
        if (list && list.length === 0 && !search) {
          history && history.push('/inventory/start');
        }
        dispatch(getInventorySuccess({ list, pagination }));
      } else {
        message.error('При получение данных, произошла ошибка');
        dispatch(getInventoryFailed());
      }
    } catch (e) {
      message.error('Произошла ошибка сервера');
      dispatch(getInventoryFailed());
    }
  };

/* List Filters */
export const fetchFilters = () => async (dispatch) => {
  try {
    const {
      data: { success, data },
    } = await apiGet('/v1/inventory/filters');
    if (success) {
      const { companies, contracts } = data;
      dispatch(getFiltersSuccess({ companies, contracts }));
    } else {
      message.error('При получение данных, произошла ошибка');
    }
  } catch (e) {
    message.error('Произошла ошибка сервера');
  }
};

export const fetchDelete = (id) => async (dispatch) => {
  try {
    const { data } = await apiDelete(`/v1/inventory/${id}`);
    if (!data.success) {
      throw new Error(data.message);
    }
  } catch (error) {
    message.error(error);
  }
};

export const fetchCopy = (id) => async (dispatch) => {
  try {
    const { data } = await apiGet(`/v1/inventory/${id}/copy`);
    if (!data.success) {
      throw new Error(data.message);
    }
  } catch (error) {
    message.error(error);
  }
};

export const fetchCompanies = () => async (dispatch) => {
  // api/v1/companies
  const {
    data: { success, data },
  } = await apiGet('/v1/companies'); // data -. list
  const { list, pagination } = data;
  dispatch(setCompanies({ list }));
};

const prepareMetalToSend = ([code, value], handbook) => {
  try {
    const metalId = handbook.find(({ code: metalCode }) => code === metalCode)?.id;

    return {
      metalId,
      value,
    };
  } catch (error) {
    return {};
  }
};

const preparePlaceToSend = (place, handbook) => {
  const { num, mass, humidity, materialId, metals } = place;
  const prepHumidity = humidity || 0;
  try {
    return {
      num: String(num),
      mass,
      humidity: prepHumidity,
      materialId,
      metals: Object.entries(metals)
        .filter(([, value]) => value)
        .map((metal) => prepareMetalToSend(metal, handbook)),
    };
  } catch (error) {
    return [];
  }
};

const prepareBatchToSend = (batch, handbook) => {
  const { num, places } = batch;

  return {
    ...batch,
    num: String(num),
    places: places.map((place) => preparePlaceToSend(place, handbook)),
  };
};

const batchEquals = (a, b) => {
  const formatA = a.format;
  const formatB = b.format;
  try {
    const { num, format, places, identifyNumber } = a;

    const checkMetals = (aMs = [], bMs = []) => {
      return aMs.every(({ metalId, value }) => {
        const current = bMs.find(({ metalId: id }) => id === metalId);
        if (!current) {
          return false;
        }

        if (formatB === 2) {
          return current?.percent === value;
        }
        return current?.mass === value;
      });
    };

    const checkPlaces = (aPs = [], bPs = []) => {
      if (aPs.length !== bPs.length) {
        return false;
      }
      return aPs.every(({ materialId, mass, metals, num, humidity }) => {
        const current = bPs.find(
          (current) =>
            current.materialId === materialId &&
            current?.num === num &&
            current?.mass === mass &&
            current?.humidity === humidity &&
            metals.length === current?.metals.length &&
            checkMetals(metals, current?.metals)
          // Проверка длины - если выставить ноль, то Первый массив станет короче, а цикл в функции идет по Первому массиву, изменений не будет и функция вернет true
        );

        if (!current) {
          return false;
        }

        return !!current;
      });
    };

    return (
      num === b?.num && format === b?.format && identifyNumber === b?.identifyNumber && checkPlaces(places, b?.places)
    );
  } catch (error) {
    return false;
  }
};

export const complexBatchSave = (inventoryId, batches, instance, metals, comments) => async (dispatch) => {
  try {
    dispatch(saveBatchesStart());

    const sortedBatches = batches.map((batch, index) => {
      return {
        ...batch,
        sort: index * 100,
      };
    });

    const ids = sortedBatches.filter(({ id }) => id).map(({ id }) => id);

    const { create, oldest } = sortedBatches
      .map((batch) => prepareBatchToSend(batch, metals))
      .reduce(
        (groups, batch) => {
          const group = batch.id ? 'oldest' : 'create';
          groups[group].push(batch);

          return groups;
        },
        { create: [], oldest: [] }
      );

    const checkOnChanges = (batch) => {
      try {
        const prev = instance.find(({ id: prevId }) => prevId === batch.id);

        if (!prev || !batch.id) {
          return true;
        } // дело не в этом

        return !batchEquals(batch, prev);
      } catch (error) {
        return true;
      }
    };

    const remove = instance.filter(({ id }) => !ids.includes(id)).map(({ id }) => id);
    const update = oldest.filter(checkOnChanges);

    const removeResponse = await Promise.all([
      ...remove.map((id) => apiDelete(`/v1/inventory/${inventoryId}/batches/${id}`)),
    ]);

    const updateResponse = await Promise.all([
      ...update.map((batch) => apiPost(`/v1/inventory/${inventoryId}/batches/${batch.id}`, batch)),
    ]);

    const createResponse = await Promise.all([
      ...create.map((batch) => apiPost(`/v1/inventory/${inventoryId}/batches`, batch)),
    ]);

    const response = await Promise.all([comments && apiPost(`/v1/inventory/${inventoryId}`, { comments })]);

    dispatch(saveBatchesFulfilled());
  } catch (error) {
    message.error('Произошла ошибка сервера');
    dispatch(saveBatchesReject());
  }
};

export const getVal = (customers, metals) => {
  const getMetalIdByCode = (code) => {
    return metals.find(({ code: metalCode }) => metalCode === code)?.id;
  };

  return customers.reduce((distributions, { batchId, balancing, id: companyId }) => {
    const distribution = balancing.reduce((list, { metal, type, weight, id }) => {
      return [
        ...list,
        {
          batchId,
          companyId,
          metalId: getMetalIdByCode(metal),
          type,
          mass: +Number(weight).toFixed(7),
          id,
        },
      ];
    }, []);

    return [...distributions, ...distribution];
  }, []);
};

const hasChanges = ({ id, mass, type, metalId }, prevDistributions) => {
  if (!id) {
    return false;
  }

  const prev = prevDistributions.find(({ id: prevId }) => prevId === id);

  return prev.metalId !== metalId || prev.mass !== mass || prev.type !== type;
};

export const complexDistributionsSave = (inventoryId, customers, metals, prevDistributions) => async (dispatch) => {
  try {
    dispatch(fetchDistributionStart());

    const distributions = getVal(customers, metals);
    const update = distributions.filter((distr) => hasChanges(distr, prevDistributions));
    const create = distributions.filter(({ id }) => !id);
    const ids = distributions.map(({ id }) => id);

    const remove = prevDistributions.filter(({ id }) => !ids.includes(id)).map(({ id }) => id);

    // правильный порядок - remove => update => create
    const response1 = await Promise.all([
      ...remove.map((id) => apiDelete(`/v1/inventory/${inventoryId}/distribution/${id}`)),
    ]);

    const response2 = await Promise.all([
      ...update.map((customer) => apiPost(`/v1/inventory/${inventoryId}/distribution/${customer.id}`, customer)),
    ]);

    const response3 = await Promise.all([
      ...create.map((customer) => apiPost(`/v1/inventory/${inventoryId}/distribution`, customer)),
    ]);

    dispatch(fetchDistributionReject());
  } catch (error) {
    message.error(error);
    dispatch(fetchDistributionReject());
  }
};

export default invoices.reducer;
