import { z } from 'zod';
import { UserRoleEnum } from 'constants/metalSale';

export const VerificationEntity = z
  .object({
    role: z.null(),
    features: z.object({
      buy: z.object({ canViewSection: z.literal(false), canCreatePriceFixation: z.boolean() }),
      sell: z.object({
        canViewSection: z.literal(false),
      }),
    }),
  })
  .or(
    z.object({
      role: UserRoleEnum,
      features: z.object({
        buy: z.object({ canViewSection: z.literal(true), canCreatePriceFixation: z.boolean() }),
        sell: z.object({
          canViewSection: z.literal(true),
        }),
      }),
    })
  )
  .or(
    z.object({
      role: UserRoleEnum,
      features: z.object({
        buy: z.object({ canViewSection: z.literal(false), canCreatePriceFixation: z.boolean() }),
        sell: z.object({
          canViewSection: z.literal(true),
        }),
      }),
    })
  )
  .or(
    z.object({
      role: UserRoleEnum,
      features: z.object({
        buy: z.object({ canViewSection: z.literal(true), canCreatePriceFixation: z.boolean() }),
        sell: z.object({
          canViewSection: z.literal(false),
        }),
      }),
    })
  );

export type VerificationEntity = z.infer<typeof VerificationEntity>;
