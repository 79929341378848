import types from '../actions/types';

/**
 * @deprecated https://jira2.knfmp.net/browse/IS-1314
 */
export default function (state = {}, action) {
  switch (action.type) {
    case types.GET_PERMISSIONS: {
      return {
        ...state,
        list: action.payload.list,
        userInfo: {
          name: action.payload.user,
          email: action.payload.email,
        },
      };
    }
    case types.SHOW_PERMISSIONS_LOADER: {
      return { ...state, showLoader: true };
    }
    case types.HIDE_PERMISSIONS_LOADER: {
      return { ...state, showLoader: false };
    }
    default:
      return state;
  }
}
