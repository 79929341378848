import { isProduction } from 'utils/isProduction';
import { WS_DEV_URL, WS_PROD_URL } from 'services/ws/consts';
import { isDevEnv } from 'utils/isDevEnv';
import { tokenWsApi } from 'services/ws/tokenWsApi';

export const getSupportWsUrl = () => (isProduction() ? WS_PROD_URL : WS_DEV_URL);

export const log = (description: string, ctx: unknown) => {
  if (isDevEnv()) {
    // eslint-disable-next-line no-console
    console.log(description, ctx);
  }
};

export async function getWsToken() {
  try {
    const { data } = await tokenWsApi.getToken();
    return data.access_token;
  } catch (err) {
    throw err;
  }
}
