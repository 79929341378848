import { CSSProperties, PropsWithChildren } from 'react';
import styled from 'styled-components';

export type HeaderProps = PropsWithChildren<{
  resetPadding?: boolean;
  isFixed?: boolean;
  style?: CSSProperties;
}>;

export const Header = styled.header<HeaderProps>`
  padding: ${(props) => (props.resetPadding ? 0 : `${props.theme.space['24']} ${props.theme.space['40']}`)};
  box-shadow: 0 1px 2px rgb(0 0 0 / 8%);
  background-color: ${({ theme }) => theme.colors.white};

  ${({ isFixed, theme }) =>
    isFixed &&
    `
      position: sticky;
      top: 0;
      z-index: ${theme.zIndices.appHeader};
  `}

  @media (max-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
    padding: ${(props) => (props.resetPadding ? 0 : `${props.theme.space['24']} ${props.theme.space['32']}`)};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    padding: ${(props) => (props.resetPadding ? 0 : `${props.theme.space['20']}`)};
  }
`;
