import { asyncPipe } from '../utils/asyncPipe';
import { apiPost } from './helper';
import { getDataFromResponse } from '../utils/getDataFromResponse';
import { AdminRelationsApi } from './adminRelationsService.types';

const RELATION_API_URL = '/v1/admin';

const RelationsTypesEnum = {
  Add: 'attach',
  Remove: 'detach',
} as const;

class AdminRelationsServiceFactory {
  addUserToCompany = (body: AdminRelationsApi.addUserToCompany.Request.Body) => {
    return asyncPipe(apiPost, getDataFromResponse)(
      `${RELATION_API_URL}/companies/${body.companyId}/users/${RelationsTypesEnum.Add}`,
      { ...body, action: RelationsTypesEnum.Add }
    );
  };

  removeUserFromCompany = (body: AdminRelationsApi.removeUserFromCompany.Request.Body) => {
    return asyncPipe(apiPost, getDataFromResponse)(
      `${RELATION_API_URL}/companies/${body.companyId}/users/${RelationsTypesEnum.Remove}`,
      { ...body, action: RelationsTypesEnum.Remove }
    );
  };
}

export const AdminRelationsService = new AdminRelationsServiceFactory();
