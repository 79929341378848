import types from '../actions/types';

const initialState = {
  balance: null,
  secondLvl: [],
  balanceResult: null,
  firstLvl: [],
  pagination: {},
  documents: [],
  secondLvlRelease: [],
  basicMetals: {
    Pt: 0,
    Pd: 0,
    Rh: 0,
    Ir: 0,
    Ru: 0,
    Os: 0,
    Au: 0,
    Ag: 0,
    Re: 0,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.RELEAS_FIRST_LVL: {
      return {
        ...state,
        firstLvl: action.payload.firstLvl,
        metals: action.payload.metals,
        pagination: action.payload.pagination,
        balanceResult: null,
        filters: action.payload.filters || {},
        secondLvlRelease: [],
        lvlLoad: false,
        isEmptyList: action.payload.isEmptyList,
      };
    }

    case types.CHANGE_SECOND_LVL: {
      return {
        ...state,
        balanceResult: null,
      };
    }

    case types.SAVE_SECOND_LVL: {
      const { firstLvl, metals } = state;
      const { id, isPlan } = action.payload;
      const currentElements = firstLvl.find(({ id: itemId }) => itemId === id);
      const secondLvlRelease = [];

      for (const key in currentElements.metals) {
        secondLvlRelease.push({
          id: key,
          title: metals.find((metal) => currentElements.metals[key].code === metal.code).name,
          code: currentElements.metals[key].code,
        });
      }
      return {
        ...state,
        secondLvlRelease,
        currentDate: id,
        activeBatch: null,
        isPlan: isPlan,
      };
    }

    case types.GET_BALANCE_RESULT: {
      const { metalsInfo, id } = action.payload;
      return {
        ...state,
        balanceResult: metalsInfo,
        activeBatch: id,
        lvlLoad: false,
      };
    }

    case types.SHIPMENT_FIRST_LVL: {
      return {
        ...state,
        firstLvl: action.payload.firstLvl,
        metals: action.payload.metals,
        pagination: action.payload.pagination,
        balanceResult: null,
        filters: action.payload.filters || {},
        secondLvlRelease: [],
        lvlLoad: false,
        isEmptyList: action.payload.isEmptyList,
      };
    }
    case types.SUPPLY_FIRST_LVL: {
      const { firstLvl, pagination, documents, filters, openNextLvl } = action.payload;
      return {
        ...state,
        firstLvl,
        pagination,
        documents,
        balanceResult: null,
        secondLvl: [],
        filters: filters || {},
        lvlLoad: openNextLvl && firstLvl.length,
        secondLvlRelease: [],
        activeBatch: null,
        isEmptyList: action.payload.isEmptyList,
      };
    }
    case types.GET_NEXT_PAGE_SUPPLY: {
      return {
        ...state,
        firstLvl: [...state.firstLvl, ...action.payload.firstLvl],
        pagination: action.payload.pagination,
        documents: [...state.documents, ...action.payload.documents],
        balanceResult: null,
        secondLvl: [],
        isEmptyList: action.payload.isEmptyList,
      };
    }
    case types.GET_NEXT_PAGE_RELEASE: {
      return {
        ...state,
        firstLvl: [...state.firstLvl, ...action.payload.firstLvl],
        pagination: action.payload.pagination,
        balanceResult: null,
        secondLvl: [],
        isEmptyList: action.payload.isEmptyList,
      };
    }
    case types.GET_NEXT_PAGE_SHIPMENT: {
      return {
        ...state,
        firstLvl: [...state.firstLvl, ...action.payload.firstLvl],
        pagination: action.payload.pagination,
        balanceResult: null,
        secondLvl: [],
        isEmptyList: action.payload.isEmptyList,
      };
    }
    case types.GET_SECOND_SUPPLY: {
      const { batches, supplyId, openNextLvl } = action.payload;
      return {
        ...state,
        secondLvl: batches,
        activeSupply: supplyId,
        activeBatch: null,
        balanceResult: null,
        lvlLoad: openNextLvl && batches.length,
      };
    }
    case types.LOADING_LVL: {
      return {
        ...state,
        lvlLoad: true,
      };
    }
    case types.NEXT_PAGE_LOADING: {
      return {
        ...state,
        nextPageLoading: true,
      };
    }
    case types.NEXT_PAGE_LOADING_END: {
      return {
        ...state,
        nextPageLoading: false,
      };
    }
    case types.CLEAR_MODAL_BALANCE_RESULT: {
      return {
        ...state,
        balanceResult: null,
      };
    }
    default:
      return state;
  }
}
