import { z } from 'zod';

export const RawTypeEntity = z.object({
  id: z.string(),
  title: z.string(),
  metals: z.array(z.number()).or(z.undefined()),
});

export type RawTypeEntity = z.infer<typeof RawTypeEntity>;

export const RawTypeListEntity = z.object({
  rawTypes: z.array(RawTypeEntity.pick({ id: true, title: true })),
});

export type RawTypeListEntity = z.infer<typeof RawTypeListEntity>;
