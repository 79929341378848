import { Result } from 'antd';
import React from 'react';

export function Fallback() {
  return (
    <div style={{ margin: '0 auto' }}>
      <div
        style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Result
          status="error"
          title="Личный кабинет недоступен в Internet Explorer"
          subTitle={
            <div
              style={{
                maxWidth: 600,
                textAlign: 'center',
                margin: 'auto',
              }}
            >
              Рекомендуем воспользоваться любым из современных браузеров:
              <br />
              <a
                href="https://browser.yandex.ru/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Яндекс Браузер
              </a>
              ,{' '}
              <a
                href="https://www.google.com/intl/ru_ru/chrome/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Chrome
              </a>
              ,{' '}
              <a
                href="https://www.mozilla.org/ru/firefox/new/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Firefox Browser
              </a>
            </div>
          }
        />
      </div>
    </div>
  );
}
