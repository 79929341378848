export const MAIN_API_PATH = '/api';
export const PRODUCTS_API_PATH = `${MAIN_API_PATH}/products`;
export const PRODUCTS_ACTIVE_API_PATH = `${PRODUCTS_API_PATH}/active`;
export const VERIFICATION_AUTH_API_PATH = `${MAIN_API_PATH}/auth/verification`;
export const SALES_API_PATH = `${MAIN_API_PATH}/sales`;
export const METAL_TRANSFERS_API_PATH = `${MAIN_API_PATH}/metal-transfers`;

export const FIX_PRICE_API_PATH = `${SALES_API_PATH}/:publicId/fix`;
export const SPOT_PRICE_API_PATH = `${SALES_API_PATH}/:publicId/spot`;
export const INIT_SALE_FORM_API_PATH = `${SALES_API_PATH}/:publicId/form`;

export const CREATE_SALE_API_PATH = `${PRODUCTS_API_PATH}/:id/sale`;
export const CANCEL_PRODUCT_API_PATH = `${PRODUCTS_API_PATH}/:id/cancel`;
export const DELETE_PRODUCT_API_PATH = `${PRODUCTS_API_PATH}/:id`;
export const INIT_FEATURES_SELL_API_PATH = `${MAIN_API_PATH}/features/sell`;
