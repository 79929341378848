import { QuestionnaireState } from './slice.types';
import { setParameter } from '../../../utils/urlParams';
import { matchPath } from 'react-router-dom';
import { QUESTIONNAIRE_SELLER_PATH } from 'constants/routePaths';

export const STEP_NAME_URL_PARAM = 'step';
export const QUESTIONNAIRE_TYPES = {
  'Вторичное сырье': 'recyclable',
  'Минеральное сырье': 'mineral',
};

export const isInvalidQuestionnaireStep = (
  stepNames: QuestionnaireState['stepNames'],
  questionnaireType: string | undefined
) => {
  const isSellerRoute = matchPath(window.location.pathname, {
    path: QUESTIONNAIRE_SELLER_PATH,
    exact: true,
    strict: false,
  });

  if (!isSellerRoute) {
    return false;
  }

  const currentStep = Number(new URLSearchParams(window.location.search).get(STEP_NAME_URL_PARAM));

  return (
    !questionnaireType ||
    !Object.keys(stepNames).includes(questionnaireType) ||
    currentStep > stepNames[questionnaireType].length ||
    currentStep <= 0
  );
};

export const setStepParamInUrl = (step: number) => {
  setParameter(window.location.href, STEP_NAME_URL_PARAM, step);
};

export const normalizeFields = (fields: string[][] | undefined) => {
  const result = (fields || []).filter((field) => {
    const items = field.filter(Boolean);
    return items.length > 0;
  });

  return result.length === 0 ? [[]] : result;
};
