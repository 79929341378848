import React from 'react';
import * as S from './styled';
import { BannerButtonEntity, ImageAlignment, PathEntity } from '../ducks/service.types';
import { Button } from 'components/ui/Button';
import { Icons } from 'components/Icons';
import { LocalesEnum } from '../../../constants/locales';
import { useScreen } from 'hooks/useScreen';
import { isNil, isEmpty } from 'lodash-es';

type BannerProps = {
  onRequest: () => void;
  id: number;
  title: string;
  titleSub: string;
  titleForm: string;
  content: string | null;
  price: string | null;
  priceContent: string | null;
  paths: PathEntity[];
  url: string;
  alignment: ImageAlignment;
  backgroundColor: string;
  button: BannerButtonEntity;
};

export function Banner({
  title,
  titleSub,
  url,
  backgroundColor,
  price,
  priceContent,
  content,
  paths,
  alignment,
  button,
  onRequest,
}: BannerProps) {
  const ruPriceFormatter = new Intl.NumberFormat(LocalesEnum.Ru, {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: 0,
  });
  const { isLargeScreen, isMediumScreen } = useScreen();

  const getPath = (paths: PathEntity[] | null) => {
    if (isEmpty(paths) || isNil(paths)) {
      return '';
    }
    if (isLargeScreen) {
      return paths.filter((path) => path.resolution === 'medium')[0].path;
    }
    if (isMediumScreen) {
      return paths.filter((path) => path.resolution === 'small')[0].path;
    }
    return paths.filter((path) => path.resolution === 'large')[0].path;
  };

  const renderButton = (hasUrl?: boolean) => {
    return (
      <Button
        variant="outlined"
        mt={12}
        onClick={hasUrl ? undefined : onRequest}
      >
        {button.name}
      </Button>
    );
  };

  return (
    <S.Banner
      style={{
        backgroundColor,
      }}
      imageAlignment={alignment}
    >
      <S.Content
        imageAlignment={alignment}
        bannerHasDescription={Boolean(content)}
      >
        <S.Main>
          <S.Title>{title}</S.Title>
          <S.SubTitle>{titleSub}</S.SubTitle>
          {button.url ? (
            <a
              href={button.url}
              target="_blank"
              rel="noreferrer"
            >
              {renderButton(!!button.url)}
            </a>
          ) : (
            renderButton()
          )}
        </S.Main>
        {(price || url) && (
          <S.Extra>
            {priceContent && <S.PriceDescription>{priceContent}</S.PriceDescription>}
            {price && <S.Price>{ruPriceFormatter.format(Number(price))}</S.Price>}
            {url && (
              <S.LinkMore
                href={url}
                target="_blank"
              >
                <S.LinkMoreText>Узнать подробности</S.LinkMoreText>
                {Icons.arrowRight}
              </S.LinkMore>
            )}
          </S.Extra>
        )}
        {content && <S.Description dangerouslySetInnerHTML={{ __html: content }} />}
      </S.Content>
      <S.Image
        style={{
          backgroundImage: `url(${getPath(paths)})`,
        }}
        alignment={alignment}
      />
    </S.Banner>
  );
}
