import { createAsyncThunk } from '@reduxjs/toolkit';
import { ticketsListApi, TicketsListParams } from 'services/support/ticketsListApi';
import { skipEmptyValues } from 'utils/skipEmptyValues';
import { axiosErrorHandle } from 'utils/axiosErrorHandle';

export const TICKETS_LIST_PREFIX = 'ticketsList';
export const GET_TICKETS_LIST = `${TICKETS_LIST_PREFIX}/getTicketsList`;
export const CREATE_TICKET = `${TICKETS_LIST_PREFIX}/createTicket`;

export const getTicketsList = createAsyncThunk(GET_TICKETS_LIST, async (payload: TicketsListParams) => {
  const { data } = await ticketsListApi.getTicketsList(skipEmptyValues(payload));
  return data;
});

export const createTicket = createAsyncThunk(CREATE_TICKET, async (payload: FormData) => {
  try {
    const { data } = await ticketsListApi.createTicket(payload);
    return data;
  } catch (err) {
    axiosErrorHandle(err, 'Не удалось создать обращение');
    throw err;
  }
});
