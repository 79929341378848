import { BRAND, z, ZodIssue } from 'zod';
import { createError, ErrorSchema, isError } from './error';
import i18n from 'i18next';

export const InvalidServerDataSchema = z
  .object({
    zodIssues: z.array(z.object({})),
  })
  .merge(ErrorSchema)
  .brand<'InvalidServerData'>();

type InvalidServerDataError = z.infer<typeof InvalidServerDataSchema>;

export function createInvalidServerDataError(zodIssues: ZodIssue[], message?: string): InvalidServerDataError {
  return {
    ...createError(message || i18n.t('error.Неправильный формат данных')),
    [BRAND]: {
      InvalidServerData: true,
    },
    zodIssues,
  };
}

export const isInvalidServerDataError = isError(InvalidServerDataSchema);
