import { z } from 'zod';
import { SaleStatusEnum } from 'constants/metalPurchase';
import { CompanyGroupEntity } from 'models/api/wts/common/companyGroup';
import { UserEntity } from 'models/api/wts/common/user';
import { ProductEntity } from './product';

export const SaleEntity = z.object({
  id: z.string(),
  weight: z.number(),
  lotsCount: z.number(),
  createdAt: z.string(),
  updatedAt: z.string(),
  status: SaleStatusEnum,
  product: ProductEntity,
  sellCompanyGroup: CompanyGroupEntity,
  createdBy: UserEntity,
});

export type SaleEntity = z.infer<typeof SaleEntity>;
