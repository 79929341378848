import React from 'react';
import { Redirect } from 'react-router-dom';
import { PermissionsEnum } from 'constants/permissions';
import { BannersSectionsEnum } from 'constants/banners';
import { Page } from './types';
import * as routePath from 'constants/routePaths';

const ManagersPage = React.lazy(() => import('./Public/Managers'));
const DocumentsExamplesPage = React.lazy(() => import('./Documents/Examples'));
const DocumentsRegulationsPage = React.lazy(() => import('./Documents/Regulations'));
const DocumnetsCompanyPage = React.lazy(() => import('./Documents/Company'));
const DocumentsPricesPage = React.lazy(() => import('./Documents/Prices'));
const DocumentsPage = React.lazy(() => import('./Documents'));
const OtherPage = React.lazy(() => import('./Other'));
const OtherReportsPage = React.lazy(() => import('./Other/Reports'));
const OtherDocumentsPage = React.lazy(() => import('./Other/Documents'));
const NotificationsPage = React.lazy(() => import('./Notifications'));
const ProfilePage = React.lazy(() => import('./Profile'));
const FinancesPage = React.lazy(() => import('pages/finance/FinancesPage'));
const SupportTicketsPage = React.lazy(() => import('./support/SupportTicketsPage'));
const SupportTicketPage = React.lazy(() => import('./support/SupportTicketPage'));
const QuestionnaireMainPage = React.lazy(() => import('./Questionnaire/Main'));
const QuestionnaireDetailPage = React.lazy(() => import('./Questionnaire/Detail'));
const DashboardPage = React.lazy(() => import('./Dashboard'));
const ShipmentsPage = React.lazy(() => import('./Shipments'));
const BalancePage = React.lazy(() => import('./balance/BalancePage'));
const BalanceSuppliesPage = React.lazy(() => import('./balance/BalanceSuppliesPage'));
const InvoicesPage = React.lazy(() => import('./Invoices'));
const InvoicesCreatePage = React.lazy(() => import('./Invoices/Create'));
const InvoicesStartPage = React.lazy(() => import('./Invoices/Start'));
const InvoicesEditPage = React.lazy(() => import('./Invoices/Edit'));
const ExitPage = React.lazy(() => import('./Exit'));
const NoMatchPage = React.lazy(() => import('./404'));
const RemainsPage = React.lazy(() => import('./Remains'));
const DocSearchPage = React.lazy(() => import('./DocSearch'));
const PricesPage = React.lazy(() => import('./Prices'));
const InvoicesCodesPage = React.lazy(() => import('./Invoices/Codes'));
const SupportFAQPage = React.lazy(() => import('./support/SupportFAQPage'));
const SupportPage = React.lazy(() => import('./support/SupportPage'));
const BalanceProductsPage = React.lazy(() => import('./balance/BalanceProductsPage'));
const TechOrdersPage = React.lazy(() => import('pages/techOrders/TechOrdersPage'));
const EarlyShipmentPage = React.lazy(() => import('./earlyShipment/EarlyShipmentPage'));
const EarlyShipmentMetalsPage = React.lazy(() => import('./earlyShipment/EarlyShipmentMetalsPage'));
const EarlyShipmentRequestsPage = React.lazy(() => import('./earlyShipment/EarlyShipmentRequestsPage'));

export const publicPages: Page[] = [
  {
    path: '/managers',
    component: ManagersPage,
    permission: PermissionsEnum.Managers,
  },
  {
    path: '/documents/examples',
    component: DocumentsExamplesPage,
    permission: PermissionsEnum.Documents,
  },
  {
    path: '/documents/regulations',
    component: DocumentsRegulationsPage,
    permission: PermissionsEnum.Documents,
  },
  {
    path: '/documents/company',
    component: DocumnetsCompanyPage,
    permission: PermissionsEnum.Documents,
  },
  {
    path: '/documents/prices',
    component: DocumentsPricesPage,
    permission: PermissionsEnum.Price,
  },
  {
    path: '/documents',
    component: DocumentsPage,
    permission: PermissionsEnum.Documents,
  },
  {
    path: '/other',
    component: OtherPage,
    permission: PermissionsEnum.ReportsAgent,
  },
  {
    path: '/other/reports',
    component: OtherReportsPage,
    permission: PermissionsEnum.ReportsAgent,
  },
  {
    path: '/other/documents',
    component: OtherDocumentsPage,
    permission: PermissionsEnum.ReportsAgent,
  },
  {
    path: '/notifications',
    component: NotificationsPage,
    permission: null,
  },
  {
    path: '/profile',
    component: ProfilePage,
    permission: null,
  },
  {
    path: routePath.REPORTS_PATH,
    component: FinancesPage,
    permission: PermissionsEnum.Finances,
    bannersSection: BannersSectionsEnum.Finances,
  },
  {
    path: routePath.SUPPORT_PATH,
    component: SupportPage,
    permission: PermissionsEnum.Support,
    exact: false,
    nestedRoutes: [
      {
        path: routePath.SUPPORT_PATH,
        component: () => <Redirect to={routePath.SUPPORT_TICKETS_PATH} />,
        exact: true,
        permission: PermissionsEnum.Support,
      },
      {
        path: routePath.SUPPORT_TICKETS_PATH,
        component: SupportTicketsPage,
        exact: false,
        nestedRoutes: [
          {
            path: routePath.SUPPORT_TICKET_PATH,
            component: SupportTicketPage,
            exact: true,
            permission: PermissionsEnum.Support,
          },
        ],
        permission: PermissionsEnum.Support,
      },
      {
        path: routePath.SUPPORT_FAQ_PATH,
        component: SupportFAQPage,
        exact: true,
        permission: PermissionsEnum.Support,
      },
    ],
  },
  {
    path: '/questionnaire',
    component: QuestionnaireMainPage,
    permission: PermissionsEnum.LBMA,
  },
  {
    path: routePath.QUESTIONNAIRE_SELLER_PATH,
    component: QuestionnaireDetailPage,
    permission: PermissionsEnum.LBMA,
  },
  {
    path: '/dashboard',
    component: DashboardPage,
    permission: PermissionsEnum.Dashboard,
  },
  {
    path: '/shipments',
    component: ShipmentsPage,
    permission: PermissionsEnum.Consignee,
  },
  {
    path: '/orders',
    component: TechOrdersPage,
    permission: PermissionsEnum.Orders,
    bannersSection: BannersSectionsEnum.Orders,
  },
  {
    path: routePath.BALANCE_PATH,
    component: BalancePage,
    exact: false,
    bannersSection: BannersSectionsEnum.Balance,
    nestedRoutes: [
      {
        path: routePath.BALANCE_SUPPLIES_PATH,
        component: BalanceSuppliesPage,
        exact: true,
        permission: PermissionsEnum.Supply,
        isNavVisible: true,
        label: 'auth.Сырье',
      },
      {
        path: routePath.BALANCE_PRODUCTS_PATH,
        component: BalanceProductsPage,
        exact: true,
        permission: PermissionsEnum.Supply,
        isNavVisible: true,
        label: 'auth.Готовая продукция',
      },
    ],
  },
  {
    path: '/inventory',
    component: InvoicesPage,
    permission: PermissionsEnum.Inventory,
  },
  {
    path: '/inventory/create',
    component: InvoicesCreatePage,
    permission: PermissionsEnum.Inventory,
  },
  {
    path: '/inventory/start',
    component: InvoicesStartPage,
    permission: PermissionsEnum.Inventory,
  },
  {
    path: '/inventory/edit/:id',
    component: InvoicesEditPage,
    permission: PermissionsEnum.Inventory,
  },
  {
    path: '/inventory/codes',
    component: InvoicesCodesPage,
    permission: PermissionsEnum.Inventory,
  },
  {
    path: '/remains',
    component: RemainsPage,
  },
  {
    path: '/documents-search',
    component: DocSearchPage,
    bannersSection: BannersSectionsEnum.SearchDocuments,
  },
  {
    path: '/prices',
    component: PricesPage,
  },
  {
    path: routePath.EARLY_SHIPMENT_PATH,
    component: EarlyShipmentPage,
    exact: false,
    nestedRoutes: [
      {
        path: routePath.EARLY_SHIPMENT_PATH_METALS,
        component: EarlyShipmentMetalsPage,
        permission: PermissionsEnum.EarlyShipment,
        exact: true,
        isNavVisible: true,
        label: 'Металлы',
      },
      {
        path: routePath.EARLY_SHIPMENT_PATH_REQUESTS,
        component: EarlyShipmentRequestsPage,
        permission: PermissionsEnum.EarlyShipment,
        exact: true,
        isNavVisible: true,
        label: 'Мои заявки',
      },
    ],
  },
  {
    path: '/exit',
    component: ExitPage,
    permission: null,
  },
  {
    path: routePath.NOT_FOUND_PATH,
    component: NoMatchPage,
    permission: null,
  },
  {
    component: () => <Redirect to={routePath.NOT_FOUND_PATH} />,
    permission: null,
  },
];
