import { PropsWithChildren } from 'react';
import { space, SpaceProps } from 'styled-system';
import styled from 'styled-components';

export const HeaderTitle = styled.div<PropsWithChildren<SpaceProps>>`
  ${space};
  position: relative;
  font-weight: 300;
  font-size: ${({ theme }) => theme.fontSizes['36']};
  line-height: 1.2em;
  color: ${({ theme }) => theme.colors['grey-2']};

  @media (max-width: 1366px) {
    font-size: ${({ theme }) => theme.fontSizes['34']};
    line-height: ${({ theme }) => theme.lineHeights['42']};
  }
  @media (max-width: 850px) {
    font-size: ${({ theme }) => theme.fontSizes['28']};
    line-height: ${({ theme }) => theme.lineHeights['36']};
  }
`;
