import { z } from 'zod';

export const TagEntity = z.object({
  id: z.number(),
  title: z.string(),
});

export const StarEntity = z.object({
  id: z.number(),
  title: z.string(),
  tags: z.array(z.number()),
});

export const SurveyEntity = z.object({
  id: z.number(),
  title: z.string(),
  tags: z.array(TagEntity),
  stars: z.array(StarEntity),
});

export type TagEntity = z.infer<typeof TagEntity>;
export type StarEntity = z.infer<typeof StarEntity>;
export type SurveyEntity = z.infer<typeof SurveyEntity>;
