import React from 'react';
import * as S from './AppLinks.styled';
import { useTranslation } from 'react-i18next';
import appleLogo from './icons/appleLogo.svg';
import googlePlayLogo from './icons/googlePlayLogo.svg';
import googlePlay from './icons/googlePlay.svg';
import appStore from './icons/appStore.svg';
import SVG from 'react-inlinesvg';
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from 'features/AppLinks/consts';
import { appLinksAnalytics } from 'features/AppLinks/appLinksAnalytics';

export type AppLinksLocation = 'sidebar' | 'auth';

type AppLinks = {
  location: AppLinksLocation;
};

export function AppLinks({ location }: AppLinks) {
  const { t } = useTranslation();

  return (
    <S.Wrapper location={location}>
      <S.Title>{t('app.Мобильное приложение')}</S.Title>
      <S.Link
        href={APP_STORE_LINK}
        target="_blank"
        onClick={() => appLinksAnalytics.clickLink('app-store', location)}
      >
        <S.LinkIcon>
          <SVG src={appleLogo} />
        </S.LinkIcon>
        <S.LinkTitle>{t('app.Загрузите в')}</S.LinkTitle>
        <S.LinkAppName>
          <SVG src={appStore} />
        </S.LinkAppName>
      </S.Link>
      <S.Link
        href={GOOGLE_PLAY_LINK}
        target="_blank"
        onClick={() => appLinksAnalytics.clickLink('google-play', location)}
      >
        <S.LinkIcon>
          <SVG src={googlePlayLogo} />
        </S.LinkIcon>
        <S.LinkTitle>{t('app.Доступно в')}</S.LinkTitle>
        <S.LinkAppName isGP>
          <SVG src={googlePlay} />
        </S.LinkAppName>
      </S.Link>
    </S.Wrapper>
  );
}
