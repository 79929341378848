import { useCallback } from 'react';

export function useQaAttr(prefix?: string) {
  const createQaAttr = useCallback(
    (value: string) => ({
      'data-qa': prefix ? `${prefix}__${value}` : value,
    }),
    [prefix]
  );

  return {
    qaAttr: createQaAttr,
  };
}
