import { z } from 'zod';

export const SupportCountersInner = z.object({
  openedTickets: z.number(),
  unreadMessages: z.number(),
});
export type SupportCountersInner = z.infer<typeof SupportCountersInner>;

export const SupportCounters = z.object({
  supportCounters: SupportCountersInner,
});
export type SupportCounters = z.infer<typeof SupportCounters>;
