import { OAUTH_MESSAGE_TYPE } from 'constants/oauth';
import { OAuthMessageSchema } from 'models/params/auth/oauth';

function createOAuthMessage(payload: OAuthMessageSchema['payload']): OAuthMessageSchema {
  return {
    type: OAUTH_MESSAGE_TYPE,
    payload,
  };
}

export function sendMessageToWindowOpener(payload: OAuthMessageSchema['payload']) {
  window.opener?.postMessage(createOAuthMessage(payload));
}
