import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { useCallback, useEffect } from 'react';
import { useLogin } from 'ducks/auth/hooks/useLogin';
import { loginAnalytics } from 'features/Login/loginAnalytics';
import { accessToken } from 'utils/accessToken';
import { refreshToken } from 'utils/refreshToken';
import { useAuth } from 'ducks/auth/hooks/useAuth';
import { LoginErrorsSchema, LoginFormSchema } from 'features/Login/models';
import { setFormFieldsErrors } from 'utils/setFormFieldsErrors';
import { resetLoginFormFieldsErrors } from 'features/Login/utils';
import { usePrevious } from 'react-use';

export function useLoginForm({ initApp }: Pick<ReturnType<typeof useAuth>, 'initApp'>) {
  const { i18n } = useTranslation();
  const { language } = i18n;

  const [form] = Form.useForm();

  const { login, isLoading, isSuccess, data, originalArgs, error, reset } = useLogin();

  const onSubmit = useCallback(
    (values: unknown) => {
      const result = LoginFormSchema.safeParse(values);

      if (result.success) {
        login({ username: result.data.username || '', password: result.data.password || '', language });
      }
    },
    [language, login]
  );

  useEffect(() => {
    if (isSuccess && originalArgs && data) {
      accessToken.setToken(data.accessToken);
      refreshToken.setToken(data.refreshToken);

      loginAnalytics.successLogin(originalArgs.username);
      initApp();
    }
  }, [data, initApp, isSuccess, originalArgs]);

  useEffect(() => {
    if (error && originalArgs) {
      loginAnalytics.errorLogin(originalArgs.username);
      setFormFieldsErrors({ error, form, errorSchema: LoginErrorsSchema });
    }
  }, [error, form, originalArgs]);

  const prevLanguage = usePrevious(language);
  useEffect(() => {
    if (prevLanguage && prevLanguage !== language) {
      resetLoginFormFieldsErrors(form);
      reset();
    }
  }, [form, language, prevLanguage, reset]);

  return {
    form,
    isSubmitting: isLoading,
    onSubmit,
    error,
  };
}
