import types from '../actions/types';
import * as R from 'ramda';

const dateRangeFilter = (date, start, end) => {
  let valids = 0;
  if (!start) {
    valids++;
  }
  if (!end) {
    valids++;
  }

  if (start && date >= start) {
    valids++;
  }
  if (end && date <= end) {
    valids++;
  }

  return valids === 2;
};

const initialState = {
  getDataLoading: true,
  services: [],
  pageSize: 20,
  page: 0,
  sorted: {
    type: 'asc',
    name: 'milliseconds',
  },
  contracts: [],
  accounts: [],
  filters: {},
  section: 'other',
  filesLoading: {},
};

export default function (state = initialState, action) {
  const getSortedServices = () => {
    const {
      services,
      sorted: { type, name },
      filters,
    } = state;

    return services
      .sort((a, b) => {
        const valueA = a[name];
        const valueB = b[name];
        const getCompare = type === 'asc' ? valueA < valueB : valueA > valueB;

        if (name === 'milliseconds' && valueA === valueB) {
          return a.id < b.id ? 1 : -1;
        }
        return getCompare ? 1 : -1;
      })
      .filter((service) => {
        return Object.entries(service).every(([key, value]) => {
          if (key in filters && filters[key] !== undefined) {
            return value === filters[key];
          }

          if (key === 'milliseconds') {
            const dateStart = filters['dateStart'] || null;
            const dateEnd = filters['dateEnd'] || null;

            return dateRangeFilter(service.milliseconds, dateStart, dateEnd);
          }

          return true;
        });
      });
  };

  switch (action.type) {
    case types.GET_SERVICES_SETTING: {
      const { services, filters } = action.payload;
      const contracts = [];
      const accounts = [];
      const sortedServices = services.map((service) => {
        const { contract, account, date } = service;
        contracts.push(contract);
        accounts.push(account);
        return {
          ...service,
          milliseconds: new Date(date).getTime(),
        };
      });
      return {
        ...state,
        getDataLoading: false,
        services: sortedServices,
        contracts: [...new Set(contracts)].filter(Boolean),
        accounts: [...new Set(accounts)].filter(Boolean),
        filters,
        page: 0,
      };
    }
    case types.GET_SERVICES_LOADING: {
      return { ...state, getDataLoading: true };
    }
    case types.GET_SERVICES_ERROR: {
      return { ...state, getDataLoading: false };
    }
    case types.CHANGE_PAGE: {
      const { page } = action;

      return { ...state, page };
    }
    case types.CHECK_SERVICE: {
      const { services } = state;
      const { id, checked } = action;
      const serviceIndex = services.findIndex(({ id: serviceId }) => serviceId === id);
      const finalServices = [
        ...services.slice(0, serviceIndex),
        { ...services[serviceIndex], checked: checked },
        ...services.slice(serviceIndex + 1, services.length),
      ];
      return { ...state, services: finalServices };
    }

    case types.TOGGLE_CURRENT_PAGE: {
      const { pageSize } = state;
      const { page, checked } = action;
      const sortedServices = getSortedServices();

      const { items, start, last } = getCurrentPageItems(page, sortedServices, pageSize);

      const finalServices = [
        ...sortedServices.slice(0, start),
        ...items.map((service) => ({
          ...service,
          checked,
        })),
        ...sortedServices.slice(last, sortedServices.length),
      ];

      return {
        ...state,
        services: finalServices,
      };
    }
    case types.SERVICES_SORT_BY: {
      const { sortType, name } = action;
      return {
        ...R.clone(state),
        sorted: { type: sortType, name },
        page: 0,
      };
    }

    case types.CLEAR_ALL_CHECKED: {
      const services = state.services.map((service) => ({
        ...service,
        checked: false,
      }));
      return { ...state, services };
    }
    case types.FILTER_SERVICES: {
      const { filters } = action;

      return { ...state, filters: { ...filters }, page: 0 };
    }
    case types.CHANGE_SECTION: {
      const { section } = action;

      return { ...state, section };
    }
    case types.MASS_DOWNLOAD__LOADING: {
      return {
        ...state,
        filesLoading: {
          ...state.filesLoading,
          [action.fileType]: true,
        },
      };
    }
    case types.MASS_DOWNLOAD__SUCCESS: {
      return {
        ...state,
        filesLoading: {
          ...state.filesLoading,
          [action.fileType]: false,
        },
      };
    }
    case types.MASS_DOWNLOAD__ERROR: {
      return {
        ...state,
        filesLoading: {
          ...state.filesLoading,
          [action.fileType]: false,
        },
      };
    }
    default:
      return state;
  }
}

export const getCurrentPageItems = (page, list, size) => {
  const start = page * size;
  const last = start + size;

  return { items: list.slice(start, last), start, last };
};
