import { actionTriggering } from 'analytics';

class WtsAnalytics {
  readonly CLICK_REGULATIONS_HEADER = 'wts_metalsale_regulations_header';
  readonly CLICK_PROMO_MANAGER = 'wts_metalsale_promo-manager';
  readonly CLICK_REGULATIONS_IN_MODAL = 'wts_matelsale_regulations_modal';
  readonly OFFER_EXPIRES = 'wts_metalsale_offer_expires';
  readonly SPOT_FAIl = 'wts_metalsale_spot_fail';
  readonly SPOT_SUCCESS = 'wts_metalsale_spot_success';

  constructor(protected dispatch = actionTriggering) {}

  /** Клик по ссылке "Открыть инструкцию по работе с сервисом" в шапке раздела продажи металлов
   * */
  clickRegulationsHeader = () => {
    this.dispatch(this.CLICK_REGULATIONS_HEADER);
  };

  /** Клик по ссылке "менеджеру" на промо странице
   * */
  clickPromoManager = () => {
    this.dispatch(this.CLICK_PROMO_MANAGER);
  };

  /** Клик по ссылке "Ознакомлен с инструкцией по эксплуатации сервиса"
   * */
  clickRegulationsInModal = () => {
    this.dispatch(this.CLICK_REGULATIONS_IN_MODAL);
  };

  /** Просрочен таймер подтверждения в модальном окне
   * @param ttl разница между expired и браузерным врменем в секундах
   * @param context информация о просроченном оффере
   * */
  offerExpired = (ttl: number, context: unknown) => {
    this.dispatch(this.OFFER_EXPIRES, {
      ActionTargetType: ttl.toString(),
      ActionTargetValue: JSON.stringify(context),
    });
  };

  /** Просрочен таймер подтверждения в модальном окне
   * @param ttl время действия (подтверждения) в секундах
   * */
  spotExpiredFixationModal = (ttl: number) => {
    this.dispatch(this.SPOT_FAIl, {
      ActionTargetValue: ttl.toString(),
    });
  };

  /** Успешная фиксация по споту
   * @param duration время с момента нажатия кнопки "Подтвердить" до получение ответа от сервера
   * */
  spotSuccess = (duration: number) => {
    this.dispatch(this.SPOT_SUCCESS, {
      ActionTargetType: duration.toString(),
    });
  };
}

export const wtsAnalytics = new WtsAnalytics();
