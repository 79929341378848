import { asyncPipe } from 'utils/asyncPipe';
import { getDataFromResponse } from 'utils/getDataFromResponse';
import { FAQApi } from './service.types';
import { apiGet } from 'services/helper';

const FAQ_API_URL = '/v1/faq';

class FAQServiceFactory {
  getQuestions = ({ rubricId, search }: FAQApi.getQuestions.Request.Params) => {
    return asyncPipe(apiGet, getDataFromResponse)<FAQApi.getQuestions.Response.Success>(
      `${FAQ_API_URL}/questions/${rubricId ?? ''}`,
      {
        search,
      }
    );
  };

  getRubrics = async () => {
    return asyncPipe(apiGet, getDataFromResponse)<FAQApi.getRubrics.Response.Success>(`${FAQ_API_URL}/rubrics`);
  };
}

export const FAQService = new FAQServiceFactory();
