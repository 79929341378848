export const PermissionsEnum = {
  Support: 'support',
  Managers: 'managers',
  Documents: 'documents',
  Price: 'price',
  ReportsAgent: 'reports.agent',
  Finances: 'finances',
  LBMA: 'lbma',
  Dashboard: 'dashboard',
  Consignee: 'consignee',
  Orders: 'sales',
  Inventory: 'inventory',
  Supply: 'supply',
  Release: 'release',
  Shipment: 'shipment',
  BalanceSupplyMetal: 'balance.report',
  BalanceSupplyNzp: 'balance.nzp',
  EarlyShipment: 'early.shipment',
} as const;

export type Permission = Values<typeof PermissionsEnum>;
