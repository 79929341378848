import { createAsyncThunk } from '@reduxjs/toolkit';
import * as antd from 'antd';
import * as restorePasswordService from 'pages/auth/BadLinkReset/services';

export const resetPassword = createAsyncThunk(
  'badLinkReset/resetPassword',
  async ({ email, token, password, localization }, { rejectWithValue }) => {
    const response = await restorePasswordService.restorePassword({
      email,
      token,
      password,
      localization,
    });

    if (!response.success) {
      antd.message.error(response.message || response.data.password.join(', '));
      return rejectWithValue(response.data);
    } else {
      antd.message.success(response.message);
      return response;
    }
  }
);
