import { useAuth as _useAuth } from 'ducks/auth/hooks/useAuth';
import { getNextPage } from 'features/Login/utils';
import { LoginProps } from 'features/Login/types';

export function useAuth(location: LoginProps['location']) {
  const { authState, initApp } = _useAuth();

  return {
    authState,
    initApp,
    successPath: {
      pathname: getNextPage(authState.init?.permissions, location?.state?.currentPage),
      state: {
        from: location,
        searchStr: location?.search,
      },
    },
  };
}
