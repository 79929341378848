import React from 'react';
import { Alert, Tooltip } from 'antd';
import { useWsNotification } from 'features/support/WsNotification/hooks/useWsNotification';
import * as S from './WsNotification.styled';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'hooks/useTheme';
import { useScreen } from 'hooks/useScreen';
import { Icons } from 'components/Icons';

type WsNotificationProps = {
  isEnabled: boolean;
  isWide?: boolean;
  size?: 'sm' | 'lg';
};

export function WsNotification({ isEnabled, isWide, size = 'lg' }: WsNotificationProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isSmallScreen } = useScreen();
  const { isNotificationVisible } = useWsNotification();

  if (!isNotificationVisible || !isEnabled) {
    return null;
  }

  const content = t('feedback.Отсутствует соединение с сервером');

  if (size === 'sm') {
    const icon = (
      <S.IconWrapper>
        {/* Не используем асинхронную подгрузку svg, чтобы иконка могла отображаться без интернета */}
        {Icons.warningTriangle}
      </S.IconWrapper>
    );

    if (isSmallScreen) {
      return icon;
    }

    return (
      <Tooltip
        title={content}
        color={theme.colors.white}
        overlayInnerStyle={{
          color: theme.colors['grey-2'],
        }}
      >
        {icon}
      </Tooltip>
    );
  }

  return (
    <S.AlertWrapper isWide={isWide}>
      <Alert
        message={content}
        showIcon
        type="warning"
      />
    </S.AlertWrapper>
  );
}
