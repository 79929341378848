import types from '../actions/types';
import * as R from 'ramda';

const initialState = {
  subscribeMsg: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_PROFILE: {
      return {
        ...state,
        profile: action.payload,
      };
    }
    case types.SHOW_SUBSCRIBE_MSG: {
      let subscribe = R.clone(state.profile.subscribe);
      subscribe = subscribe.items.map((item) => {
        item.items.map((it) => {
          if (~action.payload.value.indexOf(it.code)) {
            it.checked = true;
          } else {
            it.checked = false;
          }
          return it;
        });
        return item;
      });
      return {
        ...state,
        subscribeMsg: action.payload.messages,
        profile: {
          ...state.profile,
          ...subscribe,
        },
      };
    }
    case types.HIDE_SUBSCRIBE_MSG: {
      return {
        ...state,
        subscribeMsg: null,
      };
    }
    default:
      return state;
  }
}
