import styled from 'styled-components';
import { Badge as AntdBadge, Drawer as AntdDrawer } from 'antd';

export const MenuIcon = styled.div`
  margin-right: 8px;
  width: 20px;
  height: 20px;
`;

export const Wrapper = styled.div`
  width: 100%;
  background-color: #4f4f4f;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  ::after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    content: '';
    border-bottom: 1px solid #797979;
  }
`;

export const Group = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoIcon = styled.div`
  margin-left: 12px;
  svg {
    width: 23px;
    height: 31px;
  }
`;

export const Icon = styled.div`
  display: flex;
  color: #fff;
  svg {
    width: 28px;
    min-width: 28px;
    height: 28px;
  }
`;

export const Badge = styled(AntdBadge)`
  .ant-badge-count {
    top: 2px;
    padding: 0;
    height: 14px;
    min-width: 14px;
    background-color: #cd743d;
    color: #fff;
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    box-shadow: none;
  }
`;

export const SubIcon = styled.div`
  display: flex;
  color: #fff;
  svg {
    width: 18px;
    min-width: 18px;
    height: 18px;
  }
`;

//стили деталки

export const Drawer = styled(AntdDrawer)`
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    padding: 12px 0;
    background-color: #4f4f4f;
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
`;

export const LogoIconDetail = styled(LogoIcon)`
  margin: 0;
`;

export const LogoText = styled.div`
  display: block;
  margin-left: 4px;
  margin-top: 6px;
  svg {
    width: 105px;
    height: 11px;
  }
`;

//стили деталки юзера
export const UserDetailDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }
`;

export const UserHeader = styled.div`
  position: relative;
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  ::after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    content: '';
    border-bottom: 1px solid #797979;
  }
`;

export const NavTitle = styled.div`
  color: #fff;
  font-size: 14px;
  line-height: 15px;
`;
