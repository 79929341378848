import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import axios from 'axios';

import { ROOT_URL, JWT_ACCESS } from '../../../actions/connect';
import history from '../../../store/history';

const prices = createSlice({
  name: 'prices',
  initialState: {
    loading: false,
    prices: [],
    pagination: [],
  },
  reducers: {
    startRequest(state) {
      state.loading = true;
    },
    getPricesSuccess(state, { payload }) {
      const { list, pagination } = payload.data;
      state.prices = list;
      state.pagination = pagination;
      state.loading = false;
    },
    getPricesFailed(state) {
      state.loading = false;
    },
  },
});

export const { startRequest, getPricesSuccess, getPricesFailed } = prices.actions;

export const fetchPrices = (params) => async (dispatch) => {
  const url = `${ROOT_URL}/v1/price/list`;
  const downloadPrices = message.loading('Получение прайс-листа..', 0);

  dispatch(startRequest());
  try {
    const config = {
      params: { ...params },
      headers: {
        Authorization: `Bearer  ${localStorage.getItem(JWT_ACCESS)}`,
      },
    };

    const {
      data: { success, data },
    } = await axios.get(url, config);
    if (success) {
      history.push(`/documents/prices?page=${params.page || 1}`);
      dispatch(getPricesSuccess({ data }));
    } else {
      message.error('При получение данных, произошла ошибка');
    }
  } catch (e) {
    dispatch(getPricesFailed());
    message.error('Произошла ошибка сервера!');
  }

  downloadPrices();
};

export default prices.reducer;
