import axios from 'axios';
import { ROOT_URL, JWT_ACCESS, AD_JWT_ACCESS } from './connect';
import types from './types';
import history from '../store/history';
import { checkError } from 'actions/errorText';
import { message } from 'antd';
import { AppDispatch, ThunkDispatch } from '../store';
import { AdminCompaniesService } from '../services/adminCompaniesService';
import { AdminRelationsService } from '../services/adminRelationsService';
import { AdminRelationsApi } from '../services/adminRelationsService.types';
import { AdminContractsService } from '../services/adminContractsService';

const setLoading = () => ({
  type: types.LOADING_TAB_CONTENT,
});

const unsetLoading = () => ({
  type: types.HIDE_TAB_CONTENT_LOADER,
});

export function getCompanyById(companyId: string) {
  return (dispatch: AppDispatch) => {
    dispatch(setLoading());

    AdminCompaniesService.getCompanyById(companyId)
      .then((response) => {
        dispatch(unsetLoading());

        dispatch({
          type: types.GET_TAB_CONTENT,
          payload: {
            id: response.data.id,
            type: response.data.typeLegalId,
            name: response.data.name,
            inn: response.data.inn,
            kpp: response.data.kpp,
            address: response.data.address,
          },
          tab: 'edit',
          currentType: 'companies',
        });

        dispatch({
          type: types.GET_TAB_CONTENT,
          payload: response.data.users,
          tab: 'users',
          currentType: 'companies',
        });

        dispatch({
          type: types.GET_TAB_CONTENT,
          payload: response.data.contracts,
          tab: 'contracts',
          currentType: 'companies',
        });
      })
      .catch((error) => {
        dispatch(unsetLoading());
        checkError(error, dispatch, 'entityDetails', 'tab');
      });
  };
}

export const getContractById = (contractId: string) => {
  return (dispatch: AppDispatch) => {
    dispatch(setLoading());

    AdminContractsService.getContractById(contractId)
      .then((response) => {
        dispatch(unsetLoading());

        dispatch({
          type: types.GET_TAB_CONTENT,
          payload: {
            id: response.data.id,
            number: response.data.number,
            date: response.data.date,
          },
          tab: 'edit',
          currentType: 'contracts',
        });

        dispatch({
          type: types.GET_TAB_CONTENT,
          payload: response.data.companies,
          tab: 'companies',
          currentType: 'contracts',
        });
      })
      .catch((error) => {
        dispatch(unsetLoading());
        checkError(error, dispatch, 'entityDetails', 'tab');
      });
  };
};

export const addUserToCompany =
  (payload: AdminRelationsApi.addUserToCompany.Request.Body) => (dispatch: ThunkDispatch) => {
    AdminRelationsService.addUserToCompany(payload)
      .then(() => {
        message.success('Пользователь прикреплён к компании');
        dispatch(getCompanyById(payload.companyId));
      })
      .catch((error) => {
        checkError(error, dispatch, 'entityDetails', 'addRelation');
      });
  };

export const removeUserFromCompany =
  (payload: AdminRelationsApi.removeUserFromCompany.Request.Body) => (dispatch: ThunkDispatch) => {
    AdminRelationsService.removeUserFromCompany(payload)
      .then(() => {
        message.success('Пользователь откреплён от компании');
        dispatch(getCompanyById(payload.companyId));
      })
      .catch((error) => {
        checkError(error, dispatch, 'entityDetails', 'addRelation');
      });
  };

export const getTabContent = (currentType: string, id: string, tab: string, page?: string, search = '') => {
  if (!tab) {
    // eslint-disable-next-line no-param-reassign
    tab = 'edit';
  }
  return (dispatch: AppDispatch) => {
    dispatch({ type: types.LOADING_TAB_CONTENT });
    axios
      .get(`${ROOT_URL}/v1/admin/${currentType}/${id}/${tab}${search}`, {
        params: {
          page,
        },
        headers: {
          Authorization: `Bearer  ${localStorage.getItem(JWT_ACCESS)}`,
        },
      })
      .then((response) => {
        dispatch({ type: types.HIDE_TAB_CONTENT_LOADER });
        dispatch({
          type: types.GET_TAB_CONTENT,
          payload: response.data.data,
          tab,
          currentType,
        });
      })
      .catch((error) => {
        dispatch({ type: types.HIDE_TAB_CONTENT_LOADER });
        checkError(error, dispatch, 'entityDetails', 'tab');
      });
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onUpdateGeneral = (currentType: string, id: string, data: any, file: FileList) => {
  const bodyFormData = new FormData(data);

  file && bodyFormData.append('avatar', file[0]);
  bodyFormData.get('active')
    ? //@ts-expect-error
      bodyFormData.set('active', 1)
    : //@ts-expect-error
      bodyFormData.append('active', 0);

  return axios({
    method: 'POST',
    url: `${ROOT_URL}/admin/${currentType}/${id}`,
    data: bodyFormData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer  ${localStorage.getItem(JWT_ACCESS)}`,
    },
  })
    .then((response) => (dispatch: ThunkDispatch) => {
      dispatch({
        //@ts-expect-error
        type: types.VALID_FORM,
        payload: response.data.messages,
        currentType,
      });
      setTimeout(() => {
        dispatch({
          //@ts-expect-error
          type: types.CLEAR_VALID_FORM,
          payload: response.data.messages,
          currentType,
        });
      }, 1000);
    })
    .catch((error) => (dispatch: AppDispatch) => {
      dispatch({
        //@ts-expect-error
        type: types.INVALID_FORM,
        payload: error.response.data.data,
        currentType,
      });
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addRelation = (currentType: string, relation: string, id: string, data: any, revert: string) => {
  return (dispatch: ThunkDispatch) => {
    axios
      .post(
        `${ROOT_URL}/v1/admin/${relation}/${data.companyId}/${currentType}/${data.action}`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem(JWT_ACCESS)}`,
          },
        }
      )
      .then(() => {
        message.success('Связь успешно изменена.');
        if (revert) {
          dispatch(getTabContent(relation, id, currentType));
        } else {
          dispatch(getTabContent(currentType, id, relation));
        }
      })
      .catch((error) => {
        checkError(error, dispatch, 'entityDetails', 'addRelation');
      });
  };
};

export const removeElement = (currentType: string, id: string) => {
  return (dispatch: AppDispatch) => {
    axios
      .delete(`${ROOT_URL}/admin/${currentType}/${id}`, {
        headers: {
          Authorization: `Bearer  ${localStorage.getItem(JWT_ACCESS)}`,
        },
      })
      .then(() => {
        history.push(`/admin/${currentType}`);
        message.success('Ползователь удален!');
      })
      .catch((error) => {
        checkError(error, dispatch, 'entityDetails', 'removeElement');
      });
  };
};

export const removeRelation = (currentType: string, relation: string, userId: string, contractId: string) => {
  return (dispatch: ThunkDispatch) => {
    axios
      .post(
        `${ROOT_URL}/admin/assign/${currentType}/${relation}`,
        {
          ...{
            action: 'detach',
            [`${currentType}Id`]: userId,
            contractId,
          },
        },
        {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem(JWT_ACCESS)}`,
          },
        }
      )
      .then(() => {
        dispatch(
          getTabContent(
            currentType,
            // formDataObj[`${currentType}Id`], // todo Непонятно что и где это вообще используется, пока оставляю так :)
            //@ts-expect-error
            {},
            relation
          )
        );
      })
      .catch((error) => {
        checkError(error, dispatch, 'entityDetails', 'removeRelation');
      });
  };
};

export const loginWithUser = (id: string) => {
  return (dispatch: AppDispatch) => {
    axios
      .get(`${ROOT_URL}/admin/auth/${id}`, {
        headers: {
          Authorization: `Bearer  ${localStorage.getItem(JWT_ACCESS)}`,
        },
      })
      .then((response) => {
        //@ts-expect-error
        localStorage.setItem(AD_JWT_ACCESS, localStorage.getItem(JWT_ACCESS));
        localStorage.setItem(JWT_ACCESS, response.data.data.access_token);
        window.location.href = '/';
      })
      .catch((error) => {
        checkError(error, dispatch, 'entityDetails', 'withUser');
      });
  };
};

export const restoreUserPassword = (id: string) => {
  return (dispatch: AppDispatch) => {
    axios
      .post(
        `${ROOT_URL}/admin/restore`,
        { id },
        {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem(JWT_ACCESS)}`,
          },
        }
      )
      .then(() => {
        message.success('На почту пользователя отправлено письмо!');
      })
      .catch((error) => {
        checkError(error, dispatch, 'entityDetails', 'restorePassword');
      });
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const changeUserVisible = (companyId: string, visible: any, userId: string) => {
  return (dispatch: ThunkDispatch) => {
    dispatch({ type: types.LOADING_TAB_CONTENT });
    axios
      .post(
        `${ROOT_URL}/v1/admin/companies/${companyId}/me/visible`,
        { visible },
        {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem(JWT_ACCESS)}`,
          },
        }
      )
      .then(() => {
        message.success('Видимость пользователя успешно изменена');
        // console.log(userId);
        dispatch({ type: types.HIDE_TAB_CONTENT_LOADER });
        dispatch(getTabContent('users', userId, 'companies'));
      })
      .catch(() => {
        dispatch({ type: types.HIDE_TAB_CONTENT_LOADER });
        message.error('Не удалось изменить видимость пользователя');
      });
  };
};
