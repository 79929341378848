import { BannersSectionsEnum } from 'constants/banners';

export const RequestNamesEnum = {
  Name: 'name',
  Service: 'service',
  Email: 'email',
  Phone: 'phone',
  Company: 'company',
} as const;

export const ACTIVE_BANNERS_SECTIONS = Object.values(BannersSectionsEnum);
