import { useMediaQuery } from 'react-responsive';
import { useTheme } from './useTheme';
import { useMemo } from 'react';

export function useScreen() {
  const { breakpoints } = useTheme();

  const breakpointsLessThan = useMemo(
    () => ({
      xxl: breakpoints.xxl - 1,
      xl: breakpoints.xl - 1,
      lg: breakpoints.lg - 1,
      md: breakpoints.md - 1,
      sm: breakpoints.sm - 1,
    }),
    [breakpoints.lg, breakpoints.md, breakpoints.sm, breakpoints.xl, breakpoints.xxl]
  );

  const screens = {
    isSmallScreen: useMediaQuery({ maxWidth: breakpointsLessThan.md }),
    isMediumScreen: useMediaQuery({
      minWidth: breakpoints.md,
      maxWidth: breakpointsLessThan.lg,
    }),
    isLargeScreen: useMediaQuery({
      minWidth: breakpoints.lg,
      maxWidth: breakpointsLessThan.xl,
    }),
    isExtraLargeScreen: useMediaQuery({
      minWidth: breakpoints.xl,
      maxWidth: breakpointsLessThan.xxl,
    }),
    isXXLScreen: useMediaQuery({
      minWidth: breakpoints.xxl,
    }),
  };

  const currentBreakpoint = useMemo(() => {
    let result: number = breakpoints.xxl;

    if (screens.isExtraLargeScreen) {
      result = breakpoints.xl;
    } else if (screens.isLargeScreen) {
      result = breakpoints.lg;
    } else if (screens.isMediumScreen) {
      result = breakpoints.md;
    } else if (screens.isSmallScreen) {
      result = breakpoints.sm;
    }

    return result;
  }, [
    breakpoints.lg,
    breakpoints.md,
    breakpoints.sm,
    breakpoints.xl,
    breakpoints.xxl,
    screens.isExtraLargeScreen,
    screens.isLargeScreen,
    screens.isMediumScreen,
    screens.isSmallScreen,
  ]);

  const isScreenLessThan = {
    isScreenLessThanXxl: useMediaQuery({
      maxWidth: breakpointsLessThan.xxl,
    }),
    isScreenLessThanXl: useMediaQuery({
      maxWidth: breakpointsLessThan.xl,
    }),
    isScreenLessThanLg: useMediaQuery({
      maxWidth: breakpointsLessThan.lg,
    }),
    isScreenLessThanMd: useMediaQuery({
      maxWidth: breakpointsLessThan.md,
    }),
    isScreenLessThanSm: useMediaQuery({
      maxWidth: breakpointsLessThan.sm,
    }),
  };

  const isScreenMoreThan = {
    isScreenMoreThanXxl: useMediaQuery({
      minWidth: breakpoints.xxl,
    }),
    isScreenMoreThanXl: useMediaQuery({
      minWidth: breakpoints.xl,
    }),
    isScreenMoreThanLg: useMediaQuery({
      minWidth: breakpoints.lg,
    }),
    isScreenMoreThanMd: useMediaQuery({
      minWidth: breakpoints.md,
    }),
    isScreenMoreThanSm: useMediaQuery({
      minWidth: breakpoints.sm,
    }),
  };

  return {
    ...screens,
    currentBreakpoint,
    ...isScreenLessThan,
    ...isScreenMoreThan,
  };
}
