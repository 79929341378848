import { z } from 'zod';
import { FixationMethodsEnum, TimeLowerCaseTypeEnum } from 'constants/metalSale';

export const FixSellPriceEntity = z.object({
  metalPrice: z
    .object({
      date: z.string(),
      type: TimeLowerCaseTypeEnum,
    })
    .or(z.null()),
  currencyPrice: z
    .object({
      date: z.string(),
    })
    .or(z.null()),
  type: FixationMethodsEnum,
});

export type FixSellPriceEntity = z.infer<typeof FixSellPriceEntity>;

export const SpotSellPriceEntity = z.object({
  metalPrice: z.number().or(z.null()),
  currencyPrice: z.number().or(z.null()),
  type: FixationMethodsEnum,
});

export type SpotSellPriceEntity = z.infer<typeof SpotSellPriceEntity>;
