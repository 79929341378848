import styled from 'styled-components';
import { Form as InnerForm } from 'antd';
import { Field } from 'features/Login/components/Field/Field.styled';

export const RestorePasswordForm = styled(InnerForm)`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.space[16]};

  &&& .ant-form-item {
    margin: 0;
  }

  ${Field} {
    margin-bottom: ${({ theme }) => theme.space[2]};
  }
`;
