import { BRAND, z } from 'zod';
import { createHttpError, HttpErrorSchema } from './httpError';
import { HttpErrorCodesEnum } from './types';
import i18n from 'i18next';
import { isError } from './error';

export const AccessDeniedErrorSchema = z
  .object({
    code: z.literal(HttpErrorCodesEnum.AccessDenied),
  })
  .merge(HttpErrorSchema)
  .brand<'AccessDenied'>();

export type AccessDeniedError = z.infer<typeof AccessDeniedErrorSchema>;

export function createAccessDeniedError(message?: string, data?: unknown): AccessDeniedError {
  return {
    ...createHttpError(
      HttpErrorCodesEnum.AccessDenied,
      message || i18n.t('error.Нет доступа к разделу'),
      data,
      undefined,
      !!message
    ),
    [BRAND]: {
      AccessDenied: true,
    },
  };
}

export const isAccessDeniedError = isError(AccessDeniedErrorSchema);
