import { Button } from 'antd';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';

export function Subsection({ link, title }) {
  const match = useRouteMatch({ path: link, exact: true });
  const history = useHistory();
  return (
    <Button
      onClick={() => history.push(link)}
      type={match ? 'primary' : 'default'}
    >
      {title}
    </Button>
  );
}
