import { createAsyncThunk } from '@reduxjs/toolkit';
import * as dashboardService from './service';

/* 
    Так как список партий и договоров для фильтров апдейтятся только раз, 
    необходим отдельный ключ isIniting
*/

export const fetchDashboard = createAsyncThunk('dashboard/fetchDashboard', async ({ search, isIniting }) => {
  const { data } = await dashboardService.getMetalsData(search);
  const { data: filtersData } = await dashboardService.getMetalsData('');

  return { charts: { ...data, filters: filtersData.filters }, isIniting };
});
