import { isEmpty, uniq } from 'lodash-es';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type UsePasswordOutput = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checkPasswordValidationRules: () => void;
};

const initialErrors = {
  digit: false,
  letter: false,
  symbol: false,
  upperAndLowerCase: false,
  space: true,
  ruLetter: true,
};

export function usePassword(): UsePasswordOutput {
  const { t } = useTranslation();
  const [passwordErrors, setPasswordErrors] = useState(initialErrors);

  const errorText = {
    digit: t('error.Минимум одно число'),
    letter: t('error.Минимум одна буква'),
    symbol: t('error.Минимум один символ'),
    upperAndLowerCase: t('error.Минимум одна заглавная и одна строчная буква'),
    space: t('error.Пароль не может содержать пробел'),
    ruLetter: t('error.Пароль не может содержать символы кириллицы'),
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regExpDigits = new RegExp(/\d/);
    const regExpUpperCase = new RegExp(/[A-Z]/);
    const regExpLowerCase = new RegExp(/[a-z]/);
    const regExpSymbols = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/);
    const hasLetter = regExpUpperCase.test(e.target.value) || regExpLowerCase.test(e.target.value);
    const regExpRuLetters = new RegExp(/[а-яА-ЯёЁ]/);

    setPasswordErrors({
      digit: regExpDigits.test(e.target.value),
      letter: hasLetter,
      symbol: regExpSymbols.test(e.target.value),
      upperAndLowerCase: regExpUpperCase.test(e.target.value) && regExpLowerCase.test(e.target.value),
      space: !e.target.value.includes(' '),
      ruLetter: !regExpRuLetters.test(e.target.value),
    });
  };

  const checkPasswordValidationRules = () => ({
    validator() {
      const errors = Object.entries(passwordErrors)
        .filter(([, value]) => !value)
        .map(([key]) => errorText[key as keyof typeof errorText]);

      if (!isEmpty(errors)) {
        return Promise.reject(uniq(errors));
      }

      return Promise.resolve();
    },
  });
  return {
    onChange,
    checkPasswordValidationRules,
  };
}
