import styled, { DefaultTheme } from 'styled-components';
import { Modal as InnerModal } from 'antd';
import { space } from 'styled-system';
import { BannersSection, BannersSectionsEnum } from '../../constants/banners';

const getPaddingBySection = (
  theme: DefaultTheme,
  section: BannersSection | undefined,
  media: 'default' | 'lg' = 'default'
) => {
  if (!section) {
    return theme.space[40];
  }

  const map = {
    [BannersSectionsEnum.Balance]: {
      default: `${theme.space[40]} ${theme.space[40]} ${theme.space[24]}`,
      lg: `${theme.space[40]} ${theme.space[32]} ${theme.space[24]}`,
    },
    [BannersSectionsEnum.Finances]: {
      default: `${theme.space[32]} ${theme.space[32]} ${theme.space[8]}`,
      lg: `${theme.space[32]} ${theme.space[32]} ${theme.space[8]}`,
    },
    [BannersSectionsEnum.SearchDocuments]: {
      default: `${theme.space[40]} ${theme.space[40]} ${theme.space[0]}`,
      lg: `${theme.space[40]} ${theme.space[32]} ${theme.space[0]}`,
    },
    [BannersSectionsEnum.Orders]: {
      default: `${theme.space[32]} 64px ${theme.space[8]}`,
      lg: `${theme.space[32]} 64px ${theme.space[8]}`,
    },
  };

  return map[section][media];
};

export const BannerWrap = styled.div<{ section?: BannersSection }>`
  position: relative;
  background: ${({ theme }) => theme.colors['grey-7']};
  padding: ${({ section, theme }) => getPaddingBySection(theme, section)};
  ${space}

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
    padding: ${({ section, theme }) => getPaddingBySection(theme, section, 'lg')};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    height: auto;
  }

  & .ant-carousel .slick-dots-bottom {
    right: ${({ theme }) => theme.space[18]};
    left: auto;
    top: auto;
    bottom: 20px;
    margin: 0;
    padding: 0;
  }

  & .ant-carousel .slick-dots li {
    width: auto;
    height: auto;
    margin: 0 ${({ theme }) => theme.space[6]};
  }

  & .ant-carousel .slick-dots li button {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    border: 1px solid;
    border-color: ${({ theme }) => theme.colors['grey-5']};
    opacity: 1;
    background: transparent;

    &:hover {
      opacity: 0.68;
    }
  }

  & .ant-carousel .slick-dots li.slick-active button {
    background: ${({ theme }) => theme.colors['grey-5']};
  }
`;

export const RequestModal = styled(InnerModal)`
  &&& {
    width: 504px !important;

    .ant-modal-content {
      border-radius: 3px;
      border-top: 5px solid ${({ theme }) => theme.colors.orange};
    }

    .ant-modal-body {
      padding: 60px 56px 78px;
    }
  }
`;

export const RequestTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['24']};
  line-height: ${({ theme }) => theme.lineHeights['32']};
  margin-bottom: ${({ theme }) => theme.space['8']};
  color: ${({ theme }) => theme.colors['grey-2']};
`;

export const RequestDescription = styled.p`
  font-size: ${({ theme }) => theme.fontSizes['16']};
  line-height: 1.5;
  margin: 0 0 ${({ theme }) => theme.space['24']};
  color: ${({ theme }) => theme.colors['grey-5']};
`;

export const RequestPolicy = styled.p`
  font-size: ${({ theme }) => theme.fontSizes['12']};
  line-height: 1.5;
  margin: ${({ theme }) => theme.space['8']} 0 0;
  color: ${({ theme }) => theme.colors['grey-3']};
`;

export const Close = styled.i`
  display: flex;
  position: absolute;
  top: 60px;
  right: 60px;
  cursor: pointer;
  padding: 10px;

  &:hover {
    opacity: 0.6;
  }

  & > svg {
    width: 14px;
    height: 14px;

    path {
      fill: ${({ theme }) => theme.colors['grey-8']};
    }
  }
`;
